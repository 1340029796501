import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import PaymentInfo from './PaymentInfo';
import UserInfo from './UserInfo';
import Reward from './Reward';
import AgentEnforcementProceedingsTable from '../Agent/AgentEnforcementProceedings/AgentEnforcementProceedingsTable';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    document.title = 'Платежная информация';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
  }

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    window.history.replaceState(null, null, `?activeTab=${tab}`);
  }

  render() {
    const { activeTab } = this.state;
    const {
      location, history, userInfo,
    } = this.props;
    return (
      <div className="position-relative">
        <h1 className="page-wrapper__title d-inline-flex mr-3">Личные данные</h1>
        {activeTab === '2' && (
          <div className="d-inline-flex">
            <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
              <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
              Скачать в Excel
            </Button>
          </div>
        )}
        <TabNavList>
          <TabNavItem
            active={activeTab === '1'}
            onClick={() => { this.toggle('1'); }}
            text="Краткая информация"
          />
          {userInfo.confirmed !== 'no_need' ? (
            <TabNavItem
              active={activeTab === '3'}
              onClick={() => { this.toggle('3'); }}
              text="Платежная информация"
            />
          ) : null}
          {userInfo.hide_commission === 0 && (
            <TabNavItem
              active={activeTab === '2'}
              onClick={() => { this.toggle('2'); }}
              text="Настройки вознаграждения"
            />
          )}
          <TabNavItem
            active={activeTab === '4'}
            onClick={() => { this.toggle('4'); }}
            text="Исполнительное производство"
          />
        </TabNavList>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" ref={activeTab === '1' ? this.child : undefined}>
            { activeTab === '1' ? <UserInfo tab="1" userInfo={userInfo} location={location} /> : null }
          </TabPane>
          {userInfo.confirmed !== 'no_need' ? (
            <TabPane tabId="3" ref={activeTab === '3' ? this.child : undefined}>
              { activeTab === '3' ? <PaymentInfo tab="3" location={location} userInfo={userInfo} /> : null }
            </TabPane>
          ) : null}
          {userInfo.hide_commission === 0 && (
            <TabPane tabId="2">
              { activeTab === '2' ? <Reward tab="2" location={location} userId={userInfo.id} ref={activeTab === '2' ? this.child : undefined} /> : null }
            </TabPane>
          )}
          <TabPane tabId="4">
            { activeTab === '4' ? <AgentEnforcementProceedingsTable history={history} tab={activeTab} /> : null }
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default withUserInfoStoreStore(Profile);
