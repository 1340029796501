import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';
import { ReactDadata } from 'react-dadata';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import {
  faEye, faEyeSlash,
} from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Countdown from 'react-countdown-now';
import queryString from 'query-string';
import { switchLanguage, customSelectStyle } from '../Utils/Helpers';
import getValidation from '../Utils/Validation';
import getMask from '../Utils/Masks';
import AuthService from '../Utils/AuthService';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

const flipBehavior = ['right', 'top', 'bottom'];
const agentTypeOptions = window.location.host.split('.')[0] === 'samoletplus' ? [
  { value: 'individual', label: 'Индивидуальный предприниматель' },
  { value: 'legal', label: 'Юр. лицо' },
] : [
  { value: 'natural', label: 'Физ. лицо' },
  { value: 'self_employed', label: 'Физ. лицо (самозанятый)' },
  { value: 'individual', label: 'Индивидуальный предприниматель' },
  { value: 'legal', label: 'Юр. лицо' },
];

class RegistrationCompleteForm extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
    this.state = {
      offer: '',
      verificationPhone: false,
      showCountdown: false,
      privacyPolicy: '',
      loading: false,
      showFormPhoneVerification: false,
      passwordInputType: 'password',
      formFields: {
        lastName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        firstName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        middleName: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'name',
        },
        officeNumber: {
          value: null,
          errorMessage: '',
          errorVisible: false,
          validationType: window.location.host.split('.')[0] === 'samoletplus' ? 'intNumber' : 'none',
        },
        phone: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'phone',
        },
        email: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'email',
        },
        region: {
          value: {
            value: '',
            data: {},
          },
          errorMessage: '',
          errorVisible: false,
          validationType: 'city',
        },
        agentType: {
          value: window.location.host.split('.')[0] === 'samoletplus' ? { value: 'legal', label: 'Юр. лицо' } : { value: 'self_employed', label: 'Физ. лицо (самозанятый)' },
          errorMessage: '',
          errorVisible: false,
          validationType: 'select',
        },
        agentSalesVolume: {
          value: null,
          errorMessage: '',
          errorVisible: false,
          validationType: 'select',
        },
        smsCode: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'smsCode',
        },
        password: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'password',
        },
        passwordSubmit: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'passwordSubmit',
        },
        personalData: {
          value: false,
          errorMessage: '',
          errorVisible: false,
          validationType: 'checkbox',
        },
      },
    };
  }

  componentDidMount() {
    const { formFields } = this.state;
    fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/offer_for_${formFields.agentType.value.value}`, {
      method: 'get',
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ offer: response.path });
      })
      .catch(() => {
      });
    fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/privacy_policy`, {
      method: 'get',
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ privacyPolicy: response.path });
      });
  }

  handleChangeInput = (e, translate = false, upperCase = false) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (translate) {
      value = switchLanguage(e.target.value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formFields } = this.state;
    formFields[e.target.id].value = value;
    if (formFields[e.target.id].errorVisible) {
      formFields[e.target.id].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeAddress = (e) => {
    const { formFields } = this.state;
    formFields.region.value = e;
    if (formFields.region.errorVisible) {
      formFields.region.errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeSelect = (event, actionMeta) => {
    const { formFields } = this.state;
    if (formFields[actionMeta.name].errorVisible) {
      formFields[actionMeta.name].errorVisible = false;
    }

    if (actionMeta.name === 'agentType') {
      let agentType = 'natural';
      if (event.value === 'self_employed') {
        agentType = 'self_employed';
      }
      fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/offer_for_${agentType}`, {
        method: 'get',
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({ offer: response.path });
        })
        .catch(() => {
        });
    }
    this.setState({
      formFields: {
        ...formFields,
        [actionMeta.name]: {
          ...formFields[actionMeta.name],
          value: event,
        },
      },
    });
  };

  handleChangeSmsCode = (e) => {
    const { formFields } = this.state;
    const { value } = e.target;
    if (formFields[e.target.id].errorVisible) {
      formFields[e.target.id].errorVisible = false;
    }
    this.setState({
      formFields: {
        ...formFields,
        smsCode: {
          ...formFields.smsCode,
          value,
        },
      },
    }, () => {
      if (value.length === 4) {
        this.handleFormPhoneVerificationSubmit();
      }
    });
  };

  handleFormRegistrationSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const validationSuccess = this.validateAllFields();
    if (validationSuccess) {
      this.sendSms();
    } else {
      this.setState({ loading: false });
    }
  };

  validateAllFields = () => {
    const { formFields } = this.state;
    let validationSuccess = true;
    Object.keys(formFields).forEach((field) => {
      let value;
      if (formFields[field].validationType === 'select') {
        value = formFields[field].value ? formFields[field].value.value : null;
      } else if (formFields[field].validationType === 'passwordSubmit') {
        value = { passwordSubmit: formFields.passwordSubmit.value, password: formFields.password.value };
      } else {
        value = formFields[field].value;
      }
      formFields[field].errorMessage = getValidation(value, formFields[field].validationType);
      if (formFields[field].errorMessage) {
        validationSuccess = false;
        formFields[field].errorVisible = true;
      }
    });
    this.setState({ formFields });
    return validationSuccess;
  };

  sendSms = (reset = false) => {
    const { formFields } = this.state;
    if (reset) {
      this.setState({
        formFields: {
          ...formFields,
          smsCode: {
            ...formFields.smsCode,
            value: '',
          },
        },
      });
    }
    fetch(`${process.env.REACT_APP_API_DOMAIN}/registration/sendSms`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: formFields.phone.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ loading: false });
        if (response === 'phone_already_exist') {
          this.setState({
            showFormPhoneVerification: false,
            formFields: {
              ...formFields,
              smsCode: {
                ...formFields.smsCode,
                value: '',
              },
              phone: {
                ...formFields.phone,
                errorMessage: 'Пользователь с данным номером уже зарегистрирован',
                errorVisible: true,
              },
            },
          });
        } else {
          this.setState({
            showFormPhoneVerification: true,
            showCountdown: true,
          });
        }
      })
      .catch(() => {
        const { showModalInfo } = this.context;
        showModalInfo('Произошла ошибка, повторите попытку.');
      });
  };

  handleFormPhoneVerificationSubmit = () => {
    const { formFields } = this.state;
    const { history } = this.props;
    const { showModalInfo } = this.context;
    this.setState({ verificationPhone: true });
    fetch(`${process.env.REACT_APP_API_DOMAIN}/registration/verifyPhone`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: formFields.phone.value,
        code: formFields.smsCode.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'verified') {
          const values = queryString.parse(window.location.search);
          const referral = values.ref ? values.ref : '';
          const domain = window.location.host.split('.')[0];
          fetch(`${process.env.REACT_APP_API_DOMAIN}/signup`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              first_name: formFields.firstName.value,
              last_name: formFields.lastName.value,
              middle_name: formFields.middleName.value,
              office_number: formFields.officeNumber.value,
              city: formFields.region.value.value,
              phone: formFields.phone.value,
              email: formFields.email.value,
              agent_type_code: formFields.agentType.value.value,
              sales_volume: formFields.agentSalesVolume.value.value,
              password: formFields.password.value,
              referral,
              domain,
            }),
          })
            .then((registrationResponse) => registrationResponse.json())
            .then((registrationResponse) => {
              if (registrationResponse === 'success') {
                this.Auth.login(formFields.email.value, formFields.password.value)
                  .then(() => {
                    const { from } = { from: { pathname: '/products' } };
                    history.replace(from);
                  })
                  .catch(() => {
                    showModalInfo('Неверный логин/пароль');
                  })
                  .finally(() => {
                    this.setState({ verificationPhone: false });
                  });
              } else if (registrationResponse === 'email_exists') {
                this.setState({
                  showFormPhoneVerification: false,
                  formFields: {
                    ...formFields,
                    smsCode: {
                      ...formFields.smsCode,
                      value: '',
                    },
                    email: {
                      ...formFields.email,
                      value: '',
                      errorMessage: 'Пользователь с данным email уже зарегистрирован',
                      errorVisible: true,
                    },
                  },
                });
              } else if (registrationResponse.error) {
                showModalInfo(registrationResponse.error);
              } else {
                showModalInfo('Произошла ошибка, повторите попытку.');
              }
            })
            .catch(() => {
              showModalInfo('Произошла ошибка, повторите попытку.');
            })
            .finally(() => {
              this.setState({ verificationPhone: false });
            });
        } else {
          this.setState({
            formFields: {
              ...formFields,
              smsCode: {
                ...formFields.smsCode,
                errorMessage: 'Вы ввели неправильный код',
                errorVisible: true,
              },
            },
          });
        }
      })
      .catch(() => {
        showModalInfo('Произошла ошибка, повторите попытку.');
      })
      .finally(() => {
        this.setState({ verificationPhone: false });
      });
  };

  handleValidate = (e, equalityField = null) => {
    const { formFields } = this.state;
    let { value } = e.target;
    if (equalityField) {
      value = { [e.target.id]: e.target.value, [equalityField]: formFields[equalityField].value };
    }

    Object.keys(formFields).forEach((field) => {
      formFields[field].errorVisible = false;
    });

    formFields[e.target.id].errorMessage = getValidation(value, formFields[e.target.id].validationType);

    if (formFields[e.target.id].errorMessage) {
      formFields[e.target.id].errorVisible = true;
    }
    this.setState({ formFields });
  };

  handleValidateSelect = (e) => {
    e.preventDefault();
    const { formFields } = this.state;
    const value = formFields[e.target.id].value ? formFields[e.target.id].value.value : null;
    Object.keys(formFields).forEach((field) => {
      formFields[field].errorVisible = false;
    });
    formFields[e.target.id].errorMessage = getValidation(value, formFields[e.target.id].validationType);
    if (formFields[e.target.id].errorMessage) {
      formFields[e.target.id].errorVisible = true;
    }

    this.setState({
      formFields: {
        ...formFields,
        [e.target.id]: {
          ...formFields[e.target.id],
          errorVisible: !!formFields[e.target.id].errorMessage,
        },
      },
    });
  };

  passwordShowHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { passwordInputType } = this.state;
    this.setState({
      passwordInputType: passwordInputType === 'input' ? 'password' : 'input',
    });
  };

  hideCountdown = () => {
    this.setState({ showCountdown: false });
  };

  render() {
    const {
      formFields,
      showFormPhoneVerification,
      passwordInputType,
      loading,
      offer,
      privacyPolicy,
      showCountdown,
      verificationPhone,
    } = this.state;

    const selfEmployedMotivation = window.location.host.split('.')[0] === 'samoletplus' ? '' : 'Для агентов, зарегистрированных  как самозанятые, доступны быстрые выплаты на карту и выше вознаграждение';

    const formRegistration = (
      <form className="form-signin text-center" onSubmit={this.handleFormRegistrationSubmit}>
        <h1 className="h3 mb-3 font-weight-normal">Регистрация</h1>
        <Tippy
          content={formFields.region.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.region.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <ReactDadata
              className={classnames('form-control mb-2', { error: formFields.region.errorVisible })}
              bounds="city"
              query={formFields.region.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={this.handleChangeAddress}
              placeholder="Регион регистрации"
            />
          </div>
        </Tippy>
        <Tippy
          content={formFields.lastName.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.lastName.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <InputMask
              formatChars={getMask('name').formatChars}
              value={formFields.lastName.value}
              maskChar=""
              className={classnames('form-control mb-2', { error: formFields.lastName.errorVisible })}
              placeholder="Фамилия"
              id="lastName"
              mask={getMask('name').mask}
              onBlur={this.handleValidate}
              onChange={(e) => this.handleChangeInput(e, true)}
            />
          </div>
        </Tippy>
        <Tippy
          content={formFields.firstName.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.firstName.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <InputMask
              formatChars={getMask('name').formatChars}
              value={formFields.firstName.value}
              maskChar=""
              className={classnames('form-control mb-2', { error: formFields.firstName.errorVisible })}
              placeholder="Имя"
              id="firstName"
              mask={getMask('name').mask}
              onBlur={this.handleValidate}
              onChange={(e) => this.handleChangeInput(e, true)}
            />
          </div>
        </Tippy>
        <Tippy
          content={formFields.middleName.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.middleName.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <InputMask
              formatChars={getMask('name').formatChars}
              value={formFields.middleName.value}
              maskChar=""
              className={classnames('form-control mb-2', { error: formFields.middleName.errorVisible })}
              placeholder="Отчество"
              id="middleName"
              mask={getMask('name').mask}
              onBlur={this.handleValidate}
              onChange={(e) => this.handleChangeInput(e, true)}
            />
          </div>
        </Tippy>
        <Tippy
          content={formFields.phone.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.phone.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <InputMask
              value={formFields.phone.value}
              className={classnames('form-control mb-2', { error: formFields.phone.errorVisible })}
              placeholder="Номер телефона"
              id="phone"
              name="phone"
              mask={getMask('phone').mask}
              formatChars={getMask('phone').formatChars}
              onBlur={this.handleValidate}
              onChange={this.handleChangeInput}
            />
          </div>
        </Tippy>
        <Tippy
          content={formFields.email.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.email.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <input
            className={classnames('form-control mb-2', { error: formFields.email.errorVisible })}
            value={formFields.email.value}
            type="text"
            id="email"
            name="email"
            placeholder="mail@gmail.com"
            onBlur={this.handleValidate}
            onChange={this.handleChangeInput}
          />
        </Tippy>
        <Tippy
          content={formFields.agentType.errorVisible ? formFields.agentType.errorMessage : selfEmployedMotivation}
          arrow
          placement="right"
          visible={(!!formFields.agentType.errorVisible || (formFields.agentType.value && formFields.agentType.value.value !== 'self_employed')) && selfEmployedMotivation}
          hideOnClick
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <Select
              styles={customSelectStyle()}
              name="agentType"
              isSearchable={false}
              placeholder="Выберите свой юр. статус"
              classNamePrefix="react-select"
              inputId="agentType"
              value={formFields.agentType.value}
              onChange={this.handleChangeSelect}
              onBlur={this.handleValidateSelect}
              blurInputOnSelect={false}
              className={classnames('form-control-custom mb-2', { error: formFields.agentType.errorVisible })}
              options={agentTypeOptions}
            />
          </div>
        </Tippy>
        <Tippy
          content={formFields.agentSalesVolume.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.agentSalesVolume.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <Select
              styles={customSelectStyle()}
              name="agentSalesVolume"
              isSearchable={false}
              placeholder="Выберите объем продаж"
              classNamePrefix="react-select"
              inputId="agentSalesVolume"
              value={formFields.agentSalesVolume.value}
              onChange={this.handleChangeSelect}
              onBlur={this.handleValidateSelect}
              blurInputOnSelect={false}
              className={classnames('form-control-custom mb-2', { error: formFields.agentSalesVolume.errorVisible })}
              options={[
                { value: '1', label: 'до 10' },
                { value: '2', label: 'до 50' },
                { value: '3', label: 'до 100' },
                { value: '4', label: '100+' },
              ]}
            />
          </div>
        </Tippy>
        {window.location.host.split('.')[0] === 'samoletplus' ? (
          <Tippy
            content={formFields.officeNumber.errorMessage}
            arrow
            placement="right"
            visible={!!formFields.officeNumber.errorVisible}
            popperOptions={{
              modifiers: [
                {
                  name: 'flip',
                  enabled: true,
                  options: {
                    fallbackPlacements: flipBehavior,
                  },
                },
              ],
            }}
            theme="translucent"
          >
            <div>
              <InputMask
                formatChars={getMask('officeNumber').formatChars}
                value={formFields.officeNumber.value}
                maskChar=""
                className={classnames('form-control mb-2', { error: formFields.officeNumber.errorVisible })}
                placeholder="Номер офиса"
                id="officeNumber"
                mask={getMask('officeNumber').mask}
                onBlur={this.handleValidate}
                onChange={(e) => this.handleChangeInput(e)}
              />
            </div>
          </Tippy>
        ) : null}
        <Tippy
          content={formFields.password.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.password.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <input
            id="password"
            className={classnames('form-control mb-2', { error: formFields.password.errorVisible })}
            placeholder="Придумайте пароль"
            value={formFields.password.value}
            type={passwordInputType}
            onBlur={this.handleValidate}
            onChange={this.handleChangeInput}
          />
        </Tippy>
        <FontAwesomeIcon className="password-show" onClick={this.passwordShowHide} icon={passwordInputType === 'input' ? faEyeSlash : faEye} />
        <Tippy
          visible={!!formFields.passwordSubmit.errorVisible}
          content={formFields.passwordSubmit.errorMessage}
          arrow
          placement="right"
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <input
            type={passwordInputType}
            value={formFields.passwordSubmit.value}
            id="passwordSubmit"
            className={classnames('form-control mb-2', { error: formFields.passwordSubmit.errorVisible })}
            placeholder="Подтвердите пароль"
            onBlur={(e) => this.handleValidate(e, 'password')}
            onChange={this.handleChangeInput}
          />
        </Tippy>
        <Tippy
          content={formFields.personalData.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.personalData.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div className={classnames('custom-checkbox form-check mb-2', { error: formFields.personalData.errorVisible })}>
            <input
              id="personalData"
              type="checkbox"
              className="checkbox-styled form-check-input"
              checked={formFields.personalData.value}
              onChange={this.handleChangeInput}
            />
            <label className="form-check-label" htmlFor="personalData">
              Я ознакомился с <a target="_blank" rel="noreferrer" className="dotted_link" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${offer}`}>Договором-Офертой</a> и принимаю ее условия, соглашаюсь на хранение и обработку <a className="dotted_link" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${privacyPolicy}`}>своих данных</a>
            </label>
          </div>
        </Tippy>
        {loading
          ? (<ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />)
          : (<button className="btn btn-lg btn-success btn-block" type="submit">Продолжить</button>)}
      </form>
    );
    const formPhoneVerification = (
      <form className="form-signin text-center" onSubmit={this.handleFormPhoneVerificationSubmit}>
        <h1 className="h3 mb-3 font-weight-normal">На указанный номер телефона выслан СМС-код, введите его</h1>
        <Tippy
          content={formFields.smsCode.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.smsCode.errorVisible}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <InputMask
              className={classnames('form-control mb-2', { error: formFields.smsCode.errorVisible })}
              disabled={verificationPhone}
              value={formFields.smsCode.value}
              type="text"
              onChange={this.handleChangeSmsCode}
              maskChar={null}
              name="smsCode"
              id="smsCode"
              mask={getMask('smsCode').mask}
              placeholder="SMS Код"
            />
          </div>
        </Tippy>
        {verificationPhone && (<ReactLoading className="loading-circle d-inline-block" type="spin" height={38} width={38} />)}
        {showCountdown
          ? (
            <Countdown
              date={Date.now() + 45000}
              renderer={(props) => <div>Отправить код повторно через {props.seconds} сек.</div>}
              onComplete={this.hideCountdown}
            />
          )
          : (<a role="button" tabIndex="0" onClick={() => this.sendSms(true)} className="dotted_link">Отправить СМС повторно</a>)}
      </form>
    );
    return (
      showFormPhoneVerification ? formPhoneVerification : formRegistration
    );
  }
}

RegistrationCompleteForm.contextType = ModalMessagesContext;

export default RegistrationCompleteForm;
