import React, {
  useContext,
  useState,
  forwardRef, useEffect,
} from 'react';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import { customFetch } from '../../Utils/Helpers';
import CustomContractsTable from './CustomContractsTable';
import CustomContractsForm from './CustomContractsForm';

function CustomContracts(props) {
  const {
    agent, updateAgent, tab, history,
  } = props;
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [uploadingContractFlag, setUploadingContractFlag] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [selectedContract, setSelectedContract] = useState(false);
  const [customContract, setCustomContract] = useState({
    name: '',
    file: null,
    path: '',
  });

  const uploadCustomContract = (e) => {
    const value = e.target.files[0];
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const formData = new FormData();
    formData.append('custom_contract', value);
    setUploadingContractFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/profile/update/documents?agentId=${agent.id}`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        setCustomContract({ name: value.name, file: value, path: '' });
      })
      .catch(() => showModalInfo('Ошибка', 'error'))
      .finally(() => {
        setUploadingContractFlag(false);
      });
  };

  const handleRemoveContract = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const formData = new FormData();
    formData.append('custom_contract', '');
    setUploadingContractFlag(true);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/profile/update/documents?agentId=${agent.id}`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        setCustomContract({ name: '', file: null, path: '' });
      })
      .catch(() => showModalInfo('Ошибка', 'error'))
      .finally(() => {
        setUploadingContractFlag(false);
      });
  };

  const handleChangeContractSigned = (e) => {
    e.preventDefault();
    if (!customContract.file && !customContract.path && e.target.checked) {
      showModalInfo('Загрузите лицензионный договор', 'error');
      return;
    }
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const updatedAgent = { ...agent };
    updatedAgent.license_contract_signed = e.target.checked;
    updateAgent(updatedAgent);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agent.id}`, {
      method: 'put',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(updatedAgent),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          showModalInfo('Данные обновлены');
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  useEffect(() => {
    if (customContract.file === null && agent.id) {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/documents?agentId=${agent.id}`, {
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((newDocuments) => {
          if (newDocuments !== 'not_found') {
            Object.keys(newDocuments).forEach((document) => {
              if (newDocuments[document] && document === 'custom_contract') {
                setCustomContract({
                  name: newDocuments[document].split('/').splice(-1)[0],
                  file: null,
                  path: newDocuments[document],
                });
              }
            });
          }
        })
        .catch(() => {
          showModalInfo('Ошибка загрузки договора');
        });
    }
  }, []);

  return (
    <>
      {showForm ? (
        <CustomContractsForm selectedContract={selectedContract} setSelectedContract={setSelectedContract} agent={agent} setShowForm={setShowForm} />
      ) : (
        <>
          <div className="card mb-3">
            <div className="card-body">
              {agent.full_time_employee !== true ? (
                <>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <div className="form-check custom-checkbox">
                        <input
                          onChange={(e) => handleChangeContractSigned(e)}
                          checked={agent.license_contract_signed ? agent.license_contract_signed : false}
                          className="form-check-input checkbox-styled"
                          type="checkbox"
                          name="contractSigned"
                          id="contractSigned"
                        />
                        <label className="form-check-label" htmlFor="contractSigned">
                          Лицензионный договор подписан
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3 col-sm-12">
                      {uploadingContractFlag ? (
                        <ReactLoading className="loading-circle d-inline-block" type="spin" height={38} width={38} />
                      ) : (
                        <>
                          <div className="jq-file styler">
                            <div className="jq-file__name">{customContract.name}</div>
                            {customContract.file || customContract.path
                              ? (<div onClick={handleRemoveContract} className="jq-file__remove" />)
                              : (<div className="jq-file__browse" />)}
                            <input onChange={uploadCustomContract} required type="file" id="customContract" name="customContract" className="custom-input-file" />
                          </div>
                          {customContract.path && (
                            <div className="jq-file__download">
                              <a rel="noopener noreferrer" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${customContract.path}`}><FontAwesomeIcon icon={faFileDownload} className="font-awesome-custom-icon fa-fw fa-lg" /></a>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <button type="button" className="btn btn-success mb-3" onClick={() => setShowForm(true)}>Добавить интеграцию</button>
          <CustomContractsTable setSelectedContract={setSelectedContract} setShowForm={setShowForm} history={history} agent={agent} tab={tab} />
        </>
      )}
    </>
  );
}

export default forwardRef(CustomContracts);
