import React, { useContext, useMemo, useState } from 'react';
import Select from 'react-select';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

function AddPullPolicies() {
  const { showModalInfo } = useContext(ModalMessagesContext);
  const customStyles = useMemo(() => customSelectStyle(), []);
  const [agentId, setAgentId] = useState('');
  const [agentName, setAgentName] = useState('');
  const [outerCommission, setOuterCommission] = useState(null);
  const [addToErp, setAddToErp] = useState(false);
  const [integration, setIntegration] = useState({ label: 'B2B', value: 'b2b' });
  const [policyType, setPolicyType] = useState({ label: 'Ипотека', value: 'mortgage' });
  const [outerCommissionSource, setOuterCommissionSource] = useState({ label: 'Из реестра', value: 'fromPull' });
  const [policyDocument, setPolicyDocument] = useState({
    name: '',
    file: null,
  });
  const policyTypeOptions = [
    {
      label: 'Ипотека',
      value: 'mortgage',
    },
    // {
    //   label: 'ОСАГО',
    //   value: 'osago',
    // },
    // {
    //   label: 'КАСКО',
    //   value: 'kasko'
    // },
    // {
    //   value: 'dago',
    //   label: 'ДАГО'
    // },
    // {
    //   value: 'vzr',
    //   label: 'ВЗР'
    // },
    // {
    //   value: 'ifl',
    //   label: 'ИФЛ'
    // },
    // {
    //   value: 'dmsStudent',
    //   label: 'ДМС'
    // },
    // {
    //   value: 'telemedicine',
    //   label: 'Телемедицина'
    // },
    // {
    //   value: 'ns',
    //   label: 'Несчастный случай'
    // },
    // {
    //   value: 'cargoInsurance',
    //   label: 'Страхование грузов'
    // },
    // {
    //   value: 'infullBroker',
    //   label: 'Услуга INFULL - Ипотечный Брокер'
    // },
    // {
    //   value: 'infullLizing',
    //   label: 'Услуга INFULL - Лизинг'
    // },
  ];
  const integrationOptions = [
    {
      label: 'B2B',
      value: 'b2b',
    },
    {
      label: 'Прямая',
      value: 'direct',
    },
    // {
    //   label: 'Сравни',
    //   value: 'sravni',
    // },
    // {
    //   label: 'Ингуру',
    //   value: 'inguru',
    // },
    // {
    //   label: 'Инсапп',
    //   value: 'insapp',
    // },
    // {
    //   label: 'Умный полис',
    //   value: 'smart',
    // },
    // {
    //   label: 'Азур',
    //   value: 'azure',
    // },
  ];
  const outerCommissionSourceOptions = [
    {
      label: 'Из реестра',
      value: 'fromPull',
    },
    {
      label: 'Заполнить на сайте',
      value: 'fromRequest',
    },
  ];
  const downloadAgentInfo = (e) => {
    e.preventDefault();
    if (agentId) {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agentId}`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error, 'error');
          } else if (response) {
            setAgentName(`${response.last_name} ${response.first_name} ${response.middle_name}`);
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        });
    }
  };
  const savePolicy = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const formData = new FormData();

    formData.append('policyDocument', policyDocument.file);
    formData.append('policyType', policyType.value);
    formData.append('addToErp', addToErp);
    formData.append('integration', integration.value);
    formData.append('agentId', agentId);
    formData.append('outerCommissionSource', outerCommissionSource.value);
    formData.append('outerCommission', outerCommission);
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/pull`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          showModalInfo(response.success, 'success');
        }
        if (response.error) {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };
  return (
    <>
      <h2>Загрузить реестр полисов в формате Эксель</h2>
      <form onSubmit={savePolicy}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="policyType">Выберите продукт</label>
                <Select
                  classNamePrefix="react-select"
                  inputId="policyType"
                  className="form-control-custom"
                  name="policyType"
                  placeholder="Тип"
                  noOptionsMessage={() => 'Не найдено'}
                  value={policyType}
                  styles={customStyles}
                  onChange={(e) => setPolicyType(e)}
                  options={policyTypeOptions}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="outerCommissionSource">Источник размера КВ агента 1-го уровня</label>
                <Select
                  classNamePrefix="react-select"
                  inputId="outerCommissionSource"
                  className="form-control-custom"
                  name="outerCommissionSource"
                  placeholder="Тип"
                  noOptionsMessage={() => 'Не найдено'}
                  value={outerCommissionSource}
                  styles={customStyles}
                  onChange={(e) => setOuterCommissionSource(e)}
                  options={outerCommissionSourceOptions}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="agentId">ID агента</label>
                <input
                  className="form-control"
                  required
                  value={agentId}
                  type="number"
                  id="agentId"
                  name="agentId"
                  placeholder="9999"
                  onBlur={downloadAgentInfo}
                  onChange={(e) => setAgentId(e.target.value)}
                />
                {agentName && (
                  <div className="validation-error">
                    {agentName}
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="integration">Выберите интеграцию</label>
                <Select
                  classNamePrefix="react-select"
                  inputId="integration"
                  className="form-control-custom"
                  name="integration"
                  placeholder="Тип"
                  noOptionsMessage={() => 'Не найдена'}
                  value={integration}
                  styles={customStyles}
                  onChange={(e) => setIntegration(e)}
                  options={integrationOptions}
                />
              </div>
              {outerCommissionSource.value === 'fromRequest' ? (
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="outerCommission">КВ агента 1-го уровня (%)</label>
                  <input
                    className="form-control"
                    value={outerCommission}
                    required={outerCommissionSource.value === 'fromRequest'}
                    type="number"
                    step="0.01"
                    max="100"
                    id="outerCommission"
                    name="outerCommission"
                    placeholder="9999"
                    onChange={(e) => setOuterCommission(e.target.value)}
                  />
                </div>
              ) : null}
            </div>
            <div className="form-group row">
              <div className="col-lg-6 col-sm-12">
                <label className="d-block" htmlFor="policyDocument">Реестр</label>
                <div className="jq-file styler">
                  <div className="jq-file__name">{policyDocument.name}</div>
                  {policyDocument.file
                    ? (<div onClick={() => setPolicyDocument({ name: '', file: null })} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input required onChange={(e) => setPolicyDocument({ name: e.target.files[0].name, file: e.target.files[0] })} type="file" id="policyDocument" name="policyDocument" className="custom-input-file" />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <div className="form-check custom-checkbox">
                  <input
                    onChange={(e) => setAddToErp(e.target.checked)}
                    checked={addToErp}
                    className="form-check-input checkbox-styled"
                    type="checkbox"
                    name="addToErp"
                    id="addToErp"
                  />
                  <label className="form-check-label pl-2" htmlFor="addToErp">
                    Добавить полис в ERP
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success">Добавить</button>
      </form>
    </>
  );
}

export default AddPullPolicies;
