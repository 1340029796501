import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { reactLocalStorage } from 'reactjs-localstorage';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import ReactExport from 'react-data-export';
import queryString from 'query-string';
import {
  toLocalDate, customFetch, setTableSorting, setTableSettings7, getDefaultSorting7, downloadReport,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import Table from '../Layout/Table';

let abortController = new AbortController();

class WidgetsTable extends Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.reactTableRef = React.createRef();
    this.state = {
      excelcolumns: [],
      filtered: [],
      dataSet: [],
      columns: [],
      widgetsList: [],
      navigateTo: '',
      loading: false,
      openModalTableCustomized: false,
      theme: window.location.host.split('.')[0],
      defaultSorted: [{
        id: 'updated_at',
        desc: 'true',
      }],
    };
  }

  componentDidMount() {
    const { location, tab } = this.props;
    const { filtered, defaultSorted } = this.state;
    const urlParams = queryString.parse(location.search);
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
    this.updateTableColumns();
    this.loadWidgets();
    const newDefaultSorted = getDefaultSorting7('widgetListSettings', defaultSorted);
    this.setState({ defaultSorted: newDefaultSorted });
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModalTableCustomized } = this.state;
    const { agentId } = this.props;
    if (openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized) {
      this.updateTableColumns();
    }
    if (prevProps.agentId !== agentId) {
      this.loadWidgets();
    }
  }

  updateTableColumns = () => {
    const { show } = this.props;
    const columns = [{
      Header: '№',
      accessor: 'id',
      minWidth: 70,
      maxWidth: 100,
      width: reactLocalStorage.getObject('widgetListSettings').id,
      show: reactLocalStorage.getObject('widgetListSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Тип виджета',
      accessor: 'type_insurance',
      minWidth: 150,
      width: reactLocalStorage.getObject('widgetListSettings').type_insurance,
      show: reactLocalStorage.getObject('widgetListSettings').show_type_insurance !== false,
      showCustomized: true,
    }, {
      Header: 'Наименование',
      accessor: 'name',
      minWidth: 150,
      width: reactLocalStorage.getObject('widgetListSettings').name,
      show: reactLocalStorage.getObject('widgetListSettings').show_name !== false,
      showCustomized: true,
    }, {
      Header: 'ID агента',
      id: 'agent_id',
      accessor: (d) => (d.agent ? d.agent.id : 0),
      minWidth: 150,
      width: reactLocalStorage.getObject('widgetListSettings').agent_id,
      show: reactLocalStorage.getObject('widgetListSettings').show_agent_id !== false && (show === 'all' || show === 'subagents'),
      showCustomized: show === 'all' || show === 'subagents',
    }, {
      Header: 'Агент',
      accessor: 'user_name',
      minWidth: 150,
      width: reactLocalStorage.getObject('widgetListSettings').user_name,
      show: reactLocalStorage.getObject('widgetListSettings').show_user_name !== false && (show === 'all' || show === 'subagents'),
      showCustomized: show === 'all' || show === 'subagents',
    }, {
      Header: 'Дата создания',
      id: 'created_at',
      minWidth: 150,
      width: reactLocalStorage.getObject('widgetListSettings').created_at,
      accessor: (d) => format(toLocalDate(parseISO(d.created_at)), 'yyyy-MM-dd, HH:mm:ss'),
      disableFilters: true,
      show: reactLocalStorage.getObject('widgetListSettings').show_created_at !== false,
      showCustomized: true,
    }, {
      Header: 'Дата изменения',
      id: 'updated_at',
      minWidth: 150,
      accessor: (d) => format(toLocalDate(parseISO(d.updated_at)), 'yyyy-MM-dd, HH:mm:ss'),
      width: reactLocalStorage.getObject('widgetListSettings').updated_at,
      disableFilters: true,
      show: reactLocalStorage.getObject('widgetListSettings').show_updated_at !== false,
      showCustomized: true,
    }, {
      Header: 'Адреса страниц',
      id: 'urlsString',
      minWidth: 150,
      accessor: (d) => (d.widget_urls[0] ? d.widget_urls[0].hosts : ''),
      width: reactLocalStorage.getObject('widgetListSettings').urlsString,
      show: reactLocalStorage.getObject('widgetListSettings').show_urlsString !== false,
      showCustomized: true,
    }, {
      Header: 'Показы',
      id: 'loaded',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.loaded : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').loaded,
      show: reactLocalStorage.getObject('widgetListSettings').show_loaded !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Взаимодействия',
      id: 'started',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.started : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').started,
      show: reactLocalStorage.getObject('widgetListSettings').show_started !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Попытка расчёта',
      id: 'searching',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.searching : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').searching,
      show: reactLocalStorage.getObject('widgetListSettings').show_searching !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Успешные расчёты/Получен результат расчета КБМ',
      id: 'calculated',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.calculated : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').calculated,
      show: reactLocalStorage.getObject('widgetListSettings').show_calculated !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Выбор варианта/Переход к оформлению ДКП/Отправлен запрос на восстановление КБМ',
      id: 'choosing',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.choosing : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').choosing,
      show: reactLocalStorage.getObject('widgetListSettings').show_choosing !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Заявки на менеджера',
      id: 'offline',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.offline : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').offline,
      show: reactLocalStorage.getObject('widgetListSettings').show_offline !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Нажатие на кнопку купить/Переход к оформлению ОСАГО',
      id: 'buying',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.buying : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').buying,
      show: reactLocalStorage.getObject('widgetListSettings').show_buying !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Продажи/Оформление ДКП/КБМ восстановлен',
      id: 'real_buying',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.real_buying : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').real_buying,
      show: reactLocalStorage.getObject('widgetListSettings').show_real_buying !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Конверсия в заявки %',
      id: 'requestConversion',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.requestConversion : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').requestConversion,
      show: reactLocalStorage.getObject('widgetListSettings').show_requestConversion !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: 'Конверсия в продажи %',
      id: 'realBuyingConversion',
      accessor: (d) => (d.widget_analytics_total ? d.widget_analytics_total.realBuyingConversion : 0),
      minWidth: 70,
      width: reactLocalStorage.getObject('widgetListSettings').realBuyingConversion,
      show: reactLocalStorage.getObject('widgetListSettings').show_realBuyingConversion !== false,
      Filter: 'sliderColumnFilter',
      filter: 'filterGreaterThan',
      showCustomized: true,
    }, {
      Header: '', // No header
      id: 'delete',
      width: 40,
      disableFilters: true,
      show: true,
      Cell: ({ row }) => (
        <div title="Удалить виджет"><FontAwesomeIcon onClick={() => this.deleteWidget(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
      clickable: true,
    }];
    this.setState({ columns });
  };

  loadWidgets = () => {
    this.setState({ loading: true });
    abortController.abort();
    abortController = new AbortController();
    const { show } = this.props;
    const agent = this.props.agentId ? `&agentId=${this.props.agentId}` : '';
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets-list?show=${show}${agent}`, {
      signal: abortController.signal,
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((widgets) => {
        widgets.forEach((widget) => {
          widget.type_insurance = widget.policy_type.name;
          widget.active = 'Активный';
          if (widget.user) {
            widget.user_name = `${widget.agent.last_name} ${widget.agent.first_name} ${widget.agent.middle_name}`;
          }
          if (widget.widget_analytics_total) {
            widget.widget_analytics_total.requestConversion = 0;
            widget.widget_analytics_total.realBuyingConversion = 0;
            if (widget.widget_analytics_total.started > 0) {
              widget.widget_analytics_total.requestConversion = Math.round((widget.widget_analytics_total.offline / widget.widget_analytics_total.started) * 100);
              widget.widget_analytics_total.realBuyingConversion = Math.round((widget.widget_analytics_total.real_buying / widget.widget_analytics_total.started) * 100);
            }
            if (widget.policy_type.code === 'greenCard') {
              widget.widget_analytics_total.requestConversion = '-';
              widget.widget_analytics_total.offline = '-';
              widget.widget_analytics_total.searching = '-';
            }
            if (widget.policy_type.code === 'dkp') {
              widget.widget_analytics_total.requestConversion = '-';
              widget.widget_analytics_total.offline = '-';
              widget.widget_analytics_total.searching = '-';
              widget.widget_analytics_total.calculated = '-';
              widget.widget_analytics_total.buying = '-';
              widget.widget_analytics_total.realBuyingConversion = '-';
            }
            if (widget.policy_type.code === 'kbm') {
              widget.widget_analytics_total.requestConversion = '-';
              widget.widget_analytics_total.offline = '-';
              widget.widget_analytics_total.searching = '-';
              widget.widget_analytics_total.realBuyingConversion = '-';
            }
            if (widget.policy_type.code === 'dmsStudent') {
              widget.widget_analytics_total.requestConversion = '-';
              widget.widget_analytics_total.offline = '-';
              widget.widget_analytics_total.searching = '-';
            }
          }
        });
        this.setState({ widgetsList: widgets });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onFilteredChangeCustom = (filtered) => {
    this.setState({ filtered });
    const { tab } = this.props;
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  deleteWidget = (id) => {
    const { showModalInfo } = this.context;
    this.setState({ loading: true, widgetsList: [] }, () => {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget/${id}`, {
        method: 'delete',
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            this.loadWidgets();
          } else {
            showModalInfo('Ошибка');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка');
        });
    });
  };

  downloadReport = () => {
    const { columns } = this.state;
    downloadReport(this.reactTableRef, columns, [], this.setData);
  };

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  handleTableCustomizedSetAll = () => {
    this.simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  handleTableCustomizedSetNone = () => {
    this.simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  setData = (excelcolumns, dataSet) => {
    this.setState({ excelcolumns, dataSet }, function () {
      this.excelFile.handleDownload();
    });
  }

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columns = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('widgetListSettings');
    columns.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('widgetListSettings', settings);
    this.setState({ openModalTableCustomized: false });
  };

  onTableCellClick = (e, row, cell) => {
    e.preventDefault();
    if (!cell.column.clickable) {
      if (e.button === 0) {
        this.props.history.push(`/widgets/${row.original.id}`);
      }
      if (e.button === 1) {
        window.open(`/widgets/${row.original.id}`, '_blank');
      }
    }
  };

  render() {
    const {
      widgetsList,
      navigateTo,
      loading,
      openModalTableCustomized,
      theme,
      columns,
      dataSet,
      excelcolumns,
      defaultSorted,
    } = this.state;

    const customizedColumns = columns.map((column) => {
      const id = column.id ? column.id : column.accessor;
      if (column.showCustomized) {
        return (
          <li key={id} className="form-check">
            <input ref={this.simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
        );
      }
      return null;
    });
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;

    return (
      <>
        {navigateTo !== '' ? (
          <Redirect to={`/widgets/${navigateTo}`} />
        ) : (
          <>
            <Modal
              classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
              closeIconSize={16}
              open={openModalTableCustomized}
              onClose={this.onCloseModalTableCustomized}
              center
            >
              <h4>Настройка отображения таблицы</h4>
              <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
              <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
              <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
                <ul className="two-column-list">
                  {customizedColumns}
                </ul>
                <button type="submit" className={`btn btn-success ${theme}`}>Сохранить</button>
              </form>
            </Modal>
            <div className="d-none">
              <ExcelFile ref={(f) => this.excelFile = f} element={<button type="button">Download Data</button>}>
                <ExcelSheet data={dataSet} name="Employees">
                  {excelcolumns}
                </ExcelSheet>
              </ExcelFile>
            </div>
            <Table
              ref={this.reactTableRef}
              data={widgetsList}
              columns={columns}
              onTableCellClick={this.onTableCellClick}
              loading={loading}
              noDataText="Виджеты не найдены"
              defaultFiltered={this.state.filtered}
              defaultSorted={defaultSorted}
              onSortedChange={(newSort) => {
                setTableSorting(newSort, 'widgetListSettings');
              }}
              onResizedChange={(newResized) => {
                setTableSettings7(newResized, 'widgetListSettings');
              }}
              onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
            />
          </>
        )}
      </>
    );
  }
}

WidgetsTable.contextType = ModalMessagesContext;

export default WidgetsTable;
