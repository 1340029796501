import React, { Component } from 'react';
import queryString from 'query-string';
import { format, parse, parseISO } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import matchSorter from 'match-sorter';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import InputMask from 'react-input-mask';
import ReactTable from 'react-table-6';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import ReactLoading from 'react-loading';
import ReactExport from 'react-data-export';
import { customFetch } from '../Utils/Helpers';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class WidgetPolicesList extends Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.state = {
      dataSet: [],
      excelcolumns: [],
      filtered: [],
      requestsList: [],
      loading: true,
      startDate: null,
      endDate: null,
      activeFilterTab: '1',
      tableSettings: '',
      columns: [],
      openModalTableCustomized: false,
      defaultSortingField: 'created_at',
      defaultSortingOrder: 'true',
    };
  }

  componentDidMount() {
    const { widgetId, type, widgetType } = this.props;
    const typeName = type.charAt(0).toUpperCase() + type.slice(1);
    const widgetTypeName = widgetType.charAt(0).toUpperCase() + widgetType.slice(1);
    const tableSettings = `widget${typeName}${widgetTypeName}ListSettings`;

    let defaultSortingField = '';
    let defaultSortingOrder = '';
    if (reactLocalStorage.getObject(tableSettings).defaultSortingField) {
      defaultSortingField = reactLocalStorage.getObject(tableSettings).defaultSortingField;
      defaultSortingOrder = reactLocalStorage.getObject(tableSettings).defaultSortingOrder;
    }

    const { filtered } = this.state;
    const urlParams = queryString.parse(window.location.search);
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    let dateFrom = format(parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    let dateTo = format(parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    if (urlParams.dateTab) {
      this.setState({ activeFilterTab: urlParams.dateTab });
    } else {
      this.setState({ activeFilterTab: '1' });
    }
    Object.keys(urlParams).forEach((filter) => {
      if (filter === 'filter_date') {
        const startDate = urlParams[filter].split(' ')[0] !== 'null' ? parse(urlParams[filter].split(' ')[0], 'dd.MM.yyyy', new Date()) : null;
        const endDate = urlParams[filter].split(' ')[1] !== 'null' ? parse(urlParams[filter].split(' ')[1], 'dd.MM.yyyy', new Date()) : null;
        dateFrom = startDate !== null ? format(startDate, 'yyyy-MM-dd') : null;
        dateTo = endDate !== null ? format(endDate, 'yyyy-MM-dd') : null;
        this.setState({
          startDate,
          endDate,
        });
      } else {
        filtered.push({ id: filter, value: urlParams[filter] });
      }
    });
    this.setState({ filtered, tableSettings, defaultSortingField, defaultSortingOrder }, () => {
      this.loadPolices(dateFrom, dateTo, widgetId);
    });
    this.updateTableColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModalTableCustomized } = this.state;
    const { userInfo } = this.props;
    if ((openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized) || (prevProps.userInfo.role !== userInfo.role)) {
      this.updateTableColumns();
    }
  }

  updateTableColumns = () => {
    const {
      tableSettings,
    } = this.state;

    const { type, widgetType } = this.props;

    const columns = [{
      Header: '№',
      accessor: 'id',
      maxWidth: 70,
      width: reactLocalStorage.getObject(tableSettings).id,
      show: reactLocalStorage.getObject(tableSettings).show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Продукт страхования',
      accessor: 'type_insurance',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['type_insurance'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).type_insurance,
      show: reactLocalStorage.getObject(tableSettings).show_type_insurance !== false && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
      showCustomized: widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'mortgage' || widgetType === 'mortgageLife',
    }, {
      Header: 'Продавец',
      accessor: 'seller',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['seller'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).seller,
      show: reactLocalStorage.getObject(tableSettings).show_seller !== false && widgetType === 'dkp',
      showCustomized: widgetType === 'dkp',
    }, {
      Header: 'Тип покупателя',
      accessor: 'sellerType',
      width: reactLocalStorage.getObject(tableSettings).sellerType,
      Cell: ({ row }) => {
        if (row.sellerType === 'legal') return 'Юр. лицо';
        return 'Физ. лицо';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'legal') {
          return row.sellerType === 'legal';
        }
        if (filter.value === 'natural') {
          return row.sellerType === 'natural';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="natural">Физ. лицо</option>
          <option value="legal">Юр. лицо</option>
        </select>
      ),
      show: reactLocalStorage.getObject(tableSettings).show_sellerType !== false && widgetType === 'dkp',
      showCustomized: widgetType === 'dkp',
    }, {
      Header: 'Покупатель',
      accessor: 'buyer',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['buyer'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).buyer,
      show: reactLocalStorage.getObject(tableSettings).show_buyer !== false && widgetType === 'dkp',
      showCustomized: widgetType === 'dkp',
    }, {
      Header: 'Тип продавца',
      accessor: 'buyerType',
      width: reactLocalStorage.getObject(tableSettings).buyerType,
      Cell: ({ row }) => {
        if (row.buyerType === 'legal') return 'Юр. лицо';
        return 'Физ. лицо';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'legal') {
          return row.buyerType === 'legal';
        }
        if (filter.value === 'natural') {
          return row.buyerType === 'natural';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="natural">Физ. лицо</option>
          <option value="legal">Юр. лицо</option>
        </select>
      ),
      show: reactLocalStorage.getObject(tableSettings).show_buyerType !== false && widgetType === 'dkp',
      showCustomized: widgetType === 'dkp',
    }, {
      Header: 'Страхователь',
      accessor: 'insuredName',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insuredName'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).insuredName,
      show: reactLocalStorage.getObject(tableSettings).show_insuredName !== false && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
      showCustomized: widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'mortgage' || widgetType === 'mortgageLife',
    }, {
      Header: 'Телефон',
      accessor: 'phone',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['phone'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).phone,
      show: reactLocalStorage.getObject(tableSettings).show_phone !== false,
      showCustomized: true,
    }, {
      Header: 'Почта',
      accessor: 'email',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['email'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).email,
      show: reactLocalStorage.getObject(tableSettings).show_email !== false,
      showCustomized: true,
    }, {
      Header: 'Контактное лицо',
      accessor: 'contactName',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['contactName'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).contactName,
      show: reactLocalStorage.getObject(tableSettings).show_contactName !== false && widgetType !== 'dkp',
      showCustomized: widgetType !== 'dkp',
    }, {
      Header: widgetType === 'dkp' ? 'Объект договора' : 'Объект страхования',
      accessor: 'object_insurance',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['object_insurance'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).object_insurance,
      show: reactLocalStorage.getObject(tableSettings).show_object_insurance !== false && widgetType !== 'kbm' && widgetType !== 'property' && widgetType !== 'dmsStudent',
      showCustomized: widgetType !== 'kbm' && widgetType !== 'property' && widgetType !== 'dmsStudent',
    }, {
      Header: 'Регион страхования',
      accessor: 'region',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).region,
      show: reactLocalStorage.getObject(tableSettings).show_region !== false && widgetType === 'greenCard',
      showCustomized: widgetType === 'greenCard',
    }, {
      Header: 'Банк',
      accessor: 'bank',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['bank'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).bank,
      show: reactLocalStorage.getObject(tableSettings).show_bank !== false && widgetType === 'mortgage',
      showCustomized: widgetType === 'mortgage',
    }, {
      Header: 'Дата расчета',
      id: 'calculated_date',
      width: reactLocalStorage.getObject(tableSettings).calculated_date,
      accessor: (d) => format(parseISO(d.calculated_date), 'dd.MM.yyyy'),
      filterMethod: (filter, row) => this.customFilter(filter, row),
      Filter: () => (<div className="hideFilterInput" />),
      sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
      show: reactLocalStorage.getObject(tableSettings).show_calculated_date !== false && (type === 'request' || type === 'crm') && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'mortgage' || widgetType === 'mortgageLife' || widgetType === 'dkp'),
      showCustomized: (type === 'request' || type === 'crm') && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'mortgage' || widgetType === 'mortgageLife' || widgetType === 'dkp'),
    }, {
      Header: 'Дата оформления',
      id: 'order_date',
      width: reactLocalStorage.getObject(tableSettings).order_date,
      accessor: (d) => format(parseISO(d.order_date), 'dd.MM.yyyy'),
      filterMethod: (filter, row) => this.customFilter(filter, row),
      Filter: () => (<div className="hideFilterInput" />),
      sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
      show: reactLocalStorage.getObject(tableSettings).show_order_date !== false && type === 'policy' && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
      showCustomized: type === 'policy' && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
    }, {
      Header: 'Дата начала действия',
      accessor: 'begin_date',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['begin_date'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).begin_date,
      show: reactLocalStorage.getObject(tableSettings).show_begin_date !== false && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'dmsStudent' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
      showCustomized: widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'dmsStudent' || widgetType === 'mortgage' || widgetType === 'mortgageLife',
    }, {
      Header: 'Дата создания',
      accessor: 'created_at',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['created_at'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).created_at,
      show: reactLocalStorage.getObject(tableSettings).show_created_at !== false && widgetType === 'kbm' && widgetType === 'property',
      showCustomized: widgetType === 'kbm' || widgetType === 'property',
    }, {
      Header: 'Отправлено в CRM',
      accessor: 'inCrm',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['inCrm'] }),
      filterAll: true,
      width: reactLocalStorage.getObject(tableSettings).inCrm,
      show: reactLocalStorage.getObject(tableSettings).show_inCrm !== false && type === 'request' && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'dmsStudent' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
      showCustomized: type === 'request' && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'dmsStudent' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
    }, {
      Header: 'Статус',
      accessor: 'status',
      width: reactLocalStorage.getObject(tableSettings).status,
      Cell: ({ row }) => {
        if (row.status === 'need_help') return 'Нужна помощь';
        if (row.status === 'calculated') return 'Расчет';
        if (row.status === 'ordered') return 'Заказ';
        if (row.status === 'processing') return 'Ждет оплаты';
        return 'Черновик';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'calculated') {
          return row.status === 'calculated';
        }
        if (filter.value === 'need_help') {
          return row.status === 'need_help';
        }
        if (filter.value === 'ordered') {
          return row.status === 'ordered';
        }
        if (filter.value === 'processing') {
          return row.status === 'processing';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="calculated">Расчет</option>
          <option value="ordered">Заказ</option>
          <option value="processing">Ждет оплаты</option>
          <option value="need_help">Нужна помощь</option>
        </select>
      ),
      show: type === 'request' && reactLocalStorage.getObject(tableSettings).show_status !== false && (widgetType === 'osago' || widgetType === 'dmsStudent' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
      showCustomized: type === 'request' && (widgetType === 'osago' || widgetType === 'greenCard' || widgetType === 'dmsStudent' || widgetType === 'mortgage' || widgetType === 'mortgageLife'),
    }, {
      Header: 'Статус',
      accessor: 'kmb_status',
      width: reactLocalStorage.getObject(tableSettings).kmb_status,
      Cell: ({ row }) => {
        if (row.kmb_status === 'lowered') return 'КБМ понижен';
        if (row.kmb_status === 'unlowered') return 'КБМ не понижен';
        if (row.kmb_status === 'processing') return 'В работе';
        if (row.kmb_status === 'reserved') return 'Ожидает оплаты';
        if (row.kmb_status === 'need_correction') return 'Требуется исправление';
        return 'Черновик';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'lowered') {
          return row.status === 'lowered';
        }
        if (filter.value === 'unlowered') {
          return row.status === 'unlowered';
        }
        if (filter.value === 'processing') {
          return row.status === 'processing';
        }
        if (filter.value === 'reserved') {
          return row.status === 'reserved';
        }
        if (filter.value === 'need_correction') {
          return row.status === 'need_correction';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="lowered">КБМ понижен</option>
          <option value="unlowered">КБМ не понижен</option>
          <option value="processing">В работе</option>
          <option value="reserved">Ожидает оплаты</option>
          <option value="need_correction">Требуется исправление</option>
        </select>
      ),
      show: reactLocalStorage.getObject(tableSettings).show_kmb_status !== false && widgetType === 'kbm' && type === 'policy' && type === 'property',
      showCustomized: widgetType === 'kbm' && type === 'policy',
    }, {
      Header: 'Partner ID',
      accessor: 'partner_id',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').partner_id,
      show: (reactLocalStorage.getObject('requestListSettings').show_partner_id !== false),
      showCustomized: true,
    }, {
      Header: '№ лида',
      accessor: 'lead_id',
      maxWidth: 70,
      width: reactLocalStorage.getObject(tableSettings).lead_id,
      show: type === 'crm' && reactLocalStorage.getObject(tableSettings).show_lead_id !== false,
      showCustomized: type === 'crm',
    }, {
      Header: 'CRM',
      accessor: 'crm_type',
      width: reactLocalStorage.getObject(tableSettings).crm_type,
      Cell: ({ row }) => {
        if (row.crm_type === 'bitrix24') return 'Bitrix24';
        if (row.crm_type === 'amoCrm') return 'AmoCrm';
        return 'Черновик';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'bitrix24') {
          return row.crm_type === 'bitrix24';
        }
        if (filter.value === 'amoCrm') {
          return row.crm_type === 'amoCrm';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="bitrix24">Bitrix24</option>
          <option value="amoCrm">AmoCrm</option>
        </select>
      ),
      show: type === 'crm' && reactLocalStorage.getObject(tableSettings).show_crm_type !== false,
      showCustomized: type === 'crm',
    },
    ];
    this.setState({ columns });
  };

  loadPolices = (dateFrom, dateTo, widgetId) => {
    const { type, widgetType } = this.props;

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/requests-list?dateFrom=${dateFrom}&dateTo=${dateTo}&widgetId=${widgetId}&type=${type}&widgetType=${widgetType}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((requests) => {
        requests.forEach((r) => {
          let formData;
          r.linkCode = widgetType;
          if (widgetType === 'osago') {
            formData = r.form_data;
            r.object_insurance = `${formData.markName} ${formData.modelName}`;
            r.insuredName = `${formData.lastNameInsured} ${formData.firstNameInsured} ${formData.middleNameInsured}`;
            r.contactName = formData.contactFirstName && formData.contactLastName
              ? `${formData.contactLastName} ${formData.contactFirstName}` : r.insuredName;
            r.type_insurance = r.policy_type.name;
            r.begin_date = `${formData.policyDate}`;
            r.phone = formData.contactPhone ? `${formData.contactPhone}` : `${formData.phone}`;
            r.email = formData.contactEmail ? `${formData.contactEmail}` : `${formData.email}`;
          } else if (widgetType === 'dkp') {
            formData = r.form_data;
            r.object_insurance = formData.carMark.label !== '' && formData.carModel.label !== '' ? `${formData.carMark.label} ${formData.carModel.label}` : '-';
            r.seller = formData.sellerLastName !== ''
              && formData.sellerFirstName !== ''
              && formData.sellerMiddleName !== '' ? `${formData.sellerLastName} ${formData.sellerFirstName} ${formData.sellerMiddleName}` : '-';
            r.buyer = formData.buyerLastName !== ''
              && formData.buyerFirstName !== ''
              && formData.buyerMiddleName !== '' ? `${formData.buyerLastName} ${formData.buyerFirstName} ${formData.buyerMiddleName}` : '-';
            r.phone = formData.buyerPhone || formData.sellerPhone;
            r.email = formData.buyerEmail || formData.sellerEmail;
            r.sellerType = formData.sellerType.value;
            r.buyerType = formData.buyerType.value;
          } else if (widgetType === 'kbm') {
            formData = JSON.parse(r.form_data);
            const fio = `${formData.lastName} ${formData.firstName} ${formData.middleName}`;
            r.contactName = fio.trim() !== '' ? fio : '-';
            r.phone = formData.contactPhone !== '' ? formData.contactPhone : '-';
            r.email = formData.contactEmail !== '' ? formData.contactEmail : '-';
            r.kmb_status = r.status;
          } else if (widgetType === 'property') {
            formData = JSON.parse(r.form_data);
            r.contactName = '-';
            r.phone = formData.contactPhone !== '' ? formData.contactPhone : '-';
            r.email = formData.contactEmail !== '' ? formData.contactEmail : '-';
            r.property_kad_num = formData.kadNumber;
            r.property_status = r.status;
          } else if (widgetType === 'dmsStudent') {
            formData = JSON.parse(r.form_fields);
            const fio = `${formData.lastName} ${formData.firstName} ${formData.middleName}`;
            r.contactName = fio.trim() !== '' ? fio : '-';
            r.phone = formData.contactPhone !== '' ? formData.contactPhone : '-';
            r.email = formData.contactEmail !== '' ? formData.contactEmail : '-';
            r.begin_date = formData.startDate ? formData.startDate.substring(0, 10).split('-').reverse().join('.') : '-';
          } else if (widgetType === 'greenCard') {
            formData = r.form_data;
            if (formData.regularCarMarkId.label !== '' && formData.regularCarModelId.label !== '') {
              r.object_insurance = `${formData.regularCarMarkId.label} ${formData.regularCarModelId.label}`;
            } else if (formData.otherCarMarkId !== '' && formData.otherCarModelId !== '') {
              r.object_insurance = `${formData.otherCarMarkId} ${formData.otherCarModelId}`;
            } else {
              r.object_insurance = '-';
            }
            r.insuredName = `${formData.lastName} ${formData.firstName} ${formData.middleName}`;
            r.contactName = r.insuredName;
            r.type_insurance = r.policy_type.name;
            r.begin_date = formData.policyDate;
            r.phone = formData.phone;
            r.email = formData.email;
            r.region = formData.territory.label;
          } else if (widgetType === 'mortgage' || widgetType === 'mortgageLife') {
            formData = r.form_data;
            r.calculated_date = r.order_date;
            r.object_insurance = '';
            if (formData.lastName) {
              r.insuredName = `${formData.lastName}`;
            }
            if (formData.firstName) {
              r.insuredName = `${r.insuredName} ${formData.firstName}`;
            }
            if (formData.middleName && r.insuredName) {
              r.insuredName = `${r.insuredName} ${formData.middleName}`;
            }
            r.contactName = r.insuredName;
            r.region = formData.insuredAddress ? formData.insuredAddress.data.region_with_type : '';
            r.phone = formData.contactPhone;
            r.email = formData.contactEmail;
            r.bank = formData.bank.label;
            r.begin_date = formData.startDate ? formData.startDate.substring(0, 10).split('-').reverse().join('.') : '';
            switch (r.product_insurance) {
              case 'mortgage':
                r.type_insurance = 'Ипотека.Имущество';
                r.object_insurance = formData.buildingSquare ? `${formData.buildingType.label}, ${formData.buildingSquare} м2` : `${formData.buildingType.label}`;
                break;
              case 'mortgageLife':
                r.object_insurance = r.insuredName ? `${r.insuredName} (жизнь и здоровье)` : 'Жизнь и здоровье';
                r.type_insurance = 'Ипотека.Жизнь';
                break;
              case 'mortgageComplex':
                r.type_insurance = 'Ипотека.Комплексное страхование';
                r.object_insurance = formData.buildingSquare ? `${formData.buildingType.label}, ${formData.buildingSquare} м2` : `${formData.buildingType.label}`;
                break;
              default:
                break;
            }
          }
          if (r.calculated_date === null || typeof r.calculated_date === 'undefined') {
            r.calculated_date = '2000-01-01';
          }
          if (r.order_date === null || typeof r.order_date === 'undefined') {
            r.order_date = '2000-01-01';
          }
          if (r.document_date === null || typeof r.document_date === 'undefined') {
            r.document_date = '2000-01-01';
          }
          if (r.created_at === null || typeof r.created_at === 'undefined') {
            r.created_at = '2000-01-01';
          }
          if (type === 'crm') {
            if (r.bitrix24_lead_id !== null) {
              r.lead_id = r.bitrix24_lead_id;
              r.crm_type = 'bitrix24';
            }
            if (r.amocrm_lead_id !== null) {
              r.lead_id = r.amocrm_lead_id;
              r.crm_type = 'amoCrm';
            }
          }
          if (type === 'request') {
            const inCrmArr = [];
            if (r.bitrix24_lead_id) {
              inCrmArr.push('Bitrix24');
            }
            if (r.amocrm_lead_id) {
              inCrmArr.push('AmoCRM');
            }
            if (inCrmArr.length > 0) {
              r.inCrm = inCrmArr.join('/');
            } else {
              r.inCrm = '-';
            }
          }
        });
        this.setState({
          requestsList: requests,
          loading: false,
        });
      });
  };

  setTableSettings = (newResized) => {
    const { tableSettings } = this.state;
    const settings = reactLocalStorage.getObject(tableSettings);
    newResized.forEach((item) => {
      settings[item.id] = item.value;
    });
    reactLocalStorage.setObject(tableSettings, settings);
  };

  setTableSorting = (newSort) => {
    const { tableSettings } = this.state;
    const settings = reactLocalStorage.getObject(tableSettings);
    settings.defaultSortingField = newSort[0].id;
    settings.defaultSortingOrder = newSort[0].desc;
    reactLocalStorage.setObject(tableSettings, settings);
  };

  onFilteredChangeCustom = (filtered) => {
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}`;
    window.history.replaceState(null, null, queryParams);
  };

  toggleFilter = (tab) => {
    this.setState({ loading: true, requestsList: [] }, () => {
      const { activeFilterTab, filtered } = this.state;
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      let lastButOneMonth = date.getMonth();
      let currentYear = date.getFullYear();
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
      let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
      const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let dateFrom;
      let dateTo;
      if (activeFilterTab !== tab) {
        this.setState({ activeFilterTab: tab });
        let filterDate = null;
        if (tab === '1') {
          dateFrom = format(startDateCurrentMonth, 'yyyy-MM-dd');
          dateTo = format(endDateCurrentMonth, 'yyyy-MM-dd');
          this.setState({
            startDate: startDateCurrentMonth,
            endDate: endDateCurrentMonth,
          });
          filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
        } else if (tab === '2') {
          if (currentMonth === 1) {
            currentYear -= 1;
            lastButOneMonth = '12';
            lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
            startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
            endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
          }
          dateFrom = format(startDateLastButOneMonth, 'yyyy-MM-dd');
          dateTo = format(endDateLastButOneMonth, 'yyyy-MM-dd');
          this.setState({
            startDate: startDateLastButOneMonth,
            endDate: endDateLastButOneMonth,
          });
          filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
        } else if (tab === '3') {
          dateFrom = null;
          dateTo = null;
          this.setState({
            startDate: null,
            endDate: date,
          });
          filterDate = `${format(date, 'dd.MM.yyyy')}`;
        }
        const newFiltered = filtered;
        let findedIndex = -1;
        let findedIndexDateTab = -1;
        newFiltered.forEach((filter, i) => {
          if (filter.id === 'filter_date') {
            findedIndex = i;
          }
          if (filter.id === 'dateTab') {
            findedIndexDateTab = i;
          }
        });
        if (findedIndex !== -1) {
          if (filterDate === null) {
            newFiltered.splice(findedIndex, 1);
          } else {
            newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
          }
        } else if (filterDate !== null) {
          newFiltered.push({ id: 'filter_date', value: filterDate });
        }
        if (findedIndexDateTab !== -1) {
          newFiltered[findedIndexDateTab] = { id: 'dateTab', value: tab };
        } else {
          newFiltered.push({ id: 'dateTab', value: tab });
        }
        let queryParams = '?';
        newFiltered.forEach((filter, key, arr) => {
          if (filter.id !== 'activeTab') {
            if (filter.id === 'filter_date' && tab === '3') {
              queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            } else {
              queryParams = `${queryParams + filter.id}=${filter.value}`;
            }
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        });
        queryParams = `${queryParams}&activeTab=${this.props.tab}`;
        const { history, widgetId } = this.props;
        history.push({ search: queryParams });
        this.setState({ filtered: newFiltered });
        this.loadPolices(dateFrom, dateTo, widgetId);
      }
    });
  };

  handleChangeStart = (date) => {
    this.setState({ loading: true, requestsList: [] }, () => {
      const { endDate, filtered } = this.state;
      this.setState({ startDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateTo = null;
      const dateFrom = format(date, 'yyyy-MM-dd');
      if (endDate !== null) {
        filterDate = format(date, 'dd.MM.yyyy') + ' ' + format(endDate, 'dd.MM.yyyy');
        dateTo = format(endDate, 'yyyy-MM-dd');
      }

      const { widgetId } = this.props;
      this.loadPolices(dateFrom, dateTo, widgetId);
      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (endDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=${filter.value} null`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      const { tab, history } = this.props;
      queryParams = `${queryParams}&activeTab=${tab}`;
      history.push({ search: queryParams });
    });
  };

  handleChangeEnd = (date) => {
    this.setState({ loading: true, requestsList: [] }, () => {
      const { startDate, filtered } = this.state;
      this.setState({ endDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateFrom = null;
      const dateTo = format(date, 'yyyy-MM-dd');
      if (startDate !== null) {
        filterDate = format(startDate, 'dd.MM.yyyy') + ' ' + format(date, 'dd.MM.yyyy');
        dateFrom = format(startDate, 'yyyy-MM-dd');
      }

      const { widgetId } = this.props;
      this.loadPolices(dateFrom, dateTo, widgetId);
      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (startDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      const { tab, history } = this.props;
      queryParams = `${queryParams}&activeTab=${tab}`;
      history.push({ search: queryParams });
    });
  };

  customFilter = (filter, row) => {
    const { startDate, endDate } = this.state;
    if (startDate === null && endDate === null) {
      return (parse(row.created_at, 'yyyy-MM-dd, HH:mm:ss', new Date()) === parse(filter.value, 'dd.MM.yyyy', new Date())) ? 1 : 0;
    }
    if (startDate === null) {
      return parse(row.created_at, 'yyyy-MM-dd, HH:mm:ss', new Date()) <= parse(filter.value, 'dd.MM.yyyy', new Date()) ? 1 : 0;
    }
    if (endDate === null) {
      return parse(row.created_at, 'yyyy-MM-dd, HH:mm:ss', new Date()) >= parse(filter.value, 'dd.MM.yyyy', new Date()) ? 1 : 0;
    }
    return (parse(row.created_at, 'yyyy-MM-dd, HH:mm:ss', new Date()) >= parse(filter.value.split(' ')[0], 'dd.MM.yyyy', new Date()) && parse(row.created_at, 'yyyy-MM-dd, HH:mm:ss', new Date()) <= parse(filter.value.split(' ')[1], 'dd.MM.yyyy', new Date())) ? 1 : 0;
  };

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  downloadReport = () => {
    const { columns } = this.state;
    const { ExcelColumn } = ReactExport.ExcelFile;
    const currentRecords = this.reactTable.getResolvedState().sortedData;
    const dataToDownload = [];
    for (let index = 0; index < currentRecords.length; index += 1) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        if (columns[colIndex].show !== false) {
          if (currentRecords[index][columns[colIndex].accessor]) {
            if (currentRecords[index][columns[colIndex].accessor] && currentRecords[index][columns[colIndex].accessor].$$typeof) {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor].props.children[0];
            } else {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
            }
          } else {
            if (currentRecords[index][columns[colIndex].id] && currentRecords[index][columns[colIndex].id].$$typeof) {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id].props.children[0];
            } else {
              recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id];
            }
          }
        }
      }
      dataToDownload.push(recordToDownload);
    }

    const excelcolumns = columns.filter((column) => column.show).map((column) => <ExcelColumn label={column.Header} value={column.Header} />);

    this.setState({ excelcolumns, dataSet: dataToDownload }, () => {
      this.excelFile.handleDownload();
    });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  handleTableCustomizedSetAll = () => {
    this.simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  handleTableCustomizedSetNone = () => {
    this.simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columns = Array.from(e.target.elements).slice(0, -1);
    const { tableSettings } = this.state;
    const settings = reactLocalStorage.getObject(tableSettings);
    columns.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject(tableSettings, settings);
    this.setState({ openModalTableCustomized: false });
  };

  render() {
    const {
      requestsList,
      loading,
      activeFilterTab,
      startDate,
      endDate,
      openModalTableCustomized,
      defaultSortingField,
      defaultSortingOrder,
      columns,
      excelcolumns,
      dataSet,
    } = this.state;

    const { type } = this.props;
    const customizedColumns = columns.map((column) => {
      const id = column.id ? column.id : column.accessor;
      if (column.showCustomized) {
        return (
          <li key={id} className="form-check">
            <input ref={this.simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
        );
      }
      return null;
    });
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalTableCustomized}
          onClose={this.onCloseModalTableCustomized}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {customizedColumns}
            </ul>
            <button type="submit" className="btn btn-success">Сохранить</button>
          </form>
        </Modal>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button type="button">Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="position-relative">
          <div className="dateFilter">
            <div className="d-inline-block mb-2">
              <div
                onClick={() => {
                  this.toggleFilter('1');
                }}
                className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
              >
                Текущий месяц
              </div>
              <div
                onClick={() => {
                  this.toggleFilter('2');
                }}
                className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
              >
                Прошлый месяц
              </div>
              <div
                onClick={() => {
                  this.toggleFilter('3');
                }}
                className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
              >
                Все время
              </div>
            </div>
            <div className="d-inline-block mb-2">
              <span>Точные даты с</span>
              <DatePicker
                selected={startDate}
                className="form-control"
                selectsStart
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleChangeStart}
                maxDate={endDate}
                showYearDropdown
                showMonthDropdown
                locale={ru}
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
            </div>
            <div className="d-inline-block">
              <span>по</span>
              <DatePicker
                selected={endDate}
                className="form-control"
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                onChange={this.handleChangeEnd}
                minDate={startDate}
                showYearDropdown
                showMonthDropdown
                locale={ru}
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
            </div>
          </div>
          <ReactTable
            getTdProps={(state, rowInfo, column) => {
              if (rowInfo && column.id) {
                return {
                  onMouseDown: (e) => {
                    e.preventDefault();
                    if (e.button === 0) {
                      this.props.history.push(`/${rowInfo.row._original.linkCode}/${rowInfo.row._original.id}`);
                    }
                    if (e.button === 1) {
                      window.open(`/${rowInfo.row._original.linkCode}/${rowInfo.row._original.id}`, '_blank');
                    }
                  },
                };
              }
              return {};
            }}
            ref={(r) => this.reactTable = r}
            filtered={this.state.filtered}
            onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
            className="table"
            data={requestsList}
            columns={columns}
            defaultSorted={[
              {
                id: defaultSortingField,
                desc: defaultSortingOrder,
              },
            ]}
            previousText="Назад"
            nextText="Вперед"
            loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
            noDataText={type === 'request' ? 'Лиды не найдены' : 'Полисы не найдены'}
            pageText="Страница"
            ofText="Из"
            rowsText="строк"
            loading={loading}
            defaultPageSize={50}
            filterable
            minRows={1}
            onSortedChange={(newSort) => {
              this.setTableSorting(newSort);
            }}
            onResizedChange={(newResized) => {
              this.setTableSettings(newResized);
            }}
          />
        </div>
      </>
    );
  }
}

export default withUserInfoStoreStore(WidgetPolicesList);
