import React, {
  useContext, useMemo, useState, useRef,
} from 'react';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular';
import { CSVReader } from 'react-papaparse';
import Select from 'react-select';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import { customSelectStyle } from '../../Utils/Helpers';

const fileTypeOptions = [
  { value: 'carNumber', label: 'Гос. номер' },
  { value: 'vin', label: 'ВИН' },
];

export default function CheckOsagoFileLoad(props) {
  const {
    requestWaitingFlag,
    checkPolicy,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const fileRef = useRef(null);

  const [fileData, setFileData] = useState([]);
  const [fileType, setFileType] = useState(fileTypeOptions[0]);
  const [fileError, setFileError] = useState('');

  const customStyles = useMemo(() => customSelectStyle());

  const validFileExt = () => {
    let isValid = true;
    const fileNameArr = fileRef.current.state.file.name.split('.');
    if (fileNameArr[fileNameArr.length - 1] !== 'csv') {
      isValid = false;
    }
    return isValid;
  };

  const handleOnDrop = (fileParsedData) => {
    if (validFileExt()) {
      const numbers = fileParsedData.filter((row) => row.data[0] !== '').map((row) => row.data[0]);
      setFileError('');
      setFileData([...numbers]);
    } else {
      setFileError('Неверный формат файла. Допустимы только csv файлы,');
    }
  };

  const handleOnError = () => {
    setFileError('Ошибка обработки файла');
    showModalInfo('Ошибка обработки файла');
  };

  const handleOnRemoveFile = () => {
    setFileError('');
    setFileData([]);
  };

  const getInfo = (e) => {
    e.preventDefault();
    const parameters = fileData.map((number) => {
      if (fileType.value === 'vin') {
        return { checkType: 'vin', value: number };
      }
      return { checkType: 'regNumber', value: number };
    });
    checkPolicy(parameters);
  };

  return (
    <>
      <div className="card mb-3">
        <div className="form-group row">
          <div className="col-xl-4 col-lg-6 col-sm-12" style={{ zIndex: 100 }}>
            <label htmlFor="carYear">Тип</label>
            <Select
              classNamePrefix="react-select"
              inputId="insuranceType"
              className="form-control-custom"
              name="insuranceType"
              placeholder="Тип"
              noOptionsMessage={() => 'Не найдено'}
              value={fileType}
              styles={customStyles}
              onChange={(e) => setFileType(e)}
              options={fileTypeOptions}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-xl-4 col-lg-6 col-sm-12">
            <CSVReader
              ref={fileRef}
              onDrop={handleOnDrop}
              onError={handleOnError}
              noDrag
              noProgressBar
              onRemoveFile={handleOnRemoveFile}
              accept="text/csv, .csv, application/vnd.ms-excel"
            >
              {({ file }) => (
                <>
                  <label className="d-block" htmlFor="passport_page_two">Файл со списком VIN номеров или гос. номеров (в формате CSV)</label>
                  <div className="jq-file styler">
                    <div className="jq-file__name">{file ? <>Загружено<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" /> {file.name}</> : null}</div>
                    {file
                      ? (<div onClick={(e) => fileRef.current.removeFile(e)} className="jq-file__remove" />)
                      : (<div className="jq-file__browse" />
                      )}
                    <input type="text" onClick={(e) => fileRef.current.open(e)} id="passport_page_two" name="passport_page_two" className="custom-input-file" />
                  </div>
                  {fileError && (
                    <div className="validation-error">
                      {fileError}
                    </div>
                  )}
                </>
              )}
            </CSVReader>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12" style={{ display: 'flex' }}>
            <button className="btn btn-success" type="button" disabled={!fileData.length || requestWaitingFlag} onClick={getInfo}>Получить данные</button>
            {requestWaitingFlag && <ReactLoading className="loading-circle ml-2" type="spin" height={37} width={37} />}
          </div>
        </div>
      </div>
    </>
  );
}
