import React, {
  useMemo, useState, useEffect, useContext, useRef,
} from 'react';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import { subYears } from 'date-fns';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faFile } from '@fortawesome/fontawesome-free-regular';
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
  faStar,
  faChevronDown,
  faChevronUp,
  faExclamation,
} from '@fortawesome/fontawesome-free-solid';
import ReactLoading from 'react-loading';
import { Element } from 'react-scroll';
import CurrencyInput from 'react-currency-input-field';
import { ReactDadata } from 'react-dadata';
import Tippy from '@tippyjs/react';
import ProgressBar from '../../Utils/UiComponents/ProgressBar';
import {
  customSelectStyle,
  customFetch,
  sortAllPolicesArray,
  handleChange,
  validateField,
} from '../../Utils/Helpers';
import getMask from '../../Utils/Masks';
import CommercialOffer from '../CommercialOffer';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import MortgageOptionsForm from './MortgageOptionsForm';
import { getConstraints } from '../../Utils/Validation';
import useUserInfoStore from '../../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';
//import useSocket from '../../Utils/CustomHooks/UseSocket';

export default function MortgageShortForm(props) {
  const {
    disableField,
    formData,
    setFormData,
    saveDraft,
    bankOptions,
    handleValidate,
    classifiers,
    blurClass,
    companies,
    goToFullForm,
    draftInfo,
    goCalcFlag,
    setGoCalcFlag,
    setBlurClass,
    showForm,
    toggleFlag,
    backToForm,
    setLoadingInit,
    loadingInit,
    selectedSort,
    setSelectedSort,
    policySortOrderUp,
    setPolicySortOrderUp,
    policyIntegrationsData,
    setPolicyIntegrationsData,
    allPolices,
    setAllPolices,
    showCommission,
    setShowCommission,
    commercialOfferCompanies,
    setCommercialOfferCompanies,
    setShowCommercialOfferModalForm,
    showCommercialOfferModalForm,
    commercialOfferRef,
    setLoadingCommercialOffer,
    setScrollTo,
    setCursorToEnd,
    setModalGift,
    setDiscountPercent,
    setAllowancePercent,
    setAdjustmentType,
    adjustmentType,
    discountPercent,
    allowancePercent,
    discountPercentLoadingFlag,
    setDiscountPercentLoadingFlag,
    adjustmentTypeOptions,
  } = props;
  const { userInfo, theme } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
      theme: state.theme,
    }),
    shallow,
  );

  const { showModalInfo } = useContext(ModalMessagesContext);

  let abortController = new AbortController();
  let abortControllerDiscount = new AbortController();

  const intervalId = useRef(null);

  const customStyles = useMemo(() => customSelectStyle(), []);
  const [loadingResults, setLoadingResults] = useState(false);

  //const { listenChanel, leaveChanel } = useSocket();

  const makeAllPolicesArray = (product) => {
    const allPolicesArray = [];
    policyIntegrationsData[product].forEach((policyData) => {
      if (policyData.price || policyData.errorMessage) {
        allPolicesArray.push({
          policyData,
          insuranceInfoFlag: (companies[policyData.polis_online_code].rules && companies[policyData.polis_online_code].rules[draftInfo.type] && companies[policyData.polis_online_code].rules[draftInfo.type][formData.bank.value.value])
          || (companies[policyData.polis_online_code].policyTemplate && companies[policyData.polis_online_code].policyTemplate[draftInfo.type] && companies[policyData.polis_online_code].policyTemplate[draftInfo.type][formData.bank.value.value]) ? 'close' : 'no',
          integration: 'direct',
          rating: companies[policyData.polis_online_code].rating,
          priority: companies[policyData.polis_online_code].priority,
          priority_message: companies[policyData.polis_online_code].priority_message,
          price: policyData.price ? parseFloat(policyData.price) : 0,
          commission: policyData.agentCommission ? policyData.agentCommission : 0,
          commissionCurrency: policyData.agentCommission ? parseFloat((policyData.agentCommission * parseFloat(policyData.price)) / 100) : 0,
          errorMessage: policyData.errorMessage ? policyData.errorMessage : '',
          integrationCompanyCode: policyData.polis_online_code,
        });
      }
      return false;
    });
    return allPolicesArray;
  };

  const changeSort = (e, newSort) => {
    e.preventDefault();
    if (selectedSort === newSort) {
      const newAllPolices = { ...allPolices };
      const direction = policySortOrderUp ? 'down' : 'up';
      const newMortgagePolices = sortAllPolicesArray(newAllPolices.mortgage, selectedSort, direction, policySortOrderUp);
      const newMortgageLifePolices = sortAllPolicesArray(newAllPolices.mortgageLife, selectedSort, direction, policySortOrderUp);
      const newMortgageComplexPolices = sortAllPolicesArray(newAllPolices.mortgageComplex, selectedSort, direction, policySortOrderUp);
      setAllPolices({
        mortgage: newMortgagePolices,
        mortgageLife: newMortgageLifePolices,
        mortgageComplex: newMortgageComplexPolices,
      });
      setPolicySortOrderUp((prev) => !prev);
    } else {
      const direction = newSort === 'price' ? 'up' : 'down';
      const newAllPolices = { ...allPolices };
      const newMortgagePolices = sortAllPolicesArray(newAllPolices.mortgage, newSort, direction, policySortOrderUp);
      const newMortgageLifePolices = sortAllPolicesArray(newAllPolices.mortgageLife, newSort, direction, policySortOrderUp);
      const newMortgageComplexPolices = sortAllPolicesArray(newAllPolices.mortgageComplex, newSort, direction, policySortOrderUp);
      setAllPolices({
        mortgage: newMortgagePolices,
        mortgageLife: newMortgageLifePolices,
        mortgageComplex: newMortgageComplexPolices,
      });
      setSelectedSort(newSort);
      setPolicySortOrderUp(newSort === 'price');
    }
  };

  const toggleCompanyCommercialOffer = (e, companyCode) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    const insuranceIndex = commercialOfferCompanies.findIndex((code) => code === companyCode);
    const newCommercialOfferCompanies = [...commercialOfferCompanies];
    let type = 'set';
    if (insuranceIndex !== -1) {
      type = 'delete';
      newCommercialOfferCompanies.splice(insuranceIndex, 1);
    } else {
      newCommercialOfferCompanies.push(companyCode);
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commercial-offer/mortgage/${type}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurance: { companyCode, crossProductCode: null },
        id: draftInfo.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCommercialOfferCompanies([...newCommercialOfferCompanies]);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      });
  };

  const toggleAllCompaniesCommercialOffer = (e, allPolicyData) => {
    e.preventDefault();

    let type = 'set';
    let newCommercialOfferCompanies;
    let insurances;
    if (commercialOfferCompanies.length) {
      type = 'delete';
      newCommercialOfferCompanies = [];
      insurances = [];
    } else {
      newCommercialOfferCompanies = allPolicyData.map((policy) => (policy.policyData.polis_online_code));
      insurances = allPolicyData.map((policy) => ({
        companyCode: policy.policyData.polis_online_code,
        crossProductCode: null,
      }));
    }
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commercial-offer/mortgage/all-insurances-${type}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        insurances,
        id: draftInfo.id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCommercialOfferCompanies([...newCommercialOfferCompanies]);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      });
  };

  const checkCalc = () => {
    abortController = new AbortController();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    let intervalCount = 0;
    const checkInterval = setInterval(() => {
      if (intervalCount === 120) {
        setLoadingResults(false);
        clearInterval(checkInterval);
      } else {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/calculation-status?draftHash=${draftInfo.hash}`, {
          signal: abortController.signal,
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
        }).then((response) => response.json())
          .then((response) => {
            if (!response.error) {
              if (response.resultStatus === 'changed') {
                const newPolicesData = { ...policyIntegrationsData };
                Object.keys(newPolicesData).forEach((type) => {
                  if (response.result[type]) {
                    newPolicesData[type] = response.result[type];
                  }
                });
                setPolicyIntegrationsData(newPolicesData);
              }
              if (response.calcStatus === 'complete') {
                setLoadingResults(false);
                clearInterval(checkInterval);
              }
            }
          });
        intervalCount += 5;
      }
    }, 3500);
    intervalId.current = checkInterval;
  };

  useEffect(() => () => {
    clearInterval(intervalId.current);
  }, []);

  useEffect(() => () => {
    if (!blurClass) {
      setLoadingResults(false);
      clearInterval(intervalId.current);
      abortController.abort();
    }
  }, [blurClass]);

  /*const getCalcDataFromEvent = (eventData) => {
    if (eventData.data && eventData.data.result) {
      const { data } = eventData;
      const newPolicesData = { ...policyIntegrationsData };
      Object.keys(newPolicesData).forEach((type) => {
        if (data.result[type]) {
          newPolicesData[type] = data.result[type];
        }
      });
      setPolicyIntegrationsData(newPolicesData);
      if (data.calcStatus && data.calcStatus === 'complete') {
        setLoadingResults(false);
        leaveChanel(`calculate.mortgage.${draftInfo.id}`);
      }
    }
  };*/

  // Проставление автоматической скидки на предрасчете
  const automaticAction = () => {
    let data = {};
    if (discountPercent.soglasie && discountPercent.soglasie.bankList.includes(formData.bank.value.value) && theme.domain !== 'samoletplus') {
      setDiscountPercent((prev) => ({
        ...prev,
        soglasie: {
          ...prev.soglasie,
          value: 20,
        },
      }));
      data = {
        ...data,
        soglasie: {
          discountPercent: 20,
        },
      };
      return data;
    }
    return null;
  };

  const calculate = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setScrollTo('searchResults');

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/calculation`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        calcType: 'short',
        draftHash: draftInfo.hash,
        useEvents: false,
        automaticAction: automaticAction(),
      }),
    })
      .then(() => {
        checkCalc();
        //listenChanel(`calculate.mortgage.${draftInfo.id}`, 'calculateStatus', getCalcDataFromEvent);
      });
  };

  const stopCalculation = () => {
    setLoadingResults(false);
  };

  const makeCalculation = () => {
    setBlurClass('');
    setLoadingInit(true);
    setLoadingResults(true);
    setAllPolices({
      mortgage: [],
      mortgageLife: [],
      mortgageComplex: [],
    });
    setPolicyIntegrationsData({
      mortgage: [],
      mortgageLife: [],
      mortgageComplex: [],
    });
    calculate();
  };

  const toggleInsuranceInfoFlag = (e, targetOffer) => {
    e.preventDefault();
    const newPolicyArr = [...allPolices[draftInfo.type]];
    const offerIndex = newPolicyArr.findIndex((offer) => offer.policyData.polis_online_code === targetOffer.policyData.polis_online_code);
    newPolicyArr[offerIndex].insuranceInfoFlag = newPolicyArr[offerIndex].insuranceInfoFlag === 'close' ? 'open' : 'close';
    setAllPolices((prev) => ({
      ...prev,
      [draftInfo.type]: newPolicyArr,
    }));
  };

  useEffect(() => {
    if (draftInfo.hash && goCalcFlag) {
      makeCalculation();
      setGoCalcFlag(false);
    }
  }, [draftInfo.hash, goCalcFlag]);

  useEffect(() => {
    if (loadingInit
    && (!loadingResults || (loadingResults && policyIntegrationsData.mortgage.length))) {
      let allPolicyDataArr = makeAllPolicesArray('mortgage');
      const direction = policySortOrderUp ? 'up' : 'down';
      allPolicyDataArr = sortAllPolicesArray(allPolicyDataArr, selectedSort, direction, policySortOrderUp, true);
      setAllPolices((prev) => ({
        ...prev,
        mortgage: [...allPolicyDataArr],
      }));
    }
  }, [loadingResults, policyIntegrationsData.mortgage.length]);

  useEffect(() => {
    if (loadingInit
    && (!loadingResults || (loadingResults && policyIntegrationsData.mortgageLife.length))) {
      let allPolicyDataArr = makeAllPolicesArray('mortgageLife');
      const direction = policySortOrderUp ? 'up' : 'down';
      allPolicyDataArr = sortAllPolicesArray(allPolicyDataArr, selectedSort, direction, policySortOrderUp, true);
      setAllPolices((prev) => ({
        ...prev,
        mortgageLife: [...allPolicyDataArr],
      }));
    }
  }, [loadingResults, policyIntegrationsData.mortgageLife.length]);

  useEffect(() => {
    if (loadingInit
    && (!loadingResults || (loadingResults && policyIntegrationsData.mortgageComplex.length))) {
      let allPolicyDataArr = makeAllPolicesArray('mortgageComplex');
      const direction = policySortOrderUp ? 'up' : 'down';
      allPolicyDataArr = sortAllPolicesArray(allPolicyDataArr, selectedSort, direction, policySortOrderUp, true);
      setAllPolices((prev) => ({
        ...prev,
        mortgageComplex: [...allPolicyDataArr],
      }));
    }
  }, [loadingResults, policyIntegrationsData.mortgageComplex.length]);

  const calculateDiscount = async (insurance, calcType) => {
    setScrollTo(insurance);
    abortControllerDiscount.abort();
    abortControllerDiscount = new AbortController();
    setDiscountPercentLoadingFlag((prev) => ({
      ...prev,
      [insurance]: true,
    }));
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    let adjustmentPercent;
    if (calcType === 'discount') {
      adjustmentPercent = discountPercent[insurance].value;
    } else {
      adjustmentPercent = allowancePercent[insurance].value;
    }
    await customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/calculation`, {
      method: 'post',
      signal: abortControllerDiscount.signal,
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        calcType,
        draftHash: draftInfo.hash,
        insurance,
        adjustmentPercent,
      }),
    }).then((response) => response.json())
      .then((response) => {
        if (response.price) {
          setAllPolices((prev) => ({
            ...prev,
            [draftInfo.type]: allPolices[draftInfo.type].map((item) => (item.policyData.polis_online_code === insurance ? {
              ...item,
              price: response.price,
              policyData: {
                ...item.policyData,
                price: response.price,
                integrationRequestId: response.integrationRequestId,
                data: response.data,
              },
            } : item)),
          }));
        } else if (response.error) {
          showModalInfo(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка при применении скидки', 'error');
      })
      .finally(() => {
        setDiscountPercentLoadingFlag((prev) => ({
          ...prev,
          [insurance]: false,
        }));
      });
  };

  useEffect(() => {
    if (draftInfo.id && loadingInit) {
      setCommercialOfferCompanies([]);
      saveDraft(undefined);
    }
  }, [draftInfo.type]);

  /*useEffect(() => {
    leaveChanel(`calculate.mortgage.${draftInfo.id}`);
  }, []);*/

  const allPolicyBlock = useMemo(() => {
    if (!loadingInit) {
      return '';
    }
    const policesList = allPolices[draftInfo.type];
    return policesList.filter((offer) => offer.price && !offer.errorMessage).map((offer) => (
      <div key={offer.policyData.polis_online_code} className="search_results__table--row-container">
        {offer.priority ? <div className="search_results__table--best_offer">{offer.priority_message && showCommission ? offer.priority_message : 'Лучшее предложение'}</div> : null}
        <div name={offer.policyData.polis_online_code} className={classnames('search_results__table--row', { info: offer.insuranceInfoFlag === 'open', 'has-info': offer.insuranceInfoFlag !== 'no', priority: offer.priority })}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].logo}`} alt="logo" className="search_results__logo" />
            </div>
            <div className="search_results__company-name">
              {companies[offer.policyData.polis_online_code].name}
            </div>
          </div>
          {(discountPercentLoadingFlag[offer.policyData.polis_online_code]) ? (
            <div className="search_results__table--cell"><ReactLoading className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} /></div>
          ) : (
            <>
              <div className="search_results__table--cell">
                <span className="color-light label">Стоимость полиса:</span>
                <div>
                  <div>
                    {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(offer.price)}
                  </div>
                </div>
              </div>
              <div className="search_results__table--cell">
                {showCommission && userInfo.hide_commission === 0 ? (
                  <>
                    <span className="color-light label">Вознаграждение:</span>
                    <div>
                      <div className="search_results__commission--currency">{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format((offer.commission * offer.price) / 100)}</div>
                      <div className="search_results__commission--percentage">{offer.commission}%</div>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="color-light label">Рейтинг:</span>
                    <div className="company_item__star">
                      {[...Array(companies[offer.policyData.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                    </div>
                  </>
                )}
              </div>
              <div className="search_results__table--cell">
                <span className="color-light label">Комментарий:</span>
                <div>{['zetta', 'renessans'].includes(offer.policyData.polis_online_code) ? (
                  'Договор успешно согласован! В случае пролонгации КВ может быть изменено'
                ) : 'Договор успешно согласован!'}
                  {offer.policyData.accreditation === false ? (
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={<div>Согласно постановлению ПРАВИТЕЛЬСТВА РОССИЙСКОЙ ФЕДЕРАЦИИ от 18 января 2023 г. №39</div>}
                      hideOnClick={false}
                    >
                      <div role="button" className="gift pointer d-lock">
                        <span>Аккредитована по постановлению №39</span>
                        <i className="icon icon-tooltip"><FontAwesomeIcon icon={faExclamation} className="fa-fw" /></i>
                      </div>
                    </Tippy>
                  ) : null}
                  {((['pari', 'amt', 'ingoss'].includes(offer.policyData.polis_online_code) && formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'unicredit')
                || formData.bank.value.value === 'vtb') ? (
                  <> (Страховая сумма по договору будет автоматически увеличена в соответствии с требованиями банка!)</>
                    ) : null}
                  {(offer.policyData.polis_online_code === 'renessans' || offer.policyData.polis_online_code === 'renessans-second') && formData.bank.value.value === 'domrf' ? (<> (Полную комиссию по данному договору получает банк-кредитор АО «Банк Дом.РФ»)</>) : null}
                  <div className="mr-2 d-inline-block" />
                  {(offer.policyData.polis_online_code === 'vsk' && formData.bank.value.value === 'sberbank') ? (
                    <div role="button" className="gift pointer d-lock" onClick={() => setModalGift(true)}>
                      <span>Чек-ап в подарок</span>
                      <i className="icon icon-tooltip"><FontAwesomeIcon icon={faExclamation} className="fa-fw" /></i>
                    </div>
                  ) : null}
                </div>
                {offer.policyData.comment ? (
                  <div>
                    {offer.policyData.comment}
                  </div>
                ) : null}
                {offer.policyData.customContract && !offer.policyData.isPolisOnlineContract ? <div className="main-color">Оформление через личный договор</div> : null}
                {((discountPercent[offer.policyData.polis_online_code] && discountPercent[offer.policyData.polis_online_code].bankList.includes(formData.bank.value.value))
                  || (allowancePercent[offer.policyData.polis_online_code] && allowancePercent[offer.policyData.polis_online_code].bankList.includes(formData.bank.value.value))) ? (
                    <>
                      <div className="discount_plate">
                        <Tippy
                          placement="bottom"
                          className="tippy-custom"
                          arrow={false}
                          offset={[0, 5]}
                          content={(
                            <div>
                              Допустимая {adjustmentType[offer.policyData.polis_online_code].value.value === 'discount' ? 'скидка' : 'надбавка'} (от {getConstraints(`${adjustmentType[offer.policyData.polis_online_code].value.value}Percent${offer.policyData.polis_online_code.charAt(0).toUpperCase() + offer.policyData.polis_online_code.slice(1)}`).numericality.greaterThanOrEqualTo}% до {getConstraints(`${adjustmentType[offer.policyData.polis_online_code].value.value}Percent${offer.policyData.polis_online_code.charAt(0).toUpperCase() + offer.policyData.polis_online_code.slice(1)}`).numericality.lessThanOrEqualTo}%) которая предоставляется за счет
                              страховой компании. Скидка НЕ за счет комиссионного вознаграждения.
                              {(offer.policyData.polis_online_code === 'renessans' && (
                                formData.bank.value.value === 'vtb'
                                  || formData.bank.value.value === 'mtsbank'
                                  || formData.bank.value.value === 'bgfbank')
                              ) ? (
                                  ' Применяется только если дата подписания кредитного договора до 18.09.2023'
                                ) : null}
                              {offer.policyData.polis_online_code === 'renessans' ? (
                                ' По страхованию имущества: страховая сумма <= 10 000 000 руб. По страхованию жизни: страховая сумма <= 10 000 000 руб., возраст Застрахованного 18-40 лет или 51-60 лет.'
                              ) : null}
                            </div>
                          )}
                          hideOnClick={false}
                        >
                          <div className="mr-1 d-flex">
                            {adjustmentType[offer.policyData.polis_online_code].value.value === 'discount' ? (
                              <>
                                <div className="percent-after-select">
                                  <InputMask
                                    placeholder="%"
                                    maskChar=""
                                    onBlur={() => validateField(offer.policyData.polis_online_code, discountPercent, setDiscountPercent)}
                                    disabled={disableField}
                                    onChange={(e) => handleChange(e, offer.policyData.polis_online_code, discountPercent, setDiscountPercent)}
                                    id="discountPercent"
                                    name="discountPercent"
                                    className={classnames('form-control', { error: discountPercent[offer.policyData.polis_online_code].errorMessage })}
                                    value={discountPercent[offer.policyData.polis_online_code].value}
                                    mask={getMask('discountPercent').mask}
                                    formatChars={getMask('discountPercent').formatChars}
                                  />
                                </div>
                                {discountPercent[offer.policyData.polis_online_code].errorMessage && (
                                  <div className="validation-error">
                                    {discountPercent[offer.policyData.polis_online_code].errorMessage}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="percent-after-select">
                                  <InputMask
                                    placeholder="%"
                                    maskChar=""
                                    onBlur={() => validateField(offer.policyData.polis_online_code, allowancePercent, setAllowancePercent)}
                                    disabled={disableField}
                                    onChange={(e) => handleChange(e, offer.policyData.polis_online_code, allowancePercent, setAllowancePercent)}
                                    id="allowancePercent"
                                    name="allowancePercent"
                                    className={classnames('form-control', { error: allowancePercent[offer.policyData.polis_online_code].errorMessage })}
                                    value={allowancePercent[offer.policyData.polis_online_code].value}
                                    mask={getMask('discountPercent').mask}
                                    formatChars={getMask('discountPercent').formatChars}
                                  />
                                </div>
                                {allowancePercent[offer.policyData.polis_online_code].errorMessage && (
                                  <div className="validation-error">
                                    {allowancePercent[offer.policyData.polis_online_code].errorMessage}
                                  </div>
                                )}
                              </>
                            )}
                            <Select
                              menuPlacement="top"
                              classNamePrefix="react-select"
                              styles={customStyles}
                              name="adjustmentType"
                              isDisabled={disableField}
                              noOptionsMessage={() => 'Не найдено'}
                              inputId="adjustmentType"
                              onBlur={() => validateField(offer.policyData.polis_online_code, adjustmentType, setAdjustmentType)}
                              value={adjustmentType[offer.policyData.polis_online_code].value}
                              onChange={(e) => {
                                setDiscountPercent((prev) => ({
                                  ...prev,
                                  [offer.policyData.polis_online_code]: {
                                    ...prev[offer.policyData.polis_online_code],
                                    value: 0,
                                    errorMessage: '',
                                  },
                                }));
                                setAllowancePercent((prev) => ({
                                  ...prev,
                                  [offer.policyData.polis_online_code]: {
                                    ...prev[offer.policyData.polis_online_code],
                                    value: 0,
                                    errorMessage: '',
                                  },
                                }));
                                handleChange(e, offer.policyData.polis_online_code, adjustmentType, setAdjustmentType);
                              }}
                              options={adjustmentTypeOptions[offer.policyData.polis_online_code][formData.bank.value.value]}
                            />
                          </div>
                        </Tippy>
                        <button
                          disabled={adjustmentType[offer.policyData.polis_online_code].value.value === 'discount' ? discountPercent[offer.policyData.polis_online_code].errorMessage : allowancePercent[offer.policyData.polis_online_code].errorMessage}
                          type="button"
                          onClick={() => calculateDiscount(offer.policyData.polis_online_code, adjustmentType[offer.policyData.polis_online_code].value.value)}
                          className={classnames('btn search_results__table--button discount_button', { disabled: blurClass || (adjustmentType[offer.policyData.polis_online_code].value.value === 'discount' ? discountPercent[offer.policyData.polis_online_code].errorMessage : allowancePercent[offer.policyData.polis_online_code].errorMessage) })}
                        >
                          Применить
                        </button>
                      </div>
                    </>
                  ) : null}
              </div>
              <div className="search_results__table--cell">
                <div>
                  <button
                    type="button"
                    onClick={(e) => {
                      if (((discountPercent[offer.policyData.polis_online_code] && discountPercent[offer.policyData.polis_online_code].bankList.includes(formData.bank.value.value))
                        || (allowancePercent[offer.policyData.polis_online_code] && allowancePercent[offer.policyData.polis_online_code].bankList.includes(formData.bank.value.value)))) {
                        setFormData((prev) => ({
                          ...prev,
                          discountPercent: {
                            ...prev.discountPercent,
                            errorMessage: '',
                            value: discountPercent[offer.policyData.polis_online_code].value,
                          },
                          allowancePercent: {
                            ...prev.allowancePercent,
                            errorMessage: '',
                            value: allowancePercent[offer.policyData.polis_online_code].value,
                          },
                          adjustmentType: {
                            ...prev.adjustmentType,
                            errorMessage: '',
                            value: adjustmentType[offer.policyData.polis_online_code].value,
                          },
                        }));
                      } else {
                        setFormData((prev) => ({
                          ...prev,
                          discountPercent: {
                            ...prev.discountPercent,
                            errorMessage: '',
                            value: 0,
                          },
                          allowancePercent: {
                            ...prev.allowancePercent,
                            errorMessage: '',
                            value: 0,
                          },
                          adjustmentType: {
                            ...prev.adjustmentType,
                            errorMessage: '',
                            value: { label: 'Скидка', value: 'discount' },
                          },
                        }));
                      }
                      goToFullForm(e, offer.policyData.polis_online_code, offer.price, companies[offer.policyData.polis_online_code].name, offer.integration, offer.commission);
                    }}
                    className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass })}
                  >
                    Оформить
                  </button>
                  <button
                    type="button"
                    onClick={(e) => toggleCompanyCommercialOffer(e, offer.policyData.polis_online_code)}
                    className={classnames('btn btn-secondary search_results__table--button btn-commercial-offer', { disabled: blurClass.length > 0, active: commercialOfferCompanies.findIndex((company) => company === offer.policyData.polis_online_code) !== -1 })}
                  >
                    {commercialOfferCompanies.findIndex((company) => company === offer.policyData.polis_online_code) !== -1 ? (<><strong>-</strong> из предложения</>) : (<><strong>+</strong> в предложение</>)}
                  </button>
                </div>
                <div className="insurance-info-toggle" onClick={(e) => toggleInsuranceInfoFlag(e, offer)}>
                  {offer.insuranceInfoFlag === 'open' ? (
                    <div className="insurance-info-toggle__button">
                      <span>Скрыть подробности</span>
                      <FontAwesomeIcon icon={faChevronUp} className="fa-fw" />
                    </div>
                  ) : null}
                  {offer.insuranceInfoFlag === 'close' ? (
                    <div className="insurance-info-toggle__button">
                      <span>Подробности о полисе</span>
                      <FontAwesomeIcon icon={faChevronDown} className="fa-fw" />
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
        {offer.insuranceInfoFlag !== 'no' ? (
          <div className={classnames('insurance-info')}>
            {companies[offer.policyData.polis_online_code].policy_template && companies[offer.policyData.polis_online_code].policy_template[draftInfo.type] && companies[offer.policyData.polis_online_code].policy_template[draftInfo.type][formData.bank.value.value] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].policy_template[draftInfo.type][formData.bank.value.value]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Пример полиса</span>
              </a>
            ) : null}
            {companies[offer.policyData.polis_online_code].rules && companies[offer.policyData.polis_online_code].rules[draftInfo.type] && companies[offer.policyData.polis_online_code].rules[draftInfo.type][formData.bank.value.value] ? (
              <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].rules[draftInfo.type][formData.bank.value.value]}`}>
                <FontAwesomeIcon icon={faFile} className="fa-fw" />
                <span>Правила страхования</span>
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    ));
  }, [
    discountPercentLoadingFlag,
    discountPercent,
    allowancePercent,
    formData.bank.value.value,
    JSON.stringify(adjustmentType),
    allPolices.mortgage,
    allPolices.mortgageLife,
    allPolices.mortgageComplex,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission,
    commercialOfferCompanies.length]);

  const errorPolicyBlock = useMemo(() => {
    if (!loadingInit) {
      return '';
    }
    return allPolices[draftInfo.type].filter((offer) => (!offer.price && offer.errorMessage) || (offer.price && !offer.isSuccess && offer.errorMessage)).map((offer) => (
      <div key={offer.policyData.polis_online_code} className="search_results__table--row-container">
        <div name={offer.policyData.polis_online_code} className={classnames('search_results__table--row')}>
          <div className="search_results__table--cell">
            <span className="color-light label">Страховая:</span>
            <div>
              <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[offer.policyData.polis_online_code].logo}`} alt="logo" className="search_results__logo" />
            </div>
            <div className="search_results__company-name">
              {companies[offer.policyData.polis_online_code].name}
            </div>
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Стоимость полиса:</span>
            <div>
              <div>
                {offer.policyData.price ? new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(offer.policyData.price) : '-'}
              </div>
            </div>
          </div>
          <div className="search_results__table--cell">
            {showCommission && userInfo.hide_commission === 0 ? (
              <>
                <span className="color-light label">Вознаграждение:</span>
                <div>-</div>
              </>
            ) : (
              <>
                <span className="color-light label">Рейтинг:</span>
                <div className="company_item__star">
                  {[...Array(companies[offer.policyData.polis_online_code].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </>
            )}
          </div>
          <div className="search_results__table--cell">
            <span className="color-light label">Комментарий:</span>
            <div>
              <span className="text-danger">{offer.errorMessage}</span>
            </div>
            {theme.domain !== 'igk23' ? (
              <div>
                <span className="text-warning">Оформите полис через заявку</span>
              </div>
            ) : null}
          </div>
          {theme.domain !== 'igk23' ? (
            <div className="search_results__table--cell">
              <div className="buttons">
                <a href="/insurance_request" target="_blank" style={{ textDecoration: 'none' }}>
                  <button
                    type="button"
                    className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass })}
                  >
                    Заявка
                  </button>
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    ));
  }, [
    loadingInit,
    allPolices.mortgage,
    allPolices.mortgageLife,
    allPolices.mortgageComplex,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission,
  ]);

  const otherCompaniesBlock = useMemo(() => {
    if (Object.keys(companies).length === 0 || !loadingInit) {
      return '';
    }
    const policesList = allPolices[draftInfo.type];
    return Object.entries(companies).filter(([companyName, companyData]) => policesList.findIndex((company) => company.policyData.polis_online_code === companyName) === -1 && !companyData.hide).map(([companyName, value]) => (
      <div key={companyName} className="search_results__table--row">
        <div className="search_results__table--cell">
          <span className="color-light label">Страховая:</span>
          <div>
            <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${value.logo}`} alt="logo" className="search_results__logo" />
          </div>
          <div className="search_results__company-name">
            {value.name}
          </div>
        </div>
        {(loadingResults)
          ? <div className="search_results__table--cell"><ReactLoading className="loading-circle ml-auto mr-auto" type="spin" height={37} width={37} /></div>
          : (
            <>
              <div className="search_results__table--cell">
                <span className="color-light label">Стоимость полиса:</span>
                <div>-</div>
              </div>
              <div className="search_results__table--cell">
                {showCommission && userInfo.hide_commission === 0 ? (
                  <>
                    <span className="color-light label">Вознаграждение:</span>
                    <div>-</div>
                  </>
                ) : (
                  <>
                    <span className="color-light label">Рейтинг:</span>
                    <div className="company_item__star">
                      {[...Array(companies[companyName].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                    </div>
                  </>
                )}
              </div>
              <div className="search_results__table--cell">
                <span className="color-light label">Комментарий:</span>
                <div>
                  <span className="text-danger">Отказ</span>
                </div>
                {theme.domain !== 'igk23' ? (
                  <div>
                    <span className="text-warning">Оформите полис через заявку</span>
                  </div>
                ) : null}
              </div>
              {theme.domain !== 'igk23' ? (
                <div className="search_results__table--cell">
                  <div className="buttons">
                    <a href="/insurance_request" target="_blank" style={{ textDecoration: 'none' }}>
                      <button
                        type="button"
                        className={classnames('btn btn-success search_results__table--button mb-1', { disabled: blurClass })}
                      >
                        Заявка
                      </button>
                    </a>
                  </div>
                </div>
              ) : null}
            </>
          )}
      </div>
    ));
  }, [
    loadingInit,
    allPolices.mortgage,
    allPolices.mortgageLife,
    allPolices.mortgageComplex,
    draftInfo.type,
    loadingResults,
    selectedSort,
    policySortOrderUp,
    showCommission]);

  return (
    <>
      <form name="mortgageShortForm" id="mortgage_short_form" onSubmit={(e) => saveDraft(e, true)}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3  mb-3 mb-lg-0">
                <label htmlFor="bank">Банк-кредитор</label>
                <Select
                  menuPlacement="auto"
                  classNamePrefix="react-select"
                  styles={customStyles}
                  name="bank"
                  isDisabled={showForm === 'mortgage' || showForm === 'mortgageLife' || showForm === 'mortgageComplex' || disableField}
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="bank"
                  onBlur={handleValidate}
                  value={formData.bank.value.value ? formData.bank.value : null}
                  onChange={(e) => handleChange(e, 'bank', formData, setFormData)}
                  options={bankOptions}
                />
              </div>
              <div className="col-lg-3 mb-3 mb-lg-0">
                <label htmlFor="loanBalance">Остаток кредита</label>
                <CurrencyInput
                  inputMode="decimal"
                  id="loanBalance"
                  disabled={showForm === 'mortgage' || showForm === 'mortgageLife' || showForm === 'mortgageComplex' || disableField}
                  name="loanBalance"
                  decimalSeparator="."
                  placeholder="10 000 000"
                  value={formData.loanBalance.value}
                  decimalsLimit={2}
                  onBlur={handleValidate}
                  onValueChange={(e) => handleChange(e, 'loanBalance', formData, setFormData)}
                  className={classnames('form-control', { error: formData.loanBalance.errorMessage })}
                />
                {formData.loanBalance.errorMessage && (
                  <div className="validation-error">
                    {formData.loanBalance.errorMessage}
                  </div>
                )}
              </div>
              {(draftInfo.type !== 'mortgageLife' && formData.buildingType.value.value !== 'LAND_PLOT') ? (
                <div className="col-lg-3">
                  <label htmlFor="yearOfConstruction">Год постройки</label>
                  <InputMask
                    inputMode="decimal"
                    maskChar=""
                    onBlur={handleValidate}
                    onChange={(e) => handleChange(e, 'yearOfConstruction', formData, setFormData)}
                    id="yearOfConstruction"
                    placeholder="2014"
                    name="yearOfConstruction"
                    className={classnames('form-control', { error: formData.yearOfConstruction.errorMessage })}
                    value={formData.yearOfConstruction.value || ''}
                    mask={getMask('year').mask}
                    formatChars={getMask('year').formatChars}
                    disabled={disableField}
                  />
                  {formData.yearOfConstruction.errorMessage && (
                    <div className="validation-error">
                      {formData.yearOfConstruction.errorMessage}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div className="form-group row">
              <div className={classnames('col-lg-3 mb-lg-0', { 'mb-3': formData.bank.value.value !== 'vtb' })}>
                <label htmlFor="birthday">Дата рождения</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  disabled={showForm === 'mortgage' || showForm === 'mortgageLife' || showForm === 'mortgageComplex' || disableField}
                  className={classnames('form-control', { error: formData.birthday.errorMessage })}
                  selected={formData.birthday.value ? formData.birthday.value : null}
                  onChange={(e) => handleChange(e, 'birthday', formData, setFormData)}
                  name="birthday"
                  id="birthday"
                  onBlur={() => handleValidate(null, { fieldName: 'birthday' })}
                  onCalendarClose={() => handleValidate(null, { fieldName: 'birthday' })}
                  onClickOutside={() => handleValidate(null, { fieldName: 'birthday' })}
                  locale={ru}
                  maxDate={subYears(new Date(), 18)}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                {formData.birthday.errorMessage && (
                  <div className="validation-error">
                    {formData.birthday.errorMessage}
                  </div>
                )}
              </div>
              <div className={classnames('col-lg-3 mb-lg-0', { 'mb-3': formData.bank.value.value !== 'vtb' })}>
                <label htmlFor="gender">Пол</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customStyles}
                  onBlur={handleValidate}
                  isDisabled={showForm === 'mortgage' || showForm === 'mortgageLife' || showForm === 'mortgageComplex' || disableField}
                  name="gender"
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="gender"
                  isSearchable={false}
                  value={formData.gender.value.value ? formData.gender.value : null}
                  onChange={(e) => handleChange(e, 'gender', formData, setFormData)}
                  options={classifiers.api.gender}
                />
              </div>
              <div className={classnames('col-lg-3 mb-lg-0', { 'mb-3': formData.bank.value.value !== 'vtb' })}>
                <label htmlFor="buildingType">Тип объекта</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customStyles}
                  isDisabled={showForm === 'mortgage' || showForm === 'mortgageLife' || showForm === 'mortgageComplex' || disableField}
                  onBlur={handleValidate}
                  name="buildingType"
                  noOptionsMessage={() => 'Не найдено'}
                  inputId="buildingType"
                  isSearchable={false}
                  value={formData.buildingType.value.value ? formData.buildingType.value : null}
                  onChange={(e) => handleChange(e, 'buildingType', formData, setFormData)}
                  options={classifiers.api.buildingType}
                />
              </div>
            </div>
            {['vtb'].includes(formData.bank.value.value) ? (
              <div className="form-group row">
                <div className="col-lg-3 mb-lg-0">
                  <label htmlFor="creditAgreementAddress">Город выдачи кредита</label>
                  <ReactDadata
                    id="creditAgreementAddress"
                    name="creditAgreementAddress"
                    className={classnames('form-control', { error: formData.creditAgreementAddress.errorMessage })}
                    query={formData.creditAgreementAddress.value.value}
                    onBlur={handleValidate}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => handleChange(e, 'creditAgreementAddress', formData, setFormData)}
                    disabled={disableField}
                    onFocus={setCursorToEnd}
                    bounds="city"
                    placeholder="Введите город"
                  />
                  {formData.creditAgreementAddress.errorMessage && (
                    <div className="validation-error">
                      {formData.creditAgreementAddress.errorMessage}
                    </div>
                  )}
                </div>
                {formData.creditAgreementFlag.value ? (
                  <div className="col-lg-3 mb-lg-0">
                    <label htmlFor="creditAgreementDate">Начало действия кредитного договора</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      disabled={disableField}
                      onBlur={() => handleValidate(null, { fieldName: 'creditAgreementDate' })}
                      onCalendarClose={() => handleValidate(null, { fieldName: 'creditAgreementDate' })}
                      onClickOutside={() => handleValidate(null, { fieldName: 'creditAgreementDate' })}
                      className={classnames('form-control', { error: formData.creditAgreementDate.errorMessage })}
                      selected={formData.creditAgreementDate.value ? formData.creditAgreementDate.value : null}
                      onChange={(e) => handleChange(e, 'creditAgreementDate', formData, setFormData)}
                      name="creditAgreementDate"
                      id="creditAgreementDate"
                      locale={ru}
                      minDate={subYears(new Date(), 50)}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                      }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    {formData.creditAgreementDate.errorMessage && (
                      <div className="validation-error">
                        {formData.creditAgreementDate.errorMessage}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <MortgageOptionsForm
          formData={formData}
          toggleFlag={toggleFlag}
          draftInfo={draftInfo}
        />
        <div className="row row-grid">
          <div className="col-lg-12">
            {showForm === 'mortgage' || showForm === 'mortgageLife' || showForm === 'mortgageComplex' ? (
              <button disabled={disableField} type="button" className="btn btn-success" onClick={(e) => backToForm(e, 'short')}>Изменить данные</button>
            ) : (
              <button disabled={disableField} type="submit" className="btn btn-success">Показать цены</button>
            )}
          </div>
          {(loadingResults) && (
            <div className="col-lg-6">
              <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={38} width={38} />
              <span>Идет подбор предложений..</span>
            </div>
          )}
        </div>
      </form>
      {showForm === 'short' ? (
        <>
          {(loadingResults) && (
            <ProgressBar stopCalculation={stopCalculation} />
          )}
          {!loadingResults && !allPolices[draftInfo.type].length && loadingInit && !blurClass ? (
            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="error_block">Повторите попытку позже</div>
              </div>
            </div>
          ) : null}
          {loadingInit && (
            <div className="position-relative mt-2">
              <div className={`search_results__cont ${blurClass}`}>
                <Element name="searchResults">
                  <div className="search_results__title h1">Результаты поиска</div>
                </Element>
                {userInfo.hide_commission === 0 && (
                  <div className="form-inline mt-2 mb-4">
                    <span className="switch-text">Режим работы с клиентом</span>
                    <label className="switch">
                      <input type="checkbox" />
                      <span onClick={() => setShowCommission((prev) => !prev)} className="slider round" />
                    </label>
                  </div>
                )}
                <div className="search_results__table">
                  {allPolices[draftInfo.type].length ? (
                    <button
                      onClick={(e) => toggleAllCompaniesCommercialOffer(e, allPolices[draftInfo.type])}
                      type="button"
                      className="btn btn-secondary btn-commercial-offer-add-all show-mobile"
                    >
                      {commercialOfferCompanies.length ? <span><strong>-</strong> Убрать все СК из предложения</span> : <span><strong>+</strong> Добавить все СК в предложение</span>}
                    </button>
                  ) : null}
                  <div className="search_results__table--head">
                    <div className="search_results__table--row">
                      <div className="search_results__table--cell">Страховая компания</div>
                      <div className="search_results__table--cell">
                        <a className={classnames({ dotted_link: selectedSort === 'price' })} href="" onClick={(e) => { changeSort(e, 'price'); }}>
                          Стоимость
                          {policySortOrderUp && selectedSort === 'price' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                            : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                        </a>
                      </div>
                      <div className="search_results__table--cell">
                        {showCommission && userInfo.hide_commission === 0 ? (
                          <a className={classnames({ dotted_link: selectedSort === 'commissionCurrency' })} href="" onClick={(e) => { changeSort(e, 'commissionCurrency'); }}>
                            Вознаграждение
                            {policySortOrderUp && selectedSort === 'commissionCurrency' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        ) : (
                          <a className={classnames({ dotted_link: selectedSort === 'rating' })} href="" onClick={(e) => { changeSort(e, 'rating'); }}>
                            Рейтинг
                            {policySortOrderUp && selectedSort === 'rating' ? (<FontAwesomeIcon icon={faLongArrowAltUp} className="fa-fw" />)
                              : (<FontAwesomeIcon icon={faLongArrowAltDown} className="fa-fw" />)}
                          </a>
                        )}
                      </div>
                      <div className="search_results__table--cell">Комментарий</div>
                      <div className="search_results__table--cell">
                        {allPolices[draftInfo.type].length ? (
                          <button
                            onClick={(e) => toggleAllCompaniesCommercialOffer(e, allPolices[draftInfo.type])}
                            type="button"
                            className="btn btn-secondary btn-commercial-offer-add-all"
                          >
                            {commercialOfferCompanies.length ? <span><strong>-</strong> Убрать все СК из предложения</span> : <span><strong>+</strong> Добавить все СК в предложение</span>}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="search_results__table--body">
                    {loadingInit ? (
                      <>
                        {allPolicyBlock}
                        {errorPolicyBlock}
                        {otherCompaniesBlock}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              {blurClass && (
                <div className="blur_message">
                  Внесены изменения в данные для расчета - необходимо обновить расчёт. Нажмите кнопку «Показать цены»
                </div>
              )}
            </div>
          )}
        </>
      ) : null}
      <CommercialOffer
        policySortOrderUp={policySortOrderUp}
        selectedSort={selectedSort}
        setLoadingCommercialOffer={setLoadingCommercialOffer}
        commercialOfferCompanies={commercialOfferCompanies}
        policyId={draftInfo.id}
        policyIdHash={draftInfo.hash}
        product="mortgage"
        productName="Ипотека"
        setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
        showCommercialOfferModalForm={showCommercialOfferModalForm}
        ref={commercialOfferRef}
        contactEmail={formData.contactEmail.value}
      />
    </>
  );
}
