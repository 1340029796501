import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { ReactDadata } from 'react-dadata';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subYears, addDays, addMonths } from 'date-fns';
import { faCalendarAlt, faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import Tippy from '@tippyjs/react';
import getMask from '../../Utils/Masks';
import { customFetch, customSelectStyle, handleChange } from '../../Utils/Helpers';
import CargoInsuranceResearch from './CargoInsuranceResearch';
import AccountSystem from '../Blocks/AccountSystem';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';
import useUserInfoStore from '../../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';

export default function CargoForm(props) {
  const {
    customFieldName,
    companies,
    privacyPolicy,
    formData,
    disableField,
    goToBuying,
    handleValidate,
    toggleFlag,
    setCursorToEnd,
    insuredAddressRegistrationRef,
    saveDraft,
    draftInfo,
    chosenOffer,
    setFormData,
    linkToDeal,
    leadSource,
    managerAttracted,
    setLeadSource,
    setManagerAttracted,
    setLinkToDeal,
    customFieldOptions,
    setCustomField,
    customField,
    leadSourcesOptions,
    managerAttractedOptions,
    newAgent,
    setNewAgent,
  } = props;

  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const customStyles = useMemo(() => customSelectStyle(), []);
  const [typeRide, setTypeRide] = useState([{ label: '', value: '' }]);
  const [typeDocs, setTypeDocs] = useState([{ label: '', value: '' }]);

  useEffect(() => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/cargo_insurance`, {
      headers: {
        Authorization: lsToken,
      },
    }).then((response) => response.json()).then((response) => {
      setTypeRide(response.ride);
      setTypeDocs(response.docs);
    }).catch((ex) => console.log(ex.messages));

    setFormData((prev) => ({
      ...prev,
      startDate: {
        value: formData.startDate.value ? new Date(formData.startDate.value) : addDays(new Date(), 1),
        errorMessage: '',
        validationRequired: true,
        validationType: 'date',
      },
      endDate: {
        value: addMonths(new Date(), 1),
        errorMessage: '',
        validationRequired: true,
        validationType: 'date',
      },
    }));
  }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      endDate: {
        value: addMonths(new Date(formData.startDate.value), 1),
        errorMessage: '',
        validationRequired: true,
        validationType: 'date',
      },
    }));
  }, [formData.startDate.value]);

  const getNumericForLabel = () => {
    if (formData.overload.value) {
      return {
        members: 1,
        infocargo: 2,
        infotrans: 3,
        overload: 4,
        policy: 5,
        contact: 6,
        final: 7,
      };
    }
    return {
      members: 1,
      infocargo: 2,
      infotrans: 3,
      overload: 4,
      policy: 5,
      contact: 6,
      final: 7,
    };
  };

  const filterRide = (data) => {
    const transport = formData.transport.value?.value;

    switch (transport) {
      case 'automobile':
        return data.filter((el) => ['container', 'cistern', 'metallic', 'refrigerator', 'thermos', 'awning', 'openboard', 'platform'].includes(el.value));
      case 'aviation':
        return data.filter((el) => ['container', 'board'].includes(el.value));
      case 'railway':
        return data.filter((el) => ['container', 'cistern', 'wagon', 'refrigerator', 'platform', 'semiwagon'].includes(el.value));
      case 'seariver':
        return data.filter((el) => ['container', 'cistern', 'hold', 'deck', 'bysteam', 'barge'].includes(el.value));
      default:
        return data;
    }
  };

  const changeBeneficiaryFace = (e) => {
    setFormData((prev) => ({
      ...prev,
      beneficiaryFlag: {
        ...prev.beneficiaryFlag,
        value: !e.value,
        errorMessage: '',
      },
      beneficiaryName: {
        ...prev.beneficiaryName,
        value: { data: { inn: '' }, value: '' },
        errorMessage: '',
      },
    }));
  };

  return (
    <div className="container-cargoinsurance">
      <h3 className="mr-auto"><span className="title_num">{getNumericForLabel().members}</span>Участники</h3>
      <div className="card-body w-100 mb-4">
        <div className="form-group row">
          <div className="col-lg-4  mb-3 mb-lg-0">
            <label htmlFor="lastName">Тип страхователя</label>
            <Select
              menuPlacement="auto"
              styles={customSelectStyle()}
              onBlur={handleValidate}
              classNamePrefix="react-select"
              name="chooseFace"
              id="chooseFace"
              isDisabled={disableField}
              noOptionsMessage={() => 'Не найдено'}
              inputId="chooseFace"
              isSearchable
              value={formData.chooseFace.value}
              onChange={(e) => handleChange(e, 'chooseFace', formData, setFormData)}
              options={[{ value: true, label: 'Юр.лицо' }, { value: false, label: 'Физ.лицо' }]}
            />
          </div>
          {formData.chooseFace.value.value
            ? (
              <>
                <div className="col-lg-4  mb-3 mb-lg-0">
                  <label htmlFor="lastName">Страхователь</label>
                  <ReactDadata
                    suggestionType="party"
                    name="organizationName"
                    id="organizationName"
                    onBlur={handleValidate}
                    className={classnames('form-control', { error: formData.organizationName.errorMessage })}
                    query={formData.organizationName.value.data?.inn ? formData.organizationName.value.value : ''}
                    disabled={disableField}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => handleChange(e, 'organizationName', formData, setFormData)}
                    placeholder="Введите название или ОГРН"
                  />
                  {formData.organizationName.errorMessage && (
                    <div className="validation-error">
                      {formData.organizationName.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-4  mb-3 mb-lg-0">
                  <label htmlFor="lastName">ИНН страхователя</label>
                  <ReactDadata
                    suggestionType="party"
                    name="organizationName"
                    id="organizationName"
                    onBlur={handleValidate}
                    className={classnames('form-control', { error: formData.organizationName.errorMessage })}
                    query={formData.organizationName.value.data.inn}
                    disabled={disableField}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    mask={getMask('inn').mask}
                    formatChars={getMask('inn').formatChars}
                    onChange={(e) => handleChange(e, 'organizationName', formData, setFormData)}
                    placeholder="ИНН"
                  />
                </div>
              </>
            )
            : (
              <>
                <div className="col-lg-4  mb-3 mb-lg-0">
                  <label htmlFor="lastName">Фамилия</label>
                  <ReactDadata
                    suggestionType="fio"
                    id="lastName"
                    name="lastName"
                    onBlur={handleValidate}
                    disabled={disableField}
                    placeholder="Иванов"
                    query={formData.lastName.value}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => handleChange(e, 'lastName', formData, setFormData, null, { dadataType: 'fio' })}
                    parts={['SURNAME']}
                    className={classnames('form-control', { error: formData.lastName.errorMessage })}
                  />
                  {formData.lastName.errorMessage && (
                    <div className="validation-error">
                      {formData.lastName.errorMessage}
                    </div>
                  )}
                </div>

                <div className="col-lg-4  mb-3 mb-lg-0">
                  <label htmlFor="firstName">Имя</label>
                  <ReactDadata
                    suggestionType="fio"
                    id="firstName"
                    name="firstName"
                    disabled={disableField}
                    placeholder="Иван"
                    onBlur={handleValidate}
                    query={formData.firstName.value}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => handleChange(e, 'firstName', formData, setFormData, null, { dadataType: 'fio' })}
                    parts={['NAME']}
                    className={classnames('form-control', { error: formData.firstName.errorMessage })}
                  />
                  {formData.firstName.errorMessage && (
                    <div className="validation-error">
                      {formData.firstName.errorMessage}
                    </div>
                  )}
                </div>

                <div className="col-lg-4  mb-3 mb-lg-0">
                  <label htmlFor="middleName">Отчество</label>
                  <ReactDadata
                    suggestionType="fio"
                    id="middleName"
                    name="middleName"
                    disabled={disableField}
                    placeholder="Иванович"
                    onBlur={handleValidate}
                    query={formData.middleName.value}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onChange={(e) => handleChange(e, 'middleName', formData, setFormData, null, { dadataType: 'fio' })}
                    parts={['PATRONYMIC']}
                    className={classnames('form-control', { error: formData.middleName.errorMessage })}
                  />
                  {formData.middleName.errorMessage && (
                    <div className="validation-error">
                      {formData.middleName.errorMessage}
                    </div>
                  )}
                </div>

                <div className="col-lg-4  mb-3 mb-lg-0">
                  <label htmlFor="passport">Серия и номер паспорта</label>
                  <InputMask
                    maskChar=""
                    onChange={(e) => handleChange(e, 'passport', formData, setFormData)}
                    id="passport"
                    onBlur={handleValidate}
                    disabled={disableField}
                    placeholder="1111 111111"
                    name="passport"
                    className={classnames('form-control', { error: formData.passport.errorMessage })}
                    mask={getMask('passportNumberSerie').mask}
                    value={formData.passport.value}
                    inputMode="tel"
                  />
                  {formData.passport.errorMessage && (
                    <div className="validation-error">
                      {formData.passport.errorMessage}
                    </div>
                  )}
                </div>

                <div className="col-lg-4 mb-3 mb-lg-0 d-flex flex-column">
                  <label htmlFor="birthday">Дата рождения</label>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false },
                    }}
                    disabled={disableField}
                    onBlur={() => handleValidate(null, { fieldName: 'birthday' })}
                    onCalendarClose={() => handleValidate(null, { fieldName: 'birthday' })}
                    onClickOutside={() => handleValidate(null, { fieldName: 'birthday' })}
                    className={classnames('form-control', { error: formData.birthday.errorMessage })}
                    selected={formData.birthday.value ? new Date(formData.birthday.value) : null}
                    onChange={(e) => handleChange(e, 'birthday', formData, setFormData)}
                    name="birthday"
                    id="birthday"
                    locale={ru}
                    maxDate={new Date()}
                    minDate={subYears(new Date(), 100)}
                    showYearDropdown
                    showMonthDropdown
                    dateFormat="dd.MM.yyyy"
                    placeholderText="ДД.ММ.ГГГГ"
                    customInput={
                      <InputMask mask="99.99.9999" inputMode="tel" />
                    }
                  />
                  <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                  {formData.birthday.errorMessage && (
                    <div className="validation-error">
                      {formData.birthday.errorMessage}
                    </div>
                  )}
                </div>

                <div className="col-lg-4  mb-3 mb-lg-0 d-flex flex-column">
                  <label htmlFor="passportDate">Дата выдачи паспорта</label>
                  <DatePicker
                    popperModifiers={{
                      computeStyle: { gpuAcceleration: false },
                    }}
                    className={classnames('form-control', { error: formData.passportDate.errorMessage })}
                    selected={formData.passportDate.value ? new Date(formData.passportDate.value) : null}
                    onChange={(e) => handleChange(e, 'passportDate', formData, setFormData)}
                    name="passportDate"
                    onBlur={() => handleValidate(null, { fieldName: 'passportDate' })}
                    onCalendarClose={() => handleValidate(null, { fieldName: 'passportDate' })}
                    onClickOutside={() => handleValidate(null, { fieldName: 'passportDate' })}
                    id="passportDate"
                    locale={ru}
                    disabled={disableField}
                    maxDate={new Date()}
                    showYearDropdown
                    showMonthDropdown
                    dateFormat="dd.MM.yyyy"
                    placeholderText="ДД.ММ.ГГГГ"
                    customInput={
                      <InputMask mask="99.99.9999" inputMode="tel" />
                    }
                  />
                  <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                  {formData.passportDate.errorMessage && (
                    <div className="validation-error">
                      {formData.passportDate.errorMessage}
                    </div>
                  )}
                </div>

                <div className="col-lg-8  mb-3 mb-lg-0">
                  <label htmlFor="passportDivision">Кем выдан</label>
                  <Tippy
                    className="tippy-custom"
                    arrow={false}
                    offset={[0, 5]}
                    content={<div style={{ maxWidth: 450 }}>Заполняйте строго как указано в паспорте</div>}
                    hideOnClick={false}
                  >
                    <div className="d-inline-block">
                      <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                    </div>
                  </Tippy>
                  <InputMask
                    maskChar=""
                    onChange={(e) => handleChange(e, 'passportDivision', formData, setFormData)}
                    id="passportDivision"
                    onBlur={handleValidate}
                    name="passportDivision"
                    placeholder=""
                    disabled={disableField}
                    className={classnames('form-control', { error: formData.passportDivision.errorMessage })}
                    value={formData.passportDivision.value}
                  />
                  {formData.passportDivision.errorMessage && (
                    <div className="validation-error">
                      {formData.passportDivision.errorMessage}
                    </div>
                  )}
                </div>

                <div className="col-lg-4  mb-3 mb-lg-0">
                  <label htmlFor="passportUnitNumber">Код подразделения</label>
                  <InputMask
                    maskChar=""
                    onChange={(e) => handleChange(e, 'passportUnitNumber', formData, setFormData, null, { pureValue: true })}
                    id="passportUnitNumber"
                    name="passportUnitNumber"
                    onBlur={handleValidate}
                    mask={getMask('passportUnitNumber').mask}
                    placeholder="123-456"
                    disabled={disableField}
                    formatChars={getMask('passportUnitNumber').formatChars}
                    className={classnames('form-control', { error: formData.passportUnitNumber.errorMessage })}
                    value={formData.passportUnitNumber.value}
                  />
                  {formData.passportUnitNumber.errorMessage && (
                    <div className="validation-error">
                      {formData.passportUnitNumber.errorMessage}
                    </div>
                  )}
                </div>

                <div className="col-lg-12 col-xl-8">
                  <label htmlFor="insuredAddressRegistration">Адрес регистрации</label>
                  <ReactDadata
                    id="insuredAddressRegistration"
                    name="insuredAddressRegistration"
                    className={classnames('form-control', { error: formData.insuredAddressRegistration.errorMessage })}
                    query={formData.insuredAddressRegistration.value.value}
                    token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                    onBlur={handleValidate}
                    disabled={disableField}
                    onChange={(e) => handleChange(e, 'insuredAddressRegistration', formData, setFormData)}
                    placeholder="Можете начать с ввода улицы"
                    onFocus={setCursorToEnd}
                    ref={insuredAddressRegistrationRef}
                  />
                  {formData.insuredAddressRegistration.errorMessage && (
                    <div className="validation-error">
                      {formData.insuredAddressRegistration.errorMessage}
                    </div>
                  )}
                </div>
              </>
            )}
        </div>
        <div className="form-group row">
          <div className="col-lg-4  mb-3 mb-lg-0">
            <label htmlFor="lastName">Тип выгодоприобретателя</label>
            <Select
              menuPlacement="auto"
              styles={customSelectStyle()}
              onBlur={handleValidate}
              placeholder={'Выберите тип'}
              classNamePrefix="react-select"
              name="chooseBeneficiaryFace"
              id="chooseBeneficiaryFace"
              isDisabled={disableField}
              noOptionsMessage={() => 'Не найдено'}
              inputId="chooseBeneficiaryFace"
              isSearchable
              value={formData.chooseBeneficiaryFace.value}
              onChange={(e) => {
                handleChange(e, 'chooseBeneficiaryFace', formData, setFormData);
                changeBeneficiaryFace(e);
              }}
              options={[{ value: true, label: 'Юр.лицо' }, { value: false, label: 'Физ.лицо' }]}
            />
          </div>
          <div className="col-lg-4  mb-3 mb-lg-0">
            <label>Выгодоприобретатель</label>
            <ReactDadata
              suggestionType="party"
              name="beneficiaryName"
              id="beneficiaryName"
              onBlur={handleValidate}
              disabled={disableField || formData.beneficiaryFlag.value}
              className={classnames('form-control', { error: formData.beneficiaryName.errorMessage })}
              query={formData.beneficiaryName.value.data?.inn ? formData.beneficiaryName.value.value : ''}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'beneficiaryName', formData, setFormData)}
              placeholder="Введите название или ОГРН"
            />
            {formData.beneficiaryName.errorMessage && (
              <div className="validation-error">
                {formData.beneficiaryName.errorMessage}
              </div>
            )}
            <div className={classnames('custom-checkbox form-check mt-2', { error: formData.beneficiaryFlag.errorMessage })}>
              <input
                disabled={disableField}
                checked={formData.beneficiaryFlag.value}
                name="beneficiaryFlag"
                onChange={(e) => {
                  toggleFlag(e);
                  handleChange(e, 'beneficiaryFlag', formData, setFormData);
                  setFormData((prev) => ({
                    ...prev,
                    beneficiaryName: {
                      ...prev.beneficiaryName,
                      value: { data: { inn: '' }, value: '' },
                      errorMessage: '',
                    },
                  }));
                }}
                className="form-check-input checkbox-styled"
                type="checkbox"
                id="beneficiaryFlag"
              />
              <label className="form-check-label" htmlFor="beneficiaryFlag">в соответствии с ст. 930 ГК РФ
                <Tippy
                  className="tippy-custom"
                  arrow={false}
                  offset={[0, 5]}
                  content={(
                    <div style={{ maxWidth: 450 }}>Договор страхования имущества в пользу
                      выгодоприобретателя
                      может быть заключен без указания имени или наименования выгодоприобретателя
                      (страхование «за
                      счет кого следует»)
                    </div>
                  )}
                  hideOnClick={false}
                >
                  <div className="d-inline-block">
                    <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                  </div>
                </Tippy>
              </label>
              {formData.beneficiaryFlag.errorMessage && (
                <div className="validation-error">
                  {formData.beneficiaryFlag.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4  mb-3 mb-lg-0">
            <label>ИНН выгодоприобретателя</label>
            <ReactDadata
              suggestionType="party"
              name="beneficiaryName"
              id="beneficiaryName"
              onBlur={handleValidate}
              disabled={disableField || formData.beneficiaryFlag.value}
              className={classnames('form-control', { error: formData.beneficiaryName.errorMessage })}
              query={formData.beneficiaryName.value.data.inn}
              mask={getMask('inn').mask}
              formatChars={getMask('inn').formatChars}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'beneficiaryName', formData, setFormData)}
              placeholder="ИНН"
            />
          </div>
        </div>
      </div>
      <h3 className="mr-auto"><span className="title_num">{getNumericForLabel().infocargo}</span>Информация о
        грузе
      </h3>
      <div className="card-body w-100 mb-4">
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label>Груз</label>
            <InputMask
              maskChar=""
              id="cargoCheck"
              placeholder="Автошины"
              onBlur={handleValidate}
              name="cargoCheck"
              disabled
              className="form-control"
              value={formData.cargo.value.label}
            />
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label>Наименование груза</label>
            <InputMask
              maskChar=""
              disabled={disableField}
              onChange={(e) => handleChange(e, 'cargoFull', formData, setFormData)}
              id="cargoFull"
              onBlur={handleValidate}
              placeholder="Брус 50х50х3м/6м (сосна)"
              name="cargoFull"
              className={classnames('form-control', { error: formData.cargoFull.errorMessage })}
              value={formData.cargoFull.value}
            />
            {formData.cargoFull.errorMessage && (
              <div className="validation-error">
                {formData.cargoFull.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 mb-lg-0">
            <label>Тип упаковки</label>
            <InputMask
              maskChar=""
              disabled={disableField}
              onChange={(e) => handleChange(e, 'packaging', formData, setFormData)}
              id="packaging"
              onBlur={handleValidate}
              placeholder=""
              name="packaging"
              className={classnames('form-control', { error: formData.packaging.errorMessage })}
              value={formData.packaging.value}
            />
            {formData.packaging.errorMessage && (
              <div className="validation-error">
                {formData.packaging.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label>Вес (кг)</label>
            <InputMask
              maskChar=""
              onChange={(e) => handleChange(e, 'weight', formData, setFormData)}
              onBlur={handleValidate}
              disabled={disableField}
              id="weight"
              placeholder="2000"
              name="weight"
              inputMode="number"
              className={classnames('form-control', { error: formData.range.errorMessage })}
              mask={getMask('sum').mask}
              formatChars={getMask('sum').formatChars}
              value={formData.weight.value}
            />
            {formData.weight.errorMessage && (
              <div className="validation-error">
                {formData.weight.errorMessage}
              </div>
            )}
          </div>
          {chosenOffer.companyCode === 'pari' ? (
            <div className="col-lg-3  mb-3 mb-lg-0">
              <label>Мест</label>
              <InputMask
                maskChar=""
                onChange={(e) => handleChange(e, 'spaces', formData, setFormData)}
                onBlur={handleValidate}
                disabled={disableField}
                id="spaces"
                placeholder="3"
                name="spaces"
                className={classnames('form-control', { error: formData.spaces.errorMessage })}
                mask={getMask('sum').mask}
                formatChars={getMask('sum').formatChars}
                value={formData.spaces.value}
              />
              {formData.spaces.errorMessage && (
                <div className="validation-error">
                  {formData.spaces.errorMessage}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <h3 className="mr-auto"><span className="title_num">{getNumericForLabel().infotrans}</span>Информация о перевозке</h3>
      <div className="card-body w-100 mb-4">
        <div className="form-group row">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <label>Транспорт
              <Tippy
                className="tippy-custom"
                arrow={false}
                offset={[0, 5]}
                content={(
                  <div style={{ maxWidth: 450 }}>Марка и номер авто / номер
                    вагона/контейнера/название и IMO
                    судна
                  </div>
                )}
                hideOnClick={false}
              >
                <div className="d-inline-block">
                  <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                </div>
              </Tippy>
            </label>
            <InputMask
              maskChar=""
              onChange={(e) => handleChange(e, 'mark', formData, setFormData)}
              id="mark"
              onBlur={handleValidate}
              disabled={disableField}
              placeholder=""
              name="mark"
              className={classnames('form-control', { error: formData.mark.errorMessage })}
              value={formData.mark.value}
            />
            {formData.mark.errorMessage && (
              <div className="validation-error">
                {formData.mark.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 mb-lg-0">
            <label>Тип транспортного документа</label>
            <Select
              menuPlacement="auto"
              classNamePrefix="react-select"
              styles={customSelectStyle()}
              onBlur={handleValidate}
              placeholder={'Выберите документ'}
              isDisabled={disableField}
              name="docs"
              noOptionsMessage={() => 'Не найдено'}
              inputId="docs"
              isSearchable={false}
              value={formData.docs.value}
              onChange={(e) => handleChange(e, 'docs', formData, setFormData)}
              options={typeDocs}
            />
            {formData.docs.errorMessage && (
              <div className="validation-error">
                {formData.docs.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 mb-3 mb-lg-0">
            <label>
              Номер транспортного документа
            </label>
            <InputMask
              maskChar=""
              onBlur={handleValidate}
              disabled={disableField}
              onChange={(e) => handleChange(e, 'transDoc', formData, setFormData)}
              id="transDoc"
              placeholder="2578452345"
              name="transDoc"
              className={classnames('form-control', { error: formData.transDoc.errorMessage })}
              value={formData.transDoc.value}
            />
            {formData.transDoc.errorMessage && (
              <div className="validation-error">
                {formData.transDoc.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0 d-flex flex-column">
            <label htmlFor="tnDate">Дата документа</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              className={classnames('form-control', { error: formData.tnDate.errorMessage })}
              selected={formData.tnDate.value ? new Date(formData.tnDate.value) : null}
              onChange={(e) => handleChange(e, 'tnDate', formData, setFormData)}
              name="tnDate"
              maxDate={new Date()}
              disabled={disableField}
              id="tnDate"
              locale={ru}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
            }
            />
            {formData.tnDate.errorMessage && (
              <div className="validation-error">
                {formData.tnDate.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3 mb-lg-0">
            <label>Способ перевозки</label>
            <Select
              menuPlacement="auto"
              classNamePrefix="react-select"
              styles={customSelectStyle()}
              onBlur={handleValidate}
              isDisabled={disableField}
              name="ride"
              noOptionsMessage={() => 'Не найдено'}
              inputId="ride"
              isSearchable={false}
              value={formData.ride.value}
              onChange={(e) => handleChange(e, 'ride', formData, setFormData)}
              options={filterRide(typeRide)}
            />
            {formData.ride.errorMessage && (
              <div className="validation-error">
                {formData.ride.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-6  mb-3 mb-lg-0">
            <label>Перевозчик</label>
            <ReactDadata
              suggestionType="party"
              name="carrier"
              id="carrier"
              disabled={disableField}
              onBlur={handleValidate}
              className={classnames('form-control', { error: formData.carrier.errorMessage })}
              query={formData.carrier.value.data?.inn ? formData.carrier.value.value : ''}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'carrier', formData, setFormData)}
              placeholder="Введите название или ОГРН"
            />
            {formData.carrier.errorMessage && (
              <div className="validation-error">
                {formData.carrier.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0">
            <label>ИНН перевозчика</label>
            <ReactDadata
              suggestionType="party"
              name="carrier"
              id="carrier"
              disabled={disableField}
              onBlur={handleValidate}
              className={classnames('form-control', { error: formData.carrier.errorMessage })}
              query={formData.carrier.value.data.inn}
              mask={getMask('inn').mask}
              formatChars={getMask('inn').formatChars}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'carrier', formData, setFormData)}
              placeholder="ИНН"
            />
          </div>
          {chosenOffer.companyCode === 'pari' ? (
            <>
              <div className="col-lg-6  mb-3 mb-lg-0">
                <label htmlFor="lastName">Отправитель</label>
                <ReactDadata
                  suggestionType="party"
                  name="sender"
                  id="sender"
                  onBlur={handleValidate}
                  className={classnames('form-control', {error: formData.sender.errorMessage})}
                  query={formData.sender.value?.value}
                  disabled={disableField}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => handleChange(e, 'sender', formData, setFormData)}
                  placeholder="Введите название или ОГРН"
                />
                {formData.sender.errorMessage && (
                  <div className="validation-error">
                    {formData.sender.errorMessage}
                  </div>
                )}
              </div>
              <div className="col-lg-6  mb-3 mb-lg-0">
                <label htmlFor="sender">ИНН отправителя</label>
                <ReactDadata
                  suggestionType="party"
                  name="sender"
                  id="sender"
                  onBlur={handleValidate}
                  className={classnames('form-control', { error: formData.sender.errorMessage })}
                  query={formData.sender.value.data?.inn}
                  disabled={disableField}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => handleChange(e, 'sender', formData, setFormData)}
                  placeholder="ИНН"
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <label htmlFor="lastName">Получатель</label>
                <ReactDadata
                  suggestionType="party"
                  name="payee"
                  id="payee"
                  onBlur={handleValidate}
                  className={classnames('form-control', { error: formData.payee.errorMessage })}
                  query={formData.payee.value?.value}
                  disabled={disableField}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => handleChange(e, 'payee', formData, setFormData)}
                  placeholder="Введите название или ОГРН"
                />
                  {formData.payee.errorMessage && (
                   <div className="validation-error">
                     {formData.payee.errorMessage}
                   </div>
                  )}
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <label htmlFor="lastName">ИНН получателя</label>
                <ReactDadata
                  suggestionType="party"
                  name="payee"
                  id="payee"
                  onBlur={handleValidate}
                  className={classnames('form-control', { error: formData.payee.errorMessage })}
                  query={formData.payee.value.data?.inn}
                  disabled={disableField}
                  token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                  onChange={(e) => handleChange(e, 'payee', formData, setFormData)}
                  placeholder="ИНН"
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="form-group row">
          <div className="col-lg-6  mb-3 mb-lg-0">
            <label>Пункт отправления</label>
            <ReactDadata
              id="insuredAddressUp"
              name="insuredAddressUp"
              onBlur={handleValidate}
              disabled={disableField}
              className={classnames('form-control', { error: formData.insuredAddressUp.errorMessage })}
              query={formData.insuredAddressUp.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'insuredAddressUp', formData, setFormData)}
              placeholder="г. Москва"
            />
            {formData.insuredAddressUp.errorMessage && (
              <div className="validation-error">
                {formData.insuredAddressUp.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-6  mb-3 mb-lg-0">
            <label>Пункт назначения</label>
            <ReactDadata
              id="insuredAddressDown"
              name="insuredAddressDown"
              disabled={disableField}
              onBlur={handleValidate}
              className={classnames('form-control', { error: formData.insuredAddressDown.errorMessage })}
              query={formData.insuredAddressDown.value.value}
              token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
              onChange={(e) => handleChange(e, 'insuredAddressDown', formData, setFormData)}
              placeholder="г. Санкт-Петербург"
            />
            {formData.insuredAddressDown.errorMessage && (
              <div className="validation-error">
                {formData.insuredAddressDown.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
      <h3 className="mr-auto"><span className="title_num">{getNumericForLabel().overload}</span>Пункты перегрузки</h3>
      <div className="card-body w-100 mb-4">
        <div className="col-lg-12 mb-2 d-flex align-items-center pl-0">
          <label className="switch ml-0">
            <input
              checked={formData.overload.value}
              name="overload"
              type="checkbox"
              disabled={disableField}
              onChange={(e) => handleChange(e, 'overload', formData, setFormData)}
              id="refrisks"
            />
            <span className="slider round" />
          </label>
          <span className="switch-text ml-2">Несколько пунктов перегрузки</span>
          {formData.overload.errorMessage && (
            <div className="validation-error">
              {formData.overload.errorMessage}
            </div>
          )}
        </div>
        {formData.overload.value
          ? (
            <CargoInsuranceResearch
              formData={formData}
              disabled={disableField}
              setFormData={setFormData}
            />
          ) : null}
      </div>

      <h3 className="mr-auto"><span className="title_num">{getNumericForLabel().policy}</span>Данные о полисе</h3>
      <div className="card-body w-100 mb-4">
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0 d-flex flex-column">
            <label htmlFor="startDate">Дата начала действия</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              className={classnames('form-control', { error: formData.startDate.errorMessage })}
              selected={formData.startDate.value ? formData.startDate.value : null}
              onChange={(e) => handleChange(e, 'startDate', formData, setFormData)}
              name="startDate"
              id="startDate"
              disabled={disableField}
              locale={ru}
              minDate={new Date()}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={<InputMask mask="99.99.9999" inputMode="tel" />}
            />
            {formData.startDate.errorMessage && (
              <div className="validation-error">
                {formData.startDate.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0 d-flex flex-column">
            <label htmlFor="startDate">Дата окончания действия</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              className={classnames('form-control', { error: formData.endDate.errorMessage })}
              selected={formData.endDate.value ? formData.endDate.value : null}
              onChange={(e) => handleChange(e, 'endDate', formData, setFormData)}
              name="endDate"
              disabled
              id="endDate"
              locale={ru}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={<InputMask mask="99.99.9999" inputMode="tel" />}
            />
            {formData.endDate.errorMessage && (
              <div className="validation-error">
                {formData.endDate.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
      <h3 className="mr-auto"><span className="title_num">{getNumericForLabel().contact}</span>Контактная информация</h3>
      <div className="card-body w-100 mb-4">
        <div className="form-group row">
          <div className="col-lg-3  mb-3 mb-lg-0 d-flex flex-column">
            <label htmlFor="contactPhone">Телефон</label>
            <InputMask
              maskChar=""
              disabled={disableField}
              onChange={(e) => handleChange(e, 'contactPhone', formData, setFormData)}
              id="contactPhone"
              placeholder="+7 (999) 999-99-99"
              name="contactPhone"
              onBlur={handleValidate}
              className={classnames('form-control', { error: formData.contactPhone.errorMessage })}
              mask={getMask('phone').mask}
              formatChars={getMask('phone').formatChars}
              value={formData.contactPhone.value}
            />
            {formData.contactPhone.errorMessage && (
              <div className="validation-error">
                {formData.contactPhone.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-3  mb-3 mb-lg-0 d-flex flex-column">
            <label htmlFor="startDate">Email</label>
            <InputMask
              maskChar=""
              onChange={(e) => handleChange(e, 'contactEmail', formData, setFormData)}
              id="contactEmail"
              disabled={disableField}
              onBlur={handleValidate}
              placeholder="your@email.ru"
              name="contactEmail"
              className={classnames('form-control', { error: formData.contactEmail.errorMessage })}
              value={formData.contactEmail.value}
              mask={getMask('email').mask}
              formatChars={getMask('email').formatChars}
            />
            {formData.contactEmail.errorMessage && (
              <div className="validation-error">
                {formData.contactEmail.errorMessage}
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <SimpleNotification>
              Вы получите электронную версию документа на указанный адрес электронной почты
            </SimpleNotification>
          </div>
        </div>
      </div>

      <h3 className="mr-auto"><span className="title_num">{getNumericForLabel().final}</span>Финальные условия</h3>
      <div className="card-body mb-4 w-100">
        <div className="form-group row">
          <div className="col-12">
            <div className={classnames('custom-checkbox form-check', { error: formData.personalDataFlag.errorMessage })}>
              <input
                checked={formData.personalDataFlag.value}
                name="personalDataFlag"
                disabled={disableField}
                className="form-check-input checkbox-styled"
                type="checkbox"
                onChange={(e) => handleChange(e, 'personalDataFlag', formData, setFormData)}
                id="personalDataFlag"
              />
              <label className="form-check-label" htmlFor="personalDataFlag">Согласен с передачей
                <a
                  rel="noreferrer"
                  className="dotted_link ml-2"
                  target="_blank"
                  href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${privacyPolicy}`}
                >персональных
                  данных
                </a>
              </label>
              {formData.personalDataFlag.errorMessage && (
              <div className="validation-error">
                {formData.personalDataFlag.errorMessage}
              </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <div className={classnames('custom-checkbox form-check', { error: formData.contractInfoFlag.errorMessage })}>
              <input
                disabled={disableField}
                onChange={toggleFlag}
                checked={formData.contractInfoFlag.value}
                name="contractInfoFlag"
                className="form-check-input checkbox-styled"
                type="checkbox"
                id="contractInfoFlag"
              />
              <label className="form-check-label" htmlFor="contractInfoFlag">Подтверждаю полноту и
                достоверность
                предоставленных данных в договоре страхования
              </label>
              {formData.contractInfoFlag.errorMessage && (
                <div className="validation-error">
                  {formData.contractInfoFlag.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <div className={classnames('custom-checkbox form-check', { error: formData.insuranceRulesFlag.errorMessage })}>
              <input
                disabled={disableField}
                onChange={toggleFlag}
                checked={formData.insuranceRulesFlag.value}
                name="insuranceRulesFlag"
                className="form-check-input checkbox-styled"
                type="checkbox"
                id="insuranceRulesFlag"
              />
              <label className="form-check-label" htmlFor="insuranceRulesFlag">
                {companies[chosenOffer.companyCode] && companies[chosenOffer.companyCode].rules && companies[chosenOffer.companyCode].rules[draftInfo.type] && companies[chosenOffer.companyCode].rules[draftInfo.type] ? (
                  <>Согласен с <a rel="noreferrer" className="dotted_link" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].rules[draftInfo.type]}`}>правилами страхования</a></>
                ) : (
                  <>Согласен с правилами страхования</>
                )}
              </label>
              {formData.insuranceRulesFlag.errorMessage && (
                <div className="validation-error">
                  {formData.insuranceRulesFlag.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12">
            <SimpleNotification>
              Осуществляя покупку полиса и заполняя настоящие формы, я подтверждаю, что даю согласие
              выбранной страховой компании на обработку персональных данных и информации, в том числе на сбор,
              систематизацию, блокирование, уничтожение персональных данных и иные действия, предусмотренные Федеральным
              законом от 27.07.2006 № 152-ФЗ «О персональных данных» в целях определения условий и заключения со мной
              договора страхования, проведения маркетинговых исследований. При этом такое согласие я даю на
              неограниченный срок и могу отозвать согласие в любое время путем передачи страховой компании соответствующего
              уведомления. Безопасность передаваемой информации обеспечивается с помощью современных протоколов
              обеспечения безопасности в интернете (SSL/TLS).
            </SimpleNotification>
          </div>
        </div>
      </div>

      {userInfo.full_time_employee === 1 || customFieldOptions.length > 0 ? (
        <AccountSystem
          customFieldName={customFieldName}
          customFieldOptions={customFieldOptions}
          customField={customField}
          setCustomField={setCustomField}
          blockNumber="8"
          disableField={disableField}
          setLinkToDeal={setLinkToDeal}
          linkToDeal={linkToDeal}
          leadSourcesOptions={leadSourcesOptions}
          leadSource={leadSource}
          setLeadSource={setLeadSource}
          managerAttractedOptions={managerAttractedOptions}
          managerAttracted={managerAttracted}
          setManagerAttracted={setManagerAttracted}
          customStyles={customStyles}
          newAgent={newAgent}
          setNewAgent={setNewAgent}
        />
      ) : null}

      <div className="mt-2">
        <button
          type="submit"
          className="btn btn-success mr-2"
          disabled={disableField}
          onClick={(e) => saveDraft(e, false, true)}
        >
          Сохранить
        </button>
        <button
          type="submit"
          className="btn btn-success"
          disabled={disableField}
          onClick={(e) => goToBuying(e)}
        >
          Оформить
        </button>
      </div>
    </div>
  );
}
