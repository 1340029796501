import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faTimes,
} from '@fortawesome/fontawesome-free-solid';
import ReactLoading from 'react-loading';
import classnames from 'classnames';
import Select from 'react-select';
import { customFetch, customSelectStyle, loadCRMOptionsLists } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

class WidgetCRMBitrix24 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: '',
      key: '',
      type: 'bitrix24',
      testLeadStatus: 'none',
      loadingBitrix24Lists: true,
      sourceId: { value: 'empty', label: 'Заполните настройки' },
      productId: { value: 'empty', label: 'Заполните настройки' },
      sourceList: [{ value: 'empty', label: 'Заполните настройки' }],
      roistatFieldId: { value: 'empty', label: 'Заполните настройки' },
      fieldsList: [{ value: 'empty', label: 'Заполните настройки' }],
      assignedById: { value: 'empty', label: 'Заполните настройки' },
      userList: [{ value: 'empty', label: 'Заполните настройки' }],
      productList: [{ value: 'empty', label: 'Заполните настройки' }],
      setRoistat: false,
    };
  }

  componentDidMount() {
    const { widgetId, integrationType } = this.props;
    loadCRMOptionsLists(widgetId, integrationType).then((data) => {
      this.setCRMOptions(data);
    });
  }

  setCRMOptions = (data) => {
    if (data !== 'empty integration' && data !== 'no integration') {
      const sources = data.sources.map((item) => ({ value: item.STATUS_ID, label: item.NAME }));
      const fields = [];
      Object.keys(data.fields).forEach((key) => {
        if (!data.fields[key].isMultiple && data.fields[key].type === 'string') {
          const title = data.fields[key].title.startsWith('UF_') ? data.fields[key].formLabel : data.fields[key].title;
          fields.push({ value: key, label: title });
        }
      });
      const users = data.users.map((item) => ({ value: item.ID, label: `${item.NAME ? item.NAME : '-'} ${item.LAST_NAME ? item.LAST_NAME : '-'} (${item.EMAIL})` }));
      const products = data.products.map((item) => ({ value: item.ID, label: item.NAME }));
      this.setState({
        sourceList: sources,
        fieldsList: fields,
        userList: users,
        productList: products,
      }, () => {
        this.loadCRMInfo();
      });
    } else {
      this.loadCRMInfo();
    }
  };

  loadCRMInfo = () => {
    const { widgetId, integrationType } = this.props;
    const {
      type, sourceList, fieldsList, userList, productList,
    } = this.state;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/crm-info?&type=${type}&widgetId=${widgetId}&integrationType=${integrationType}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let domain = '';
        let key = '';
        let {
          sourceId, roistatFieldId, assignedById, productId,
        } = this.state;
        let setRoistat = false;
        let goRefresh = false;
        data.forEach((dataRow) => {
          if (dataRow.field_name === 'domain') {
            domain = dataRow.value;
          }
          if (dataRow.field_name === 'key') {
            key = dataRow.value;
          }
        });
        data.forEach((dataRow) => {
          if (dataRow.field_name === 'sourceId' && domain !== '' && key !== '') {
            const source = dataRow.value.split('/');
            sourceId = { value: source[0], label: source[1] };
          }
          if (dataRow.field_name === 'roistatFieldId' && domain !== '' && key !== '') {
            const roistatField = dataRow.value.split('/');
            roistatFieldId = { value: roistatField[0], label: roistatField[1] };
          }
          if (dataRow.field_name === 'assignedById' && domain !== '' && key !== '') {
            const assignedBy = dataRow.value.split('/');
            assignedById = { value: assignedBy[0], label: assignedBy[1] };
          }
          if (dataRow.field_name === 'productId' && domain !== '' && key !== '') {
            const product = dataRow.value.split('/');
            productId = { value: product[0], label: product[1] };
          }
          if (dataRow.field_name === 'setRoistat') {
            setRoistat = dataRow.value === 'true';
          }
        });
        if (domain !== '' && key !== '' && sourceId.value === 'empty' && sourceList.length > 0 && sourceList[0].value !== 'empty') {
          sourceId = { value: sourceList[0].value, label: sourceList[0].label };
          goRefresh = true;
        }
        if (domain !== '' && key !== '' && roistatFieldId.value === 'empty' && fieldsList.length > 0 && fieldsList[0].value !== 'empty') {
          roistatFieldId = { value: fieldsList[0].value, label: fieldsList[0].label };
          goRefresh = true;
        }
        if (domain !== '' && key !== '' && assignedById.value === 'empty' && userList.length > 0 && userList[0].value !== 'empty') {
          assignedById = { value: userList[0].value, label: userList[0].label };
          goRefresh = true;
        }
        if (domain !== '' && key !== '' && assignedById.value === 'empty' && userList.length > 0 && userList[0].value !== 'empty') {
          productId = { value: productList[0].value, label: productList[0].label };
          goRefresh = true;
        }
        this.setState({
          domain,
          key,
          sourceId,
          roistatFieldId,
          setRoistat,
          assignedById,
          productId,
          loadingBitrix24Lists: false,
        }, () => {
          if (goRefresh) {
            this.saveCrmInfo(true);
          }
        });
      });
  };

  saveCrmInfo = (silent) => {
    const { widgetId, integrationType } = this.props;
    const { showModalInfo } = this.context;
    const {
      domain,
      key,
      type,
      sourceId,
      setRoistat,
      roistatFieldId,
      assignedById,
      productId,
    } = this.state;
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/crm-info`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        integrationType,
        widgetId,
        type,
        fields: {
          domain,
          key,
          sourceId: `${sourceId.value}/${sourceId.label}`,
          roistatFieldId: `${roistatFieldId.value}/${roistatFieldId.label}`,
          assignedById: `${assignedById.value}/${assignedById.label}`,
          productId: `${productId.value}/${productId.label}`,
          setRoistat: setRoistat ? 'true' : 'false',
        },
      }),
    })
      .then((response) => response.json())
      .then(() => {
        if (domain === '' || key === '') {
          this.setState({
            sourceId: { value: 'empty', label: 'Заполните настройки' },
            sourceList: [{ value: 'empty', label: 'Заполните настройки' }],
            roistatFieldId: { value: 'empty', label: 'Заполните настройки' },
            fieldsList: [{ value: 'empty', label: 'Заполните настройки' }],
            assignedById: { value: 'empty', label: 'Заполните настройки' },
            userList: [{ value: 'empty', label: 'Заполните настройки' }],
            productList: [{ value: 'empty', label: 'Заполните настройки' }],
          });
        } else if (sourceId.value === 'empty' || roistatFieldId === 'empty' || assignedById.value === 'empty') {
          loadCRMOptionsLists(widgetId, integrationType).then((data) => {
            this.setCRMOptions(data);
          });
        }
        if (typeof silent === 'undefined') {
          showModalInfo('Изменения сохранены');
        }
      });
  };

  handleChangeDomain = (e) => {
    const { target } = e;
    this.setState({ domain: target.value });
  };

  handleChangeKey = (e) => {
    const { target } = e;
    this.setState({ key: target.value });
  };

  setTestLead = () => {
    const { widgetId, integrationType, widgetType } = this.props;
    const { testLeadStatus } = this.state;
    if (testLeadStatus !== 'pending') {
      this.setState({ testLeadStatus: 'pending' });
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      const roistatVisit = document.cookie.replace(/(?:(?:^|.*;\s*)roistat_visit\s*\=\s*([^;]*).*$)|^.*$/, '$1');
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/sendTestLeadToCrm`, {
        method: 'post',
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          integrationType,
          url: window.location.href,
          widgetId,
          leadType: 'test',
          roistatVisit,
          widgetType,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.result) {
            this.setState({ testLeadStatus: 'success' });
          } else {
            this.setState({ testLeadStatus: 'fail' });
          }
        })
        .catch(() => {
          this.setState({ testLeadStatus: 'fail' });
        });
    }
  };

  handleChangeSourceId = (e) => {
    this.setState({ sourceId: e }, () => this.saveCrmInfo());
  };

  handleChangeProductId = (e) => {
    this.setState({ productId: e }, () => this.saveCrmInfo());
  };

  handleChangeRoistatFieldId = (e) => {
    this.setState({ roistatFieldId: e }, () => this.saveCrmInfo());
  };

  handleChangeAssignedById = (e) => {
    this.setState({ assignedById: e }, () => this.saveCrmInfo());
  };

  changesSetRoistat = () => {
    this.setState((prevState) => ({
      setRoistat: !prevState.setRoistat,
    }), () => this.saveCrmInfo());
  };

  render() {
    const {
      showMenu,
      activeFlag,
      changeCRMIntegrationActive,
      integrationType,
    } = this.props;

    const {
      domain,
      key,
      testLeadStatus,
      sourceId,
      assignedById,
      setRoistat,
      sourceList,
      roistatFieldId,
      fieldsList,
      userList,
      productList,
      productId,
      loadingBitrix24Lists,
    } = this.state;

    const customStyles = customSelectStyle();

    return (
      <>
        <div className="position-relative">
          <div className="form-group row">
            <div className="col-lg-12">
              <FormNotificationColorLine>
                <p>Для корректной работы необходимо:</p>
                <ol>
                  <li>Указать <b>домен</b> вашего аккаунта в Bitrix24 (без https://)</li>
                  <li>Создать в вашем аккаунте Bitrix24 входящий вебхук <strong>(Левое меню->Приложения->Вебхуки->Добавить вебхук->Входящий вебхук)</strong>, в правах доступа поставить чекбоксы - «Пользователи» и «CRM»</li>
                  <li>Указать код авторизации вебхука</li>
                </ol>
              </FormNotificationColorLine>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <LinkBreadcrumbs onClick={(e) => showMenu(e)}>К списку CRM</LinkBreadcrumbs>
            </div>
          </div>
          {loadingBitrix24Lists ? (
            <>
              <div className="form-group row">
                <div className="col-12">
                  <ReactLoading type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <p>Выполняется загрузка настроек...</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <div className="form-check custom-checkbox">
                        <input onChange={(e) => changeCRMIntegrationActive(e, 'bitrix24', integrationType)} checked={activeFlag} name="widgetBitrix24ActiveFlag" className="form-check-input checkbox-styled" type="checkbox" id="widgetBitrix24ActiveFlag" />
                        <label className="form-check-label" htmlFor="widgetBitrix24ActiveFlag">
                          Активная интеграция
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="widgetBitrix24domain">Домен в Bitrix24</label>
                      <input placeholder="infull.bitrix24.ru" value={domain} onChange={this.handleChangeDomain} onBlur={this.saveCrmInfo} name="widgetBitrix24domain" type="text" className="form-control" id="widgetBitrix24domain" />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="widgetBitrix24key">Код авторизации вебхука</label>
                      <input value={key} onChange={this.handleChangeKey} onBlur={this.saveCrmInfo} name="widgetBitrix24key" type="text" className="form-control" id="widgetBitrix24key" />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="widgetBitrix24sourceId">Источник лида Битрикс 24</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="widgetBitrix24sourceId"
                        noOptionsMessage={() => 'Не найдено'}
                        id="widgetBitrix24sourceId"
                        value={sourceId}
                        onChange={this.handleChangeSourceId}
                        options={sourceList}
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="widgetBitrix24AssignedById">Ответственный</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="widgetBitrix24AssignedById"
                        noOptionsMessage={() => 'Не найдено'}
                        id="widgetBitrix24AssignedById"
                        value={assignedById}
                        onChange={this.handleChangeAssignedById}
                        options={userList}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="widgetBitrix24Product">Продукт</label>
                      <Select
                        classNamePrefix="react-select"
                        styles={customStyles}
                        name="widgetBitrix24Product"
                        noOptionsMessage={() => 'Не найдено'}
                        id="widgetBitrix24Product"
                        value={productId}
                        onChange={this.handleChangeProductId}
                        options={productList}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <div className="form-check custom-checkbox">
                        <input onChange={this.changesSetRoistat} checked={setRoistat} name="widgetBitrix24roistat" className="form-check-input checkbox-styled" type="checkbox" id="widgetBitrix24roistat" />
                        <label className="form-check-label" htmlFor="widgetBitrix24roistat">
                          Передавать номер визита roistat
                        </label>
                      </div>
                    </div>
                  </div>
                  {setRoistat ? (
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <label htmlFor="widgetBitrix24roistatFieldId">Поле для заполнения номера визита roistat</label>
                        <Select
                          classNamePrefix="react-select"
                          styles={customStyles}
                          name="widgetBitrix24roistatFieldId"
                          noOptionsMessage={() => 'Не найдено'}
                          id="widgetBitrix24roistatFieldId"
                          value={roistatFieldId}
                          onChange={this.handleChangeRoistatFieldId}
                          options={fieldsList}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <button type="button" className={classnames('btn', 'btn-success', 'mr-3', { disabled: testLeadStatus === 'pending' })} onClick={this.setTestLead}>Отправить тестовый лид</button>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  {testLeadStatus === 'pending' ? (
                    <ReactLoading type="spin" height={30} width={30} className="loading-circle ml-3 d-inline-block" />
                  ) : null}
                  {testLeadStatus === 'success' ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} className="fa-fw" color="green" size="2x" /><span style={{ marginLeft: '15px', verticalAlign: 'super', color: 'green' }}>Тестовый лид успешно создан</span>
                    </>
                  ) : null}
                  {testLeadStatus === 'fail' ? (
                    <>
                      <FontAwesomeIcon icon={faTimes} className="fa-fw" color="red" size="2x" /><span style={{ marginLeft: '15px', verticalAlign: 'super', color: 'red' }}>Произошла ошибка</span>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

WidgetCRMBitrix24.contextType = ModalMessagesContext;

export default WidgetCRMBitrix24;
