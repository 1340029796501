import React, {
  forwardRef, useState, useContext, useMemo, useEffect,
} from 'react';
import ReactLoading from 'react-loading';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import ru from 'date-fns/locale/ru';
import InputMask from 'react-input-mask';
import {
  startOfMonth, endOfMonth, endOfDay, startOfDay,
} from 'date-fns';
import { customFetch, customSelectStyle, toLocalDate } from '../Utils/Helpers';
import {
  ModalMessagesContext,
} from '../Utils/ContextsServices/ModalMessagesService';
import getCompanies from '../Utils/InsurancesCompaniesStyle';

const FileSaver = require('file-saver');

function Reports() {
  const [downloading, setDownloading] = useState(false);
  const [product, setProduct] = useState({ value: 'osago', label: 'ОСАГО' });
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [insurance, setInsurance] = useState(insuranceOptions[0]);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const [insuranceFilterFlag, setInsuranceFilterFlag] = useState(false);

  const { showModalInfo } = useContext(ModalMessagesContext);
  const customStyles = useMemo(() => customSelectStyle());

  const getReport = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setDownloading(true);
    const url = `${process.env.REACT_APP_API_DOMAIN}/insurance/${insurance.value}/report`;
    customFetch(url, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        product: product.value,
        startDate: toLocalDate(startDate),
        endDate: toLocalDate(endDate),
        insuranceFilterFlag,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          showModalInfo('Отчет будет отправлен вам на почту');
        }
      })
      .catch(() => {
        showModalInfo('Возникла ошибка', 'error');
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const downloadMainReport = (type) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    setDownloading(true);
    const url = `${process.env.REACT_APP_API_DOMAIN}/${type}`;
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: toLocalDate(startDate),
        endDate: toLocalDate(endDate),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          showModalInfo('Отчет будет отправлен вам на почту');
        }
      })
      .catch(() => {
        showModalInfo('Возникла ошибка', 'error');
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  useEffect(() => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    getCompanies(lsToken, product.value).then((response) => {
      const companiesOptions = Object.entries(response).map((company) => ({ value: company[0], label: company[1].name }));
      if (product.value === 'osago') {
        companiesOptions.unshift({ label: 'Умный полис', value: 'smart' });
        companiesOptions.unshift({ label: 'Ингуру', value: 'inguru' });
        companiesOptions.unshift({ label: 'Инсапп', value: 'insapp' });
      }
      setInsuranceOptions(companiesOptions);
    });
  }, [product]);

  useEffect(() => {
    setInsurance(insuranceOptions[0]);
  }, [insuranceOptions]);

  return (
    <form onSubmit={getReport}>
      <div className="card mb-4">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="product">Продукт</label>
              <Select
                classNamePrefix="react-select"
                inputId="product"
                className="form-control-custom"
                name="product"
                placeholder="Тип"
                noOptionsMessage={() => 'Не найдено'}
                value={product}
                styles={customStyles}
                onChange={(e) => setProduct(e)}
                options={[
                  { label: 'ОСАГО', value: 'osago' },
                  { label: 'Ипотека', value: 'mortgage' },
                ]}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="insurance">Для кого отчет</label>
              <Select
                classNamePrefix="react-select"
                inputId="insurance"
                className="form-control-custom"
                name="insurance"
                placeholder="Тип"
                noOptionsMessage={() => 'Не найдено'}
                value={insurance}
                styles={customStyles}
                onChange={(e) => setInsurance(e)}
                options={insuranceOptions}
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="startDate">От</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(startOfDay(date))}
                name="startDate"
                id="startDate"
                locale={ru}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
            </div>
            <div className="col-lg-3">
              <label htmlFor="endDate">До</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                className="form-control"
                selected={endDate}
                onChange={(date) => setEndDate(endOfDay(date))}
                name="endDate"
                id="endDate"
                locale={ru}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
            </div>
          </div>
          {product.value === 'mortgage' ? (
            <div className="form-group row">
              <div className="col-12">
                <div className="form-check custom-checkbox">
                  <input className="form-check-input checkbox-styled" onChange={(e) => { setInsuranceFilterFlag(e.target.checked); }} checked={insuranceFilterFlag} type="checkbox" id="insuranceFilterFlag" />
                  <label className="form-check-label" htmlFor="insuranceFilterFlag">
                    Фильтровать по выбранной СК
                  </label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-12">
          <button type="submit" className="btn btn-success mb-2 mr-3">Скачать</button>
          <button type="button" className="btn btn-success mr-3 mb-2" onClick={() => downloadMainReport('getReportCsv')}>Скачать общий отчет по полисам</button>
          <button type="button" className="btn btn-success mb-2" onClick={() => downloadMainReport('getAgentsCsv')}>Скачать отчет по агентам</button>
          {downloading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
        </div>
      </div>
    </form>
  );
}

export default forwardRef(Reports);
