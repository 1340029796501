import React, { Component } from 'react';
import { Redirect } from 'react-router';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { customFetch, customSelectStyle } from '../Utils/Helpers';
import WidgetsTable from './WidgetsTable';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class WidgetsList extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      navigateTo: '',
      activeTab: '1',
      flags: {
        modalWidgetTypeSelect: false,
      },
      widgetTypes: [],
      selectedWidgetType: { label: 'ОСАГО', value: 'osago' },
    };
  }

  componentDidMount() {
    document.title = 'Список виджетов';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
    this.getWidgetTypes();
  }

  getWidgetTypes = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/get-types`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((widgetTypes) => {
        widgetTypes = widgetTypes.map((type) => ({ label: type.name, value: type.code }));
        this.setState({ widgetTypes });
      });
  }

  setNewWidget = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { selectedWidgetType } = this.state;
    const widgetType = { type: selectedWidgetType.value };

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widget`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type: widgetType }),
    })
      .then((response) => response.json())
      .then((widgetId) => {
        this.setState({
          navigateTo: widgetId,
        });
      });
  };

  toggleWidgetTypeSelect = () => {
    this.setState((prevState) => ({
      ...prevState,
      flags: {
        ...prevState.flags,
        modalWidgetTypeSelect: !prevState.flags.modalWidgetTypeSelect,
      },
    }));
  }

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  handleChangeNewWidgetType = (e) => {
    this.setState({ selectedWidgetType: e });
  }

  toggle(tab) {
    const { activeTab } = this.state;
    const { history } = this.props;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    history.push({ search: `?activeTab=${tab}` });
  }

  render() {
    const {
      navigateTo,
      activeTab,
      flags,
      selectedWidgetType,
      widgetTypes,
    } = this.state;
    const {
      history,
      location,
      userInfo,
    } = this.props;

    const customStyles = customSelectStyle();

    return (
      <>
        {navigateTo !== '' ? (
          <Redirect to={`/widgets/${navigateTo}`} />
        ) : (
          <div className="position-relative">
            <h1 className="page-wrapper__title d-inline-block mr-3">Список виджетов</h1>
            <div className="page_header__control mb-2 mb-md-0 d-inline-flex">
              <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
                <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
                Скачать в Excel
              </Button>
              <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
                <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
                Настроить таблицу
              </Button>
            </div>
            <h2>Ваши сохраненные виджеты</h2>
            <div className="form-group row">
              <div className="col-lg-12">
                <button type="button" className="btn btn-success mr-3" onClick={this.toggleWidgetTypeSelect}>Создать новый виджет</button>
              </div>
            </div>
            <TabNavList>
              <TabNavItem
                active={activeTab === '1'}
                onClick={() => { this.toggle('1'); }}
                text="Мои виджеты"
              />
              <TabNavItem
                active={activeTab === '2'}
                onClick={() => { this.toggle('2'); }}
                text="Виджеты агентов"
              />
              {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
                <TabNavItem
                  active={activeTab === '3'}
                  onClick={() => { this.toggle('3'); }}
                  text="Все виджеты (админ)"
                />
              )}
            </TabNavList>
            <TabContent animation="false" activeTab={activeTab}>
              <TabPane tabId="1">
                { activeTab === '1' ? <WidgetsTable ref={activeTab === '1' ? this.child : undefined} tab="1" history={history} location={location} /> : null }
              </TabPane>
              <TabPane tabId="2">
                { activeTab === '2' ? <WidgetsTable ref={activeTab === '2' ? this.child : undefined} tab="2" history={history} location={location} show="subagents" /> : null }
              </TabPane>
              {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
                <TabPane tabId="3">
                  { activeTab === '3' ? <WidgetsTable ref={activeTab === '3' ? this.child : undefined} tab="3" history={history} location={location} show="all" /> : null }
                </TabPane>
              )}
            </TabContent>
            <Modal
              classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
              closeIconSize={16}
              open={flags.modalWidgetTypeSelect}
              onClose={this.toggleWidgetTypeSelect}
              center
            >
              <h4>Выберите тип виджета</h4>
              <div className="form-group row">
                <div className="col-lg-12 col-xl-12">
                  <Select
                    classNamePrefix="react-select"
                    onChange={this.handleChangeNewWidgetType}
                    inputId="widgetFont"
                    name="widgetFont"
                    noOptionsMessage={() => 'Не найдено'}
                    value={selectedWidgetType}
                    styles={customStyles}
                    options={widgetTypes}
                  />
                </div>
              </div>
              <button className="btn btn-success mr-2" type="button" onClick={this.setNewWidget}>Создать виджет</button>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

export default withUserInfoStoreStore(WidgetsList);
