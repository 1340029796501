import React, {
  useState, useEffect, useMemo, useContext, useImperativeHandle, forwardRef,
} from 'react';
import { ReactDadata } from 'react-dadata';
import classnames from 'classnames';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import ru from 'date-fns/locale/ru';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-regular';
import { parseISO, format, subYears } from 'date-fns';
import ReactLoading from 'react-loading';
import {
  customFetch, customSelectStyle, setCursorToEnd, setNewPartnerForDigitalBroker,
} from '../../Utils/Helpers';
import getMask from '../../Utils/Masks';
import {
  ModalMessagesContext,
} from '../../Utils/ContextsServices/ModalMessagesService';

function KaskoForm(props, ref) {
  const {
    loadPolicy,
    policy,
    urlParams,
    copyDraft,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const customStyles = useMemo(() => customSelectStyle(), []);

  const policyPeriodOptions = [
    { value: 12, label: '12 Месяцев' },
    { value: 9, label: '9 Месяцев' },
    { value: 8, label: '8 Месяцев' },
    { value: 7, label: '7 Месяцев' },
    { value: 6, label: '6 Месяцев' },
    { value: 5, label: '5 Месяцев' },
    { value: 4, label: '4 Месяца' },
    { value: 3, label: '3 Месяца' },
    { value: 1, label: '1 Месяц' },
  ];
  const categoryOptions = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
  ];
  const targetOfUsingOptions = [
    { value: 'personal', label: 'Личная' },
    { value: 'taxi', label: 'Такси' },
    { value: 'training', label: 'Учебная' },
    { value: 'dangerous', label: 'Перевозка опасных и легковоспламеняющихся грузов' },
    { value: 'rental', label: 'Прокат/краткосрочная аренда' },
    { value: 'passenger', label: 'Регулярные пассажирские перевозки/перевозки пассажиров по заказам' },
    { value: 'special', label: 'Дорожные и специальные транспортные средства' },
    { value: 'services', label: 'Экстренные и коммунальные службы' },
    { value: 'other', label: 'Прочее' },
  ];
  const identificationTypeOptions = [
    { value: 'vin', label: 'VIN' },
    { value: 'body', label: 'Номер кузова' },
    { value: 'chassis', label: 'Номер шасси' },
  ];
  const documentTypeOptions = [
    { value: 'sertificate', label: 'СТС' },
    { value: 'passport', label: 'ПТС' },
    { value: 'epassport', label: 'ЭПТС' },
  ];
  const [loadingInfoFlags, setLoadingInfoFlags] = useState({
    marks: false,
    models: false,
    carInfo: false,
  });
  const [errorFlag, setErrorFlag] = useState({});
  const [marks, setMarks] = useState({});
  const [models, setModels] = useState({});
  const [newAgent, setNewAgent] = useState({ value: '', label: '' });
  const [disableFields, setDisableFields] = useState(false);
  const yearOptions = useMemo(() => Array.from(new Array(100), (val, index) => (new Date()).getFullYear() - index).map((year) => ({ value: year, label: year })), []);
  const [formData, setFormData] = useState({
    policy_period: {
      value: { value: 12, label: '12 Месяцев' },
      errorMessage: '',
      validationType: 'select',
      validationRequired: true,
    },
    start_police: {
      value: null,
      errorMessage: '',
      validationType: 'date',
      validationRequired: true,
    },
    insurer: {
      last_name: {
        value: '',
        errorMessage: '',
        validationType: 'plain',
        validationRequired: true,
      },
      first_name: {
        value: '',
        errorMessage: '',
        validationType: 'plain',
        validationRequired: true,
      },
      middle_name: {
        value: '',
        errorMessage: '',
        validationType: 'plain',
        validationRequired: true,
      },
      address_registration: {
        value: {
          value: '',
          data: {},
        },
        errorMessage: '',
        validationType: 'address',
        validationRequired: true,
      },
      phone: {
        value: '',
        errorMessage: '',
        validationType: 'phone',
        validationRequired: true,
      },
      email: {
        value: '',
        errorMessage: '',
        validationType: 'email',
        validationRequired: true,
      },
    },
    vehicle: {
      carNumber: {
        value: '',
        errorMessage: false,
        validationType: 'plain',
        validationRequired: true,
      },
      mark: {
        value: { value: '', label: '' },
        errorMessage: '',
        validationType: 'select',
        validationRequired: true,
      },
      model: {
        value: { value: '', label: '' },
        errorMessage: '',
        validationType: 'select',
        validationRequired: true,
      },
      category: {
        value: { label: 'B', value: 'B' },
        errorMessage: '',
        validationType: 'select',
        validationRequired: true,
      },
      year: {
        value: { label: '', value: '' },
        errorMessage: false,
        validationType: 'select',
        validationRequired: true,
      },
      targetOfUsing: {
        value: { label: 'Личная', value: 'personal' },
        errorMessage: false,
        validationType: 'select',
        validationRequired: true,
      },
      horsePower: {
        value: '',
        errorMessage: false,
        validationType: 'decimal',
        validationRequired: true,
      },
      identificationType: {
        value: { value: 'vin', label: 'VIN' },
        errorMessage: false,
        validationType: 'select',
        validationRequired: true,
      },
      vinNumber: {
        value: '',
        errorMessage: false,
        validationType: 'vin',
        validationRequired: true,
      },
      chassisNumber: {
        value: '',
        errorMessage: false,
        validationType: 'chassisNumber',
        validationRequired: true,
      },
      bodyNumber: {
        value: '',
        errorMessage: false,
        validationType: 'bodyNumber',
        validationRequired: true,
      },
      documentType: {
        value: { value: 'sertificate', label: 'СТС' },
        errorMessage: false,
        validationType: 'select',
        validationRequired: true,
      },
      documentNumber: {
        value: '',
        errorMessage: false,
        validationType: 'numberDocument',
        validationRequired: true,
      },
      documentSerial: {
        value: '',
        errorMessage: false,
        validationType: 'serialDocument',
        validationRequired: true,
      },
      edocumentNumber: {
        value: '',
        errorMessage: false,
        validationType: 'numberEDocument',
        validationRequired: true,
      },
      documentDate: {
        value: null,
        errorMessage: false,
        validationType: 'date',
        validationRequired: true,
      },
    },
  });

  const downloadModels = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        model: {
          ...prevState.vehicle.model,
          errorMessage: '',
          value: { label: '', value: '' },
        },
      },
    }));

    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_models/${event.value}?category=${formData.vehicle.category.value.value}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setModels(response.value);
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      });
  };

  const downloadMarks = (category) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_marks?category=${category}`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setMarks(response.value);
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      })
      .finally(() => {
        setLoadingInfoFlags((prevState) => ({
          ...prevState,
          marks: false,
        }));
      });
  };

  const handleChange = (event, object, fieldName, dadataType) => {
    if (fieldName === 'mark') {
      downloadModels(event);
    }
    if (fieldName === 'category') {
      downloadMarks(event.value);
    }
    let value;
    if (event.target) {
      value = event.target.value;
    } else if (dadataType === 'fio') {
      value = event.value;
    } else {
      value = event;
    }
    if (object) {
      setFormData((prevState) => ({
        ...prevState,
        [object]: {
          ...prevState[object],
          [fieldName]: {
            ...prevState[object][fieldName],
            errorMessage: '',
            value,
          },
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          errorMessage: '',
          value,
        },
      }));
    }
  };

  const getValuesFromFormData = () => {
    const flatJson = {};
    Object.keys(formData).forEach((key) => {
      if ('value' in formData[key]) {
        if (formData[key].value && typeof formData[key].value === 'object' && ('value' in formData[key].value) && !('data' in formData[key].value)) {
          flatJson[key] = formData[key].value.value;
        } else {
          flatJson[key] = formData[key].value;
        }
      } else {
        flatJson[key] = {};
        Object.keys(formData[key]).forEach((subKey) => {
          if (formData[key][subKey].value && typeof formData[key][subKey].value === 'object' && ('value' in formData[key][subKey].value) && !('data' in formData[key][subKey].value)) {
            flatJson[key][subKey] = formData[key][subKey].value.value;
          } else {
            flatJson[key][subKey] = formData[key][subKey].value;
          }
        });
      }
    });
    return flatJson;
  };

  const copyForm = () => {
    copyDraft();
  };

  useImperativeHandle(ref, () => ({
    copyForm: () => {
      copyForm();
    },
  }));

  const checkVehicleInfo = () => {
    if (formData.vehicle.carNumber.value.replace(/_/g, '').length > 7) {
      setLoadingInfoFlags((prevState) => ({
        ...prevState,
        loadingCarInfo: true,
      }));
      setErrorFlag({});
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      const regNumber = formData.vehicle.carNumber.value;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/car/info?checkType=number_plate&value=${regNumber}`, {
        headers: {
          Authorization: token,
        },
      })
        .then((response) => response.json())
        .then((info) => {
          if (info.error === 'Too many requests') {
            setErrorFlag({ errorTooManyRequests: true });
            setLoadingInfoFlags((prevState) => ({
              ...prevState,
              loadingCarInfo: false,
            }));
          } else if (info.is_error) {
            setErrorFlag({ errorWrongRegNumber: true });
            setLoadingInfoFlags((prevState) => ({
              ...prevState,
              loadingCarInfo: false,
            }));
          } else {
            let findMark = false;
            let findModel = false;
            if (info.result[0].car_mark) {
              Object.keys(marks).some((value) => {
                if (marks[value].toLowerCase() === info.result[0].car_mark.toLowerCase()) {
                  findMark = {
                    value,
                    label: marks[value],
                  };
                  return true;
                }
              });
              if (findMark) {
                setFormData((prevState) => ({
                  ...prevState,
                  vehicle: {
                    ...prevState.vehicle,
                    mark: {
                      ...prevState.vehicle.mark,
                      errorMessage: '',
                      value: findMark,
                    },
                  },
                }));
                customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_models/${findMark.value}?category=${formData.vehicle.category.value.value}`, {
                  headers: {
                    Authorization: token,
                  },
                })
                  .then((response) => response.json())
                  .then((models) => {
                    if (!models.Error) {
                      setModels(models);
                      if (info.result[0].car_model) {
                        Object.keys(models.value).some((id) => {
                          if (models.value[id].toLowerCase() === info.result[0].car_model.toLowerCase()) {
                            findModel = {
                              value: id,
                              label: models.value[id],
                            };
                            return true;
                          }
                        });
                      }
                      if (findModel) {
                        setFormData((prevState) => ({
                          ...prevState,
                          vehicle: {
                            ...prevState.vehicle,
                            model: {
                              ...prevState.vehicle.model,
                              errorMessage: '',
                              value: findModel,
                            },
                          },
                        }));
                      } else {
                        setErrorFlag({ errorCantCompareCar: true });
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(`Error Reading data ${err}`);
                  });
              } else {
                setErrorFlag({ errorCantCompareCar: true });
              }
            }

            let serialDocument;
            let numberDocument;
            let vehicleTypeDocument;
            let vehicleIdentificationType;
            if (info.result[0].credential.credential_type === 'VEHICLE_REGISTRATION') {
              serialDocument = info.result[0].credential.series;
              numberDocument = info.result[0].credential.number;
              vehicleTypeDocument = { value: 'sertificate', label: 'СТС' };
            } else if (info.result[0].credential.credential_type === 'VEHICLE_PASSPORT') {
              if (info.result[0].credential.number > 10) {
                serialDocument = '';
                numberDocument = '';
                vehicleTypeDocument = { value: 'epassport', label: 'ЭПТС' };
              } else {
                serialDocument = info.result[0].credential.series;
                numberDocument = info.result[0].credential.number;
                vehicleTypeDocument = { value: 'passport', label: 'ПТС' };
              }
            } else {
              serialDocument = '';
              numberDocument = '';
              vehicleTypeDocument = { value: 'sertificate', label: 'СТС' };
            }

            if (info.result[0].vin_number) {
              vehicleIdentificationType = { value: 'vin', label: 'VIN' };
            } else if (info.result[0].car_body_number) {
              vehicleIdentificationType = { value: 'body', label: 'Номер кузова' };
            } else {
              vehicleIdentificationType = { value: 'vin', label: 'VIN' };
            }

            setLoadingInfoFlags((prevState) => ({
              ...prevState,
              loadingCarInfo: false,
            }));
            setFormData((prevState) => ({
              ...prevState,
              vehicle: {
                ...prevState.vehicle,
                identificationType: {
                  value: vehicleIdentificationType,
                },
                documentSerial: {
                  ...prevState.vehicle.documentSerial,
                  value: serialDocument,
                  errorMessage: false,
                },
                documentType: {
                  value: vehicleTypeDocument,
                },
                documentNumber: {
                  ...prevState.vehicle.documentNumber,
                  value: numberDocument,
                  errorMessage: false,
                },
                vinNumber: {
                  ...prevState.vehicle.vinNumber,
                  value: info.result[0].vin_number ? info.result[0].vin_number : '',
                  errorMessage: false,
                },
                bodyNumber: {
                  ...prevState.vehicle.bodyNumber,
                  value: info.result[0].car_body_number ? info.result[0].car_body_number : '',
                  errorMessage: false,
                },
                documentDate: {
                  ...prevState.vehicle.documentDate,
                  value: info.result[0].credential.issue_date ? info.result[0].credential.issue_date : '',
                  errorMessage: false,
                },
                horsePower: {
                  ...prevState.vehicle.horsePower,
                  value: info.result[0].engine_power ? info.result[0].engine_power.toFixed(2) : '',
                  errorMessage: false,
                },
                year: {
                  ...prevState.vehicle.year,
                  value: info.result[0].manufacturing_year ? { value: info.result[0].manufacturing_year, label: info.result[0].manufacturing_year } : { value: '', label: '' },
                  errorMessage: false,
                },
              },
            }));
            return info;
          }
        })
        .catch(() => {
          setErrorFlag({ errorWrongRegNumber: true });
          setLoadingInfoFlags((prevState) => ({
            ...prevState,
            loadingCarInfo: false,
          }));
        });
    } else {
      setErrorFlag({
        errorBlankRegNumber: true,
      });
    }
  };

  const saveDraft = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    if (policy.id) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}`, {
        method: 'put',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formData: getValuesFromFormData(formData),
          newAgent: newAgent.value,
        }),
      }).then((response) => response.json())
        .then(() => {
          showModalInfo('Черновик обновлен');
        })
        .catch(() => {
          showModalInfo('Ошибка');
        });
    } else {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy`, {
        method: 'post',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formData: getValuesFromFormData(formData),
          type: 'kasko',
          newAgent: newAgent.value,
        }),
      }).then((response) => response.json())
        .then((newDraft) => {
          loadPolicy(newDraft.id).then(() => {
            window.history.pushState(null, null, `/kasko/${newDraft.id}`);
          });
          showModalInfo('Черновик сохранен');
        })
        .catch(() => {
          showModalInfo('Ошибка');
        });
    }
  };

  useEffect(() => {
    if (policy.id) {
      if (policy.status !== 'pending') {
        setDisableFields(true);
      }
      const data = {
        ...formData,
        start_police: {
          ...formData.start_police,
          value: policy.form_data.start_police,
        },
        policy_period: {
          ...formData.policy_period,
          value: policy.form_data.policy_period ? policyPeriodOptions.find(({ value }) => value === Number(policy.form_data.policy_period)) : { value: 12, label: '12 Месяцев' },
        },
        insurer: {
          ...formData.insurer,
          last_name: {
            ...formData.insurer.last_name,
            value: policy.form_data.insurer.last_name,
          },
          first_name: {
            ...formData.insurer.first_name,
            value: policy.form_data.insurer.first_name,
          },
          middle_name: {
            ...formData.insurer.middle_name,
            value: policy.form_data.insurer.middle_name,
          },
          address_registration: {
            ...formData.insurer.address_registration,
            value: policy.form_data.insurer.address_registration,
          },
          phone: {
            ...formData.insurer.phone,
            value: policy.form_data.insurer.phone,
          },
          email: {
            ...formData.insurer.email,
            value: policy.form_data.insurer.email,
          },
        },
        vehicle: {
          ...formData.vehicle,
          carNumber: {
            ...formData.vehicle.carNumber,
            value: policy.form_data.vehicle.carNumber,
            errorMessage: '',
          },
          category: {
            ...formData.vehicle.category,
            value: policy.form_data.vehicle.category ? categoryOptions.find(({ value }) => value === policy.form_data.vehicle.category) : { label: 'B', value: 'B' },
            errorMessage: '',
          },
          year: {
            ...formData.vehicle.year,
            value: policy.form_data.vehicle.year ? yearOptions.find(({ value }) => value === policy.form_data.vehicle.year) : { label: '', value: '' },
            errorMessage: '',
          },
          targetOfUsing: {
            ...formData.vehicle.targetOfUsing,
            value: policy.form_data.vehicle.targetOfUsing ? targetOfUsingOptions.find(({ value }) => value === policy.form_data.vehicle.targetOfUsing) : { label: 'Личная', value: 'personal' },
            errorMessage: '',
          },
          horsePower: {
            ...formData.vehicle.horsePower,
            value: policy.form_data.vehicle.horsePower,
            errorMessage: '',
          },
          identificationType: {
            ...formData.vehicle.identificationType,
            value: policy.form_data.vehicle.identificationType ? identificationTypeOptions.find(({ value }) => value === policy.form_data.vehicle.identificationType) : { value: 'vin', label: 'VIN' },
            errorMessage: '',
          },
          vinNumber: {
            ...formData.vehicle.vinNumber,
            value: policy.form_data.vehicle.vinNumber,
            errorMessage: '',
            validationRequired: policy.form_data.vehicle.identificationType === 'vin',
          },
          chassisNumber: {
            ...formData.vehicle.chassisNumber,
            value: policy.form_data.vehicle.chassisNumber,
            errorMessage: '',
            validationRequired: policy.form_data.vehicle.identificationType === 'chassis',
          },
          bodyNumber: {
            ...formData.vehicle.bodyNumber,
            value: policy.form_data.vehicle.bodyNumber,
            errorMessage: '',
            validationRequired: policy.form_data.vehicle.identificationType === 'body',
          },
          documentType: {
            ...formData.vehicle.documentType,
            value: policy.form_data.vehicle.documentType ? documentTypeOptions.find(({ value }) => value === policy.form_data.vehicle.documentType) : { value: 'sertificate', label: 'СТС' },
            errorMessage: '',
          },
          documentNumber: {
            ...formData.vehicle.documentNumber,
            value: policy.form_data.vehicle.documentNumber,
            errorMessage: '',
            validationRequired: policy.form_data.vehicle.documentType !== 'epassport',
          },
          documentSerial: {
            ...formData.vehicle.documentSerial,
            value: policy.form_data.vehicle.documentSerial,
            errorMessage: '',
            validationRequired: policy.form_data.vehicle.documentType !== 'epassport',
          },
          edocumentNumber: {
            ...formData.vehicle.edocumentNumber,
            value: policy.form_data.vehicle.edocumentNumber,
            errorMessage: '',
            validationRequired: policy.form_data.vehicle.documentType === 'epassport',
          },
          documentDate: {
            ...formData.vehicle.documentDate,
            value: policy.form_data.vehicle.documentDate,
            errorMessage: '',
          },
        },
      };

      setNewPartnerForDigitalBroker(policy, setNewAgent);

      setFormData(data);
    }
  }, [policy]);

  useEffect(() => {
    if (Object.keys(marks).length === 0) {
      downloadMarks(formData.vehicle.category.value.value);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(marks).length > 0 && policy.id && !formData.vehicle.mark.value.value) {
      if (policy.form_data.vehicle.mark) {
        let markValue = false;
        Object.keys(marks).some((value) => {
          if (value === policy.form_data.vehicle.mark) {
            markValue = {
              value,
              label: marks[value],
            };
            return true;
          }
          return false;
        });
        if (markValue) {
          setFormData((prevState) => ({
            ...prevState,
            vehicle: {
              ...prevState.vehicle,
              mark: {
                ...prevState.vehicle.mark,
                errorMessage: '',
                value: markValue,
              },
            },
          }));
          downloadModels(markValue);
        }
      }
    }
  }, [marks, policy.id]);

  useEffect(() => {
    if (Object.keys(models).length > 0 && policy.id && !formData.vehicle.model.value.value) {
      if (policy.form_data.vehicle.model) {
        let modelValue = false;
        Object.keys(models).some((value) => {
          if (value === policy.form_data.vehicle.model) {
            modelValue = {
              value,
              label: models[value],
            };
            return true;
          }
          return false;
        });
        if (modelValue) {
          setFormData((prevState) => ({
            ...prevState,
            vehicle: {
              ...prevState.vehicle,
              model: {
                ...prevState.vehicle.model,
                errorMessage: '',
                value: modelValue,
              },
            },
          }));
        }
      }
    }
  }, [models, policy.id]);

  const handleValidate = () => {

  };

  const optionItemsMarks = Object.keys(marks).map((id) => ({ value: id, label: marks[id] })).sort((a, b) => a.label.localeCompare(b.label));
  const optionItemsModels = Object.keys(models).map((id) => ({ value: id, label: models[id] })).sort((a, b) => a.label.localeCompare(b.label));
  return (
    <form onSubmit={saveDraft}>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">1</span>
          <span>Данные транспортного средства</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="carNumber">Гос. номер</label>
              <InputMask
                maskChar=""
                disabled={disableFields}
                onChange={(e) => handleChange(e, 'vehicle', 'carNumber')}
                id="carNumber"
                placeholder="А111АА111"
                name="carNumber"
                className={classnames('form-control column-margin upperCase', { error: formData.vehicle.carNumber.errorMessage })}
                mask={formData.vehicle.category.value.value === 'A' ? getMask('motorcycleNumber').mask : getMask('carNumber').mask}
                formatChars={formData.vehicle.category.value.value === 'A' ? getMask('motorcycleNumber').formatChars : getMask('carNumber').formatChars}
                value={formData.vehicle.carNumber.value}
              />
              {formData.vehicle.carNumber.errorMessage && (
                <div className="validation-error">
                  {formData.vehicle.carNumber.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label className="hidden d-none d-lg-block">hide</label>
              <button disabled={disableFields} type="button" className="btn btn-success mb-2 mb-md-0" onClick={checkVehicleInfo}>Найти данные по гос.номеру</button>
            </div>
            <div className="col-lg-5 force-to-bottom">
              {loadingInfoFlags.loadingCarInfo
                && <ReactLoading className="loading-circle" type="spin" height={37} width={37} />}
              {errorFlag.errorBlankRegNumber
                && <div className="error_block">Неправильно заполнен номер</div>}
              {errorFlag.errorTooManyRequests
                && <div className="error_block">Превышена частота или лимит запросов, повторите попытку позже или заполните данные вручную</div>}
              {errorFlag.errorWrongRegNumber
                && <div className="error_block">Данные не найдены</div>}
              {errorFlag.errorCantCompareCar
                && <div className="error_block">Введите марку и модель вручную</div>}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label>Категория</label>
              <Select
                styles={customStyles}
                name="category"
                isDisabled={disableFields}
                placeholder="Выберите категорию"
                classNamePrefix="react-select"
                inputId="category"
                value={formData.vehicle.category.value}
                onChange={(e) => handleChange(e, 'vehicle', 'category')}
                className={classnames('form-control-custom', { error: formData.vehicle.category.errorMessage })}
                options={categoryOptions}
                noOptionsMessage={() => 'Не найдено'}
              />
              {formData.vehicle.category.errorMessage && (
                <div className="validation-error">
                  {formData.vehicle.category.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label>Марка автомобиля</label>
              <Select
                isDisabled={disableFields}
                styles={customStyles}
                name="mark"
                placeholder="Выберите марку"
                classNamePrefix="react-select"
                inputId="mark"
                value={formData.vehicle.mark.value.value ? formData.vehicle.mark.value : null}
                onChange={(e) => handleChange(e, 'vehicle', 'mark')}
                className={classnames('form-control-custom', { error: formData.vehicle.mark.errorMessage })}
                options={optionItemsMarks}
                noOptionsMessage={() => 'Не найдено'}
              />
              {formData.vehicle.mark.errorMessage && (
                <div className="validation-error">
                  {formData.vehicle.mark.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label>Модель автомобиля</label>
              <Select
                styles={customStyles}
                name="model"
                isDisabled={disableFields}
                placeholder="Выберите модель"
                classNamePrefix="react-select"
                inputId="model"
                value={formData.vehicle.model.value.value ? formData.vehicle.model.value : null}
                onChange={(e) => handleChange(e, 'vehicle', 'model')}
                className={classnames('form-control-custom', { error: formData.vehicle.model.errorMessage })}
                options={optionItemsModels}
                noOptionsMessage={() => 'Не найдено'}
              />
              {formData.vehicle.model.errorMessage && (
                <div className="validation-error">
                  {formData.vehicle.model.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label htmlFor="year">Год выпуска</label>
              <Select
                styles={customStyles}
                name="year"
                isDisabled={disableFields}
                placeholder="Выберите год"
                classNamePrefix="react-select"
                inputId="year"
                value={formData.vehicle.year.value.value ? formData.vehicle.year.value : null}
                onChange={(e) => handleChange(e, 'vehicle', 'year')}
                className={classnames('form-control-custom', { error: formData.vehicle.year.errorMessage })}
                options={yearOptions}
                noOptionsMessage={() => 'Не найдено'}
              />
              {formData.vehicle.year.errorMessage && (
                <div className="validation-error">
                  {formData.vehicle.year.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="targetOfUsing">Цель использования</label>
              <Select
                styles={customStyles}
                name="targetOfUsing"
                isDisabled={disableFields}
                placeholder="Выберите цель"
                classNamePrefix="react-select"
                inputId="targetOfUsing"
                value={formData.vehicle.targetOfUsing.value.value ? formData.vehicle.targetOfUsing.value : null}
                onChange={(e) => handleChange(e, 'vehicle', 'targetOfUsing')}
                className={classnames('form-control-custom', { error: formData.vehicle.targetOfUsing.errorMessage })}
                options={targetOfUsingOptions}
              />
              {formData.vehicle.targetOfUsing.errorMessage && (
                <div className="validation-error">
                  {formData.vehicle.targetOfUsing.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="horsePower">Мощность, л.с.</label>
              <InputMask
                maskChar=""
                disabled={disableFields}
                onChange={(e) => handleChange(e, 'vehicle', 'horsePower')}
                id="horsePower"
                placeholder="120"
                name="horsePower"
                className={classnames('form-control', { error: formData.vehicle.horsePower.errorMessage })}
                mask={getMask('decimal').mask}
                formatChars={getMask('decimal').formatChars}
                value={formData.vehicle.horsePower.value}
              />
              {formData.vehicle.horsePower.errorMessage && (
                <div className="validation-error">
                  {formData.vehicle.horsePower.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="identificationType">Идентификатор ТС</label>
              <Select
                styles={customStyles}
                classNamePrefix="react-select"
                isDisabled={disableFields}
                name="identificationType"
                isSearchable={false}
                inputId="identificationType"
                noOptionsMessage={() => 'Не найдено'}
                value={formData.vehicle.identificationType.value.value ? formData.vehicle.identificationType.value : null}
                onChange={(e) => handleChange(e, 'vehicle', 'identificationType')}
                options={identificationTypeOptions}
              />
            </div>
            {(formData.vehicle.identificationType.value.value === 'vin') && (
              <div className="col-lg-3">
                <label htmlFor="vinNumber">VIN</label>
                <InputMask
                  maskChar=""
                  disabled={disableFields}
                  autoComplete="off"
                  onChange={(e) => handleChange(e, 'vehicle', 'vinNumber')}
                  id="vinNumber"
                  placeholder="ААА12345ААААААААА"
                  name="vinNumber"
                  className={classnames('form-control upperCase', { error: formData.vehicle.vinNumber.errorMessage })}
                  mask={getMask('vin').mask}
                  formatChars={getMask('vin').formatChars}
                  value={formData.vehicle.vinNumber.value}
                />
                {formData.vehicle.vinNumber.errorMessage && (
                  <div className="validation-error">
                    {formData.vehicle.vinNumber.errorMessage}
                  </div>
                )}
              </div>
            )}
            {(formData.vehicle.identificationType.value.value === 'body') && (
              <div className="col-lg-3">
                <label htmlFor="bodyNumber">Номер кузова</label>
                <InputMask
                  maskChar=""
                  disabled={disableFields}
                  onChange={(e) => handleChange(e, 'vehicle', 'bodyNumber')}
                  id="bodyNumber"
                  placeholder="ААА12345ААААААААА"
                  name="bodyNumber"
                  className={classnames('form-control upperCase', { error: formData.vehicle.bodyNumber.errorMessage })}
                  mask={getMask('bodyNumber').mask}
                  formatChars={getMask('bodyNumber').formatChars}
                  value={formData.vehicle.bodyNumber.value}
                />
                {formData.vehicle.bodyNumber.errorMessage && (
                  <div className="validation-error">
                    {formData.vehicle.bodyNumber.errorMessage}
                  </div>
                )}
              </div>
            )}
            {(formData.vehicle.identificationType.value.value === 'chassis') && (
              <div className="col-lg-3">
                <label htmlFor="chassisNumber">Номер шасси</label>
                <InputMask
                  maskChar=""
                  disabled={disableFields}
                  onChange={(e) => handleChange(e, 'vehicle', 'chassisNumber')}
                  id="chassisNumber"
                  placeholder="ААА12345ААААААААА"
                  name="chassisNumber"
                  className={classnames('form-control upperCase', { error: formData.vehicle.chassisNumber.errorMessage })}
                  mask={getMask('chassisNumber').mask}
                  formatChars={getMask('chassisNumber').formatChars}
                  value={formData.vehicle.chassisNumber.value}
                />
                {formData.vehicle.chassisNumber.errorMessage && (
                  <div className="validation-error">
                    {formData.vehicle.chassisNumber.errorMessage}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="documentType">Тип документа</label>
              <Select
                styles={customStyles}
                classNamePrefix="react-select"
                isDisabled={disableFields}
                name="documentType"
                noOptionsMessage={() => 'Не найдено'}
                inputId="documentType"
                isSearchable={false}
                value={formData.vehicle.documentType.value.value ? formData.vehicle.documentType.value : null}
                onChange={(e) => handleChange(e, 'vehicle', 'documentType')}
                options={documentTypeOptions}
              />
            </div>
            { formData.vehicle.documentType.value.value !== 'epassport' ? (
              <>
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <label htmlFor="documentSerial">Серия</label>
                  <InputMask
                    autoComplete="off"
                    maskChar=""
                    disabled={disableFields}
                    onChange={(e) => handleChange(e, 'vehicle', 'documentSerial')}
                    id="documentSerial"
                    placeholder="11АА"
                    name="documentSerial"
                    className={classnames('form-control upperCase', { error: formData.vehicle.documentSerial.errorMessage })}
                    mask={getMask('serialDocument').mask}
                    formatChars={getMask('serialDocument').formatChars}
                    value={formData.vehicle.documentSerial.value}
                  />
                  {formData.vehicle.documentSerial.errorMessage && (
                    <div className="validation-error">
                      {formData.vehicle.documentSerial.errorMessage}
                    </div>
                  )}
                </div>
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <label htmlFor="documentNumber">Номер</label>
                  <InputMask
                    autoComplete="off"
                    maskChar=""
                    disabled={disableFields}
                    onChange={(e) => handleChange(e, 'vehicle', 'documentNumber')}
                    id="documentNumber"
                    placeholder="111111"
                    name="documentNumber"
                    className={classnames('form-control upperCase', { error: formData.vehicle.documentNumber.errorMessage })}
                    mask={getMask('numberDocument').mask}
                    formatChars={getMask('numberDocument').formatChars}
                    value={formData.vehicle.documentNumber.value}
                  />
                  {formData.vehicle.documentNumber.errorMessage && (
                    <div className="validation-error">
                      {formData.vehicle.documentNumber.errorMessage}
                    </div>
                  )}
                </div>
              </>
            )
              : (
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <label htmlFor="edocumentNumber">Номер</label>
                  <InputMask
                    maskChar=""
                    disabled={disableFields}
                    onChange={(e) => handleChange(e, 'vehicle', 'edocumentNumber')}
                    id="edocumentNumber"
                    placeholder="123456789012345"
                    name="edocumentNumber"
                    className={classnames('form-control', { error: formData.vehicle.edocumentNumber.errorMessage })}
                    mask={getMask('numberEDocument').mask}
                    value={formData.vehicle.edocumentNumber.value}
                  />
                  {formData.vehicle.edocumentNumber.errorMessage && (
                    <div className="validation-error">
                      {formData.vehicle.edocumentNumber.errorMessage}
                    </div>
                  )}
                </div>
              )}
            <div className="col-lg-3">
              <label htmlFor="documentDate">Дата выдачи</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableFields}
                className={classnames('form-control', { error: formData.vehicle.documentDate.errorMessage })}
                selected={formData.vehicle.documentDate.value ? parseISO(formData.vehicle.documentDate.value) : null}
                onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), 'vehicle', 'documentDate')}
                name="documentDate"
                id="documentDate"
                locale={ru}
                maxDate={new Date()}
                minDate={formData.vehicle.year.value.value ? new Date(formData.vehicle.year.value.value, 0) : subYears(new Date(), 100)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.vehicle.documentDate.errorMessage && (
                <div className="validation-error">
                  {formData.vehicle.documentDate.errorMessage}
                </div>
              )}
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">2</span>
          <span>Страхователь</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="lastName">Фамилия</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="lastName"
                name="lastName"
                placeholder="Иванов"
                query={formData.insurer.last_name.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'insurer', 'last_name', 'fio')}
                parts={['SURNAME']}
                disabled={disableFields}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.insurer.last_name.errorMessage })}
              />
              {formData.insurer.last_name.errorMessage && (
                <div className="validation-error">
                  {formData.insurer.last_name.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="firstName">Имя</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="firstName"
                name="firstName"
                placeholder="Иван"
                query={formData.insurer.first_name.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'insurer', 'first_name', 'fio')}
                parts={['NAME']}
                disabled={disableFields}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.insurer.first_name.errorMessage })}
              />
              {formData.insurer.first_name.errorMessage && (
                <div className="validation-error">
                  {formData.insurer.first_name.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3">
              <label htmlFor="middleName">Отчество</label>
              <ReactDadata
                onBlur={handleValidate}
                suggestionType="fio"
                id="middleName"
                name="middleName"
                placeholder="Иванович"
                query={formData.insurer.middle_name.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'insurer', 'middle_name', 'fio')}
                parts={['PATRONYMIC']}
                disabled={disableFields}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.insurer.middle_name.errorMessage })}
              />
              {formData.insurer.middle_name.errorMessage && (
                <div className="validation-error">
                  {formData.insurer.middle_name.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-9">
              <label htmlFor="insurerAddressRegistration">Адрес регистрации</label>
              <ReactDadata
                disabled={disableFields}
                id="insurerAddressRegistration"
                name="insurerAddressRegistration"
                className={classnames('form-control', { error: formData.insurer.address_registration.errorMessage })}
                query={formData.insurer.address_registration.value.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'insurer', 'address_registration')}
                placeholder="Можете начать с ввода улицы"
                onFocus={setCursorToEnd}
              />
              {formData.insurer.address_registration.errorMessage && (
                <div className="validation-error">
                  {formData.insurer.address_registration.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">3</span>
          <span>Контактные данные страхователя</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="email">Email</label>
              <InputMask
                disabled={disableFields}
                maskChar=""
                onChange={(e) => handleChange(e, 'insurer', 'email')}
                id="email"
                placeholder="your@email.ru"
                name="email"
                className={classnames('form-control', { error: formData.insurer.email.errorMessage })}
                value={formData.insurer.email.value}
                mask={getMask('email').mask}
                formatChars={getMask('email').formatChars}
              />
              {formData.insurer.email.errorMessage && (
                <div className="validation-error">
                  {formData.insurer.email.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0 mortgage-form_dt_step_2">
              <label htmlFor="phone">Телефон</label>
              <InputMask
                disabled={disableFields}
                maskChar=""
                onChange={(e) => handleChange(e, 'insurer', 'phone')}
                id="phone"
                placeholder="+7 (999) 999-99-99"
                name="phone"
                className={classnames('form-control', { error: formData.insurer.phone.errorMessage })}
                mask={getMask('phone').mask}
                formatChars={getMask('phone').formatChars}
                value={formData.insurer.phone.value}
              />
              {formData.insurer.phone.errorMessage && (
                <div className="validation-error">
                  {formData.insurer.phone.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header fill_section__title">
          <span className="title_num">4</span>
          <span>Данные о полисе</span>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="start_police">Дата начала действия</label>
              <DatePicker
                locale={ru}
                disabled={disableFields}
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                className={classnames('form-control', { error: formData.start_police.errorMessage })}
                selected={formData.start_police.value ? parseISO(formData.start_police.value) : null}
                onChange={(e) => handleChange(format(e, 'yyyy-MM-dd'), null, 'start_police')}
                name="start_police"
                id="start_police"
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.start_police.errorMessage && (
                <div className="validation-error">
                  {formData.start_police.errorMessage}
                </div>
              )}
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <label htmlFor="policy_period">Срок действия</label>
              <Select
                isDisabled={disableFields}
                classNamePrefix="react-select"
                styles={customStyles}
                name="policy_period"
                placeholder="Выберите период"
                noOptionsMessage={() => 'Не найдено'}
                inputId="policy_period"
                isSearchable={false}
                value={formData.policy_period.value ? formData.policy_period.value : null}
                onChange={(e) => handleChange(e, null, 'policy_period')}
                options={policyPeriodOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row row-grid">
        <div className="col-lg-12">
          {!policy.is_demo || urlParams.adminEdit ? (
            <button disabled={disableFields} type="submit" className="btn btn-success mr-3 mb-3">Сохранить</button>
          ) : null}
        </div>
      </div>
    </form>
  );
}

export default forwardRef(KaskoForm);
