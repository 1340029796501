import React, { Component } from 'react';
import ru from 'date-fns/locale/ru';
import ReactTable from 'react-table-6';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/fontawesome-free-regular';
import {
  getDayOfYear, getYear, format, parseISO, parse,
} from 'date-fns';
import matchSorter from 'match-sorter';
import ReactLoading from 'react-loading';
import { toUTC, customFetch, customSelectStyle } from '../Utils/Helpers';

const regionName = {
  'Москва': 'moscow',
  'Московская обл.': 'moscowObl',
  'Санкт-Петербург': 'spb',
  'Ленинградская обл.': 'spbObl',
  'Россия': 'other',
};

const columns = [{
  Header: 'Страховая',
  accessor: 'company',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
  filterAll: true,
}, {
  Header: 'Регион',
  accessor: 'region',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
  filterAll: true,
}, {
  Header: '1',
  accessor: 'first',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['first'] }),
  filterAll: true,
}, {
  Header: '2',
  accessor: 'second',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['second'] }),
  filterAll: true,
}, {
  Header: '3',
  accessor: 'third',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['third'] }),
  filterAll: true,
}, {
  Header: '4',
  accessor: 'fourth',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['fourth'] }),
  filterAll: true,
}, {
  Header: '5',
  accessor: 'fifth',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['fifth'] }),
  filterAll: true,
}, {
  Header: 'Дата от',
  id: 'date_from',
  accessor: (d) => format(parseISO(d.date_from), 'dd.MM.yyyy'),
  sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['date_from'] }),
  filterAll: true,
}, {
  Header: 'Дата до',
  id: 'date_to',
  accessor: (d) => ((d.date_to === null) ? '' : format(parseISO(d.date_to), 'dd.MM.yyyy')),
  sortMethod: (a, b) => {
    if (a === '') a = parseISO('2990-01-01');
    if (b === '') b = parseISO('2990-01-01');
    return parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1;
  },
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['date_to'] }),
  filterAll: true,
}, {
  id: 'status',
  Header: 'Статус',
  filterMethod: (filter, row) => {
    if (filter.value === 'all') {
      return true;
    }
    if (filter.value === 'true') {
      return parseISO(row._original.date_from) <= new Date()
        && (parseISO(row._original.date_to) >= new Date() || row._original.date_to === null);
    }
    return parseISO(row._original.date_from) > new Date()
      || parseISO(row._original.date_to) < new Date();
  },
  Filter: ({ filter, onChange }) => (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Без фильтра</option>
      <option value="true">Действует</option>
      <option value="false">Недействительно</option>
    </select>
  ),
  accessor: (d) => ((parseISO(d.date_from) <= new Date()
    && (parseISO(d.date_to) >= new Date() || d.date_to === null))
    ? (<><FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />Действует</>)
    : (<><FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />Недействительно</>)),
},
];

export default class PriorityOfIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [{
        id: 'status',
        value: 'true',
      }],
      isToggleCommissionFormOn: false,
      errorMessage: false,
      commissionDate: null,
      loading: true,
      priorities: [],
      integrations: {},
      companyName: {},
      optionPriority: {},
      uploading: false,
    };
  }

  componentDidMount() {
    this.getCompanies();
    const { filtered } = this.state;
    const { location, tab } = this.props;
    const urlParams = queryString.parse(location.search);
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
  }

  getPriorityOptions = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { optionPriority } = this.state;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/integrations-to-insurances`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((integrationsList) => {
        integrationsList.forEach((row) => {
          if (!(row.insurance in optionPriority)) {
            optionPriority[row.insurance] = [];
          }
          optionPriority[row.insurance].push({ value: row.code, label: row.name });
        });
        Object.values(optionPriority).forEach((optionArr) => {
          optionArr.push({ value: null, label: '-' });
        });
        this.setState({ optionPriority }, () => {
          this.clearExcessStages(integrationsList);
        });
      });
  }

  clearExcessStages = (integrationsList) => {
    const { integrations, companyName } = this.state;
    Object.values(companyName).forEach((company) => {
      const integrationsCount = integrationsList.filter((row) => row.insurance === company);
      switch (integrationsCount.length) {
        case 1:
          delete integrations.second[company];
          delete integrations.third[company];
          delete integrations.fourth[company];
          delete integrations.fifth[company];
          break;
        case 2:
          delete integrations.third[company];
          delete integrations.fourth[company];
          delete integrations.fifth[company];
          break;
        case 3:
          delete integrations.fourth[company];
          delete integrations.fifth[company];
          break;
        case 4:
          delete integrations.fifth[company];
          break;
        default:
          break;
      }
    });
    this.setState({ integrations }, () => {
      this.getPriority();
    });
  }

  getCompanies = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/companies`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((companyList) => {
        const { companyName } = this.state;
        Object.entries(companyList).forEach(([key, value]) => {
          companyName[value.name] = key;
        });
        this.setState({ companyName }, () => {
          this.createPriorityBaseMatrix();
        });
      });
  };

  createPriorityBaseMatrix = () => {
    const { integrations, companyName } = this.state;
    [...Array(5).keys()].forEach((index) => {
      let stage = 'first';
      switch (index) {
        case 1:
          stage = 'second';
          break;
        case 2:
          stage = 'third';
          break;
        case 3:
          stage = 'fourth';
          break;
        case 4:
          stage = 'fifth';
          break;
        default:
          break;
      }
      integrations[stage] = {};
      Object.values(companyName).forEach((code) => {
        integrations[stage][code] = {
          moscow: null,
          spb: null,
          moscowObl: null,
          spbObl: null,
          other: null,
        };
      });
    });
    this.setState({ integrations }, () => {
      this.getPriorityOptions();
    });
  }

  getPriority = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { integrations, companyName } = this.state;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/priorityIntegration`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((priorities) => {
        Object.values(priorities).forEach((priority) => {
          if (parseISO(priority.date_from) <= new Date()
            && (parseISO(priority.date_to) >= new Date() || priority.date_to === null)) {
            for (const key in integrations) {
              if (integrations[key][companyName[priority.company]]) {
                integrations[key][companyName[priority.company]][regionName[priority.region]] = priority[key];
              }
            }
          }
        });
        this.setState((prevState) => ({
          loading: !prevState.loading,
          priorities: Object.values(priorities),
          integrations,
        }));
      });
  }

  toggleCommission = () => {
    this.setState((prevState) => ({
      isToggleCommissionFormOn: !prevState.isToggleCommissionFormOn,
    }));
  };

  handleChangePriority = (e, priority, company, actionMeta) => {
    const { value } = e;
    const { integrations } = this.state;
    Object.values(integrations).forEach((integration) => {
      if (integration[company] && integration[company][actionMeta.name] !== null && integration[company][actionMeta.name] === value) {
        integration[company][actionMeta.name] = integrations[priority][company][actionMeta.name];
      }
    });
    integrations[priority][company][actionMeta.name] = value;
    this.setState({
      integrations,
    });
  };

  handleChangeDate = (date) => {
    if (getDayOfYear(date) === getDayOfYear(new Date()) && getYear(date) === getYear(new Date())) {
      this.setState({ commissionDate: toUTC(new Date()) });
    } else {
      this.setState({ commissionDate: date });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const {
      commissionDate,
      integrations,
    } = this.state;
    if (commissionDate == null) {
      this.setState({
        commissionDate: toUTC(new Date()),
        errorMessage: 'Дата начала действия вознаграждения не может быть раньше текущей даты',
      });
      return 0;
    }
    this.setState({
      errorMessage: false,
      uploading: true,
    });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/priorityIntegration`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        integrations,
        commissionDate: format(commissionDate, 'yyyy-MM-dd HH:mm:ss'),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ uploading: false });
        if (res.error) {
          this.setState({ errorMessage: res.error });
        } else {
          this.setState((prevState) => ({
            isToggleCommissionFormOn: !prevState.isToggleCommissionFormOn,
          }));
        }
      })
      .catch(() => {
        this.setState({ uploading: false });
      });
  };

  onFilteredChangeCustom = (filtered) => {
    const { tab } = this.props;
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  render() {
    const {
      commissionDate,
      errorMessage,
      isToggleCommissionFormOn,
      loading,
      priorities,
      integrations,
      filtered,
      companyName,
      optionPriority,
      uploading,
    } = this.state;
    const priorityForm = Object.values(integrations).length && Object.values(optionPriority).length && Object.values(companyName).length ? Object.entries(companyName).map(([company, companyCode]) => (
      <div className="card mb-4">
        <div className="card-body">
          <div key={`${companyName[company]}`} className="form-group row">
            <div className="col">
              <div className="form-group row">
                <div className="col-md-2"><p className="h5">{company}</p></div>
                {[...Array(Object.values(integrations).filter((stage) => companyCode in stage).length).keys()].map((index) => {
                  return (
                    <div key={`${index}${company}`} className="col-md-2 align-center"><p className="h5">{index + 1}</p></div>
                  );
                })}
              </div>
              {Object.keys(regionName).map((region) => (
                <div key={`${companyName[company]}${regionName[region]}`} className="form-group row">
                  <label htmlFor="inputMoscow" className="col-md-2 col-form-label">{region}</label>
                  {Object.keys(integrations).map((priority) => {
                    if (integrations[priority][companyName[company]] && optionPriority[companyName[company]]) {
                      return (
                        <div className="col-md-2" key={`${companyName[company]}${priority}${regionName[region]}`}>
                          <Select
                            classNamePrefix="react-select"
                            styles={customSelectStyle()}
                            name={regionName[region]}
                            isSearchable={false}
                            placeholder="Выберите приоритет"
                            onChange={(e, actionMeta) => this.handleChangePriority(e, priority, companyName[company], actionMeta)}
                            value={optionPriority[companyName[company]].filter(({ value }) => value === integrations[priority][companyName[company]][regionName[region]])}
                            options={optionPriority[companyName[company]]}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )) : '';
    return (
      <>
        {isToggleCommissionFormOn
          ? (
            <form onSubmit={this.handleSubmit} className="mb-4">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-md-2 my-auto">
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        selected={commissionDate}
                        onChange={(date) => this.handleChangeDate(date)}
                        className="form-control"
                        name="commissionDate"
                        id="commissionDate"
                        locale={ru}
                        showYearDropdown
                        showMonthDropdown
                        minDate={new Date()}
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText="Дата от"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {priorityForm}
              <button disabled={uploading} type="submit" className="btn btn-success mr-2">Сохранить</button>
              <button disabled={uploading} type="button" className="btn btn-secondary" onClick={this.toggleCommission}>Отмена</button>
              {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
            </form>
          )
          : <button type="button" className="btn btn-success mb-4" onClick={this.toggleCommission}>Редактировать приоритет интеграций</button>}
        {errorMessage && (
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="error_block">{errorMessage}</div>
            </div>
          </div>
        )}
        <ReactTable
          className="table"
          data={priorities}
          columns={columns}
          defaultFiltered={filtered}
          onFilteredChange={(filteredNew) => this.onFilteredChangeCustom(filteredNew)}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
          defaultSorted={[
            {
              id: 'date_to',
              desc: true,
            },
          ]}
        />
      </>
    );
  }
}
