import React, {
  useEffect, useState, useContext, forwardRef,
} from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import classnames from 'classnames';
import getMask from '../Utils/Masks';
import { customSelectStyle, customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import PartnerProgramExclusionTable from './PartnerProgramExclusion/PartnerProgramExclusionTable';
import PartnerProgramExclusionForm from './PartnerProgramExclusion/PartnerProgramExclusionForm';
import useUserInfoStore from '../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';

const AgentPartnerProgram = forwardRef((props) => {
  const {
    agent,
    updateAgent,
  } = props;
  const { userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow,
  );

  const typesList = [
    {
      value: 'rub',
      label: 'Фиксированная ставка (руб.)',
    },
    {
      value: 'percent',
      label: 'Процент (%)',
    },
    {
      value: 'profitPercent',
      label: 'Процент с дохода (%)',
    },
  ];

  const customStyles = customSelectStyle();

  const [bet, setBet] = useState({
    value: '',
    saveFlag: false,
  });
  const [type, setType] = useState({
    value: typesList[0],
    saveFlag: false,
  });
  const [showForm, setShowForm] = useState('');
  const [customContracts, setCustomContracts] = useState([]);
  const [tableSelectedExclusion, setTableSelectedExclusion] = useState({
    contract: null,
    exclusion: null,
  });
  const [exclusions, setExclusions] = useState([]);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const changeBet = (e) => {
    const { target } = e;
    setBet((prev) => ({
      ...prev,
      value: target.value,
    }));
  };

  const validBet = () => {
    let { value } = bet;

    if (type.value.value !== 'rub' && parseInt(bet.value, 10) > 100) {
      value = 100;
    }

    setBet((prev) => ({
      ...prev,
      value,
      saveFlag: true,
    }));
  };

  const changeType = (e) => {
    setType((prev) => ({
      ...prev,
      value: e,
      saveFlag: true,
    }));
  };

  const saveBet = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${agent.id}/save-partner-program-bet`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        bet: bet.value,
        type: type.value.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          agent.bonus = bet.value;
          agent.bonus_type = type.value.value;
          updateAgent(agent);
          showModalInfo('Изменения сохранены');
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error.customMessage);
      })
      .finally(() => {
        setBet((prev) => ({
          ...prev,
          saveFlag: false,
        }));
        setType((prev) => ({
          ...prev,
          saveFlag: false,
        }));
      });
  };

  const getExclusions = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/partnerCommission?agentId=${agent.id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setExclusions(response);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        const errorMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(errorMessage);
      })
      .finally(() => {
      });
  };

  const getAgentOrDefaultContracts = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/partnerCommission/getAgentOrDefaultContracts?agentId=${agent.id}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCustomContracts(response);
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        const errorMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(errorMessage);
      });
  };

  useEffect(() => {
    if (bet.saveFlag || type.saveFlag) {
      saveBet();
    }
  }, [bet.saveFlag, type.saveFlag]);

  useEffect(() => {
    let { value } = bet;

    if (type.value.value !== 'rub' && parseInt(bet.value, 10) > 100) {
      value = 100;
    }

    setBet((prev) => ({
      ...prev,
      value,
    }));
  }, [type.value.value]);

  useEffect(() => {
    let betValue = bet.value;
    let typeValue = type.value;
    if (agent.id) {
      if (agent.bonus) {
        betValue = agent.bonus;
      }
      if (agent.bonus_type) {
        const typeValueIndex = typesList.findIndex((option) => option.value === agent.bonus_type);
        typeValue = typeValueIndex !== -1 ? typesList[typeValueIndex] : type.value;
      }
      getAgentOrDefaultContracts();
      getExclusions();
    }
    setType((prev) => ({
      ...prev,
      value: typeValue,
    }));
    setBet((prev) => ({
      ...prev,
      value: betValue,
    }));
  }, [agent.id]);

  useEffect(() => {
    if (showForm === false) {
      getExclusions();
      setTableSelectedExclusion({
        contract: null,
        exclusion: null,
      });
    }
  }, [showForm]);

  return (
    showForm ? (
      <PartnerProgramExclusionForm setShowForm={setShowForm} agent={agent} customContracts={customContracts} exclusions={exclusions} tableSelectedExclusion={tableSelectedExclusion} />
    ) : (
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="bet">Ставка</label>
              <InputMask
                maskChar=""
                onBlur={validBet}
                onChange={changeBet}
                id="bet"
                placeholder="100"
                name="bet"
                className={classnames('form-control')}
                mask={getMask('decimal').mask}
                formatChars={getMask('decimal').formatChars}
                value={bet.value}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label htmlFor="type">Тип начисления</label>
              <Select
                styles={customStyles}
                classNamePrefix="react-select"
                name="type"
                isSearchable={false}
                inputId="type"
                noOptionsMessage={() => 'Не найдено'}
                value={type.value.value ? type.value : null}
                onChange={changeType}
                options={typesList}
              />
            </div>
          </div>
          {userInfo.role === 'admin' || userInfo.role === 'superadmin' ? <button type="button" className="btn btn-success mb-3" onClick={() => setShowForm(true)}>Добавить исключение</button> : null}
          <PartnerProgramExclusionTable agent={agent} setShowForm={setShowForm} setTableSelectedExclusion={setTableSelectedExclusion} exclusions={exclusions} getExclusions={getExclusions} />
        </div>
      </div>
    )
  );
});

export default AgentPartnerProgram;
