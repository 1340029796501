import React, { Component } from 'react';
import {
  endOfToday, startOfMonth, subMonths, format, subYears, endOfMonth,
} from 'date-fns';
import ReactLoading from 'react-loading';

import { customFetch } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';
import IconCircleIncrease from '../../img/icon-circle-increase.svg';
import IconCircleSignal from '../../img/icon-circle-signal.svg';
import DashboardCardsList from '../Layout/Dashboard/DashboardCardsList';
import DashboardCardLinesBoard from './DashboardCardLinesBoard';
import DashboardCardAgentsBoard from './DashboardCardAgentsBoard';
import DropdownDashboardControls from '../Layout/DropdownDashboardControls';
import RadioButtonListTable from '../Layout/RadioButtonTable';
import DashboardContainerControl from '../Layout/Dashboard/DashboardContainerControl';
import CustomIcon from '../Layout/CustomIcon';
import CustomMultiDatapicker from '../Layout/CustomMultiDatapicker';

let abortController = new AbortController();

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingFlag: false,
      firstPeriod: {
        startDate: subMonths(startOfMonth(new Date()), 1),
        endDate: subMonths(new Date(), 1),
      },
      secondPeriod: {
        startDate: startOfMonth(new Date()),
        endDate: endOfToday(),
      },
      numbers: {
        agents: {
          all: 0,
          new: 0,
          allNetwork: 0,
          newNetwork: 0,
          activeAgents: 0,
          authAgents: 0,
          diffNew: 0,
          diffNewNetwork: 0,
          diffActiveAgents: 0,
        },
        polices: {
          self: 0,
          agents: 0,
          all: 0,
          widgets: 0,
          subAgentsWidgets: 0,
          diffSelf: 0,
          diffAgents: 0,
          diffAll: 0,
          diffWidgets: 0,
          diffSubAgentsWidgets: 0,
        },
        profit: {
          self: 0,
          agents: 0,
          all: 0,
          widgets: 0,
          subAgentsWidgets: 0,
          diffSelf: 0,
          diffAgents: 0,
          diffAll: 0,
          diffWidgets: 0,
          diffSubAgentsWidgets: 0,
        },
        insurancePremium: {
          self: 0,
          agents: 0,
          all: 0,
          widgets: 0,
          average: 0,
          subAgentsWidgets: 0,
          diffSelf: 0,
          diffAgents: 0,
          diffAll: 0,
          diffWidgets: 0,
          diffSubAgentsWidgets: 0,
          diffAverage: 0,
        },
        averagePC: {
          self: 0,
          agents: 0,
          all: 0,
          widgets: 0,
          subAgentsWidgets: 0,
          diffSelf: 0,
          diffAgents: 0,
          diffAll: 0,
          diffWidgets: 0,
          diffSubAgentsWidgets: 0,
        },
      },
      listRadio: {
        list: [
          {
            title: 'Текущий месяц',
            method: (e, index) => this.handleChangeRadio(e, index),
            checked: true,
          },
          {
            title: 'Прошлый месяц',
            method: (e, index) => this.handleChangeRadio(e, index),
            checked: false,
          },
          {
            title: 'Квартал',
            method: (e, index) => this.handleChangeRadio(e, index),
            checked: false,
          },
          {
            title: 'Год',
            method: (e, index) => this.handleChangeRadio(e, index),
            checked: false,
          },
          {
            title: 'Все время',
            method: (e, index) => this.handleChangeRadio(e, index),
            checked: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    const { setTour } = this.props;
    setTour('test_tour');
    document.title = 'Главная';
    this.loadNumbers();
  }

  componentWillUnmount() {
    abortController.abort();
  }

  calcDiff = (prevNumbers) => {
    const { numbers } = this.state;
    Object.keys(numbers).forEach((key) => {
      if (key === 'agents') {
        if (numbers[key].new - prevNumbers[key].new === 0) {
          numbers[key].diffNew = 0;
        } else if (prevNumbers[key].new === 0) {
          numbers[key].diffNew = Infinity;
        } else {
          numbers[key].diffNew = Math.ceil(((numbers[key].new - prevNumbers[key].new) / prevNumbers[key].new) * 100);
        }
        if (numbers[key].newNetwork - prevNumbers[key].newNetwork === 0) {
          numbers[key].diffNewNetwork = 0;
        } else if (prevNumbers[key].newNetwork === 0) {
          numbers[key].diffNewNetwork = Infinity;
        } else {
          numbers[key].diffNewNetwork = Math.ceil(((numbers[key].newNetwork - prevNumbers[key].newNetwork) / prevNumbers[key].newNetwork) * 100);
        }
        if (numbers[key].activeAgents - prevNumbers[key].activeAgents === 0) {
          numbers[key].diffActiveAgents = 0;
        } else if (prevNumbers[key].activeAgents === 0) {
          numbers[key].diffActiveAgents = Infinity;
        } else {
          numbers[key].diffActiveAgents = Math.ceil(((numbers[key].activeAgents - prevNumbers[key].activeAgents) / prevNumbers[key].activeAgents) * 100);
        }
        if (numbers[key].allNetwork - prevNumbers[key].allNetwork === 0) {
          numbers[key].diffAllNetwork = 0;
        } else if (prevNumbers[key].activeAgents === 0) {
          numbers[key].diffAllNetwork = Infinity;
        } else {
          numbers[key].diffAllNetwork = Math.ceil(((numbers[key].allNetwork - prevNumbers[key].allNetwork) / prevNumbers[key].allNetwork) * 100);
        }
      } else {
        if (numbers[key].self - prevNumbers[key].self === 0) {
          numbers[key].diffSelf = 0;
        } else if (prevNumbers[key].self === 0) {
          numbers[key].diffSelf = Infinity;
        } else {
          numbers[key].diffSelf = Math.ceil(((numbers[key].self - prevNumbers[key].self) / prevNumbers[key].self) * 100);
        }
        if (numbers[key].agents - prevNumbers[key].agents === 0) {
          numbers[key].diffAgents = 0;
        } else if (prevNumbers[key].agents === 0) {
          numbers[key].diffSelf = Infinity;
        } else {
          numbers[key].diffAgents = Math.ceil(((numbers[key].agents - prevNumbers[key].agents) / prevNumbers[key].agents) * 100);
        }
        if (numbers[key].all - prevNumbers[key].all === 0) {
          numbers[key].diffAll = 0;
        } else if (prevNumbers[key].all === 0) {
          numbers[key].diffAll = Infinity;
        } else {
          numbers[key].diffAll = Math.ceil(((numbers[key].all - prevNumbers[key].all) / prevNumbers[key].all) * 100);
        }
        if (numbers[key].widgets - prevNumbers[key].widgets === 0) {
          numbers[key].diffWidgets = 0;
        } else if (prevNumbers[key].widgets === 0) {
          numbers[key].diffWidgets = Infinity;
        } else {
          numbers[key].diffWidgets = Math.ceil(((numbers[key].widgets - prevNumbers[key].widgets) / prevNumbers[key].widgets) * 100);
        }
        if (numbers[key].subAgentsWidgets - prevNumbers[key].subAgentsWidgets === 0) {
          numbers[key].diffSubAgentsWidgets = 0;
        } else if (prevNumbers[key].subAgentsWidgets === 0) {
          numbers[key].diffSubAgentsWidgets = Infinity;
        } else {
          const diffSubAgentsWidgets = Math.ceil(((numbers[key].subAgentsWidgets - prevNumbers[key].subAgentsWidgets) / prevNumbers[key].subAgentsWidgets) * 100);
          numbers[key].diffSubAgentsWidgets = diffSubAgentsWidgets >= 1000 ? diffSubAgentsWidgets : Infinity;
        }
        if (numbers[key].average - prevNumbers[key].average === 0) {
          numbers[key].diffAverage = 0;
        } else if (prevNumbers[key].average === 0) {
          numbers[key].diffAverage = Infinity;
        } else {
          numbers[key].diffAverage = Math.ceil(((numbers[key].average - prevNumbers[key].average) / prevNumbers[key].average) * 100);
        }
      }
    });
    this.setState({ numbers }, () => {
      this.setState({ loadingFlag: false });
    });
  }

  loadNumbers = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { firstPeriod, secondPeriod } = this.state;
    this.setState({ loadingFlag: true }, () => {
      abortController.abort();
      abortController = new AbortController();
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/service-info/dashboard`, {
        method: 'post',
        signal: abortController.signal,
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstPeriod: {
            startDate: format(firstPeriod.startDate, 'yyyy-MM-dd'),
            endDate: format(firstPeriod.endDate, 'yyyy-MM-dd'),
          },
          secondPeriod: {
            startDate: secondPeriod.startDate ? format(secondPeriod.startDate, 'yyyy-MM-dd') : null,
            endDate: secondPeriod.endDate ? format(secondPeriod.endDate, 'yyyy-MM-dd') : null,
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const { numbers } = this.state;
          const allPoliciesPrevMonth = data.policesCount.prevMonth + data.subAgentsPoliciesCount.prevMonth + data.widgetsPoliciesCount.prevMonth + data.subAgentsWidgetsPoliciesCount.prevMonth;
          const allInsurancePremiumPrevMonth = data.premium.prevMonth + data.subAgentsPremium.prevMonth + data.subAgentsWidgetsPremium.prevMonth + data.widgetsPremium.prevMonth;
          const prevNumbers = {
            agents: {
              new: data.newSubAgentsCount.prevMonth,
              newNetwork: data.newNetworkSubAgentsCount.prevMonth,
              activeAgents: data.activeAgents.prevMonth,
              allNetwork: data.allNetworkCount.prevMonth,
            },
            polices: {
              self: data.policesCount.prevMonth,
              agents: data.subAgentsPoliciesCount.prevMonth,
              widgets: data.widgetsPoliciesCount.prevMonth,
              subAgentsWidgets: data.subAgentsWidgetsPoliciesCount.prevMonth,
              all: allPoliciesPrevMonth,
            },
            profit: {
              self: data.monthProfit.prevMonth,
              agents: data.subAgentsMonthProfit.prevMonth.toFixed(2),
              widgets: data.widgetsMonthProfit.prevMonth,
              subAgentsWidgets: data.subAgentsWidgetsMonthProfit.prevMonth,
              all: data.monthProfit.prevMonth + data.subAgentsMonthProfit.prevMonth + data.widgetsMonthProfit.prevMonth + data.subAgentsWidgetsMonthProfit.prevMonth,
            },
            insurancePremium: {
              self: data.premium.prevMonth,
              agents: data.subAgentsPremium.prevMonth,
              subAgentsWidgets: data.subAgentsWidgetsPremium.prevMonth,
              widgets: data.widgetsPremium.prevMonth,
              all: data.premium.prevMonth + data.subAgentsPremium.prevMonth + data.subAgentsWidgetsPremium.prevMonth + data.widgetsPremium.prevMonth,
              average: allPoliciesPrevMonth > 0 ? allInsurancePremiumPrevMonth / allPoliciesPrevMonth : 0,
            },
            averagePC: {
              self: data.averageProfit.prevMonth === null ? 0 : data.averageProfit.prevMonth,
              agents: data.subAgentsAverageProfit.prevMonth === null ? 0 : data.subAgentsAverageProfit.prevMonth,
              widgets: data.widgetsAverageProfit.prevMonth,
              subAgentsWidgets: data.subAgentsWidgetsAverageProfit.prevMonth === null ? 0 : data.subAgentsWidgetsAverageProfit.prevMonth,
              all: data.generalAverageProfit.prevMonth === null ? 0 : data.generalAverageProfit.prevMonth,
            },
          };

          const allPoliciesCurrentMonth = data.policesCount.currentMonth + data.subAgentsPoliciesCount.currentMonth + data.widgetsPoliciesCount.currentMonth + data.subAgentsWidgetsPoliciesCount.currentMonth;
          const allInsurancePremiumCurrentMonth = (data.premium.currentMonth + data.subAgentsPremium.currentMonth + data.widgetsPremium.currentMonth + data.subAgentsWidgetsPremium.currentMonth).toFixed(2);
          numbers.agents.all = data.subAgentsCount.currentMonth;
          numbers.agents.allNetwork = data.allNetworkCount.currentMonth;
          numbers.agents.new = data.newSubAgentsCount.currentMonth;
          numbers.agents.newNetwork = data.newNetworkSubAgentsCount.currentMonth;
          numbers.agents.activeAgents = data.activeAgents.currentMonth;
          numbers.agents.authAgents = data.authAgents.currentMonth;

          numbers.polices.self = data.policesCount.currentMonth;
          numbers.polices.graphs = data.policyGraph;
          numbers.polices.agents = data.subAgentsPoliciesCount.currentMonth;
          numbers.polices.widgets = data.widgetsPoliciesCount.currentMonth;
          numbers.polices.subAgentsWidgets = data.subAgentsWidgetsPoliciesCount.currentMonth;
          numbers.polices.all = allPoliciesCurrentMonth;

          numbers.profit.self = data.monthProfit.currentMonth;
          numbers.profit.graphs = data.profitGraph;
          numbers.profit.agents = data.subAgentsMonthProfit.currentMonth.toFixed(2);
          numbers.profit.widgets = data.widgetsMonthProfit.currentMonth;
          numbers.profit.subAgentsWidgets = data.subAgentsWidgetsMonthProfit.currentMonth;
          numbers.profit.all = data.monthProfit.currentMonth + data.subAgentsMonthProfit.currentMonth + data.widgetsMonthProfit.currentMonth + data.subAgentsWidgetsMonthProfit.currentMonth;

          numbers.insurancePremium.self = data.premium.currentMonth;
          numbers.insurancePremium.agents = data.subAgentsPremium.currentMonth;
          numbers.insurancePremium.widgets = data.widgetsPremium.currentMonth;
          numbers.insurancePremium.graphs = data.primeGraph;
          numbers.insurancePremium.subAgentsWidgets = data.subAgentsWidgetsPremium.currentMonth;
          numbers.insurancePremium.all = allInsurancePremiumCurrentMonth;
          numbers.insurancePremium.average = allPoliciesCurrentMonth > 0 ? allInsurancePremiumCurrentMonth / allPoliciesCurrentMonth : 0;

          numbers.averagePC.self = data.averageProfit.currentMonth === null ? 0 : data.averageProfit.currentMonth.toFixed(2);
          numbers.averagePC.agents = data.subAgentsAverageProfit.currentMonth === null ? 0 : data.subAgentsAverageProfit.currentMonth.toFixed(2);
          numbers.averagePC.widgets = data.widgetsAverageProfit.currentMonth === null ? 0 : data.widgetsAverageProfit.currentMonth.toFixed(2);
          numbers.averagePC.subAgentsWidgets = data.subAgentsWidgetsAverageProfit.currentMonth === null ? 0 : data.subAgentsWidgetsAverageProfit.currentMonth.toFixed(2);
          numbers.averagePC.all = data.generalAverageProfit.currentMonth === null ? 0 : data.generalAverageProfit.currentMonth.toFixed(2);
          numbers.averagePC.graphs = data.averageGraph;

          this.setState({ numbers }, () => {
            this.calcDiff(prevNumbers);
          });
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            const { showModalInfo } = this.context;
            showModalInfo('Возникла ошибка', 'error');
            this.setState({ loadingFlag: false });
          }
        });
    });
  };

  handleChangeRadio = (e, index) => {
    this.setState((prevState) => ({
      listRadio: {
        list: prevState.listRadio.list.map((child, i) => {
          if (i === index) {
            child.checked = !child.checked;
          } else {
            child.checked = false;
          }
          return child;
        }),
      },
    }));

    let dateObj;

    switch (index) {
      case 0: dateObj = {
        firstPeriod: {
          startDate: subMonths(startOfMonth(new Date()), 1),
          endDate: subMonths(new Date(), 1),
        },
        secondPeriod: {
          startDate: startOfMonth(new Date()),
          endDate: endOfToday(),
        },
      };
        break;
      case 1: dateObj = {
        firstPeriod: {
          startDate: subMonths(startOfMonth(new Date()), 2),
          endDate: subMonths(endOfMonth(new Date()), 2),
        },
        secondPeriod: {
          startDate: subMonths(startOfMonth(new Date()), 1),
          endDate: subMonths(endOfMonth(new Date()), 1),
        },
      };
        break;
      case 2: dateObj = {
        firstPeriod: {
          startDate: subMonths(startOfMonth(new Date()), 8),
          endDate: subMonths(endOfMonth(new Date()), 5),
        },
        secondPeriod: {
          startDate: subMonths(startOfMonth(new Date()), 4),
          endDate: subMonths(endOfMonth(new Date()), 1),
        },
      };
        break;
      case 3: dateObj = {
        firstPeriod: {
          startDate: subYears(new Date(), 2),
          endDate: subYears(new Date(), 1),
        },
        secondPeriod: {
          startDate: subYears(new Date(), 1),
          endDate: endOfToday(),
        },
      };
        break;
      case 4: dateObj = {
        firstPeriod: {
          startDate: new Date(2018, 0, 2),
          endDate: endOfToday(),
        },
        secondPeriod: {
          startDate: new Date(2018, 0, 2),
          endDate: endOfToday(),
        },
      };
        break;
      default: dateObj = {
        firstPeriod: {
          startDate: subMonths(startOfMonth(new Date()), 1),
          endDate: subMonths(new Date(), 1),
        },
        secondPeriod: {
          startDate: startOfMonth(new Date()),
          endDate: endOfToday(),
        },
      };
        break;
    }

    this.setState(dateObj, () => {
      this.loadNumbers();
    });
  }

  handleChangeDate = (date, type) => {
    const { secondPeriod } = this.state;
    let { startDate } = secondPeriod;
    let { endDate } = secondPeriod;
    let updateFlag = false;
    if (type === 'endDate') {
      updateFlag = endDate !== date && date;
      endDate = date;
    } else {
      startDate = date;
    }
    const dayDisagree = Math.floor((endDate - startDate) / (24 * 3600 * 1000));
    if (dayDisagree) {
      this.setState((prevState) => ({
        secondPeriod: {
          ...prevState.secondPeriod,
          [type]: date,
        },
        firstPeriod: {
          ...prevState.firstPeriod,
          [type]: subMonths(date, 1),
        },
      }), () => {
        if (updateFlag) {
          this.loadNumbers();
        }
      });
    }
  };

  render() {
    const {
      numbers,
      secondPeriod,
      loadingFlag,
      listRadio,
    } = this.state;
    const ProfitData = {
      titleBoard: 'Прибыль',
      titleIcon: <img src={IconCircleIncrease} alt="" className="icon" />,
      titleIconGraph: <img src={IconCircleSignal} alt="" className="icon" />,
      footerText: 'Общая прибыль',
      mainInfoTitle: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.profit.all),
      mainInfoProgress: {
        rotate: numbers.profit.diffAll > 0,
        iconBackground: numbers.profit.diffAll > 0 ? null : '#ffe2de',
        iconColor: numbers.profit.diffAll > 0 ? null : '#dc2f39',
        icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
        title: numbers.profit.diffAll > 0 ? numbers.profit.diffAll : numbers.profit.diffAll * -1,
        graphs: {
          data: numbers.profit.graphs,
          mark: '₽',
          colorLine: ['#378ddc'],
          hugeGraph: false,
          textTooltip: 'Общая прибыль: ',
          lineObjectName: ['profit_count'],
        },
      },
      progressBarLine: [
        { value: (numbers.profit.self / numbers.profit.all) * 100, color: '#f9da76', title: 'С ваших продаж' },
        { value: (numbers.profit.widgets / numbers.profit.all) * 100, color: '#9e5bb3', title: 'Через ваши виджеты' },
        { value: (numbers.profit.agents / numbers.profit.all) * 100, color: '#e9654a', title: 'С продаж ваших агентов' },
        { value: (numbers.profit.subAgentsWidgets / numbers.profit.all) * 100, color: '#a3ca71', title: 'С виджетов агентов' },
      ],
      ListDashboard: [
        {
          title: 'С ваших продаж',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.profit.self),
          rotate: numbers.profit.diffSelf > 0,
          iconBackground: numbers.profit.diffSelf > 0 ? null : '#ffe2de',
          iconColor: numbers.profit.diffSelf > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.profit.diffSelf > 0 ? numbers.profit.diffSelf : numbers.profit.diffSelf * -1,
        },
        {
          title: 'Через ваши виджеты',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.profit.widgets),
          rotate: numbers.profit.diffWidgets > 0,
          iconBackground: numbers.profit.diffWidgets > 0 ? null : '#ffe2de',
          iconColor: numbers.profit.diffWidgets > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.profit.diffWidgets > 0 ? numbers.profit.diffWidgets : numbers.profit.diffWidgets * -1,
        },
        {
          title: 'С продаж ваших агентов',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.profit.agents),
          rotate: numbers.profit.diffAgents > 0,
          iconBackground: numbers.profit.diffAgents > 0 ? null : '#ffe2de',
          iconColor: numbers.profit.diffAgents > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.profit.diffAgents > 0 ? numbers.profit.diffAgents : numbers.profit.diffAgents * -1,
        },
        {
          title: 'С виджетов агентов',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.profit.subAgentsWidgets),
          rotate: numbers.profit.diffSubAgentsWidgets > 0,
          iconBackground: numbers.profit.diffSubAgentsWidgets > 0 ? null : '#ffe2de',
          iconColor: numbers.profit.diffSubAgentsWidgets > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.profit.diffSubAgentsWidgets > 0 ? numbers.profit.diffSubAgentsWidgets : numbers.profit.diffSubAgentsWidgets * -1,
        },
      ],
    };

    const PrimeInsuranceData = {
      titleBoard: 'Страховая премия',
      titleIcon: <img src={IconCircleIncrease} alt="" className="icon" />,
      titleIconGraph: <img src={IconCircleSignal} alt="" className="icon" />,
      footerText: 'ОБЩАЯ СТРАХОВАЯ ПРЕМИЯ',
      mainInfoTitle: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', maximumFractionDigits: 0 }).format(numbers.insurancePremium.all),
      mainInfoProgress: {
        rotate: numbers.insurancePremium.diffAll > 0,
        iconBackground: numbers.insurancePremium.diffAll > 0 ? null : '#ffe2de',
        iconColor: numbers.insurancePremium.diffAll > 0 ? null : '#dc2f39',
        width: '40.6%',
        startWithGraphs: true,
        icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
        title: numbers.insurancePremium.diffAll > 0 ? numbers.insurancePremium.diffAll : numbers.insurancePremium.diffAll * -1,
        graphs: {
          data: numbers.insurancePremium.graphs,
          colorLine: ['#378ddc'],
          hugeGraph: false,
          textTooltip: 'Общая страховая премия: ',
          mark: '₽',
          lineObjectName: ['prime_count'],
        },
      },
      progressBarLine: [
        { value: (numbers.insurancePremium.self / numbers.insurancePremium.all) * 100, color: '#f9da76', title: 'С ваших продаж' },
        { value: (numbers.insurancePremium.widgets / numbers.insurancePremium.all) * 100, color: '#9e5bb3', title: 'Через ваши виджеты' },
        { value: (numbers.insurancePremium.agents / numbers.insurancePremium.all) * 100, color: '#e9654a', title: 'С продаж ваших агентов' },
        { value: (numbers.insurancePremium.subAgentsWidgets / numbers.insurancePremium.all) * 100, color: '#a3ca71', title: 'Через виджеты агентов' },
      ],
      ListDashboard: [
        {
          title: 'С ваших продаж',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.insurancePremium.self),
          rotate: numbers.insurancePremium.diffSelf > 0,
          iconBackground: numbers.insurancePremium.diffSelf > 0 ? null : '#ffe2de',
          iconColor: numbers.insurancePremium.diffSelf > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.insurancePremium.diffSelf > 0 ? numbers.insurancePremium.diffSelf : numbers.insurancePremium.diffSelf * -1,
        },
        {
          title: 'Через ваши виджеты',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.insurancePremium.widgets),
          rotate: numbers.insurancePremium.diffWidgets > 0,
          iconBackground: numbers.insurancePremium.diffWidgets > 0 ? null : '#ffe2de',
          iconColor: numbers.insurancePremium.diffWidgets > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.insurancePremium.diffWidgets > 0 ? numbers.insurancePremium.diffWidgets : numbers.insurancePremium.diffWidgets * -1,
        },
        {
          title: 'С продаж ваших агентов',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.insurancePremium.agents),
          rotate: numbers.insurancePremium.diffAgents > 0,
          iconBackground: numbers.insurancePremium.diffAgents > 0 ? null : '#ffe2de',
          iconColor: numbers.insurancePremium.diffAgents > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.insurancePremium.diffAgents > 0 ? numbers.insurancePremium.diffAgents : numbers.insurancePremium.diffAgents * -1,
        },
        {
          title: 'Через виджеты агентов',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.insurancePremium.subAgentsWidgets),
          rotate: numbers.insurancePremium.diffSubAgentsWidgets > 0,
          iconBackground: numbers.insurancePremium.diffSubAgentsWidgets > 0 ? null : '#ffe2de',
          iconColor: numbers.insurancePremium.diffSubAgentsWidgets > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.insurancePremium.diffSubAgentsWidgets > 0 ? numbers.insurancePremium.diffSubAgentsWidgets : numbers.insurancePremium.diffSubAgentsWidgets * -1,
        },
        {
          title: 'Средняя страховая премия',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.insurancePremium.average),
          rotate: numbers.insurancePremium.diffAverage > 0,
          iconBackground: numbers.insurancePremium.diffAverage > 0 ? null : '#ffe2de',
          iconColor: numbers.insurancePremium.diffAverage > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.insurancePremium.diffAverage > 0 ? numbers.insurancePremium.diffAverage : numbers.insurancePremium.diffAverage * -1,
        },
      ],
    };

    const AgentsData = {
      titleBoard: 'Агентская сеть',
      footerText: 'ОБЩИЙ РАЗМЕР СЕТИ',
      mainInfoTitle: numbers.agents.allNetwork,
      mainInfoProgress: {
        rotate: numbers.agents.diffAllNetwork > 0,
        iconBackground: numbers.agents.diffAllNetwork > 0 ? null : '#ffe2de',
        iconColor: numbers.agents.diffAllNetwork > 0 ? null : '#dc2f39',
        width: '57.6%',
        icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
        title: numbers.agents.diffAllNetwork > 0 ? numbers.agents.diffAllNetwork : numbers.agents.diffAllNetwork * -1,
      },
      ListDashboard: [
        {
          title: 'Ваши агенты',
          value: numbers.agents.all,
          disabled: true,
        },
        {
          title: 'Вовлеченные агенты\n (факт авторизации за месяц)',
          value: numbers.agents.authAgents,
          disabled: true,
        },
        {
          title: 'Подключенные вами',
          value: numbers.agents.new,
          rotate: numbers.agents.diffNew > 0,
          iconBackground: numbers.agents.diffNew > 0 ? null : '#ffe2de',
          iconColor: numbers.agents.diffNew > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.agents.diffNew > 0 ? numbers.agents.diffNew : numbers.agents.diffNew * -1,
        },
        {
          title: 'Подключенные вашими агентами',
          value: numbers.agents.newNetwork,
          rotate: numbers.agents.diffNewNetwork > 0,
          iconBackground: numbers.agents.diffNewNetwork > 0 ? null : '#ffe2de',
          iconColor: numbers.agents.diffNewNetwork > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.agents.diffNewNetwork > 0 ? numbers.agents.diffNewNetwork : numbers.agents.diffNewNetwork * -1,
        },
        {
          title: 'Активные агенты (продажи от 1 полиса)',
          value: numbers.agents.activeAgents,
          rotate: numbers.agents.diffActiveAgents > 0,
          iconBackground: numbers.agents.diffActiveAgents > 0 ? null : '#ffe2de',
          iconColor: numbers.agents.diffActiveAgents > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.agents.diffActiveAgents > 0 ? numbers.agents.diffActiveAgents : numbers.agents.diffActiveAgents * -1,
        },
      ],
    };

    const PolicyData = {
      titleBoard: 'Оформлено полисов',
      titleIcon: <img src={IconCircleIncrease} alt="" className="icon" />,
      titleIconGraph: <img src={IconCircleSignal} alt="" className="icon" />,
      footerText: 'ВСЕГО ОФОРМЛЕНО',
      mainInfoTitle: numbers.polices.all,
      mainInfoProgress: {
        rotate: numbers.polices.diffAll > 0,
        iconBackground: numbers.polices.diffAll > 0 ? null : '#ffe2de',
        iconColor: numbers.polices.diffAll > 0 ? null : '#dc2f39',
        icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
        title: numbers.polices.diffAll > 0 ? numbers.polices.diffAll : numbers.polices.diffAll * -1,
        graphs: {
          data: numbers.polices.graphs,
          mark: '',
          colorLine: ['#378ddc'],
          hugeGraph: false,
          textTooltip: 'Всего оформлено: ',
          lineObjectName: ['policies_count', 'policies_countSubAgents', 'policy_countWidget', 'policy_countSubAgentsWidget'],
        },
      },
      progressBarLine: [
        { value: (numbers.polices.self / numbers.polices.all) * 100, color: '#f9da76', title: 'Вами' },
        { value: (numbers.polices.widgets / numbers.polices.all) * 100, color: '#9e5bb3', title: 'Через ваши виджеты' },
        { value: (numbers.polices.agents / numbers.polices.all) * 100, color: '#e9654a', title: 'Вашими агентами' },
        { value: (numbers.polices.subAgentsWidgets / numbers.polices.all) * 100, color: '#a3ca71', title: 'Через виджеты агентов' },
      ],
      ListDashboard: [
        {
          title: 'Вами',
          value: numbers.polices.self,
          rotate: numbers.polices.diffSelf > 0,
          iconBackground: numbers.polices.diffSelf > 0 ? null : '#ffe2de',
          iconColor: numbers.polices.diffSelf > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.polices.diffSelf > 0 ? numbers.polices.diffSelf : numbers.polices.diffSelf * -1,
        },
        {
          title: 'Через ваши виджеты',
          value: numbers.polices.widgets,
          rotate: numbers.polices.diffWidgets > 0,
          iconBackground: numbers.polices.diffWidgets > 0 ? null : '#ffe2de',
          iconColor: numbers.polices.diffWidgets > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.polices.diffWidgets > 0 ? numbers.polices.diffWidgets : numbers.polices.diffWidgets * -1,
        },
        {
          title: 'Вашими агентами',
          value: numbers.polices.agents,
          rotate: numbers.polices.diffAgents > 0,
          iconBackground: numbers.polices.diffAgents > 0 ? null : '#ffe2de',
          iconColor: numbers.polices.diffAgents > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.polices.diffAgents > 0 ? numbers.polices.diffAgents : numbers.polices.diffAgents * -1,
        },
        {
          title: 'Через виджеты агентов',
          value: numbers.polices.subAgentsWidgets,
          rotate: numbers.polices.diffSubAgentsWidgets > 0,
          iconBackground: numbers.polices.diffSubAgentsWidgets > 0 ? null : '#ffe2de',
          iconColor: numbers.polices.diffSubAgentsWidgets > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.polices.diffSubAgentsWidgets > 0 ? numbers.polices.diffSubAgentsWidgets : numbers.polices.diffSubAgentsWidgets * -1,
        },
      ],
    };

    const PercentData = {
      titleBoard: 'Средний размер КВ',
      footerText: 'ОБЩЕЕ СРЕДНЕЕ КВ',
      mainInfoTitle: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.averagePC.all),
      mainInfoProgress: {
        rotate: numbers.averagePC.diffAll > 0,
        iconBackground: numbers.averagePC.diffAll > 0 ? null : '#ffe2de',
        iconColor: numbers.averagePC.diffAll > 0 ? null : '#dc2f39',
        icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
        title: numbers.averagePC.diffAll > 0 ? numbers.averagePC.diffAll : numbers.averagePC.diffAll * -1,
        startWithGraphs: true,
        graphs: {
          data: numbers.averagePC.graphs,
          mark: '₽',
          colorLine: ['#378ddc'],
          hugeGraph: false,
          textTooltip: 'Общая средняя КВ: ',
          lineObjectName: ['profit_count'],
        },
      },
      progressBarLine: [],
      ListDashboard: [
        {
          title: 'Ваших продаж',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.averagePC.self),
          rotate: numbers.averagePC.diffSelf > 0,
          iconBackground: numbers.averagePC.diffSelf > 0 ? null : '#ffe2de',
          iconColor: numbers.averagePC.diffSelf > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.averagePC.diffSelf > 0 ? numbers.averagePC.diffSelf : numbers.averagePC.diffSelf * -1,
        },
        {
          title: 'Через виджеты',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.averagePC.widgets),
          rotate: numbers.averagePC.diffWidgets > 0,
          iconBackground: numbers.averagePC.diffWidgets > 0 ? null : '#ffe2de',
          iconColor: numbers.averagePC.diffWidgets > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.averagePC.diffWidgets > 0 ? numbers.averagePC.diffWidgets : numbers.averagePC.diffWidgets * -1,
        },
        {
          title: 'Продаж ваших агентов',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.averagePC.agents),
          rotate: numbers.averagePC.diffAgents > 0,
          iconBackground: numbers.averagePC.diffAgents > 0 ? null : '#ffe2de',
          iconColor: numbers.averagePC.diffAgents > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.averagePC.diffAgents > 0 ? numbers.averagePC.diffAgents : numbers.averagePC.diffAgents * -1,
        },
        {
          title: 'Через виджеты ваших агентов',
          value: new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(numbers.averagePC.subAgentsWidgets),
          rotate: numbers.averagePC.diffSubAgentsWidgets > 0,
          iconBackground: numbers.averagePC.diffSubAgentsWidgets > 0 ? null : '#ffe2de',
          iconColor: numbers.averagePC.diffSubAgentsWidgets > 0 ? null : '#dc2f39',
          icon: <CustomIcon icon="upArrow" width="15px" height="15px" pureSvg />,
          valueIcon: numbers.averagePC.diffSubAgentsWidgets > 0 ? numbers.averagePC.diffSubAgentsWidgets : numbers.averagePC.diffSubAgentsWidgets * -1,
        },
      ],
    };
    return (
      <>
        <DashboardContainerControl>
          <h1 className="page-wrapper__title">Дашборд</h1>
          <DropdownDashboardControls>
            <RadioButtonListTable
              list={listRadio.list}
            />
            <CustomMultiDatapicker
              value={{ value: secondPeriod.endDate }}
              height="40px"
              selectsEnd
              endDate={secondPeriod.endDate}
              showTimeSelect={false}
              startDate={secondPeriod.startDate}
              endtDate={secondPeriod.endDate}
              maxDate={new Date()}
              changeStartDate={(e) => this.handleChangeDate(e, 'startDate', 'secondPeriod')}
              changeEndDate={(e) => this.handleChangeDate(e, 'endDate', 'secondPeriod')}
            />
          </DropdownDashboardControls>
        </DashboardContainerControl>
        <LoadingBanner loadingFlag={loadingFlag}>
          {loadingFlag
            ? <div className="d-flex justify-content-center mt-5"><ReactLoading className="loading-circle" type="spin" /></div>
            : (
              <DashboardCardsList>
                <DashboardCardLinesBoard
                  titleBoard={ProfitData.titleBoard}
                  iconTitle={ProfitData.titleIcon}
                  iconGraph={ProfitData.titleIconGraph}
                  footerText={ProfitData.footerText}
                  progressBarData={ProfitData.progressBarLine}
                  listDashboardData={ProfitData.ListDashboard}
                  mainInfoData={ProfitData.mainInfoProgress}
                  mainInfoTitle={ProfitData.mainInfoTitle}
                  listDashboardDotVisible="disc"
                />
                <DashboardCardLinesBoard
                  titleBoard={PolicyData.titleBoard}
                  iconTitle={PolicyData.titleIcon}
                  iconGraph={PolicyData.titleIconGraph}
                  footerText={PolicyData.footerText}
                  progressBarData={PolicyData.progressBarLine}
                  listDashboardData={PolicyData.ListDashboard}
                  mainInfoData={PolicyData.mainInfoProgress}
                  mainInfoTitle={PolicyData.mainInfoTitle}
                  listDashboardDotVisible="disc"
                />
                <DashboardCardLinesBoard
                  titleBoard={PercentData.titleBoard}
                  iconTitle={PercentData.titleIcon}
                  iconGraph={PercentData.titleIconGraph}
                  footerText={PercentData.footerText}
                  progressBarData={PercentData.progressBarLine}
                  listDashboardData={PercentData.ListDashboard}
                  mainInfoData={PercentData.mainInfoProgress}
                  mainInfoTitle={PercentData.mainInfoTitle}
                  listDashboardDotVisible={false}
                />
                <DashboardCardLinesBoard
                  titleBoard={PrimeInsuranceData.titleBoard}
                  iconTitle={PrimeInsuranceData.titleIcon}
                  iconGraph={PrimeInsuranceData.titleIconGraph}
                  footerText={PrimeInsuranceData.footerText}
                  progressBarData={PrimeInsuranceData.progressBarLine}
                  listDashboardData={PrimeInsuranceData.ListDashboard}
                  mainInfoData={PrimeInsuranceData.mainInfoProgress}
                  mainInfoTitle={PrimeInsuranceData.mainInfoTitle}
                  listDashboardDotVisible={false}
                />
                <DashboardCardAgentsBoard
                  titleBoard={AgentsData.titleBoard}
                  iconTitle={AgentsData.titleIcon}
                  iconGraph={AgentsData.titleIconGraph}
                  footerText={AgentsData.footerText}
                  progressBarData={AgentsData.progressBarLine}
                  listDashboardData={AgentsData.ListDashboard}
                  mainInfoData={AgentsData.mainInfoProgress}
                  mainInfoTitle={AgentsData.mainInfoTitle}
                  listDashboardDotVisible={false}
                />
              </DashboardCardsList>
            )}
        </LoadingBanner>
      </>
    );
  }
}

Dashboard.contextType = ModalMessagesContext;

export default Dashboard;
