import React, { Component } from 'react';
import ReactTable from 'react-table-6';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import ReactLoading from 'react-loading';
import { setTableSettings, setTableSorting, customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';

class DkpTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dkps: [],
      filtered: [],
    };
  }

  componentDidMount() {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { type } = this.props;
    const { showModalInfo } = this.context;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/dkp?type=${type}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((dkps) => {
        this.setState({ dkps });
      })
      .catch(() => {
        showModalInfo('Произошла ошибка');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onFilteredChangeCustom = (filtered) => {
    const { tab } = this.props;
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  deleteDkp = (id) => {
    this.setState({ loading: true, dkps: [] }, () => {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      const { type } = this.props;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/dkp/${id}?type=${type}`, {
        headers: {
          Authorization: token,
        },
        method: 'delete',
      })
        .then((response) => response.json())
        .then((dkps) => {
          this.setState({ dkps });
        })
        .catch(() => {
          const { showModalInfo } = this.context;
          showModalInfo('Произошла ошибка');
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const {
      loading,
      dkps,
      filtered,
    } = this.state;
    const { history, type } = this.props;
    let defaultSortingField = 'id';
    let defaultSortingOrder = 'true';
    if (reactLocalStorage.getObject('dkpTableSettings').defaultSortingField) {
      defaultSortingField = reactLocalStorage.getObject('dkpTableSettings').defaultSortingField;
      defaultSortingOrder = reactLocalStorage.getObject('dkpTableSettings').defaultSortingOrder;
    }
    const columns = [{
      Header: '№',
      accessor: 'id',
      width: reactLocalStorage.getObject('dkpTableSettings').id,
      maxWidth: 70,
    }, {
      Header: 'Агент',
      id: 'agentName',
      width: reactLocalStorage.getObject('dkpTableSettings').agentName,
      accessor: (d) => `${d.agent.last_name} ${d.agent.first_name} ${d.agent.middle_name}`,
      show: type === 'all',
    }, {
      Header: 'Дата',
      id: 'created_at',
      width: reactLocalStorage.getObject('dkpTableSettings').created_at,
      accessor: (d) => (d.created_at ? d.created_at : ''),
    }, {
      Header: 'ФИО',
      id: 'sellerName',
      width: reactLocalStorage.getObject('dkpTableSettings').sellerName,
      accessor: (d) => `${d.form_data.sellerLastName} ${d.form_data.sellerFirstName} ${d.form_data.sellerMiddleName}`,
    }, {
      Header: '',
      width: 40,
      filterable: false,
      Cell: (row) => (
        <div title="Удалить черновик"><FontAwesomeIcon onClick={() => this.deleteDkp(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    },
    ];
    return (
      <>
        <ReactTable
          getTdProps={(state, rowInfo, column) => {
            if (rowInfo && column.id) {
              return {
                onMouseDown: (e) => {
                  e.preventDefault();
                  if (e.button === 0) {
                    history.push(`dkp/${rowInfo.row.id}`);
                  }
                  if (e.button === 1) {
                    window.open(`/dkp/${rowInfo.row.id}`, '_blank');
                  }
                },
              };
            } return {};
          }}
          className="table"
          data={dkps}
          columns={columns}
          defaultFiltered={filtered}
          onFilteredChange={(data) => this.onFilteredChangeCustom(data)}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
          defaultSorted={[
            {
              id: defaultSortingField,
              desc: defaultSortingOrder,
            },
          ]}
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'dkpTableSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings(newResized, 'dkpTableSettings');
          }}
        />
      </>
    );
  }
}

DkpTable.contextType = ModalMessagesContext;

export default DkpTable;
