import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';
import ReactLoading from 'react-loading';
import Modal from 'react-responsive-modal';
import Countdown from 'react-countdown-now';
import 'rc-tooltip/assets/bootstrap.css';
import queryString from 'query-string';
import { switchLanguage } from '../Utils/Helpers';
import getValidation from '../Utils/Validation';
import getMask from '../Utils/Masks';
import AuthService from '../Utils/AuthService';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

const flipBehavior = ['right', 'top', 'bottom'];

class RegistrationCompleteForm extends Component {
  constructor(props) {
    super(props);
    this.Auth = new AuthService();
    this.state = {
      verificationPhone: false,
      showCountdown: false,
      privacy_policy: '',
      openModalPersonalData: false,
      loading: false,
      showFormPhoneVerification: false,
      formFields: {
        phone: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'phone',
        },
        email: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'email',
        },
        smsCode: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'smsCode',
        },
        personalData: {
          value: '',
          errorMessage: '',
          errorVisible: false,
          validationType: 'checkbox',
        },
      },
    };
  }

  componentDidMount() {
  }

  handleChangeInput = (e, translate = false, upperCase = false) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (translate) {
      value = switchLanguage(e.target.value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formFields } = this.state;
    formFields[e.target.id].value = value;
    if (formFields[e.target.id].errorVisible) {
      formFields[e.target.id].errorVisible = false;
    }
    this.setState({ formFields });
  };

  handleChangeSmsCode = (e) => {
    const { formFields } = this.state;
    const { value } = e.target;
    if (formFields[e.target.id].errorVisible) {
      formFields[e.target.id].errorVisible = false;
    }
    this.setState({
      formFields: {
        ...formFields,
        smsCode: {
          ...formFields.smsCode,
          value,
        },
      },
    }, () => {
      if (value.length === 4) {
        this.handleFormPhoneVerificationSubmit();
      }
    });
  };

  handleFormRegistrationSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const validationSuccess = this.validateAllFields();
    if (validationSuccess) {
      this.sendSms();
    } else {
      this.setState({ loading: false });
    }
  };

  validateAllFields = () => {
    const { formFields } = this.state;
    let validationSuccess = true;
    Object.keys(formFields).forEach((field) => {
      let value;
      if (formFields[field].validationType === 'passwordSubmit') {
        value = { passwordSubmit: formFields.passwordSubmit.value, password: formFields.password.value };
      } else {
        value = formFields[field].value;
      }
      formFields[field].errorMessage = getValidation(value, formFields[field].validationType);
      if (formFields[field].errorMessage) {
        formFields[field].errorVisible = true;
        validationSuccess = false;
      }
    });
    this.setState({ formFields });
    return validationSuccess;
  };

  sendSms = (reset = false) => {
    const { formFields } = this.state;
    if (reset) {
      this.setState({
        formFields: {
          ...formFields,
          smsCode: {
            ...formFields.smsCode,
            value: '',
          },
        },
      });
    }
    fetch(`${process.env.REACT_APP_API_DOMAIN}/registration/sendSms`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: formFields.phone.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ loading: false });
        if (response === 'phone_already_exist') {
          this.setState({
            showFormPhoneVerification: false,
            formFields: {
              ...formFields,
              smsCode: {
                ...formFields.smsCode,
                value: '',
              },
              phone: {
                ...formFields.phone,
                errorMessage: 'Пользователь с данным номером уже зарегистрирован',
                errorVisible: true,
              },
            },
          });
        } else {
          this.setState({
            showCountdown: true,
            showFormPhoneVerification: true,
          });
        }
      })
      .catch(() => {
        const { showModalInfo } = this.context;
        showModalInfo('Произошла ошибка, повторите попытку.');
      });
  };

  handleFormPhoneVerificationSubmit = () => {
    const { formFields } = this.state;
    const { history } = this.props;
    const { showModalInfo } = this.context;
    this.setState({ verificationPhone: true });
    fetch(`${process.env.REACT_APP_API_DOMAIN}/registration/verifyPhone`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: formFields.phone.value,
        code: formFields.smsCode.value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'verified') {
          const values = queryString.parse(window.location.search);
          const referral = values.ref ? values.ref : '';

          fetch(`${process.env.REACT_APP_API_DOMAIN}/registration/signup`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              phone: formFields.phone.value,
              email: formFields.email.value,
              simple: true,
              referral,
            }),
          })
            .then((registrationResponse) => registrationResponse.json())
            .then((registrationResponse) => {
              if (registrationResponse.status === 'success') {
                this.Auth.login(formFields.email.value, registrationResponse.password)
                  .then(() => {
                    const { from } = { from: { pathname: '/profile?activeTab=1' } };
                    history.replace(from);
                  })
                  .catch(() => {
                    showModalInfo('Неверный логин/пароль');
                  })
                  .finally(() => {
                    this.setState({ verificationPhone: false });
                  });
              } else if (registrationResponse === 'email_exists') {
                this.setState({
                  showFormPhoneVerification: false,
                  formFields: {
                    ...formFields,
                    smsCode: {
                      ...formFields.smsCode,
                      value: '',
                    },
                    email: {
                      ...formFields.email,
                      value: '',
                      errorMessage: 'Пользователь с данным email уже зарегистрирован',
                      errorVisible: true,
                    },
                  },
                });
              } else {
                showModalInfo('Произошла ошибка, повторите попытку.');
              }
            })
            .catch(() => {
              showModalInfo('Произошла ошибка, повторите попытку.');
            })
            .finally(() => {
              this.setState({ verificationPhone: false });
            });
        } else {
          this.setState({
            formFields: {
              ...formFields,
              smsCode: {
                ...formFields.smsCode,
                errorMessage: 'Вы ввели неправильный код',
                errorVisible: true,
              },
            },
          });
        }
      })
      .catch(() => {
        showModalInfo('Произошла ошибка, повторите попытку.');
      })
      .finally(() => {
        this.setState({ verificationPhone: false });
      });
  };

  handleValidate = (e, equalityField = null) => {
    const { formFields } = this.state;
    let { value } = e.target;
    if (equalityField) {
      value = { [e.target.id]: e.target.value, [equalityField]: formFields[equalityField].value };
    }
    Object.keys(formFields).forEach((field) => {
      formFields[field].errorVisible = false;
    });
    formFields[e.target.id].errorMessage = getValidation(value, formFields[e.target.id].validationType);
    if (formFields[e.target.id].errorMessage) {
      formFields[e.target.id].errorVisible = true;
    }
    this.setState({ formFields });
  };

  onCloseModalPersonalData = () => {
    this.setState({ openModalPersonalData: false });
  };

  onOpenModalPersonalData = (e) => {
    e.preventDefault();
    const { privacy_policy } = this.state;

    if (privacy_policy === '') {
      fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/privacy_policy`, {
        method: 'get',
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            privacy_policy: response.html,
            openModalPersonalData: true,
          });
        });
    } else {
      this.setState({ openModalPersonalData: true });
    }
  };

  hideCountdown = () => {
    this.setState({ showCountdown: false });
  };

  render() {
    const {
      openModalPersonalData,
      formFields,
      showFormPhoneVerification,
      loading,
      privacy_policy,
      showCountdown,
      verificationPhone,
    } = this.state;
    const formRegistration = (
      <form className="form-signin text-center" onSubmit={this.handleFormRegistrationSubmit}>
        <h1 className="h3 mb-3 font-weight-normal">Регистрация</h1>
        <Tippy
          content={formFields.phone.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.phone.errorVisible}
          hideOnClick={false}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <InputMask
              value={formFields.phone.value}
              className={classnames('form-control mb-2', { error: formFields.phone.errorVisible })}
              placeholder="Номер телефона"
              id="phone"
              name="phone"
              mask={getMask('phone').mask}
              formatChars={getMask('phone').formatChars}
              onBlur={this.handleValidate}
              onChange={this.handleChangeInput}
            />
          </div>
        </Tippy>
        <Tippy
          content={formFields.email.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.email.errorVisible}
          hideOnClick={false}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <input
            className={classnames('form-control mb-2', { error: formFields.email.errorVisible })}
            value={formFields.email.value}
            type="text"
            id="email"
            name="email"
            placeholder="mail@gmail.com"
            onBlur={this.handleValidate}
            onChange={this.handleChangeInput}
          />
        </Tippy>
        <Tippy
          content={formFields.personalData.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.personalData.errorVisible}
          hideOnClick={false}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div className={classnames('custom-checkbox', { error: formFields.personalData.errorVisible })}>
            <input
              id="personalData"
              type="checkbox"
              className="checkbox-styled"
              checked={formFields.personalData.value}
              onChange={this.handleChangeInput}
            />
            <label htmlFor="personalData">
              Я соглашаюсь на хранение и обработку <a className="dotted_link" href="" onClick={this.onOpenModalPersonalData}>своих данных</a>
            </label>
          </div>
        </Tippy>
        {loading
          ? (<ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />)
          : (<button className="btn btn-lg btn-success btn-block" type="submit">Продолжить</button>)}
      </form>
    );
    const formPhoneVerification = (
      <form className="form-signin text-center" onSubmit={this.handleFormPhoneVerificationSubmit}>
        <h1 className="h3 mb-3 font-weight-normal">На указанный номер телефона выслан СМС-код, введите его</h1>
        <Tippy
          content={formFields.smsCode.errorMessage}
          arrow
          placement="right"
          visible={!!formFields.smsCode.errorVisible}
          hideOnClick={false}
          popperOptions={{
            modifiers: [
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: flipBehavior,
                },
              },
            ],
          }}
          theme="translucent"
        >
          <div>
            <InputMask
              className={classnames('form-control mb-2', { error: formFields.smsCode.errorVisible })}
              disabled={verificationPhone}
              value={formFields.smsCode.value}
              type="text"
              onChange={(e) => this.handleChangeSmsCode(e)}
              maskChar={null}
              name="smsCode"
              id="smsCode"
              mask={getMask('smsCode').mask}
              placeholder="SMS Код"
            />
          </div>
        </Tippy>
        {verificationPhone && (<ReactLoading className="loading-circle d-inline-block" type="spin" height={38} width={38} />)}
        {showCountdown
          ? (
            <Countdown
              date={Date.now() + 45000}
              renderer={(props) => <div>Отправить код повторно через {props.seconds} сек.</div>}
              onComplete={this.hideCountdown}
            />
          )
          : (<a role="button" tabIndex="0" onClick={() => this.sendSms(true)} className="dotted_link">Отправить СМС повторно</a>)}
      </form>
    );
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalPersonalData}
          onClose={this.onCloseModalPersonalData}
          center
        >
          <div dangerouslySetInnerHTML={{ __html: privacy_policy }} />
        </Modal>
        {showFormPhoneVerification ? formPhoneVerification : formRegistration}
      </>
    );
  }
}

RegistrationCompleteForm.contextType = ModalMessagesContext;

export default RegistrationCompleteForm;
