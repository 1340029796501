import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import ReportsTable from './ReportsTable';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import TabNavList from '../Layout/Tabs/TabNavList';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    document.title = 'Статистика';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
  }

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  toggle(tab) {
    const { activeTab } = this.state;
    const { history } = this.props;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    history.push({ search: `?activeTab=${tab}` });
  }

  render() {
    const { activeTab } = this.state;
    const {
      location,
      userInfo,
    } = this.props;
    return (
      <div className="position-relative">
        <h1 className="page-wrapper__title d-inline-block mr-3">Статистика</h1>
        <div className="page_header__control mb-2 mb-md-0 d-inline-flex">
          <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
            <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
            Скачать в Excel
          </Button>
          <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
            <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
            Настроить таблицу
          </Button>
        </div>
        <h2>Статистика личных и субагентских продаж</h2>
        <TabNavList>
          <TabNavItem
            active={activeTab === '1'}
            onClick={() => { this.toggle('1'); }}
            text="Мои продажи"
          />
          <TabNavItem
            active={activeTab === '2'}
            onClick={() => { this.toggle('2'); }}
            text="Агентские продажи"
          />
          {userInfo.full_time_employee === 1 && (
            <TabNavItem
              active={activeTab === '3'}
              onClick={() => { this.toggle('3'); }}
              text="Все продажи (штатные)"
            />
          )}
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <TabNavItem
              active={activeTab === '5'}
              onClick={() => { this.toggle('5'); }}
              text="Продажи внешних агентов 1-го уровня"
            />
          )}
        </TabNavList>
        <TabContent animation="false" activeTab={activeTab}>
          <TabPane tabId="1">
            { activeTab === '1' ? <ReportsTable ref={activeTab === '1' ? this.child : undefined} tab="1" location={location} show={null} /> : null }
          </TabPane>
          <TabPane tabId="2">
            { activeTab === '2' ? <ReportsTable ref={activeTab === '2' ? this.child : undefined} tab="2" location={location} show="subagents" /> : null }
          </TabPane>
          {userInfo.full_time_employee === 1 && (
            <TabPane tabId="3">
              { activeTab === '3' ? <ReportsTable ref={activeTab === '3' ? this.child : undefined} tab="3" location={location} show="full_time_employee" /> : null }
            </TabPane>
          )}
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <>
              <TabPane tabId="5">
                { activeTab === '5' ? <ReportsTable ref={activeTab === '5' ? this.child : undefined} tab="5" location={location} show="not_employee_first_level" /> : null }
              </TabPane>
            </>
          )}
        </TabContent>
      </div>
    );
  }
}

export default withUserInfoStoreStore(Reports);
