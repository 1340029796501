import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import ru from 'date-fns/locale/ru';
import ReactLoading from 'react-loading';
import { customFetch } from '../../Utils/Helpers';

export default function ModalAcceptForm(props) {
  const {
    showAcceptModalForm,
    setShowAcceptModalForm,
    showModalInfo,
    loadPolicy,
    policy,
  } = props;

  const [orderDate, setOrderDate] = useState(new Date());
  const [loadingAcceptForm, setLoadingAcceptForm] = useState(false);

  const checkAccept = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    setLoadingAcceptForm(true);
    showModalInfo('Идёт перевод платежа в статус оплачено');
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/accept_payment`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: policy.id,
        orderDate,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setLoadingAcceptForm(false);
        if (response.error) {
          setShowAcceptModalForm(false);
          showModalInfo(response.error, 'error');
        } else {
          setShowAcceptModalForm(false);
          showModalInfo('Полис переведен в оплачено!');
          loadPolicy(policy.id);
        }
      })
      .catch(() => {
        setShowAcceptModalForm(false);
        setLoadingAcceptForm(false);
        showModalInfo('Ошибка', 'error');
      });
  };

  useEffect(() => {
    if (showAcceptModalForm) {
      setOrderDate(new Date(policy.order_date));
    }
  }, [showAcceptModalForm]);

  return (
    <Modal
      classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
      closeIconSize={8}
      open={showAcceptModalForm}
      onClose={() => setShowAcceptModalForm(false)}
      center
    >
      <h4 className="text-left">Перевод в статус оплачено</h4>
      <form onSubmit={(e) => checkAccept(e)}>
        <div className="form-group row">
          <div className="col-lg-8">
            <label htmlFor="startDate">Дата заключения</label>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              className={classnames('form-control')}
              selected={orderDate}
              onChange={(e) => setOrderDate(e)}
              name="startDate"
              id="startDate"
              locale={ru}
              maxDate={new Date()}
              showYearDropdown
              showMonthDropdown
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-12">
            {loadingAcceptForm ? (
              <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={37} width={37} />
            ) : (
              <button type="submit" className="btn btn-success mr-3">Применить</button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
}
