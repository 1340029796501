import React, { useMemo } from 'react';
import { format } from 'date-fns';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';

export default function MortgageChosenOffer(props) {
  const {
    chosenOffer,
    companies,
    backToForm,
    formData,
    draftInfo,
    disableField,
  } = props;

  const url = `url(${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].logo})`;

  const draftTypeName = useMemo(() => {
    if (draftInfo.type === 'mortgageLife') {
      return 'Жизнь';
    }
    if (draftInfo.type === 'mortgageComplex') {
      return 'Комплексное страхование';
    }
    return 'Имущество';
  }, [draftInfo.type]);

  return (
    <div className="form-group row">
      <div className="col-lg-12">
        <FormNotificationColorLine>
          <div className="chosen-offer">
            <div className="chosen-offer__logo logo-box">
              <div className="logo-box__logo" style={{ backgroundImage: url }} />
            </div>
            <div className="chosen-offer__price">
              <span>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(chosenOffer.price)}</span>
              <span>({draftTypeName})</span>
            </div>
            {!disableField ? (
              <button type="button" className="btn btn-success" onClick={(e) => backToForm(e, 'short', true)}>Изменить данные</button>
            ) : null}
          </div>
          <hr className="mb-0" />
          <ul className="chosen-offer-info">
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Банк:</span>
              <span>{formData.bank.value.label}</span>
            </li>
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Остаток кредита:</span>
              <span>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(formData.loanBalance.value)}</span>
              {formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'unicredit' && formData.bank.value.value !== 'uralfd' ? (
                <Tippy
                  className="tippy-custom"
                  arrow={false}
                  offset={[0, 5]}
                  content={<div>Страховая сумма по договору будет автоматически увеличена в соответствии с требованиями банка!</div>}
                  hideOnClick={false}
                >
                  <div className="d-inline-block ml-1">
                    <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw fa-lg" />
                  </div>
                </Tippy>
              ) : null}
            </li>
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Дата рождения:</span>
              <span>{format(formData.birthday.value, 'dd.MM.yyyy')}</span>
            </li>
            {draftInfo.type === 'mortgage' || draftInfo.type === 'mortgageComplex' ? (
              <li className="chosen-offer-info__item">
                <span className="chosen-offer-info__item-header">Год постройки:</span>
                <span>{formData.yearOfConstruction.value}</span>
              </li>
            ) : null}
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Кредитный договор:</span>
              <span>{formData.creditAgreementFlag.value ? 'Есть' : 'Нет'}</span>
            </li>
            <li className="chosen-offer-info__item">
              <span className="chosen-offer-info__item-header">Право собственности:</span>
              <span>{formData.ownership.value ? 'Есть' : 'Нет'}</span>
            </li>
            {draftInfo.type === 'mortgage' || draftInfo.type === 'mortgageComplex' ? (
              <>
                <li className="chosen-offer-info__item">
                  <span className="chosen-offer-info__item-header">Газосодержащие коммуникации:</span>
                  <span>{formData.hasGasFlag.value ? 'Есть' : 'Нет'}</span>
                </li>
                <li className="chosen-offer-info__item">
                  <span className="chosen-offer-info__item-header">Сдаётся в аренду:</span>
                  <span>{formData.usingForRent.value ? 'Да' : 'Нет'}</span>
                </li>
                <li className="chosen-offer-info__item">
                  <span className="chosen-offer-info__item-header">Деревянные перекрытия:</span>
                  <span>{formData.fireDangerFlag.value ? 'Есть' : 'Нет'}</span>
                </li>
              </>
            ) : null}
            {formData.bank.value.value === 'vtb' ? (
              <>
                <li className="chosen-offer-info__item">
                  <span className="chosen-offer-info__item-header">Город выдачи кредита:</span>
                  <span>{formData.creditAgreementAddress.value.data.city}</span>
                </li>
                {formData.creditAgreementFlag.value ? (
                  <li className="chosen-offer-info__item">
                    <span className="chosen-offer-info__item-header">Начало действия кредитного договора:</span>
                    <span>{format(formData.creditAgreementDate.value, 'dd.MM.yyyy')}</span>
                  </li>
                ) : null}
              </>
            ) : null}
          </ul>
        </FormNotificationColorLine>
      </div>
    </div>
  );
}
