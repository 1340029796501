import React from 'react';
import Modal from 'react-responsive-modal';
import { ReactDadata } from 'react-dadata';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { addDays } from 'date-fns';
import ru from 'date-fns/locale/ru';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-regular';
import getMask from '../../Utils/Masks';
import { handleChange } from '../../Utils/Helpers';

export default function ModalCommercialOfferForm(props) {
  const {
    formData,
    setFormData,
    saveDraft,
    phoneCheckRequestFlag,
    handleValidate,
    disableField,
    showCommercialOfferModalForm,
    setShowCommercialOfferModalForm,
    loadingCommercialOffer,
  } = props;

  return (
    <Modal
      classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
      closeIconSize={16}
      open={showCommercialOfferModalForm}
      onClose={() => setShowCommercialOfferModalForm('')}
      center
    >
      <h3 className="text-center">Проверьте правильность заполнения данных</h3>
      <form name="commercial_offer_modal_form" id="commercial_offer_modal_form" onSubmit={(e) => saveDraft(e, false, true)}>
        <div className="card-body">
          <div className="form-row">
            <div className="form-group col-lg-4 mortgage-form_dt_step_1">
              <label htmlFor="lastName">Фамилия</label>
              <ReactDadata
                suggestionType="fio"
                id="lastName"
                name="lastName"
                placeholder="Иванов"
                query={formData.lastName.value}
                onBlur={handleValidate}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'lastName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['SURNAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.lastName.errorMessage })}
              />
              {formData.lastName.errorMessage && (
                <div className="validation-error">
                  {formData.lastName.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group col-lg-4 mortgage-form_dt_step_2">
              <label htmlFor="firstName">Имя</label>
              <ReactDadata
                suggestionType="fio"
                id="firstName"
                name="firstName"
                placeholder="Иван"
                onBlur={handleValidate}
                query={formData.firstName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'firstName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['NAME']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.firstName.errorMessage })}
              />
              {formData.firstName.errorMessage && (
                <div className="validation-error">
                  {formData.firstName.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group col-lg-4">
              <label htmlFor="middleName">Отчество</label>
              <ReactDadata
                suggestionType="fio"
                id="middleName"
                name="middleName"
                placeholder="Иванович"
                onBlur={handleValidate}
                query={formData.middleName.value}
                token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                onChange={(e) => handleChange(e, 'middleName', formData, setFormData, null, { dadataType: 'fio' })}
                parts={['PATRONYMIC']}
                disabled={disableField}
                firstCapital
                translate
                className={classnames('form-control', { error: formData.middleName.errorMessage })}
              />
              {formData.middleName.errorMessage && (
                <div className="validation-error">
                  {formData.middleName.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <label htmlFor="startDate">Дата начала действия</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled={disableField}
                onBlur={handleValidate}
                className={classnames('form-control', { error: formData.startDate.errorMessage })}
                selected={formData.startDate.value ? formData.startDate.value : null}
                onChange={(e) => handleChange(e, 'startDate', formData, setFormData)}
                name="startDate"
                id="startDate"
                locale={ru}
                minDate={addDays(new Date(), 1)}
                maxDate={addDays(new Date(), 60)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.startDate.errorMessage && (
                <div className="validation-error">
                  {formData.startDate.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group col-lg-4">
              <label htmlFor="endDate">Дата окончания действия</label>
              <DatePicker
                popperModifiers={{
                  computeStyle: { gpuAcceleration: false },
                }}
                disabled
                onBlur={handleValidate}
                className={classnames('form-control', { error: formData.endDate.errorMessage })}
                selected={formData.endDate.value ? formData.endDate.value : null}
                onChange={(e) => handleChange(e, 'endDate', formData, setFormData)}
                name="endDate"
                id="endDate"
                locale={ru}
                minDate={addDays(new Date(), 1)}
                showYearDropdown
                showMonthDropdown
                dateFormat="dd.MM.yyyy"
                placeholderText="ДД.ММ.ГГГГ"
                customInput={
                  <InputMask mask="99.99.9999" inputMode="tel" />
                }
              />
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
              {formData.endDate.errorMessage && (
                <div className="validation-error">
                  {formData.endDate.errorMessage}
                </div>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-4">
              <label htmlFor="contactPhone">Телефон</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'contactPhone', formData, setFormData)}
                id="contactPhone"
                placeholder="+7 (999) 999-99-99"
                name="contactPhone"
                className={classnames('form-control', { error: formData.contactPhone.errorMessage })}
                mask={getMask('phone').mask}
                formatChars={getMask('phone').formatChars}
                value={formData.contactPhone.value}
              />
              {formData.contactPhone.errorMessage && (
                <div className="validation-error">
                  {formData.contactPhone.errorMessage}
                </div>
              )}
            </div>
            <div className="form-group col-lg-4">
              <label htmlFor="contactEmail">Email</label>
              <InputMask
                maskChar=""
                onBlur={handleValidate}
                disabled={disableField}
                onChange={(e) => handleChange(e, 'contactEmail', formData, setFormData)}
                id="contactEmail"
                placeholder="your@email.ru"
                name="contactEmail"
                className={classnames('form-control', { error: formData.contactEmail.errorMessage })}
                value={formData.contactEmail.value}
                mask={getMask('email').mask}
                formatChars={getMask('email').formatChars}
              />
              {formData.contactEmail.errorMessage && (
                <div className="validation-error">
                  {formData.contactEmail.errorMessage}
                </div>
              )}
            </div>
          </div>
          {phoneCheckRequestFlag ? (
            <div className="form-row">
              <div className="form-group col-12">
                <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={20} width={20} />
                Проверяем телефон...
              </div>
            </div>
          ) : null}
          <div className="form-row">
            <div className="col-lg-12">
              {loadingCommercialOffer ? (
                <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={37} width={37} />
              ) : (
                <button type="submit" className="btn btn-success mr-3">
                  {showCommercialOfferModalForm === 'send' ? (
                    <>
                      Продолжить
                    </>
                  ) : (
                    <>
                      Скачать КП
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
