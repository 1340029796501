import React, {
  useEffect, useState, useRef, forwardRef, useImperativeHandle, useContext,
} from 'react';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import queryString from 'query-string';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table-6';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import InputMask from 'react-input-mask';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCheckCircle, faTimesCircle } from '@fortawesome/fontawesome-free-regular';
import { format, parse, parseISO } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  toLocalDate,
  customFetch,
  setTableSorting,
  setTableSettings7,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

function InsuranceProgramsTable(props, ref) {
  const {
    setProgram, history, tab, programsList, setProgramsList,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [flagModalTableCustomized, setFlagModalTableCustomized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [defaultSortingField, setDefaultSortingField] = useState('created_at');
  const [defaultSortingOrder, setDefaultSortingOrder] = useState('true');
  const [activeFilterTab, setActiveFilterTab] = useState('1');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeFilterTabHChangedFlag, setActiveFilterTabHChangedFlag] = useState(false);

  const simulateClick = new MultiRef();

  const tableRef = useRef(null);
  const customizeTableRef = useRef(null);
  const programsListRef = useRef([]);

  useImperativeHandle(ref, () => ({
    openModalTableCustomized: () => {
      setFlagModalTableCustomized(true);
    },
  }));

  useEffect(() => {
    programsListRef.current = programsList;
  }, [programsList]);

  const delProgram = (id) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program/${id}`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const newProgramList = programsListRef.current.filter((p) => p.id !== id);
        setProgramsList(newProgramList);
      }).catch(() => {
        showModalInfo('Ошибка');
      });
  };

  const updateTableColumns = () => {
    const newColumns = [{
      Header: '№',
      accessor: 'id',
      maxWidth: 70,
      width: reactLocalStorage.getObject('insuranceProgramListSettings').id,
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Наименование',
      accessor: 'name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('insuranceProgramListSettings').name,
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_name !== false,
      showCustomized: true,
    }, {
      Header: 'Тип страхования',
      accessor: 'type_insurance_name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['type_insurance_name'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('insuranceProgramListSettings').type_insurance_name,
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_type_insurance_name !== false,
      showCustomized: true,
    }, {
      Header: 'Страховая компания',
      accessor: 'insurance_name',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['insurance_name'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('insuranceProgramListSettings').insurance_name,
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_insurance_name !== false,
      showCustomized: true,
    }, {
      Header: 'Дата создания',
      id: 'created_at',
      width: reactLocalStorage.getObject('insuranceProgramListSettings').created_at,
      filterable: false,
      accessor: (d) => format(toLocalDate(parseISO(d.created_at)), 'yyyy-MM-dd, HH:mm:ss'),
      sortMethod: (a, b) => (parse(a, 'yyyy-MM-dd, HH:mm:ss', new Date()) > parse(b, 'yyyy-MM-dd, HH:mm:ss', new Date()) ? 1 : -1),
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_created_at !== false,
    }, {
      Header: 'Дата изменения',
      accessor: 'updated_at',
      filterable: false,
      width: reactLocalStorage.getObject('insuranceProgramListSettings').updated_at,
      Cell: (row) => <div>{ format(toLocalDate(parseISO(row.original.updated_at)), 'yyyy-MM-dd, HH:mm:ss') }</div>,
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_updated_at !== false,
    }, {
      Header: 'Цена',
      accessor: 'cost',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['cost'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('insuranceProgramListSettings').cost,
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_cost !== false,
      showCustomized: true,
    }, {
      Header: 'Тип цены',
      accessor: 'cost_type_label',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['cost_type_label'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('insuranceProgramListSettings').cost_type_label,
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_cost_type_label !== false,
      showCustomized: true,
    }, {
      Header: 'Страны',
      accessor: 'country_list',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['country_list'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('insuranceProgramListSettings').country_list,
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_country_list !== false,
      showCustomized: true,
    }, {
      id: 'has_options',
      Header: 'Есть опции',
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'true') {
          return row._original.has_options;
        }
        if (filter.value === 'false') {
          return !row._original.has_options;
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Без фильтра</option>
          <option value>Есть</option>
          <option value={false}>Нет</option>
        </select>
      ),
      accessor: (d) => {
        if (d.has_options) {
          return (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        } if (!d.has_options) {
          return (<FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        }
        return 'Без статуса';
      },
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_has_options !== false,
      showCustomized: true,
    }, {
      id: 'has_risks',
      Header: 'Есть риски',
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'true') {
          return row._original.has_options;
        }
        if (filter.value === 'false') {
          return !row._original.has_options;
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Без фильтра</option>
          <option value>Есть</option>
          <option value={false}>Нет</option>
        </select>
      ),
      accessor: (d) => {
        if (d.has_risks) {
          return (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        } if (!d.has_risks) {
          return (<FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        }
        return 'Без статуса';
      },
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_has_risks !== false,
      showCustomized: true,
    }, {
      id: 'has_sales',
      Header: 'Есть скидки',
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'true') {
          return row._original.has_sales;
        }
        if (filter.value === 'false') {
          return !row._original.has_sales;
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Без фильтра</option>
          <option value>Есть</option>
          <option value={false}>Нет</option>
        </select>
      ),
      accessor: (d) => {
        if (d.has_sales) {
          return (<FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        } if (!d.has_sales) {
          return (<FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />);
        }
        return 'Без статуса';
      },
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_has_sales !== false,
      showCustomized: true,
    }, {
      Header: 'Статус',
      accessor: 'status_name',
      width: reactLocalStorage.getObject('insuranceProgramListSettings').status_name,
      Cell: ({ row }) => {
        if (row.status_name === 'Активная') return 'Активная';
        return 'Неактивная';
      },
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'active') {
          return row.status_name === 'Активная';
        }
        if (filter.value === 'inactive') {
          return row.status_name === 'Неактивная';
        }
        return true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Все</option>
          <option value="active">Активные</option>
          <option value="inactive">Неактивные</option>
        </select>
      ),
      show: reactLocalStorage.getObject('insuranceProgramListSettings').show_status_name,
      showCustomized: true,
    }, {
      Header: '',
      width: 40,
      filterable: false,
      Cell: (row) => (
        <div title="Удалить виджет"><FontAwesomeIcon onClick={() => delProgram(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    }];
    setColumns(newColumns);
  };

  const loadPrograms = (dateFrom, dateTo) => {
    setLoading(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program?dateFrom=${dateFrom}&dateTo=${dateTo}&service=true`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          data.forEach((program) => {
            if (program.name === null || program.name === '') {
              program.name = '-';
            }
            if (program.type_insurance === null) {
              program.type_insurance_name = '-';
            } else {
              program.type_insurance_name = program.type_insurance.name;
            }
            if (program.insurance === null) {
              program.insurance_name = '-';
            } else {
              program.insurance_name = program.insurance.name;
            }
            if (program.cost === null) {
              program.cost = 0;
            }
            if (program.status === 'active') {
              program.status_name = 'Активная';
            } else {
              program.status_name = 'Неактивная';
            }
            switch (program.cost_type) {
              case 'percent':
                program.cost_type_label = '%';
                break;
              case 'rub':
                program.cost_type_label = 'руб.';
                break;
              default:
                break;
            }
            program.country_list = program.country && program.country !== '' ? JSON.parse(program.country).map((c) => c.label).join('/') : '-';
          });
          setProgramsList(data);
          setTimeout(() => { updateTableColumns(); }, 1000);
        } else {
          showModalInfo(data.error, 'error');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableCustomizedSetAll = () => {
    simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  const handleTableCustomizedSetNone = () => {
    simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  const handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columnsArray = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('insuranceProgramListSettings');
    columnsArray.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('insuranceProgramListSettings', settings);
    setFlagModalTableCustomized(false);
  };

  const onFilteredChangeCustom = (newFiltered) => {
    setFiltered(newFiltered);
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  const customFilter = () => {

  };

  const handleChangeStart = (date) => {
    setLoading(true);
    setProgramsList([]);
    setStartDate(date);
    let filterDate = format(date, 'dd.MM.yyyy');
    let dateTo = null;
    const dateFrom = format(date, 'yyyy-MM-dd');
    if (endDate !== null) {
      filterDate = `${format(date, 'dd.MM.yyyy')} ${format(endDate, 'dd.MM.yyyy')}`;
      dateTo = format(endDate, 'yyyy-MM-dd');
    }
    loadPrograms(dateFrom, dateTo);
    const newFiltered = filtered;
    let findedIndex = -1;
    newFiltered.forEach((filter, i) => {
      if (filter.id === 'filter_date') {
        findedIndex = i;
      }
    });
    if (findedIndex !== -1) {
      newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
    } else {
      newFiltered.push({ id: 'filter_date', value: filterDate });
    }
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (endDate === null && filter.id === 'filter_date') {
          queryParams = `${queryParams + filter.id}=${filter.value} null`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    history.push({ search: queryParams });
  };

  const handleChangeEnd = (date) => {
    setLoading(true);
    setProgramsList([]);
    setEndDate(date);
    let filterDate = format(date, 'dd.MM.yyyy');
    let dateFrom = null;
    const dateTo = format(date, 'yyyy-MM-dd');
    if (startDate !== null) {
      filterDate = `${format(startDate, 'dd.MM.yyyy')} ${format(date, 'dd.MM.yyyy')}`;
      dateFrom = format(startDate, 'yyyy-MM-dd');
    }

    loadPrograms(dateFrom, dateTo);
    const newFiltered = filtered;
    let findedIndex = -1;
    newFiltered.forEach((filter, i) => {
      if (filter.id === 'filter_date') {
        findedIndex = i;
      }
    });
    if (findedIndex !== -1) {
      newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
    } else {
      newFiltered.push({ id: 'filter_date', value: filterDate });
    }
    let queryParams = '?';
    newFiltered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (startDate === null && filter.id === 'filter_date') {
          queryParams = `${queryParams + filter.id}=null ${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    history.push({ search: queryParams });
  };

  useEffect(() => {
    let defSortingField = '';
    let defSortingOrder = '';
    if (reactLocalStorage.getObject('insuranceProgramListSettings').defaultSortingField) {
      defSortingField = reactLocalStorage.getObject('insuranceProgramListSettings').defaultSortingField;
      defSortingOrder = reactLocalStorage.getObject('insuranceProgramListSettings').defaultSortingOrder;
    }
    setDefaultSortingField(defSortingField);
    setDefaultSortingOrder(defSortingOrder);

    const urlParams = queryString.parse(window.location.search);

    const urlParamsDateTab = urlParams.dateTab ? urlParams.dateTab : '1';

    setActiveFilterTab(urlParamsDateTab);
    setActiveFilterTabHChangedFlag(true);
  }, []);

  useEffect(() => {
    if (!flagModalTableCustomized && tableRef) {
      updateTableColumns();
    }
  }, [flagModalTableCustomized]);

  const customizedColumns = columns.map((column) => {
    const id = column.id ? column.id : column.accessor;
    if (column.showCustomized) {
      return (
        <li key={id} className="form-check">
          <input ref={simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
          <label className="form-check-label" htmlFor={id}>{column.Header}</label>
        </li>
      );
    }
    return null;
  });

  useEffect(() => {
    if (activeFilterTabHChangedFlag) {
      setLoading(true);
      setProgramsList([]);
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      let lastButOneMonth = date.getMonth();
      let currentYear = date.getFullYear();
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
      let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
      const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let dateFrom;
      let dateTo;
      let filterDate = null;
      if (activeFilterTab === '1') {
        dateFrom = format(startDateCurrentMonth, 'yyyy-MM-dd');
        dateTo = format(endDateCurrentMonth, 'yyyy-MM-dd');
        setStartDate(startDateCurrentMonth);
        setEndDate(endDateCurrentMonth);
        filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
      } else if (activeFilterTab === '2') {
        if (currentMonth === 1) {
          currentYear -= 1;
          lastButOneMonth = '12';
          lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
          startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
          endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
        }
        dateFrom = format(startDateLastButOneMonth, 'yyyy-MM-dd');
        dateTo = format(endDateLastButOneMonth, 'yyyy-MM-dd');
        setStartDate(startDateLastButOneMonth);
        setEndDate(endDateLastButOneMonth);
        filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
      } else if (activeFilterTab === '3') {
        dateFrom = null;
        dateTo = null;
        setStartDate(null);
        setEndDate(date);
        filterDate = `${format(date, 'dd.MM.yyyy')}`;
      }

      let newFiltered = [];
      if (filtered.length) {
        newFiltered = filtered;
      } else {
        const urlParams = queryString.parse(window.location.search);
        Object.keys(urlParams).forEach((filter) => {
          if (filter !== 'filter_date') {
            newFiltered.push({ id: filter, value: urlParams[filter] });
          }
        });
      }

      let findedIndex = -1;
      let findedIndexDateTab = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
        if (filter.id === 'dateTab') {
          findedIndexDateTab = i;
        }
      });
      if (findedIndex !== -1) {
        if (filterDate === null) {
          newFiltered.splice(findedIndex, 1);
        } else {
          newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
        }
      } else if (filterDate !== null) {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      if (findedIndexDateTab !== -1) {
        newFiltered[findedIndexDateTab] = { id: 'dateTab', value: activeFilterTab };
      } else {
        newFiltered.push({ id: 'dateTab', value: activeFilterTab });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (filter.id === 'filter_date' && activeFilterTab === '3') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
          }
          if (!Object.is(arr.length - 1, key)) {
            queryParams = `${queryParams}&`;
          }
        }
      });
      queryParams = `${queryParams}&activeTab=${tab}`;
      history.push({ search: queryParams });
      setFiltered(newFiltered);
      loadPrograms(dateFrom, dateTo);
      setActiveFilterTabHChangedFlag(false);
    }
  }, [activeFilterTabHChangedFlag]);

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalTableCustomized}
        onClose={() => { setFlagModalTableCustomized(false); }}
        center
      >
        <h4>Настройка отображения таблицы</h4>
        <p onClick={handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
        <p onClick={handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
        <form ref={customizeTableRef} onSubmit={handleSubmitTableCustomized} id="tableCustomized">
          <ul className="two-column-list">
            {customizedColumns}
          </ul>
          <button type="submit" className="btn btn-success">Сохранить</button>
        </form>
      </Modal>
      <div className="dateFilter">
        <div className="d-inline-block mb-2">
          <div
            onClick={() => { setActiveFilterTab('1'); setActiveFilterTabHChangedFlag(true); }}
            className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
          >
            Текущий месяц
          </div>
          <div
            onClick={() => { setActiveFilterTab('2'); setActiveFilterTabHChangedFlag(true); }}
            className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
          >
            Прошлый месяц
          </div>
          <div
            onClick={() => { setActiveFilterTab('3'); setActiveFilterTabHChangedFlag(true); }}
            className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
          >
            Все время
          </div>
        </div>
        <div className="d-inline-block mb-2">
          <span>Точные даты с</span>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            selected={startDate}
            className="form-control"
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeStart}
            maxDate={endDate}
            showYearDropdown
            showMonthDropdown
            locale={ru}
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
        </div>
        <div className="d-inline-block">
          <span>по</span>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            selected={endDate}
            className="form-control"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeEnd}
            minDate={startDate}
            showYearDropdown
            showMonthDropdown
            locale={ru}
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
        </div>
      </div>
      <ReactTable
        getTdProps={(state, rowInfo, column) => {
          if (rowInfo && column.id) {
            return {
              onMouseDown: (e) => {
                e.preventDefault();
                setProgram({
                  ...rowInfo.row._original,
                  saveChangesFlag: false,
                });
              },
            };
          } return {};
        }}
        ref={tableRef}
        filtered={filtered}
        onFilteredChange={(newFiltered) => onFilteredChangeCustom(newFiltered)}
        className="table"
        data={programsList}
        columns={columns}
        defaultSorted={[
          {
            id: defaultSortingField,
            desc: defaultSortingOrder,
          },
        ]}
        previousText="Назад"
        nextText="Вперед"
        loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
        noDataText="Виджеты не найдены"
        pageText="Страница"
        ofText="Из"
        rowsText="строк"
        loading={loading}
        defaultPageSize={50}
        filterable
        minRows={1}
        onSortedChange={(newSort) => {
          setTableSorting(newSort, 'insuranceProgramListSettings');
        }}
        onResizedChange={(newResized) => {
          setTableSettings7(newResized, 'insuranceProgramListSettings');
        }}
      />
    </>
  );
}

export default forwardRef(InsuranceProgramsTable);
