import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import { faLongArrowAltLeft } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO } from 'date-fns';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import IflFormContainer from './IflFormContainer';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  customSelectStyle,
  loadSuggestions,
} from '../../Utils/Helpers';
import IflIntegrationAnswers from './IflIntegrationAnswers';
import TabNavList from '../../Layout/Tabs/TabNavList';
import TabNavItem from '../../Layout/Tabs/TabNavItem';
import PolicyInfo from '../../Utils/UiComponents/PolicyInfo';

const FileSaver = require('file-saver');

export default function Ifl(props) {
  const {
    history,
    match,
    location,
    setTour,
  } = props;

  const formRef = useRef(null);

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [policyInfo, setPolicyInfo] = useState({
    integration: '',
    bitrix24_lead_id: '',
    erp_status: false,
    createdDate: '',
    updatedDate: '',
    policyStatus: '',
    widgetId: 0,
    agentFIO: '',
    agent_id: 0,
    commission: 0,
    cost: 0,
    company: '',
    insuranceId: '',
    orderDate: '',
    status: 'not_exist',
    linkPdf: false,
    receipt: false,
    objectInsurance: '',
    prolongation_parent_id: '',
    chain: false,
    typeDeclaration: null,
    middleOfficeAgent: null,
    digital_broker_id: null,
  });
  const [reasonForCancel, setReasonForCancel] = useState({ label: 'По ошибке', value: 'ПоОшибке' });
  const [reasonForChangeCost, setReasonForChangeCost] = useState(null);
  const [reasonForNullify, setReasonForNullify] = useState(null);
  const [modalSureIsOpen, setModalSureIsOpen] = useState(false);
  const [newCost, setNewCost] = useState(null);
  const [newAgent, setNewAgent] = useState(null);
  const [cancelingPolicyFlag, setCancelingPolicyFlag] = useState(false);
  const [policyErrors, setPolicyErrors] = useState({
    notAllowed: false,
    notExist: false,
  });
  const [policy, setPolicy] = useState({
    id: 0,
  });
  const [activeTab, setActiveTab] = useState('1');

  const goToAgent = (e, id) => {
    e.preventDefault();
    history.push(`/agent/${id}`);
  };

  const goToWidget = (e) => {
    e.preventDefault();
    history.push(`/widgets/${policyInfo.widgetId}`);
  };

  const copyIflForm = () => {
    formRef.current.copyForm();
  };

  const checkPayment = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/ifl/${policy.id}/check_payment`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          showModalInfo(response.message);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const loadPolicy = (id) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${id}?product=ifl`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((policyData) => {
        let errorNotAllowed = false;
        let newPolicy = policy;
        let newPolicyInfo = policyInfo;
        if (policyData.error) {
          errorNotAllowed = true;
        } else if (policyData.id) {
          let policyStatus;
          switch (policyData.status) {
            case 'complete':
              policyStatus = 'Оплачен';
              break;
            case 'ordered':
              policyStatus = 'Заказ';
              break;
            case 'pending':
              policyStatus = 'Редактирование';
              break;
            case 'processing':
              policyStatus = 'Обрабатывается';
              break;
            case 'calculated':
              policyStatus = 'Расчет';
              break;
            case 'canceled':
              policyStatus = 'Аннулирован';
              break;
            case 'need_help':
              policyStatus = 'Нужна помощь';
              break;
            default:
              policyStatus = '';
              break;
          }
          newPolicy = policyData;
          let insuranceId = '-';
          let linkPdf = false;
          if (policyData.status === 'complete' || policyData.status === 'canceled') {
            insuranceId = policyData.insurance_id ? policyData.insurance_id : '';
            linkPdf = policyData.link ? policyData.link : '';
          }
          newPolicyInfo = {
            integration: policyData.type_integration,
            bitrix24_lead_id: policyData.bitrix24_lead_id,
            erp_status: policyData.erp_status,
            policyStatus: policyData.is_demo ? 'Демо-данные' : policyStatus,
            linkPdf: [linkPdf],
            receipt: policyData.receipt,
            company: policyData.company,
            createdDate: policyData.created_at ? format(parseISO(policyData.created_at), 'dd.MM.yyyy') : '',
            updatedDate: policyData.updated_at ? format(parseISO(policyData.updated_at), 'dd.MM.yyyy') : '',
            widgetId: policyData.widget_id != null ? policyData.widget_id : 0,
            agentFIO: `${policyData.agent.last_name} ${policyData.agent.first_name} ${policyData.agent.middle_name}`,
            agent_id: policyData.agentId,
            commission: policyData.commission,
            cost: policyData.cost,
            insuranceId,
            orderDate: policyData.order_date ? format(parseISO(policyData.order_date), 'dd.MM.yyyy') : '',
            status: policyData.status,
            objectInsurance: policyData.policy_object.name,
            prolongation_parent_id: policyData.prolongation_parent_id,
            chain: policyData.chain ? policyData.chain : false,
            typeDeclaration: policyData.type_declaration ? policyData.type_declaration : null,
            middleOfficeAgent: policyData.middle_office_agent ? policyData.middle_office_agent : null,
            digital_broker_id: policyData.digital_broker_id ? policyData.digital_broker_id : null,
          };
        }
        if (Object.prototype.hasOwnProperty.call(newPolicy, 'form_data')
          && Object.prototype.hasOwnProperty.call(newPolicy.form_data, 'period')
          && Object.prototype.hasOwnProperty.call(newPolicy.form_data.period, 'value')
        ) {
          const { period } = newPolicy.form_data;
          newPolicy.form_data.period = {};
          newPolicy.form_data.period.value = period;
        }
        setPolicy(newPolicy);
        setTour('ifl-form');
        setPolicyInfo(newPolicyInfo);
        setPolicyErrors((prev) => ({
          ...prev,
          notAllowed: errorNotAllowed,
        }));
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
        setPolicyErrors((prev) => ({
          ...prev,
          notExist: true,
        }));
      });
  };

  const makeRequestForSupport = (e) => {
    e.preventDefault();
    formRef.current.makeRequestForSupport();
  };

  const getApiLogs = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/ifl/${policy.id}/get-draft-logs`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.headers.get('Content-Type').includes('json')) {
          return response.json();
        }
        return response.blob();
      })
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else {
          FileSaver.saveAs(response, 'logs.zip');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const goToPolicy = (e) => {
    e.preventDefault();
    history.push(`/ifl/${policyInfo.prolongation_parent_id}`);
    loadPolicy(policyInfo.prolongation_parent_id);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    window.history.replaceState(null, null, `?activeTab=${tab}`);
  };

  const onCloseModalSure = () => {
    setModalSureIsOpen(false);
  };

  const onOpenModalSure = (e, type) => {
    e.preventDefault();
    setModalSureIsOpen(type);
  };

  const addToErp = () => {
    setCancelingPolicyFlag(true);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/ifl/${policy.id}/addToErp`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          showModalInfo(response.message);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setCancelingPolicyFlag(false);
        setModalSureIsOpen(false);
      });
  };

  const editPolicy = () => {
    setCancelingPolicyFlag(true);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    let reason;
    switch (modalSureIsOpen) {
      case 'cancel':
        reason = reasonForCancel;
        break;
      case 'changeCost':
        reason = reasonForChangeCost;
        break;
      case 'nullify':
        reason = reasonForNullify;
        break;
      default:
        reason = '';
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policy.id}/${modalSureIsOpen}`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        reason,
        updateInErp: true,
        product: 'ifl',
        newCost,
        newAgent,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          loadPolicy(policy.id);
          showModalInfo(response.message);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setCancelingPolicyFlag(false);
        setModalSureIsOpen(false);
      });
  };

  const copyDraft = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/copy/${policy.id}`, {
      method: 'get',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        history.push(`/ifl/${response}`);
        loadPolicy(response);
        showModalInfo('Черновик скопирован');
      })
      .catch(() => {
        showModalInfo('Произошла ошибка');
      });
  };

  useEffect(() => {
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      setActiveTab(urlParams.activeTab);
    }
    if (match.params.id) {
      loadPolicy(match.params.id);
    } else {
      setTour('ifl-form');
    }
  }, []);

  return (
    <div className="position-relative">
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={modalSureIsOpen}
        onClose={onCloseModalSure}
        center
      >
        <h4>Вы уверены?</h4>
        {modalSureIsOpen === 'changeAgent' ? (
          <div className="form-group row">
            <div className="col-md-12">
              <label>Укажите нового агента</label>
              <AsyncSelect
                styles={customSelectStyle()}
                name="newAgent"
                placeholder="Укажите причину"
                classNamePrefix="react-select"
                inputId="newAgent"
                value={newAgent || ''}
                onChange={(e) => setNewAgent(e)}
                className="form-control-custom"
                loadOptions={debounce(loadSuggestions(''), 500)}
                noOptionsMessage={() => 'Не найдено'}
              />
            </div>
          </div>
        ) : null}
        {modalSureIsOpen === 'cancel' ? (
          <div className="form-group row">
            <div className="col-md-12">
              <label>Укажите причину аннулирования</label>
              <Select
                styles={customSelectStyle()}
                name="reasonForCancel"
                placeholder="Укажите причину"
                classNamePrefix="react-select"
                inputId="reasonForCancel"
                value={reasonForCancel}
                onChange={(e) => setReasonForCancel(e)}
                className="form-control-custom"
                options={[
                  { label: 'По ошибке', value: 'ПоОшибке' },
                  { label: 'Отказ клиента', value: 'ОтказКлиента' },
                ]}
                noOptionsMessage={() => 'Не найдено'}
              />
            </div>
          </div>
        ) : null}
        {modalSureIsOpen === 'changeCost' ? (
          <div className="form-group row">
            <div className="col-md-12">
              <label>Укажите причину изменения</label>
              <input
                className="form-control"
                required
                value={reasonForChangeCost}
                type="text"
                id="reasonForChangeCost"
                name="reasonForChangeCost"
                onChange={(e) => setReasonForChangeCost(e.target.value)}
              />
            </div>
            <div className="col-md-12">
              <label>Укажите СП</label>
              <input
                className="form-control"
                required
                value={newCost}
                type="number"
                step="0.01"
                id="newCost"
                name="newCost"
                onChange={(e) => setNewCost(e.target.value)}
              />
            </div>
          </div>
        ) : null}
        {modalSureIsOpen === 'add'
          ? (<button disabled={cancelingPolicyFlag} className="btn btn-success mr-2" type="button" onClick={addToErp}>Да</button>)
          : (<button disabled={cancelingPolicyFlag} className="btn btn-success mr-2" type="button" onClick={editPolicy}>Да</button>)}
        <button disabled={cancelingPolicyFlag} type="button" className="btn btn-secondary" onClick={onCloseModalSure}>Нет</button>
        {cancelingPolicyFlag && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
      </Modal>
      <PolicyInfo
        product="ifl"
        policy={policy}
        policyInfo={policyInfo}
        copyForm={copyIflForm}
        checkPayment={checkPayment}
        makeRequestForSupport={makeRequestForSupport}
        getApiLogs={getApiLogs}
        onOpenModalSure={onOpenModalSure}
        goToAgent={goToAgent}
        goToWidget={goToWidget}
        goToPolicy={goToPolicy}
        history={history}
      />
      {policyErrors.notAllowed && (
        <div className="row mb-3">
          <div className="col-lg-3">
            <div className="error_block">Недостаточно прав</div>
          </div>
        </div>
      )}
      {policyErrors.notExist && (
        <div className="row mb-3">
          <div className="col-lg-3">
            <div className="error_block">Данный полис не существует</div>
          </div>
        </div>
      )}
      {policy.is_demo ? (
        <div>
          <a href="" onClick={(e) => { e.preventDefault(); history.push('/admin?activeTab=12'); }} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору демо-черновиков</a>
        </div>
      ) : null}
      <TabNavList>
        <TabNavItem
          active={activeTab === '1'}
          onClick={() => { toggleTab('1'); }}
          text="Новый полис"
        />
        {policy.id ? (
          <TabNavItem
            active={activeTab === '2'}
            onClick={() => { toggleTab('2'); }}
            text="Предыдущие результаты"
          />
        ) : null}
      </TabNavList>
      <TabContent animation="false" activeTab={activeTab}>
        <TabPane className="d-table w-100" tabId="1">
          { activeTab === '1' ? (
            <IflFormContainer
              ref={activeTab === '1' ? formRef : undefined}
              policy={policy}
              match={match}
              history={history}
              policyStatus={policyInfo.status}
              loadPolicy={loadPolicy}
              copyDraft={copyDraft}
              location={location}
              setTour={setTour}
            />
          ) : null }
        </TabPane>
        <TabPane tabId="2">
          { activeTab === '2' ? <IflIntegrationAnswers ref={activeTab === '2' ? formRef : undefined} formId={policy.id} copyDraft={copyDraft} match={match} /> : null }
        </TabPane>
      </TabContent>
    </div>
  );
}
