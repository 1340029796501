import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import ru from 'date-fns/locale/ru';
import { faCalendarAlt, faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactLoading from 'react-loading';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { format, parseISO } from 'date-fns';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import classnames from 'classnames';
import Acrobat from '../../img/acrobat.svg';
import IconDownload from '../../img/icon-download.svg';
import {
  toUTC, customFetch, toLocalDate, customSelectStyle, getThemes, uploadImage,
} from '../Utils/Helpers';
import ModalInfoList from '../Utils/ModalNotification/ModalInfoList';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class NewsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      theme: [],
      themesOptions: [],
      isToggleNewsEdit: false,
      uploading: false,
      newsStartDate: null,
      newsEndDate: null,
      editorState: EditorState.createEmpty(),
      modalInfoMessages: [],
      messageIdGen: 1,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    getThemes().then((response) => {
      const themesOptions = response.map((theme) => ({ value: theme.domain, label: theme.domain }));
      this.setState({ themesOptions });
    });
    this.loadNews(id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadNews(this.props.match.params.id);
    }
  }

  loadNews = async (id) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    let news = await customFetch(`${process.env.REACT_APP_API_DOMAIN}/news/${id}`, {
      headers: {
        Authorization: lsToken,
      },
    });

    news = await news.json();

    try {
      if (!news.error) {
        this.setState({
          news,
          editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(news.txt))),
          newsStartDate: toLocalDate(parseISO(news.date_start)),
          newsEndDate: news.date_end ? toLocalDate(parseISO(news.date_end)) : null,
          theme: news.themes,
        });
        if (news.is_read) {
          const { updateUnreadNewsCount } = this.props;
          updateUnreadNewsCount();
        }
      } else {
        this.setState((prevState) => ({
          modalInfoMessages: prevState.modalInfoMessages.concat({ text: news.error, timeout: 5000, id: prevState.messageIdGen + 1 }),
          messageIdGen: prevState.messageIdGen + 1,
        }));
      }
    } catch (err) {
    }
  };

  uploadImageNews = (file) => new Promise((resolve) => {
    const formData = new FormData();
    formData.append('file', file);
    uploadImage(file, formData).then((res) => {
      if (!res.error) {
        resolve({ data: { link: res } });
      }
    });
  });

  flushMessages = (messages) => {
    this.setState({ modalInfoMessages: messages });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleChangeDate = (type, date) => {
    this.setState({
      [type]: date,
    });
  };

  handleChangeInput = (key, e) => {
    const { news } = this.state;
    news[key] = e.target.value;
    this.setState({ news });
  };

  handleChangeSelect = (key, e) => {
    this.setState({ [key]: e });
  }

  handleChangeToggle = () => {
    this.setState((prevState) => ({
      isToggleNewsEdit: !prevState.isToggleNewsEdit,
    }));
  };

  handleChangeCheckbox = (key, e) => {
    const { news } = this.state;
    news[key] = e.target.checked;
    this.setState({
      news,
    });
  };

  openTagNews = (e, tag) => {
    const { history } = this.props;
    history.push(`/news?tag=${tag}`);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      newsStartDate,
      newsEndDate,
      editorState,
      news,
      theme,
    } = this.state;
    this.setState({ uploading: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const existedFiles = [];
    Object.keys(news.files).forEach((file) => {
      existedFiles.push(news.files[file].path);
    });
    const formData = new FormData();
    Object.keys(news.files).forEach((file) => {
      formData.append(file, news.files[file]);
    });
    formData.append('date_start', format(toUTC(newsStartDate), 'yyyy-MM-dd HH:mm:ss'));
    formData.append('date_end', newsEndDate ? format(toUTC(newsEndDate), 'yyyy-MM-dd HH:mm:ss') : '');
    formData.append('title', news.title);
    formData.append('txt', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    formData.append('tags', news.tags ? news.tags : '');
    formData.append('main_block', +news.main_block);
    formData.append('top_block', +news.top_block);
    formData.append('left_block', +news.left_block);
    formData.append('first_level_only', +news.first_level_only);
    formData.append('existedFiles', JSON.stringify(existedFiles));
    formData.append('themes', JSON.stringify(theme));
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/news/${news.id}/update`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((updatedNews) => {
        this.setState((prevState) => ({
          news: updatedNews,
          uploading: false,
          isToggleNewsEdit: !prevState.isToggleNewsEdit,
        }));
      });
  };

  uploadFile = (acceptedFiles) => {
    const { news } = this.state;
    acceptedFiles.forEach((file) => {
      news.files.push(file);
    });
    this.setState({ news });
  };

  removeFile = (index) => {
    const { news } = this.state;
    news.files.splice(index, 1);
    this.setState({ news });
  };

  render() {
    const { history, userInfo } = this.props;
    const {
      news, isToggleNewsEdit, newsStartDate, newsEndDate, editorState, uploading, modalInfoMessages, theme, themesOptions,
    } = this.state;
    const agentName = news.agent ? `${news.agent.last_name} ${news.agent.first_name} ${news.agent.middle_name}` : '';
    const text = news.txt ? parse(draftToHtml(JSON.parse(news.txt))) : '';
    return (
      <>
        <ModalInfoList messages={modalInfoMessages} flushMessages={this.flushMessages} />
        {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
          isToggleNewsEdit
            ? (
              <form onSubmit={this.handleSubmit} className="mb-4">
                <h2>Редактирование новости</h2>
                <div className="card">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-sm-6 col-xl-4">
                        <label htmlFor="policyStartDate">Дата начала</label>
                        <DatePicker
                          popperModifiers={{
                            computeStyle: { gpuAcceleration: false },
                          }}
                          selected={newsStartDate}
                          onChange={(date) => this.handleChangeDate('newsStartDate', date)}
                          className="form-control"
                          name="newsStartDate"
                          id="newsStartDate"
                          locale={ru}
                          minDate={new Date()}
                          showYearDropdown
                          showMonthDropdown
                          showTimeSelect
                          timeCaption="Время"
                          timeFormat="HH:mm"
                          dateFormat="dd.MM.yyyy HH:mm"
                          placeholderText="12.12.1990 12:30"
                          dropdownMode="scroll"
                          required
                          customInput={
                            <InputMask mask="99.99.9999 99:99" />
                          }
                        />
                        <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <label htmlFor="policyStartDate">Дата окончания</label>
                        <DatePicker
                          popperModifiers={{
                            computeStyle: { gpuAcceleration: false },
                          }}
                          selected={newsEndDate}
                          onChange={(date) => this.handleChangeDate('newsEndDate', date)}
                          className="form-control"
                          name="newsEndDate"
                          id="newsEndDate"
                          locale={ru}
                          minDate={new Date()}
                          showYearDropdown
                          showMonthDropdown
                          showTimeSelect
                          timeCaption="Время"
                          timeFormat="HH:mm"
                          dateFormat="dd.MM.yyyy HH:mm"
                          placeholderText="12.12.1990 12:30"
                          dropdownMode="scroll"
                          customInput={
                            <InputMask mask="99.99.9999 99:99" />
                            }
                        />
                        <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="newsHeader">Заголовок новости</label>
                        <input value={news.title} type="text" className="form-control" id="newsHeader" onChange={(e) => this.handleChangeInput('title', e)} placeholder="" />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="newsHeader">Выбрать кастомицазию</label>
                        <div className="d-flex flex-row align-items-center w-100">
                          <Select
                            isMulti
                            classNamePrefix="react-select"
                            styles={customSelectStyle()}
                            name="theme"
                            placeholder="Только для выбранных"
                            id="theme"
                            value={theme}
                            onChange={(e) => this.handleChangeSelect('theme', e)}
                            options={themesOptions}
                          />
                          <button type="button" className="btn btn-success ml-2" onClick={() => this.handleChangeSelect('theme', themesOptions)}>Добавить все</button>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label htmlFor="newsText">Текст новости</label>
                        <Editor
                          toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                            blockType: {
                              inDropdown: true,
                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                            },
                            image: {
                              uploadEnabled: true,
                              uploadCallback: (file) => this.uploadImageNews(file),
                              previewImage: true,
                            },
                          }}
                          stripPastedStyles
                          editorState={editorState}
                          wrapperClassName="demo-wrapper"
                          editorClassName="form-control"
                          onEditorStateChange={this.onEditorStateChange}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="newsTags">Тэги (указывать через запятую)</label>
                        <input
                          value={news.tags}
                          type="text"
                          className="form-control"
                          id="newsTags"
                          onChange={(e) => this.handleChangeInput('tags', e)}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label>Файлы</label>
                        {news.files.length > 0 && (
                        <ul>
                          {news.files.map((file, index) => (
                            <li key={file.path}>
                              {file.path.split('/').slice(-1)[0].split('.')[0]} - {file.size} bytes <FontAwesomeIcon
                                onClick={() => this.removeFile(index)}
                                icon={faTrashAlt}
                                className="fa-fw"
                              />
                            </li>
                          ))}
                        </ul>
                        )}
                        <Dropzone onDrop={(acceptedFiles) => this.uploadFile(acceptedFiles)}>
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p>Загрузить файл или картинку</p>
                                <p>Перетащить с помощью Drag's'Drop</p>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <div className="form-check custom-checkbox">
                          <input
                            checked={news.main_block}
                            className="form-check-input checkbox-styled"
                            onChange={(e) => this.handleChangeCheckbox('main_block', e)}
                            type="checkbox"
                            id="newsMainBlock"
                          />
                          <label className="form-check-label" htmlFor="newsMainBlock">
                            Новость
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input
                            checked={news.top_block}
                            className="form-check-input checkbox-styled"
                            onChange={(e) => this.handleChangeCheckbox('top_block', e)}
                            type="checkbox"
                            id="newsTopBlock"
                          />
                          <label className="form-check-label" htmlFor="newsTopBlock">
                            Уведомление сверху
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input
                            checked={news.left_block}
                            className="form-check-input checkbox-styled"
                            onChange={(e) => this.handleChangeCheckbox('left_block', e)}
                            type="checkbox"
                            id="newsLeftBlock"
                          />
                          <label className="form-check-label" htmlFor="newsLeftBlock">
                            Уведомление слева
                          </label>
                        </div>
                        <div className="form-check custom-checkbox">
                          <input
                            checked={news.first_level_only}
                            className="form-check-input checkbox-styled"
                            onChange={(e) => this.handleChangeCheckbox('first_level_only', e)}
                            type="checkbox"
                            id="newsFirstLevelOnly"
                          />
                          <label className="form-check-label" htmlFor="newsFirstLevelOnly">
                            Только для агентов 1 уровня
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12">
                        <button type="submit" className="btn btn-success mr-2">Сохранить</button>
                        <button type="button" className="btn btn-secondary" onClick={this.handleChangeToggle}>Отмена
                        </button>
                        {uploading
                            && (
                            <ReactLoading
                              className="loading-circle ml-3 d-inline-block"
                              type="spin"
                              height={38}
                              width={38}
                            />
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )
            : (<button type="button" className="btn btn-success mb-4" onClick={this.handleChangeToggle}>Редактировать</button>)
        )}
        <div className="row">
          <div className="col-12">
            <LinkBreadcrumbs onClick={(e) => { e.preventDefault(); history.push('/news'); }}>К списку новостей</LinkBreadcrumbs>
          </div>
        </div>
        <div className="news-open-container">
          <h2 className="news-open-head">{news.title}</h2>
          <div className="news-open-container__info">
            <div className="news-open-container__info-autor dot-border">Автор: {agentName}</div>
            <div className={classnames('news-open-container__info-date', news.tags ? 'dot-border' : '')}>Дата создания: {new Date(Date.parse(news.date_start)).toLocaleDateString('ru', { year: 'numeric', month: '2-digit', day: '2-digit' })}</div>
            <div className="news-open-container__info-tags">
              {news.tags && news.tags.split(',').map((tag) => (
                <a key={tag} className="news-open-container__info-tags__tag" onClick={(e) => this.openTagNews(e, tag)}>#{tag}</a>
              ))}
            </div>
          </div>
          <div className="news-open-container__text">
            {text}
          </div>
          <div className={`news-open-container__file ${news.files?.length ? '' : 'd-none'}`}>
            {news.files && news.files.map((file) => (
              <div className="news-open-container__file-block">
                <img src={Acrobat} alt="" />
                <div className="news-open-container__file-block__info">
                  <p className="title-file">{file.path.split('/').slice(-1)[0].split('.')[0]}</p>
                  <p className="info-file">{file.path.split('/').slice(-1)[0].split('.').slice(-1)[0]} • {(file.size / 1000).toFixed(2).toString().replace('.', ',')}Kb</p>
                </div>
                <a href={`${process.env.REACT_APP_BACKEND_DOMAIN}/${file.path}`} className="ml-auto" rel="noopener noreferrer" target="_blank">
                  <img src={IconDownload} alt="" className="icon mr-3 pb-1" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default withUserInfoStoreStore(NewsDetail);
