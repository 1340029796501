import { addMinutes, subMinutes } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import React from 'react';
import ReactExport from 'react-data-export';
import AuthService from './AuthService';
import getValidation from './Validation';

const Auth = new AuthService(process.env.REACT_APP_API_DOMAIN);

export function abortFetching(controller) {
  controller.abort();
}

export function customFetch(url, options, controller) {
  const token = localStorage.getItem('id_token');
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
  const opts = {
    method: 'get',
    headers: {
      Authorization: lsToken,
    },
  };
  if (controller) {
    opts.signal = controller.signal;
    options.signal = controller.signal;
  }
  if (Auth.isTokenExpired(token)) {
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/refresh`, opts)
      .then((response) => response.json())
      .then((response) => {
        localStorage.setItem('id_token', response.token);
        options.headers.Authorization = `Bearer ${response.token}`;
        return fetch(url, {
          ...options,
        })
          .then((res) => res);
      })
      .catch(() => {
        window.location.replace('/login');
      });
  }
  return fetch(url, {
    ...options,
  })
    .then((res) => {
      if (res.status === 401) {
        localStorage.removeItem('id_token');
        window.location.replace('/login');
      }
      if (res.status === 207) {
        localStorage.removeItem('id_token');
        window.location.replace('/tech_work');
      }
      if (res.status === 500) {
        throw new Error('FATAL ERROR');
      }
      if (res.status === 404) {
        return { customMessage: 'METHOD NOT FOUND' };
      }
      return res;
    });
}

export function getDefaultSorting(tableName) {
  const defaultSorting = {
    field: '',
    order: '',
  };
  if (reactLocalStorage.getObject(tableName).defaultSortingField) {
    defaultSorting.field = reactLocalStorage.getObject(tableName).defaultSortingField;
    defaultSorting.order = reactLocalStorage.getObject(tableName).defaultSortingOrder;
  }
  return defaultSorting;
}

export function getThemes(onlyDomain = true) {
  const token = `Bearer ${localStorage.getItem('id_token')}`;
  const params = onlyDomain ? '?onlyDomain=true' : '';
  return customFetch(`${process.env.REACT_APP_API_DOMAIN}/themes${params}`, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((response) => response.json());
}

export function uploadImage(file, formData) {
  const token = `Bearer ${localStorage.getItem('id_token')}`;

  return customFetch(`${process.env.REACT_APP_API_DOMAIN}/email_editor/upload`, {
    method: 'POST',
    headers: {
      Authorization: token,
      Accept: 'application/json',
    },
    body: formData,
  })
    .then((response) => response.json());
}

export function getCustomFieldOptions() {
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
  return customFetch(`${process.env.REACT_APP_API_DOMAIN}/custom_field`, {
    headers: {
      Authorization: lsToken,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}

export function getManagers() {
  const token = `Bearer ${localStorage.getItem('id_token')}`;
  return customFetch(`${process.env.REACT_APP_API_DOMAIN}/managers`, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}

export function getProducts(activeIntegration) {
  const token = `Bearer ${localStorage.getItem('id_token')}`;
  let activeIntegrationString = '';
  if (activeIntegration) {
    activeIntegrationString = '?activeIntegration=true';
  }
  return customFetch(`${process.env.REACT_APP_API_DOMAIN}/products${activeIntegrationString}`, {
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json());
}

export function getDefaultSorting7(tableName, oldSorted) {
  let defaultSorting = {
    id: '',
    desc: '',
  };
  if (typeof oldSorted !== undefined) {
    defaultSorting = { ...oldSorted };
  }
  if (reactLocalStorage.getObject(tableName).defaultSortingField) {
    defaultSorting.id = reactLocalStorage.getObject(tableName).defaultSortingField;
    defaultSorting.desc = reactLocalStorage.getObject(tableName).defaultSortingOrder;
  }
  return [defaultSorting];
}

export function setTableSettings(newResized, tableName) {
  const settings = reactLocalStorage.getObject(tableName);
  newResized.forEach((item) => {
    settings[item.id] = item.value;
  });
  reactLocalStorage.setObject(tableName, settings);
}

export function sortAllPolicesArray(array, sortBy, direction, policySortOrderUp, priorityFlag = false) {
  if (typeof direction === 'undefined') {
    direction = policySortOrderUp ? 'up' : 'down';
  } else {
    direction = typeof direction !== 'undefined' ? direction : 'up';
  }
  array.sort((a, b) => {
    if (priorityFlag) {
      if (a.priority < b.priority) return 1;
      if (a.priority > b.priority) return -1;
    }
    if (a[sortBy] > b[sortBy]) {
      if (direction === 'up') {
        return 1;
      }
      return -1;
    }
    if (a[sortBy] < b[sortBy]) {
      if (direction === 'up') {
        return -1;
      }
      return 1;
    }
    if (!priorityFlag) {
      if (a.priority < b.priority) return 1;
      if (a.priority > b.priority) return -1;
    }
    return 0;
  });
  return array;
}

export function setTableSettings7(newResized, tableName) {
  const settings = reactLocalStorage.getObject(tableName);
  Object.entries(newResized).forEach(([key, value]) => {
    if (typeof value === 'number') {
      settings[key] = value;
    }
  });
  reactLocalStorage.setObject(tableName, settings);
}

export function setTableSorting(newSort, tableName) {
  const settings = reactLocalStorage.getObject(tableName);
  if (newSort.length) {
    settings.defaultSortingField = newSort[0].id;
    settings.defaultSortingOrder = newSort[0].desc;
  } else {
    delete settings.defaultSortingField;
    delete settings.defaultSortingOrder;
  }
  reactLocalStorage.setObject(tableName, settings);
}

export function setTableCustomized(elements, tableName) {
  const columnsArray = Array.from(elements).slice(0, -1);
  const settings = reactLocalStorage.getObject(tableName);
  columnsArray.forEach((column) => {
    settings[`show_${column.name}`] = column.checked;
  });
  reactLocalStorage.setObject(tableName, settings);
}

export function getCustomizedColumns(columns, simulateClick) {
  return columns.map((column) => {
    const id = column.id ? column.id : column.accessor;
    if (column.showCustomized) {
      return (
          <li key={id} className="form-check">
            <input ref={simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
      );
    }
    return null;
  });
}

export function switchLanguage(string) {
  const letters = {
    q: 'й', w: 'ц', e: 'у', r: 'к', t: 'е', y: 'н', u: 'г', i: 'ш', o: 'щ', p: 'з', '[': 'х', ']': 'ъ', a: 'ф', s: 'ы', d: 'в', f: 'а', g: 'п', h: 'р', j: 'о', k: 'л', l: 'д', ';': 'ж', '\'': 'э', z: 'я', x: 'ч', c: 'с', v: 'м', b: 'и', n: 'т', m: 'ь', ',': 'б', '.': 'ю', Q: 'Й', W: 'Ц', E: 'У', R: 'К', T: 'Е', Y: 'Н', U: 'Г', I: 'Ш', O: 'Щ', P: 'З', '{': 'Х', '}': 'Ъ', A: 'Ф', S: 'Ы', D: 'В', F: 'А', G: 'П', H: 'Р', J: 'О', K: 'Л', L: 'Д', ':': 'Ж', '"': 'Э', Z: '?', X: 'ч', C: 'С', V: 'М', B: 'И', N: 'Т', M: 'Ь', '<': 'Б', '>': 'Ю', '`': 'ё', '~': 'Ё',
  };
  const value = string.split('');
  value.forEach((letter, index) => {
    if (letters[letter]) {
      value[index] = letters[letter];
    }
  });
  return value.join('');
}

export function toUTC(date) {
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset));
}

export function toLocalDate(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export function customSelectStyle() {
  return {
    option: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
      '&:hover': { backgroundColor: '#fff' },
    }),
    placeholder: () => ({
      color: '#c8c9ca',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#f5f5f5' : '#fff',
      boxShadow: 'unset',
      '&:hover': { borderColor: '#a9a9a9' },
      borderColor: state.isFocused ? '#a9a9a9' : '#e1e1e1',
    }),
  };
}

export const selectGroupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const selectGroupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#515151',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

export function prepFormData(data) {
  const formData = new FormData();
  Object.keys(data).forEach((field) => {
    if ('file' in data[field]) {
      if (data[field].file) {
        formData.append(field, data[field].file);
      } else {
        formData.append(field, data[field].path);
      }
    } else if (data[field].value && Array.isArray(data[field].value)) {
      formData.append(field, JSON.stringify(data[field].value));
    } else if (data[field].validationType === 'select') {
      formData.append(field, data[field].value.value);
    } else {
      formData.append(field, data[field].value);
    }
  });
  return formData;
}

export function handleChange(event, fieldName, formData, setFormData, path = null, args = {}) {
  let value;
  const newFormData = { ...formData };
  if (event) {
    if (event.target) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked;
      } else if (event.target.type === 'file') {
        if (event.target.files[0]) {
          value = event.target.files[0];
        } else {
          value = '';
        }
      } else {
        value = event.target.value;
      }
    } else if (args.dadataType && args.dadataType === 'fio') {
      value = event.value;
    } else {
      value = event;
    }
  } else {
    value = '';
  }
  if (args.customMask) {
    value = value.slice(0, 25);
    const chunks = value.replace(/_/g, '').split(':');
    let shortFlag = false;
    if (chunks.length > 3 && chunks[2].length === 6) {
      shortFlag = true;
    }
    value = value.replace(/\D/g, '');
    if (value.length > 2) {
      value = value.slice(0, 2) + ':' + value.slice(2);
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + ':' + value.slice(5);
    }
    if (shortFlag) {
      if (value.length > 11) {
        value = value.slice(0, 12) + ':' + value.slice(12);
      }
    } else {
      if (value.length > 13) {
        value = value.slice(0, 13) + ':' + value.slice(13);
      }
    }
  }
  if (args.pureValue) {
    value = value.replace(/[^a-zа-я0-9]+/ig, '');
  }
  if (args.translate) {
    value = switchLanguage(value);
  }
  if (args.upperCase) {
    value = value.toUpperCase();
  }
  if (args.firstCapital) {
    value = value.toLowerCase();
    value = value.charAt(0).toUpperCase() + value.slice(1);
  }
  if (args.firstCapital && args.multiWords) {
    value = value.toLowerCase();
    value = value.split(' ');
    value.forEach((word, index) => {
      value[index] = word.charAt(0).toUpperCase() + word.slice(1);
    });
    value = value.join(' ');
  }
  if (fieldName === 'pType') {
    newFormData.pProgram.value = {};
  }
  if (fieldName === 'insuranceLimit'
    || fieldName === 'homeProperty'
    || fieldName === 'civilResponsibility'
    || fieldName === 'decoration'
    || fieldName === 'flatSum') {
    value = value.replace(/[^\d.]/g, '');
  }

  if (path) {
    const paths = path.split('.');
    paths.push(fieldName);
    //let object = newFormData;
    if (paths.length === 1) {
      newFormData[paths[0]].value = value;
    } else if (paths.length === 2) {
      newFormData[paths[0]][paths[1]].value = value;
    } else if (paths.length === 3) {
      newFormData[paths[0]][paths[1]][paths[2]].value = value;
    } else if (paths.length === 4) {
      newFormData[paths[0]][paths[1]][paths[2]][paths[3]].value = value;
    } else if (paths.length === 5) {
      newFormData[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]].value = value;
    } else if (paths.length === 6) {
      newFormData[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]].value = value;
    } else if (paths.length === 7) {
      newFormData[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]].value = value;
    } else if (paths.length === 8) {
      newFormData[paths[0]][paths[1]][paths[2]][paths[3]][paths[4]][paths[5]][paths[6]][paths[7]].value = value;
    }

    // function setDeepValue(object) {
    //   while (paths.length > 1) {
    //     object = object[paths.shift()];
    //   }
    //   object = object[paths.shift()];
    //   object.value = value;
    // }
    //
    // setDeepValue(newFormData);
  } else {
    newFormData[fieldName].errorMessage = '';
    newFormData[fieldName].value = value;
  }
  setFormData(newFormData);
}

export function setDeepObjectValue(formData, setFormData, path, value, errorMessage) {
  const newFormData = { ...formData };
  const paths = path.split('.');

  function setDeepValue(object) {
    while (paths.length > 1) {
      object = object[paths.shift()];
    }
    object = object[paths.shift()];

    object.value = value;
    object.errorMessage = errorMessage;
  }

  setDeepValue(newFormData);
  setFormData(newFormData);
}

export function setDeepObjectValidationFlag(formData, setFormData, flagPaths) {
  const newFormData = { ...formData };
  Object.entries(flagPaths).forEach(([path, flag]) => {
    const paths = path.split('.');

    function setDeepValue(object) {
      while (paths.length > 1) {
        object = object[paths.shift()];
      }
      object = object[paths.shift()];

      object.validationRequired = flag;
    }

    setDeepValue(newFormData);
  });
  setFormData(newFormData);
}

export function getDeepObjectValue(formData, path) {
  let data = { ...formData };
  let error = '';
  const paths = path.split('.');
  paths.forEach((pathPart) => {
    if (data[pathPart] && !error) {
      data = data[pathPart];
    } else {
      error = 'Не корректно указан путь в formData для валидации';
    }
  });
  return { error, data: data.value };
}

export function validateField(fieldName, formData, setFormData) {
  const newFormData = { ...formData };
  const { value } = newFormData[fieldName];
  newFormData[fieldName].errorMessage = getValidation(value, newFormData[fieldName].validationType);
  setFormData(newFormData);
}

export function validateFields(fields) {
  let validationSuccess = true;
  Object.keys(fields).forEach((field) => {
    if (Array.isArray(fields[field])) {
      fields[field].forEach((driver, i) => {
        if (fields[field][i].validationType && fields[field].validationRequired) {
          const { value } = fields[field][i];
          fields[field][i].errorMessage = getValidation(value, fields[field][i].validationType);
          if (fields[field][i].errorMessage) {
            validationSuccess = false;
          }
        }
      });
    } else if (typeof fields[field] === 'object' && fields[field] !== null && fields[field].validationType && fields[field].validationRequired) {
      let value;
      if (fields[field].validationType === 'select') {
        value = fields[field].value ? fields[field].value.value : null;
      } else if (fields[field].validationType === 'multiSelect') {
        value = fields[field].value.length ? fields[field].value : null;
      } else {
        value = fields[field].value;
      }
      fields[field].errorMessage = getValidation(value, fields[field].validationType);
      if (fields[field].errorMessage) {
        validationSuccess = false;
      }
    }
  });
  return {
    result: fields,
    validationSuccess,
  };
}

export function sortConversion(a, b) {
  a = a !== '-' ? Number(a.split('%')[0]) : '';
  b = b !== '-' ? Number(b.split('%')[0]) : '';
  return ((a > b) || (b === '' && a === 0)) ? 1 : -1;
}

export function downloadDescriptionOptions() {
  const token = `Bearer ${localStorage.getItem('id_token')}`;
  return fetch(`${process.env.REACT_APP_API_DOMAIN}/agent_descriptions`, {
    method: 'get',
    headers: {
      Authorization: token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      let descriptionOptions = [];
      if (!response.error) {
        descriptionOptions = response.map((description) => ({ value: description, label: description }));
      }
      return descriptionOptions;
    })
    .catch(() => []);
}

export function lightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + String("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
}

export function onFilteredChangeCustom(newFiltered, startDate = null, tab = null) {
  let queryParams = '?';
  newFiltered.forEach((filter, key, arr) => {
    if (filter.id !== 'activeTab') {
      if (filter.id === 'filter_date' && startDate === null) {
        queryParams = `${queryParams + filter.id}=null ${filter.value}`;
      } else {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
      }
      if (!Object.is(arr.length - 1, key)) {
        queryParams = `${queryParams}&`;
      }
    }
  });
  if (queryParams === '?') {
    queryParams = `${queryParams}activeTab=${tab}`;
  } else {
    queryParams = `${queryParams}&activeTab=${tab}`;
  }
  window.history.replaceState(null, null, queryParams);
  return newFiltered;
}

const setAddressEnd = (e) => {
  const { target } = e;
  target.setSelectionRange(target.value.length, target.value.length);
};

export function setCursorToEnd(e) {
  e.target.onfocus = setAddressEnd;
}

const validFileExt = (fileRef) => {
  let isValid = true;
  const fileNameArr = fileRef.current.state.file.name.split('.');
  if (fileNameArr[fileNameArr.length - 1] !== 'csv') {
    isValid = false;
  }
  return isValid;
};

export function handleOnDrop(fileParsedData, fileRef, setFileError, setFileData) {
  if (validFileExt(fileRef)) {
    const numbers = fileParsedData.filter((row) => row.data[0] !== '').map((row) => row.data);
    setFileError('');
    setFileData([...numbers]);
  } else {
    setFileError('Неверный формат файла. Допустимы только csv файлы,');
  }
}

export function handleOnError(setFileError, showModalInfo) {
  setFileError('Ошибка обработки файла');
  showModalInfo('Ошибка обработки файла');
}

export function handleOnRemoveFile(setFileError, setFileData) {
  setFileError('');
  setFileData([]);
}

export function downloadReport(tableRef, columns, ignoreColumns, setData) {
  const { ExcelColumn } = ReactExport.ExcelFile;
  const { filteredData } = tableRef.current.getTableData();
  const dataToDownload = [];
  for (let index = 0; index < filteredData.length; index += 1) {
    const recordToDownload = {};
    for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
      if (columns[colIndex].show !== false) {
        if (filteredData[index].values[columns[colIndex].accessor]) {
          recordToDownload[columns[colIndex].Header] = filteredData[index].values[columns[colIndex].accessor];
        } else {
          recordToDownload[columns[colIndex].Header] = filteredData[index].values[columns[colIndex].id];
        }
      }
    }
    dataToDownload.push(recordToDownload);
  }
  const excelcolumns = columns.filter((column) => column.show !== false && ignoreColumns.includes(column.id) === false).map((column) => <ExcelColumn label={column.Header} value={column.Header} />);
  setData(excelcolumns, [...dataToDownload]);
}

export function downloadReport6(tableRef, columns, setExcelColumns, setDataSet, setGoDownloadFile) {
  const { ExcelColumn } = ReactExport.ExcelFile;
  const currentRecords = tableRef.current.getResolvedState().sortedData;
  const dataToDownload = [];
  for (let index = 0; index < currentRecords.length; index += 1) {
    const recordToDownload = {};
    for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
      if (columns[colIndex].show !== false) {
        if (currentRecords[index][columns[colIndex].accessor]) {
          if (currentRecords[index][columns[colIndex].accessor] && currentRecords[index][columns[colIndex].accessor].$$typeof) {
            recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor].props.children[0];
          } else {
            recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor];
          }
        } else if (currentRecords[index][columns[colIndex].id] && currentRecords[index][columns[colIndex].id].$$typeof) {
          recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id].props.children[0];
        } else {
          recordToDownload[columns[colIndex].Header] = currentRecords[index][columns[colIndex].id];
        }
      }
    }
    dataToDownload.push(recordToDownload);
  }
  const excelcolumns = columns.filter((column) => column.show !== false).map((column) => <ExcelColumn label={column.Header} value={column.Header} />);

  setExcelColumns([...excelcolumns]);
  setDataSet([...dataToDownload]);
  setGoDownloadFile(true);
}

export function handleTableCustomizedSetAll(simulateClick) {
  simulateClick.map.forEach((input) => {
    if (!input.checked) {
      input.click();
    }
  });
}

export function handleTableCustomizedSetNone(simulateClick) {
  simulateClick.map.forEach((input) => {
    if (input.checked) {
      input.click();
    }
  });
}

export function handleSubmitTableCustomized(e, tableSettingsName, setFlagModalTableCustomized, updateTableColumns) {
  e.preventDefault();
  const columnsArray = Array.from(e.target.elements).slice(0, -1);
  const settings = reactLocalStorage.getObject(tableSettingsName);
  columnsArray.forEach((column) => {
    settings[`show_${column.name}`] = column.checked;
  });
  reactLocalStorage.setObject(tableSettingsName, settings);
  setFlagModalTableCustomized(false);
  updateTableColumns();
}

export function loadCRMOptionsLists(widgetId = null, integrationType = 'agent', option = []) {
  let paramOptionString = '';
  option.forEach((val) => {
    paramOptionString += `&option[]=${val}`;
  });

  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
  return customFetch(`${process.env.REACT_APP_API_DOMAIN}/crm/bitrix24/optionLists?widgetId=${widgetId}&integrationType=${integrationType}${paramOptionString}`, {
    method: 'get',
    headers: {
      Authorization: lsToken,
    },
  })
    .then((response) => response.json());
}

export function onContextMenuClick(e, route, id) {
  e.preventDefault();
  window.open(`/${route}/${id}`, '_blank');
}

export function onTableCellClick(e, row, cell, route, history) {
  e.preventDefault();
  if (!cell.column.clickable) {
    if (e.target.type !== 'checkbox' && cell.column.Header) {
      if (e.button === 0) {
        history.push(`/${route}/${row.original.id}`);
      }
      if (e.button === 1) {
        window.open(`/${route}/${row.original.id}`, '_blank');
      }
    }
  }
}

export function loadSuggestions(type, domain = '') {
  return function (inputValue, resolve) {
    if (inputValue.length === 0) {
      resolve([]);
    } else {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agents/search?name=${inputValue}&type=${type}&domain=${domain}`, {
        method: 'get',
        headers: {
          Authorization: token,
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            const agents = response.map((agent) => ({ value: agent.id, label: `${agent.last_name} ${agent.first_name} ${agent.middle_name} - ${agent.id}` }));
            resolve(agents);
          }
        });
    }
  };
}

export function truncateString(s, w) {
  return s.length > w ? `${s.substring(0, w).trim()}...` : s;
}

export function setNewPartnerForDigitalBroker(policy, setNewAgent) {
  if (policy.digital_broker_id) {
    setNewAgent({
      value: policy.agent.id,
      label: `${policy.agent.last_name} ${policy.agent.first_name} ${policy.agent.middle_name} - ${policy.agent.id}`,
    });
  }
}

export function toggleSelectAll(value, data, setSelected, tableData, customField = false) {
  const newSelected = {};
  if (value) {
    const { filteredData } = tableData;
    for (let i = 0; i < filteredData.length; i += 1) {
      if (data.find((x) => filteredData[i].original.id === x.id)) {
        newSelected[filteredData[i].original.id] = customField ? filteredData[i].original[customField] : true;
      }
    }
    setSelected(newSelected, 1, 1);
  } else {
    setSelected({}, 0, 0);
  }
}

export function toggleRow(id, selected, policies, setSelected, customField = false) {
  const newSelected = Object.assign({}, selected);
  if (selected[id]) {
    delete newSelected[id];
  } else {
    newSelected[id] = customField || !selected[id];
  }
  let selectFiltered = 2;
  let selectAll = 0;
  if (Object.entries(newSelected).length === 0) {
    selectFiltered = 0;
  }
  if (Object.entries(newSelected).length === policies.length) {
    selectFiltered = 1;
    selectAll = 1;
  }
  setSelected(newSelected, selectFiltered, selectAll);
}

export function toggleSelectFiltered(selectFiltered, policies, tableData, setSelected, customField = false) {
  const newSelected = {};
  if (selectFiltered === 0) {
    const { pageData } = tableData;
    for (let i = 0; i < pageData.length; i += 1) {
      if (i >= pageData.length) break;
      if (policies.find((x) => pageData[i].original.id === x.id)) {
        newSelected[pageData[i].original.id] = customField ? pageData[i].original[customField] : true;
      }
    }
  }
  setSelected(newSelected, (policies.length === Object.keys(newSelected).length && selectFiltered === 0) ? 1 : 0, selectFiltered === 0 ? 1 : 0);
}
