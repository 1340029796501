import React, { Component } from 'react';
import { ReactDadata } from 'react-dadata';
import DatePicker from 'react-datepicker';
import { format, parse, parseISO } from 'date-fns';
import ru from 'date-fns/locale/ru';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/fontawesome-free-regular';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import ym from 'react-yandex-metrika';
import OutsideClickHandler from 'react-outside-click-handler';
import { customSelectStyle, switchLanguage, customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import getMask from '../../Utils/Masks';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';
import LinkBreadcrumbs from '../../Layout/LinkBreadcrumbs';

const customStyles = customSelectStyle();
const FileSaver = require('file-saver');

class Dkp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingInfoFlags: {
        all: true,
        marks: true,
        policy: true,
      },
      dropDown: '',
      loading: false,
      loadingCarInfo: false,
      errorBlankRegNumber: false,
      errorWrongRegNumber: false,
      errorCantCompareCar: false,
      models: [],
      marks: [],
      formData: {
        city: '',
        createDate: null,
        sellerFirstName: '',
        sellerMiddleName: '',
        sellerLastName: '',
        sellerBirthday: null,
        sellerPassport: '',
        sellerPassportDate: null,
        sellerAddress: '',
        sellerPassportAuthor: '',
        sellerPhone: '',
        sellerEmail: '',
        sellerType: { value: 'natural', label: 'Физ. лицо' },
        sellerPost: '',
        sellerOrganisationChart: '',
        sellerOrganisationName: '',
        sellerOrganisationAddress: '',
        sellerOrganisationOgrn: '',
        sellerOrganisationInn: '',
        sellerOrganisationPaymentAccount: '',
        sellerOrganisationBankName: '',
        sellerOrganisationCorrespondentAccount: '',
        sellerOrganisationBik: '',
        buyerFirstName: '',
        buyerMiddleName: '',
        buyerLastName: '',
        buyerBirthday: null,
        buyerPassport: '',
        buyerPassportDate: null,
        buyerAddress: '',
        buyerPassportAuthor: '',
        buyerPhone: '',
        buyerEmail: '',
        buyerType: { value: 'natural', label: 'Физ. лицо' },
        buyerPost: '',
        buyerOrganisationChart: '',
        buyerOrganisationName: '',
        buyerOrganisationAddress: '',
        buyerOrganisationOgrn: '',
        buyerOrganisationInn: '',
        buyerOrganisationPaymentAccount: '',
        buyerOrganisationBankName: '',
        buyerOrganisationCorrespondentAccount: '',
        buyerOrganisationBik: '',
        carMark: { label: '', value: '' },
        carModel: { label: '', value: '' },
        carColor: '',
        carYear: { label: '', value: '' },
        carCategory: '',
        carRun: '',
        carPrice: '',
        carRegistrationNumber: '',
        carVin: '',
        carBodyNumber: '',
        carChassis: '',
        carRegistrationNumberNone: false,
        carVinNone: false,
        carBodyNumberNone: false,
        carChassisNone: false,
        carEngineModelAndNumber: '',
        carEnginePower: '',
        carEngineCapacity: '',
        carPassportSeriesNumber: '',
        carEPassportNumber: '',
        carPassportDate: null,
        carPassportAuthor: '',
        carPassportType: { value: 'passport', label: 'ПТС' },
        carSertificateSeriesNumber: '',
        carSertificateDate: null,
        carSertificateAuthor: '',
      },
      formId: null,
      yearsOption: Array.from(new Array(100), (val, index) => (new Date()).getFullYear() - index).map((year) => ({ value: year, label: year })),
    };
  }

  componentDidMount() {
    document.title = 'Договор купли-продажи';
    const { showModalInfo } = this.context;
    const { location, history } = this.props;
    if (location.state && location.state.isNewRequest) {
      showModalInfo('Черновик сохранен');
      const state = { ...history.location.state };
      delete state.isNewRequest;
      history.replace({ ...history.location, state });
    }
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_marks`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ marks: response.value });
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      })
      .finally(() => {
        this.setState((prevState) => ({
          ...prevState,
          loadingInfoFlags: {
            ...prevState.loadingInfoFlags,
            marks: false,
          },
        }));
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    const { loadingInfoFlags } = this.state;
    if (prevProps.match.params.id !== match.params.id && !loadingInfoFlags.all) {
      this.downloadData();
    }
    if (loadingInfoFlags.all) {
      if (loadingInfoFlags.marks !== prevState.loadingInfoFlags.marks) {
        if (match.params.id) {
          const formId = match.params.id;
          this.setState({ formId }, () => {
            this.downloadData();
          });
        } else {
          this.setState((prevState) => ({
            ...prevState,
            loadingInfoFlags: {
              ...prevState.loadingInfoFlags,
              all: false,
            },
          }));
        }
      }
    }
  }

  downloadData = () => {
    const { formId } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/dkp/${formId}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((dkp) => {
        if (dkp.error) {
          this.setState({ dkpFormLoadingError: 'Недостаточно прав' });
        } else {
          const { formData } = this.state;
          Object.entries(dkp.form_data).forEach(([key, value]) => {
            formData[key] = value;
          });

          if (dkp.form_data.carEPassportNumber) formData.carPassportType = { value: 'epassport', label: 'ЭПТС' };

          if (formData.carMark.value) {
            customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_models/${formData.carMark.value}`, {
              headers: {
                Authorization: token,
              },
            })
              .then((response) => response.json())
              .then((response) => {
                this.setState({ models: response.value });
              })
              .catch((err) => {
                console.log(`Error Reading data ${err}`);
              });
          }
          this.setState({ formData });
        }
      })
      .catch(() => {
        this.setState({ dkpFormLoadingError: 'Ошибка загрузки формы' });
      })
      .finally(() => {
        this.setState((prevState) => ({
          ...prevState,
          loadingInfoFlags: {
            ...prevState.loadingInfoFlags,
            all: false,
          },
        }));
      });
  };

  handleChangeMark = (event) => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        carMark: event,
        carModel: { label: '', value: '' },
      },
    });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_models/${event.value}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ models: response.value });
      })
      .catch((err) => {
        console.log(`Error Reading data ${err}`);
      });
  };

  handleChangeModel = (event) => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        carModel: event,
      },
    });
  };

  checkVehicleInfo = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { formData } = this.state;
    if (formData.carRegistrationNumber.replace(/_/g, '').length > 7) {
      this.setState({
        loadingCarInfo: true,
        errorBlankRegNumber: false,
        errorWrongRegNumber: false,
        errorCantCompareCar: false,
      });
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/car/info?checkType=number_plate&value=${formData.carRegistrationNumber}`, {
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((info) => {
          if (info.error === 'Too many requests') {
            this.setState({ loadingCarInfo: false, errorWrongRegNumber: true });
          } else if (info.is_error) {
            this.setState({ loadingCarInfo: false, errorWrongRegNumber: true });
          } else {
            const { marks } = this.state;
            let findMark = false;
            let findModel = false;
            if (info.result[0].car_mark) {
              Object.keys(marks).some((value) => {
                if (marks[value].toLowerCase() === info.result[0].car_mark.toLowerCase()) {
                  findMark = {
                    value,
                    label: marks[value],
                  };
                  return true;
                }
              });
              if (findMark) {
                this.setState((prevState) => ({
                  formData: {
                    ...prevState.formData,
                    carMark: findMark,
                  },
                }));
                customFetch(`${process.env.REACT_APP_API_DOMAIN}/car_models/${findMark.value}`, {
                  headers: {
                    Authorization: lsToken,
                  },
                })
                  .then((response) => response.json())
                  .then((models) => {
                    if (!models.Error) {
                      this.setState({ models: models.value });
                      if (info.result[0].car_model) {
                        Object.keys(models.value).some((id) => {
                          if (models.value[id].toLowerCase() === info.result[0].car_model.toLowerCase()) {
                            findModel = {
                              value: id,
                              label: models.value[id],
                            };
                            return true;
                          }
                        });
                      }
                      if (findModel) {
                        this.setState((prevState) => ({
                          formData: {
                            ...prevState.formData,
                            carModel: findModel,
                          },
                        }));
                      } else {
                        this.setState({ errorCantCompareCar: true });
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(`Error Reading data ${err}`);
                  });
              } else {
                this.setState({ errorCantCompareCar: true });
              }
            }
            let serialDocument;
            let numberDocument;
            let numberEDocument;
            let vehicleTypeDocument;
            if (info.result[0].credential.credential_type === 'VEHICLE_REGISTRATION') {
              serialDocument = info.result[0].credential.series;
              numberDocument = info.result[0].credential.number;
              vehicleTypeDocument = { value: 'sertificate', label: 'СТС' };
            } else if (info.result[0].credential.credential_type === 'VEHICLE_PASSPORT') {
              if (info.result[0].credential.number > 10) {
                serialDocument = '';
                numberDocument = '';
                numberEDocument = info.result[0].credential.number;
                vehicleTypeDocument = { value: 'epassport', label: 'ЭПТС' };
              } else {
                serialDocument = info.result[0].credential.series;
                numberDocument = info.result[0].credential.number;
                vehicleTypeDocument = { value: 'passport', label: 'ПТС' };
              }
            } else {
              serialDocument = '';
              numberDocument = '';
              vehicleTypeDocument = { value: 'sertificate', label: 'СТС' };
            }
            this.setState((prevState) => ({
              formData: {
                ...prevState.formData,
                carYear: info.result[0].manufacturing_year ? { value: info.result[0].manufacturing_year, label: info.result[0].manufacturing_year } : { value: '', label: '' },
                carSertificateSeriesNumber: vehicleTypeDocument.value === 'sertificate' ? serialDocument + numberDocument : '',
                carVin: info.result[0].vin_number ? info.result[0].vin_number : '',
                carSertificateDate: (vehicleTypeDocument.value === 'sertificate' && info.result[0].credential.issue_date) ? format(parseISO(info.result[0].credential.issue_date), 'dd.MM.yyyy') : '',
                carBodyNumber: info.result[0].car_body_number ? info.result[0].car_body_number : '',
                carEnginePower: info.result[0].engine_power ? info.result[0].engine_power.toFixed(2) : '',
                carPassportType: numberEDocument ? { value: 'epassport', label: 'ЭПТС' } : { value: 'passport', label: 'ПТС' },
                carEPassportNumber: numberEDocument || '',
              },
              loadingCarInfo: false,
            }));
            return info;
          }
        })
        .catch(() => {
          this.setState({ loadingCarInfo: false, errorWrongRegNumber: true });
        });
    } else {
      this.setState({ errorBlankRegNumber: true, errorWrongRegNumber: false });
    }
  };

  handleChangeCheckbox = (e) => {
    const { formData } = this.state;
    formData[e.target.id] = e.target.checked;
    this.setState({ formData });
  };

  handleChangeDate = (type, date) => {
    const { formData } = this.state;
    formData[type] = format(date, 'dd.MM.yyyy');
    this.setState({ formData });
  };

  handleChangeAddress = (query, name) => {
    const { formData } = this.state;
    formData[name] = query;
    this.setState({ formData });
  };

  handleChangeFio = (e, name) => {
    const { formData } = this.state;
    formData[name] = e.value;
    this.setState({ formData });
  };

  handleChangeInput = (e, translate = false, upperCase = false) => {
    let { value } = e.target;
    if (translate) {
      value = switchLanguage(e.target.value);
    }
    if (upperCase) {
      value = value.toUpperCase();
    }
    const { formData } = this.state;
    formData[e.target.id] = value;
    this.setState({ formData });
  };

  handleChangeCarYear = (e) => {
    const { formData } = this.state;
    formData.carYear = e;
    this.setState({ formData });
  };

  handleSaveForm = () => {
    const { formData } = this.state;
    const { history } = this.props;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/dkp`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ formData, service: true }),
    })
      .then((response) => response.json())
      .then((response) => {
        history.push({
          pathname: `/dkp/${response.id}`,
          state: { isNewRequest: true },
        });
      })
      .catch(() => {
        const { showModalInfo } = this.context;
        showModalInfo('Произошла ошибка');
      });
  };

  handleUpdateForm = () => {
    const { formData, formId } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { showModalInfo } = this.context;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/dkp/${formId}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ formData }),
    })
      .then((response) => response.json())
      .then(() => {
        showModalInfo('Черновик обновлен');
      })
      .catch(() => {
        showModalInfo('Произошла ошибка');
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    ym('reachGoal', 'downloadDkpReport');
    const { formData, formId } = this.state;
    this.setState({ loading: true });
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    if (!formId) {
      this.handleSaveForm();
    } else {
      this.handleUpdateForm();
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/dkp/generatePdf`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ formData }),
    })
      .then((response) => response.blob())
      .then((file) => {
        this.setState({ loading: false });
        FileSaver.saveAs(file, `Договор купли-продажи ${formData.carMark.label} ${formData.carModel.label} от ${formData.createDate ? formData.createDate : ''}.pdf`);
      });
  };

  copyForm = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { history } = this.props;
    const { formId } = this.state;
    const { showModalInfo } = this.context;
    this.setState({ dropDown: '' });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/dkp/copy`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ formId }),
    })
      .then((response) => response.json())
      .then((newFormId) => {
        history.push(`/dkp/${newFormId}`);
        showModalInfo('Черновик скопирован');
        this.setState({ formId: newFormId });
      })
      .catch(() => {
        showModalInfo('Произошла ошибка');
      });
  };

  handleChangeSelect = (event, actionMeta) => {
    const { formData } = this.state;
    if (actionMeta.name === 'carPassportType' && event.value === 'passport') {
      formData.carEPassportNumber = '';
    } else {
      formData.carPassportSeriesNumber = '';
    }

    this.setState({
      formData: {
        ...formData,
        [actionMeta.name]: event,
      },
    });
  };

  dropDownBlock = () => {
    this.setState((prevState) => ({
      dropDown: prevState.dropDown === 'opened' ? '' : 'opened',
    }));
  };

  dropDownBlockClose = () => {
    const { dropDown } = this.state;
    if (dropDown !== '') {
      this.setState({ dropDown: '' });
    }
  };

  convertDkpToPolicy = (e) => {
    e.preventDefault();
    const { history } = this.props;
    const { formId } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ loading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/dkp/${formId}/convertDkpToPolicy`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        history.push(`/osago/${response.id}`);
      })
      .catch(() => {
        const { showModalInfo } = this.context;
        this.setState({ loading: false });
        showModalInfo('Произошла ошибка');
      });
  };

  render() {
    const {
      formData,
      yearsOption,
      loading,
      dkpFormLoadingError,
      formId,
      loadingCarInfo,
      errorBlankRegNumber,
      errorWrongRegNumber,
      errorCantCompareCar,
      marks,
      models,
      loadingInfoFlags,
      dropDown,
    } = this.state;
    const { history } = this.props;
    const optionItemsMarks = Object.keys(marks).map((id) => ({ value: id, label: marks[id] })).sort((a, b) => a.label.localeCompare(b.label));
    const optionItemsModels = Object.keys(models).map((id) => ({ value: id, label: models[id] })).sort((a, b) => a.label.localeCompare(b.label));
    return (
      <div className="position-relative">
        {formId && (
        <>
          <h1 className="page_title d-inline-block mr-3">Договор купли-продажи</h1>
          <div className="page_header__control page_header__control-custom mb-2 mb-md-0">
            <div className="dropdown_absolute">
              <div className={`dropdown_block js-dropdown_block mr-1 ${dropDown}`}>
                <OutsideClickHandler
                  onOutsideClick={this.dropDownBlockClose}
                >
                  <div onClick={this.dropDownBlock} className="dropdown_block__target">Действие с черновиком</div>
                  <ul className="dropdown_block__list js-dropdown_block__list">
                    <li><a href="#" onClick={this.copyForm}>Скопировать</a></li>
                    <li><a href="#" onClick={this.convertDkpToPolicy}>Оформить ОСАГО</a></li>
                  </ul>
                </OutsideClickHandler>
              </div>
            </div>
          </div>
          {!dkpFormLoadingError && (
          <h2 className="page_title d-inline-block mr-3">Черновик №{formId}</h2>
          )}
          <div className="mb-2">
            <LinkBreadcrumbs onClick={(e) => {
              e.preventDefault();
              history.push('/dkp?activeTab=2');
            }}>К списку черновиков</LinkBreadcrumbs>
          </div>
        </>
        )}
        {dkpFormLoadingError && (
          <div className="row mb-3">
            <div className="col-lg-3">
              <div className="error_block">{dkpFormLoadingError}</div>
          </div>
        </div>
        )}
        <LoadingBanner loadingFlag={loadingInfoFlags.all}>
          <form onSubmit={this.handleSubmit}>
            <div className="card mb-4">
              <div className="card-header fill_section__title">
                <span className="title_num">1</span>
                <span>Транспортное средство</span>
              </div>
              <div className="card-body">
                {!formData.carRegistrationNumberNone && (
                <div className="form-group row align-items-center">
                  <div className="col-lg-3">
                    <label htmlFor="carRegistrationNumber">Гос. номер</label>
                    <InputMask
                      maskChar={null}
                      onChange={(e) => this.handleChangeInput(e, true, true)}
                      placeholder="А111АА111"
                      name="carRegistrationNumber"
                      className="form-control column-margin"
                      id="carRegistrationNumber"
                      mask="a999aa999"
                      value={formData.carRegistrationNumber}
                      formatChars={{
                        9: '[0-9]',
                        a: '[АВЕКМНОРСТУХавекмнорстухFDTRVYJHCNE{[fdtrvyjhcne]',
                      }}
                    />
                  </div>
                  <div className="col-lg-9">
                    <label className="hidden d-block">hide</label>
                    <button type="button" className="btn btn-success mb-2 mr-3 mb-md-0" onClick={this.checkVehicleInfo}>Найти данные ТС по гос.номеру</button>
                    {loadingCarInfo
                        && <ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
                    {errorBlankRegNumber
                        && <div className="d-inline-block error_block">Неправильно заполнен номер</div>}
                    {errorWrongRegNumber
                        && <div className="d-inline-block error_block">Данные не найдены</div>}
                    {errorCantCompareCar
                        && <div className="d-inline-block error_block">Введите марку и модель вручную</div>}
                  </div>
                </div>
                )}
                <div className="form-group">
                  <div className="form-check custom-checkbox">
                    <input onChange={this.handleChangeCheckbox} checked={formData.carRegistrationNumberNone} name="carRegistrationNumberNone" className="form-check-input checkbox-styled" type="checkbox" id="carRegistrationNumberNone" />
                    <label className="form-check-label" htmlFor="carRegistrationNumberNone">
                      Гос. номер отсутствует
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label>Марка автомобиля</label>
                    <Select
                      onBlur={this.handleValidateSelect}
                      classNamePrefix="react-select"
                      styles={customStyles}
                      name="carMark"
                      noOptionsMessage={() => 'Не найдено'}
                      placeholder="Выберите марку"
                      value={formData.carMark}
                      onChange={this.handleChangeMark}
                      className="form-control-custom"
                      options={optionItemsMarks}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label>Модель автомобиля</label>
                    <Select
                      onBlur={this.handleValidateSelect}
                      classNamePrefix="react-select"
                      styles={customStyles}
                      name="carModel"
                      noOptionsMessage={() => 'Не найдено'}
                      placeholder="Выберите модель"
                      value={formData.carModel}
                      className="form-control-custom"
                      onChange={this.handleChangeModel}
                      options={optionItemsModels}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="carColor">Цвет ТС</label>
                    <input value={formData.carColor} onChange={this.handleChangeInput} name="carColor" type="text" className="form-control" id="carColor" placeholder="Также как написано в ПТС" />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="carYear">Год выпуска</label>
                    <Select
                      menuPlacement="auto"
                      classNamePrefix="react-select"
                      inputId="carYear"
                      className="form-control-custom"
                      styles={customStyles}
                      name="carYear"
                      noOptionsMessage={() => 'Не найдено'}
                      placeholder="Выберите год"
                      value={formData.carYear}
                      onChange={this.handleChangeCarYear}
                      options={yearsOption}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="carCategory">Тип ТС</label>
                    <input value={formData.carCategory} onChange={this.handleChangeInput} name="carCategory" type="text" className="form-control" id="carCategory" placeholder="Также как написано в ПТС" />
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-3">
                    <label htmlFor="carVin">VIN</label>
                    <InputMask
                      className="form-control"
                      disabled={formData.carVinNone}
                      formatChars={{ a: '(?=[A-Za-z0-9])[^OIoi]' }}
                      onChange={(e) => this.handleChangeInput(e, false, true)}
                      value={formData.carVin}
                      maskChar=""
                      name="carVin"
                      id="carVin"
                      placeholder="ААА12345ААААААААА"
                      mask="aaaaaaaaaaaaaaaaa"
                    />
                  </div>
                  <div className="form-check custom-checkbox">
                    <label className="hidden d-block">hide</label>
                    <input onChange={this.handleChangeCheckbox} checked={formData.carVinNone} name="carVinNone" className="form-check-input checkbox-styled" type="checkbox" id="carVinNone" />
                    <label className="form-check-label" htmlFor="carVinNone">
                      VIN отсутствует
                    </label>
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-3">
                    <label htmlFor="carBodyNumber">Номер кузова</label>
                    <InputMask
                      className="form-control"
                      disabled={formData.carBodyNumberNone}
                      formatChars={{ a: '(?=[A-Za-z0-9])' }}
                      onChange={(e) => this.handleChangeInput(e, false, true)}
                      value={formData.carBodyNumber}
                      maskChar=""
                      name="carBodyNumber"
                      id="carBodyNumber"
                      placeholder="ААА12345ААААА"
                      mask="aaaaaaaaaaaaaaaaa"
                    />
                  </div>
                  <div className="form-check custom-checkbox">
                    <label className="hidden d-block">hide</label>
                    <input onChange={this.handleChangeCheckbox} checked={formData.carBodyNumberNone} name="carBodyNumberNone" className="form-check-input checkbox-styled" type="checkbox" id="carBodyNumberNone" />
                    <label className="form-check-label" htmlFor="carBodyNumberNone">
                      Номер кузова отсутствует
                    </label>
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-3">
                    <label htmlFor="carChassis">Номер шасси</label>
                    <InputMask
                      className="form-control"
                      disabled={formData.carChassisNone}
                      formatChars={{ a: '(?=[A-Za-z0-9])' }}
                      onChange={(e) => this.handleChangeInput(e, false, true)}
                      value={formData.carChassis}
                      maskChar=""
                      name="carChassis"
                      id="carChassis"
                      placeholder="ААА12345ААААААААА"
                      mask="aaaaaaaaaaaaaaaaa"
                    />
                  </div>
                  <div className="form-check custom-checkbox">
                    <label className="hidden d-block">hide</label>
                    <input onChange={this.handleChangeCheckbox} checked={formData.carChassisNone} name="carChassisNone" className="form-check-input checkbox-styled" type="checkbox" id="carChassisNone" />
                    <label className="form-check-label" htmlFor="carChassisNone">
                      Номер шасси отсутствует
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="carEngineModelAndNumber">Модель и номер двигателя</label>
                    <input value={formData.carEngineModelAndNumber} onChange={this.handleChangeInput} name="carEngineModelAndNumber" type="text" className="form-control" id="carEngineModelAndNumber" placeholder="Также как написано в ПТС" />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="carEnginePower">Мощность, л.с.</label>
                    <input value={formData.carEnginePower} onChange={this.handleChangeInput} name="carEnginePower" type="text" className="form-control" id="carEnginePower" placeholder="120" />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="carEngineCapacity">Объем двигателя, л</label>
                    <input value={formData.carEngineCapacity} onChange={this.handleChangeInput} name="carEngineCapacity" type="text" className="form-control" id="carEngineCapacity" placeholder="2" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="carPassportType">Тип ПТС</label>
                    <Select
                      menuPlacement="auto"
                      classNamePrefix="react-select"
                      inputId="carPassportType"
                      className="form-control-custom"
                      styles={customStyles}
                      name="carPassportType"
                      noOptionsMessage={() => 'Не найдено'}
                      placeholder="Выберите тип"
                      value={formData.carPassportType}
                      onChange={this.handleChangeSelect}
                      options={[
                        { value: 'passport', label: 'ПТС' },
                        { value: 'epassport', label: 'ЭПТС' },
                      ]}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {formData.carPassportType.value === 'passport'
                    ? (
                      <div className="col-lg-3">
                        <label htmlFor="carPassportSeriesNumber">Серия и номер ПТС</label>
                        <InputMask
                          maskChar={null}
                          formatChars={{ '*': '[A-Za-zА-Яа-я0-9[\\]\\;\\,\\.\']', 9: '[0-9]' }}
                          onChange={(e) => this.handleChangeInput(e, true, true)}
                          value={formData.carPassportSeriesNumber}
                          name="carPassportSeriesNumber"
                          className="form-control"
                          id="carPassportSeriesNumber"
                          mask="**** 999999"
                          placeholder="11АА 111111"
                        />
                      </div>
                    )
                    : (
                      <div className="col-lg-3">
                        <label htmlFor="carEPassportNumber">Номер ЭПТС</label>
                        <InputMask
                          maskChar={null}
                          onChange={this.handleChangeInput}
                          value={formData.carEPassportNumber}
                          name="carEPassportNumber"
                          className="form-control"
                          id="carEPassportNumber"
                          mask={getMask('numberEDocument').mask}
                          placeholder="123456789012345"
                        />
                      </div>
                    )}
                  <div className="col-lg-3">
                    <label htmlFor="carPassportDate">Дата выдачи {formData.carPassportType.value === 'passport' ? 'ПТС' : 'ЭПТС'}</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      className="form-control"
                      selected={formData.carPassportDate ? parse(formData.carPassportDate, 'dd.MM.yyyy', new Date()) : null}
                      onChange={(date) => this.handleChangeDate('carPassportDate', date)}
                      name="carPassportDate"
                      id="carPassportDate"
                      locale={ru}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="carPassportAuthor">Кем выдан {formData.carPassportType.value === 'passport' ? 'ПТС' : 'ЭПТС'}</label>
                    <input value={formData.carPassportAuthor} onChange={(e) => this.handleChangeInput(e, true)} name="carPassportAuthor" type="text" className="form-control" id="carPassportAuthor" placeholder="Также как написано в паспорте" />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="carSertificateSeriesNumber">Серия и номер СТС</label>
                    <InputMask
                      maskChar={null}
                      formatChars={{ '*': '[A-Za-zА-Яа-я0-9[\\]\\;\\,\\.\']', 9: '[0-9]' }}
                      onChange={(e) => this.handleChangeInput(e, true, true)}
                      value={formData.carSertificateSeriesNumber}
                      name="carSertificateSeriesNumber"
                      className="form-control"
                      id="carSertificateSeriesNumber"
                      mask="**** 999999"
                      placeholder="11АА 111111"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="carSertificateDate">Дата выдачи СТС</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      className="form-control"
                      selected={formData.carSertificateDate ? parse(formData.carSertificateDate, 'dd.MM.yyyy', new Date()) : null}
                      onChange={(date) => this.handleChangeDate('carSertificateDate', date)}
                      name="carSertificateDate"
                      id="carSertificateDate"
                      locale={ru}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="carSertificateAuthor">Кем выдан СТС</label>
                    <input value={formData.carSertificateAuthor} onChange={(e) => this.handleChangeInput(e, true)} name="carSertificateAuthor" type="text" className="form-control" id="carSertificateAuthor" placeholder="Также как написано в паспорте" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="carRun">Пробег, км</label>
                    <InputMask
                      maskChar={null}
                      className="form-control"
                      onChange={this.handleChangeInput}
                      value={formData.carRun}
                      name="carRun"
                      id="carRun"
                      mask="999999999"
                      placeholder="10000"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="carPrice">Стоимость ТС, ₽</label>
                    <InputMask
                      maskChar={null}
                      className="form-control"
                      onChange={this.handleChangeInput}
                      value={formData.carPrice}
                      name="carPrice"
                      id="carPrice"
                      mask="999999999"
                      placeholder="10000"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header fill_section__title">
                <span className="title_num">2</span>
                <span>Продавец ТС</span>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="sellerType">Тип</label>
                    <Select
                      menuPlacement="auto"
                      classNamePrefix="react-select"
                      inputId="sellerType"
                      className="form-control-custom"
                      styles={customStyles}
                      name="sellerType"
                      noOptionsMessage={() => 'Не найдено'}
                      placeholder="Выберите тип"
                      value={formData.sellerType}
                      onChange={this.handleChangeSelect}
                      options={[
                        { value: 'natural', label: 'Физ. лицо' },
                        { value: 'legal', label: 'Юр. лицо' },
                      ]}
                    />
                  </div>
                </div>
                {formData.sellerType.value === 'legal' && (
                <>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sellerLastName">Фамилия представителя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="sellerLastName"
                        name="sellerLastName"
                        placeholder="Иванов"
                        query={formData.sellerLastName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'sellerLastName')}
                        parts={['SURNAME']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerFirstName">Имя представителя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="sellerFirstName"
                        name="sellerFirstName"
                        placeholder="Иван"
                        query={formData.sellerFirstName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'sellerFirstName')}
                        parts={['NAME']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerMiddleName">Отчество представителя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="sellerMiddleName"
                        name="sellerMiddleName"
                        placeholder="Иванович"
                        query={formData.sellerMiddleName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'sellerMiddleName')}
                        parts={['PATRONYMIC']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerPost">Должность</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerPost}
                        name="sellerPost"
                        id="sellerPost"
                        placeholder="Директор"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="sellerOrganisationChart">На основании чего действует</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerOrganisationChart}
                        name="sellerOrganisationChart"
                        id="sellerOrganisationChart"
                        placeholder="Устав"
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="sellerOrganisationName">Наименование организации</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerOrganisationName}
                        name="sellerOrganisationName"
                        id="sellerOrganisationName"
                        placeholder="Полис.Онлайн"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-9">
                      <label htmlFor="sellerOrganisationAddress">Юр. адрес</label>
                      <ReactDadata
                        className="form-control"
                        id="sellerOrganisationAddress"
                        name="sellerOrganisationAddress"
                        query={formData.sellerOrganisationAddress.value}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeAddress(e, 'sellerOrganisationAddress')}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sellerOrganisationOgrn">ОГРН</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerOrganisationOgrn}
                        name="sellerOrganisationOgrn"
                        id="sellerOrganisationOgrn"
                        placeholder="123456789"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerOrganisationInn">ИНН</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerOrganisationInn}
                        name="sellerOrganisationInn"
                        id="sellerOrganisationInn"
                        placeholder="123456789"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerOrganisationPaymentAccount">Р/С №</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerOrganisationPaymentAccount}
                        name="sellerOrganisationPaymentAccount"
                        id="sellerOrganisationPaymentAccount"
                        placeholder="123456789"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerOrganisationBankName">Наименование банка</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerOrganisationBankName}
                        name="sellerOrganisationBankName"
                        id="sellerOrganisationBankName"
                        placeholder="123456789"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sellerOrganisationCorrespondentAccount">К/С №</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerOrganisationCorrespondentAccount}
                        name="sellerOrganisationCorrespondentAccount"
                        id="sellerOrganisationCorrespondentAccount"
                        placeholder="123456789"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerOrganisationBik">БИК</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.sellerOrganisationBik}
                        name="sellerOrganisationBik"
                        id="sellerOrganisationBik"
                        placeholder="123456789"
                      />
                    </div>
                  </div>
                </>
                )}
                {formData.sellerType.value === 'natural' && (
                <>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sellerLastName">Фамилия</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="sellerLastName"
                        name="sellerLastName"
                        placeholder="Иванов"
                        query={formData.sellerLastName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'sellerLastName')}
                        parts={['SURNAME']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerFirstName">Имя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="sellerFirstName"
                        name="sellerFirstName"
                        placeholder="Иван"
                        query={formData.sellerFirstName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'sellerFirstName')}
                        parts={['NAME']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerMiddleName">Отчество</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="sellerMiddleName"
                        name="sellerMiddleName"
                        placeholder="Иванович"
                        query={formData.sellerMiddleName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'sellerMiddleName')}
                        parts={['PATRONYMIC']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerBirthday">Дата рождения</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        className="form-control"
                        selected={formData.sellerBirthday ? parse(formData.sellerBirthday, 'dd.MM.yyyy', new Date()) : null}
                        onChange={(date) => this.handleChangeDate('sellerBirthday', date)}
                        name="sellerBirthday"
                        id="sellerBirthday"
                        locale={ru}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        placeholderText="ДД.ММ.ГГГГ"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                            }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sellerPassport">Серия и номер паспорта</label>
                      <InputMask
                        maskChar={null}
                        formatChars={{ '*': '[A-Za-zА-Яа-я0-9[\\]\\;\\,\\.\']', 9: '[0-9]' }}
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e, true)}
                        value={formData.sellerPassport}
                        name="sellerPassport"
                        id="sellerPassport"
                        mask="**** ******"
                        placeholder="1111 111111"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="sellerPassportDate">Дата выдачи паспорта</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        className="form-control"
                        selected={formData.sellerPassportDate ? parse(formData.sellerPassportDate, 'dd.MM.yyyy', new Date()) : null}
                        onChange={(date) => this.handleChangeDate('sellerPassportDate', date)}
                        name="sellerPassportDate"
                        id="sellerPassportDate"
                        locale={ru}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        placeholderText="ДД.ММ.ГГГГ"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                            }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="sellerPassportAuthor">Кем выдан</label>
                      <input value={formData.sellerPassportAuthor} onChange={(e) => this.handleChangeInput(e, true)} name="sellerPassportAuthor" type="text" className="form-control" id="sellerPassportAuthor" placeholder="Также как написано в паспорте" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="sellerAddress">Адрес регистрации</label>
                      {formData.sellerAddress !== ''
                          && <ReactDadata className="form-control" id="sellerAddress" name="sellerAddress" query={formData.sellerAddress.value} token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed" onChange={(e) => this.handleChangeAddress(e, 'sellerAddress')} placeholder="" />}
                      {(formData.sellerAddress === undefined || formData.sellerAddress === '')
                          && <ReactDadata className="form-control" id="sellerAddress" name="sellerAddress" query={formData.sellerAddress.value} token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed" onChange={(e) => this.handleChangeAddress(e, 'sellerAddress')} placeholder="" />}
                    </div>
                  </div>
                </>
                )}
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="phone">Телефон</label>
                    <InputMask
                      className="form-control"
                      onChange={this.handleChangeInput}
                      value={formData.sellerPhone}
                      name="sellerPhone"
                      id="sellerPhone"
                      mask="+7 (999) 999-99-99"
                      placeholder="+7 (999) 999-99-99"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="sellerEmail">Email</label>
                    <input
                      className="form-control"
                      value={formData.sellerEmail}
                      onChange={this.handleChangeInput}
                      name="sellerEmail"
                      type="text"
                      id="sellerEmail"
                      placeholder="your@email.ru"
                    />
                  </div>
                  <div className="col-lg-6">
                    <SimpleNotification>Вы получите электронную версию документа на указанный адрес электронной почты</SimpleNotification>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header fill_section__title">
                <span className="title_num">3</span>
                <span>Покупатель ТС</span>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="buyerType">Тип</label>
                    <Select
                      menuPlacement="auto"
                      classNamePrefix="react-select"
                      inputId="buyerType"
                      className="form-control-custom"
                      styles={customStyles}
                      name="buyerType"
                      noOptionsMessage={() => 'Не найдено'}
                      placeholder="Выберите тип"
                      value={formData.buyerType}
                      onChange={this.handleChangeSelect}
                      options={[
                        { value: 'natural', label: 'Физ. лицо' },
                        { value: 'legal', label: 'Юр. лицо' },
                      ]}
                    />
                  </div>
                </div>
                {formData.buyerType.value === 'legal' && (
                <>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="buyerLastName">Фамилия представителя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="buyerLastName"
                        name="buyerLastName"
                        placeholder="Иванов"
                        query={formData.buyerLastName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'buyerLastName')}
                        parts={['SURNAME']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerFirstName">Имя представителя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="buyerFirstName"
                        name="buyerFirstName"
                        placeholder="Иван"
                        query={formData.buyerFirstName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'buyerFirstName')}
                        parts={['NAME']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerMiddleName">Отчество представителя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="buyerMiddleName"
                        name="buyerMiddleName"
                        placeholder="Иванович"
                        query={formData.buyerMiddleName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'buyerMiddleName')}
                        parts={['PATRONYMIC']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerPost">Должность</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerPost}
                        name="buyerPost"
                        id="buyerPost"
                        placeholder="Директор"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="buyerOrganisationChart">На основании чего действует</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerOrganisationChart}
                        name="buyerOrganisationChart"
                        id="buyerOrganisationChart"
                        placeholder="Устав"
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="buyerOrganisationName">Наименование организации</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerOrganisationName}
                        name="buyerOrganisationName"
                        id="buyerOrganisationName"
                        placeholder="Полис.Онлайн"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-9">
                      <label htmlFor="buyerOrganisationAddress">Юр. адрес</label>
                      <ReactDadata
                        className="form-control"
                        id="buyerOrganisationAddress"
                        name="buyerOrganisationAddress"
                        query={formData.buyerOrganisationAddress.value}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeAddress(e, 'buyerOrganisationAddress')}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="buyerOrganisationOgrn">ОГРН</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerOrganisationOgrn}
                        name="buyerOrganisationOgrn"
                        id="buyerOrganisationOgrn"
                        placeholder="123456789"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerOrganisationInn">ИНН</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerOrganisationInn}
                        name="buyerOrganisationInn"
                        id="buyerOrganisationInn"
                        placeholder="123456789"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerOrganisationPaymentAccount">Р/С №</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerOrganisationPaymentAccount}
                        name="buyerOrganisationPaymentAccount"
                        id="buyerOrganisationPaymentAccount"
                        placeholder="123456789"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerOrganisationBankName">Наименование банка</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerOrganisationBankName}
                        name="buyerOrganisationBankName"
                        id="buyerOrganisationBankName"
                        placeholder="123456789"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="buyerOrganisationCorrespondentAccount">К/С №</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerOrganisationCorrespondentAccount}
                        name="buyerOrganisationCorrespondentAccount"
                        id="buyerOrganisationCorrespondentAccount"
                        placeholder="123456789"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerOrganisationBik">БИК</label>
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e)}
                        value={formData.buyerOrganisationBik}
                        name="buyerOrganisationBik"
                        id="buyerOrganisationBik"
                        placeholder="123456789"
                      />
                    </div>
                  </div>
                </>
                )}
                {formData.buyerType.value === 'natural' && (
                <>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="buyerLastName">Фамилия</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="buyerLastName"
                        name="buyerLastName"
                        placeholder="Иванов"
                        query={formData.buyerLastName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'buyerLastName')}
                        parts={['SURNAME']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerFirstName">Имя</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="buyerFirstName"
                        name="buyerFirstName"
                        placeholder="Иван"
                        query={formData.buyerFirstName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'buyerFirstName')}
                        parts={['NAME']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerMiddleName">Отчество</label>
                      <ReactDadata
                        suggestionType="fio"
                        id="buyerMiddleName"
                        name="buyerMiddleName"
                        placeholder="Иванович"
                        query={formData.buyerMiddleName}
                        token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                        onChange={(e) => this.handleChangeFio(e, 'buyerMiddleName')}
                        parts={['PATRONYMIC']}
                        firstCapital
                        translate
                        className="form-control"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerBirthday">Дата рождения</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        className="form-control"
                        selected={formData.buyerBirthday ? parse(formData.buyerBirthday, 'dd.MM.yyyy', new Date()) : null}
                        onChange={(date) => this.handleChangeDate('buyerBirthday', date)}
                        name="buyerBirthday"
                        id="buyerBirthday"
                        locale={ru}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        placeholderText="ДД.ММ.ГГГГ"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                            }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="buyerPassport">Серия и номер паспорта</label>
                      <InputMask
                        maskChar={null}
                        formatChars={{ '*': '[A-Za-zА-Яа-я0-9[\\]\\;\\,\\.\']', 9: '[0-9]' }}
                        className="form-control"
                        onChange={(e) => this.handleChangeInput(e, true)}
                        value={formData.buyerPassport}
                        name="buyerPassport"
                        id="buyerPassport"
                        mask="**** ******"
                        placeholder="1111 111111"
                      />
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="buyerPassportDate">Дата выдачи паспорта</label>
                      <DatePicker
                        popperModifiers={{
                          computeStyle: { gpuAcceleration: false },
                        }}
                        className="form-control"
                        selected={formData.buyerPassportDate ? parse(formData.buyerPassportDate, 'dd.MM.yyyy', new Date()) : null}
                        onChange={(date) => this.handleChangeDate('buyerPassportDate', date)}
                        name="buyerPassportDate"
                        id="buyerPassportDate"
                        locale={ru}
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        placeholderText="ДД.ММ.ГГГГ"
                        customInput={
                          <InputMask mask="99.99.9999" inputMode="tel" />
                            }
                      />
                      <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="buyerPassportAuthor">Кем выдан</label>
                      <input value={formData.buyerPassportAuthor} onChange={(e) => this.handleChangeInput(e, true)} name="buyerPassportAuthor" type="text" className="form-control" id="buyerPassportAuthor" placeholder="Также как написано в паспорте" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="buyerAddress">Адрес регистрации</label>
                      {formData.buyerAddress !== ''
                          && <ReactDadata className="form-control" id="buyerAddress" name="buyerAddress" query={formData.buyerAddress.value} token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed" onChange={(e) => this.handleChangeAddress(e, 'buyerAddress')} placeholder="" />}
                      {(formData.buyerAddress === undefined || formData.buyerAddress === '')
                          && <ReactDadata className="form-control" id="buyerAddress" name="buyerAddress" query={formData.buyerAddress.value} token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed" onChange={(e) => this.handleChangeAddress(e, 'buyerAddress')} placeholder="" />}
                    </div>
                  </div>
                </>
                )}
                <div className="row">
                  <div className="col-lg-3">
                    <label htmlFor="phone">Телефон</label>
                    <InputMask
                      className="form-control"
                      onChange={this.handleChangeInput}
                      value={formData.buyerPhone}
                      name="buyerPhone"
                      id="buyerPhone"
                      mask="+7 (999) 999-99-99"
                      placeholder="+7 (999) 999-99-99"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="buyerEmail">Email</label>
                    <input
                      className="form-control"
                      value={formData.buyerEmail}
                      onChange={this.handleChangeInput}
                      name="buyerEmail"
                      type="text"
                      id="buyerEmail"
                      placeholder="your@email.ru"
                    />
                  </div>
                  <div className="col-lg-6">
                    <SimpleNotification>Вы получите электронную версию документа на указанный адрес электронной почты</SimpleNotification>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-header fill_section__title">
                <span className="title_num">4</span>
                <span>Общие данные</span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="city">Место составления</label>
                    <ReactDadata
                      className="form-control"
                      id="city"
                      name="city"
                      bounds="city"
                      query={formData.city.value}
                      token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed"
                      onChange={(e) => this.handleChangeAddress(e, 'city')}
                      placeholder=""
                    />
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="createDate">Дата составления</label>
                    <DatePicker
                      popperModifiers={{
                        computeStyle: { gpuAcceleration: false },
                      }}
                      className="form-control"
                      selected={formData.createDate ? parse(formData.createDate, 'dd.MM.yyyy', new Date()) : null}
                      onChange={(date) => this.handleChangeDate('createDate', date)}
                      name="createDate"
                      id="createDate"
                      locale={ru}
                      showYearDropdown
                      showMonthDropdown
                      dateFormat="dd.MM.yyyy"
                      placeholderText="ДД.ММ.ГГГГ"
                      customInput={
                        <InputMask mask="99.99.9999" inputMode="tel" />
                        }
                    />
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <button type="submit" disabled={loading} className="btn btn-success mr-3">Скачать договор</button>
                <button type="button" disabled={loading} className="btn btn-success mr-3" onClick={() => (formId ? this.handleUpdateForm() : this.handleSaveForm())}>Сохранить</button>
                {loading && <ReactLoading className="loading-circle d-inline-block" type="spin" height={38} width={38} />}
              </div>
            </div>
          </form>
        </LoadingBanner>
      </div>
    );
  }
}

Dkp.contextType = ModalMessagesContext;

export default Dkp;
