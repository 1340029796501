import React from 'react';
import { ReactDadata } from 'react-dadata';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import Modal from 'react-responsive-modal';
import ReactLoading from 'react-loading';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { customFetch, customSelectStyle, downloadDescriptionOptions } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import getMask from '../Utils/Masks';
import SimpleNotification from '../Layout/FormNotifications/SimpleNotification';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class AgentsForm extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      fullTimeEmployeeAgents: [],
      uploading: false,
      openModal: false,
      addedAgent: '',
      agentCategory: { value: 'pro', label: 'Профессионал' },
      agentType: { value: 'natural', label: 'Физ. лицо' },
      commissionType: { value: 'hide', label: 'Скрывать' },
      agentSalesVolume: null,
      techAgents: [],
      descriptionOptions: [],
      newAgent: {
        first_name: '',
        last_name: '',
        middle_name: '',
        office_number: null,
        phone: '',
        email: '',
        city: '',
        comment: '',
        domain: window.location.host.split('.')[0],
        full_time_employee: false,
        tech_agent: false,
        tech_agent_id: '',
        company: '',
        link_to_crm: null,
        category: 'pro',
        agent_type_code: 'natural',
        sales_volume: '1',
        curator: '',
        description: '',
        payment_partner: false,
      },
      quota: 80,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { userInfo } = this.props;
    if (userInfo.id) {
      this.downloadInfo();
    }
  }

  componentDidUpdate(prevProps) {
    const { userInfo } = this.props;
    if (prevProps.userInfo !== userInfo) {
      this.downloadInfo();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { newAgent } = this.state;
    this.handleAddAgent(newAgent);
  };

  handleClick = () => {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  };

  handleInput = (key, e) => {
    const { newAgent } = this.state;
    const state = Object.assign({}, newAgent);
    if (key === 'city') {
      state[key] = e.value;
    } else if (key === 'full_time_employee' || key === 'tech_agent' || key === 'payment_partner') {
      state[key] = e.target.checked;
    } else {
      state[key] = e.target.value;
    }
    if (key === 'tech_agent' && e.target.checked) {
      state.agent_type_code = 'legal';
      this.setState({ agentType: { value: 'legal', label: 'Юр. лицо' } });
    }
    if (key === 'payment_partner' && e.target.checked) {
      this.setState({ quota: 80 });
    }
    this.setState({ newAgent: state });
  };

  handleAddAgent(agent) {
    this.setState({ uploading: true });
    const newAgent = agent;
    const { commissionType, quota } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    newAgent.theme = window.location.host.split('.')[0];
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agents`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newAgent),
    })
      .then((response) => response.json())
      .then((response) => {
        const { showModalInfo } = this.context;
        if (response === 'email_exists') {
          showModalInfo('Агент с указанным email уже существует');
          this.setState({ uploading: false });
        } else {
          this.setState({ addedAgent: response.id });
          customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/quota`, {
            method: 'post',
            headers: {
              Authorization: token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              quota,
              id: response.id,
              type: commissionType.value,
            }),
          })
            .then((commission) => commission.json())
            .then((commission) => {
              if (commission.error) {
                showModalInfo(commission.error);
              } else {
                this.setState({ openModal: true });
              }
            })
            .catch(() => {
              showModalInfo('Ошибка');
            })
            .finally(() => {
              this.setState({ uploading: false });
            });
        }
      })
      .catch(() => {
        this.setState({ uploading: false });
      });
  }

  changeAgentType = (e) => {
    const { newAgent } = this.state;
    newAgent.agent_type_code = e.value;
    if (e.value !== 'legal') {
      newAgent.tech_agent = false;
      newAgent.company = null;
    }
    this.setState({
      newAgent,
      agentType: e,
    });
  };

  changeAgentSalesVolume = (e) => {
    const { newAgent } = this.state;
    newAgent.sales_volume = e.value;
    this.setState({
      newAgent,
      agentSalesVolume: e,
    });
  };

  handleChangeCommissionType = (e) => {
    this.setState({ commissionType: e });
  };

  handleChangeSelect = (event, actionMeta) => {
    const { value } = event;
    const { newAgent } = this.state;
    this.setState({
      newAgent: {
        ...newAgent,
        [actionMeta.name]: value,
      },
    });
  };

  handleChangeDescription = (value, actionMeta) => {
    const { newAgent } = this.state;
    let descriptionList = '';
    if (value) {
      descriptionList = value.map((description) => description.value).join(',');
    }
    this.setState({
      newAgent: {
        ...newAgent,
        [actionMeta.name]: descriptionList,
      },
    });
  }

  downloadInfo = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { userInfo } = this.props;
    if (userInfo.hide_commission === 0) {
      this.setState({ commissionType: { value: 'quota', label: 'Квота от входящей' } });
    }
    if (userInfo.allow_checkbox_payment_partner) {
      this.setState((prevState) => ({
        newAgent: {
          ...prevState.newAgent,
          payment_partner: true,
        },
      }));
    }
    if (userInfo.role === 'admin' || userInfo.role === 'superadmin') {
      this.setState((prevState) => ({
        newAgent: {
          ...prevState.newAgent,
          curator: userInfo.id,
        },
      }));
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agents/full_time_employee`, {
        method: 'get',
        headers: {
          Authorization: lsToken,
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            const agents = response.map((agent) => ({ value: agent.id, label: `${agent.last_name} ${agent.first_name} ${agent.middle_name}` }));
            this._isMounted && this.setState({ fullTimeEmployeeAgents: agents });
          }
        });
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/techAgents`, {
        method: 'get',
        headers: {
          Authorization: lsToken,
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((techAgents) => {
          this._isMounted && this.setState({
            techAgents: techAgents.map((agent) => ({ value: agent.id, label: `${agent.last_name} ${agent.first_name} ${agent.middle_name}` })).concat([{ value: null, label: 'Без тех. агента (через Polis.Online)' }]),
          });
        });
    }
  };

  changeAgentCategory = (e) => {
    const { newAgent } = this.state;
    const { userInfo } = this.props;
    if (userInfo.role === 'admin' || userInfo.role === 'superadmin') {
      let quota;
      switch (e.value) {
        case 'vip':
          quota = 85;
          break;
        case 'pro':
          quota = 80;
          break;
        case 'standard':
          quota = 75;
          break;
        case 'novice':
          quota = 70;
          break;
        default:
          quota = 70;
          break;
      }
      this.setState({ quota });
    }
    newAgent.category = e.value;
    this.setState({
      newAgent,
      agentCategory: e,
    });
  };

  dowloadOptions = () => {
    downloadDescriptionOptions().then((descriptionOptions) => {
      this.setState({ descriptionOptions });
    });
  };

  handleChangeQuota = (e) => {
    this.setState({ quota: e.target.value });
  };

  onCloseModal = () => {
    const { addedAgent } = this.state;
    const { history } = this.props;
    this.setState({ openModal: false });
    history.replace(`/agent/${addedAgent}`, { agentId: addedAgent });
  };

  render() {
    const {
      openModal,
      agentCategory,
      agentType,
      agentSalesVolume,
      newAgent,
      techAgents,
      uploading,
      commissionType,
      fullTimeEmployeeAgents,
      descriptionOptions,
      quota,
    } = this.state;
    const { userInfo } = this.props;

    const fullTimeEmployee = newAgent.full_time_employee;
    const techAgent = newAgent.tech_agent;
    return (
      <div>
        <div>
          <form onSubmit={this.handleSubmit} className="mb-4">
            <div className="card mb-4">
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <InputMask formatChars={{ a: '[А-Яа-яёЁ\\-]' }} maskChar="" className="form-control" id="lastName" onChange={(e) => this.handleInput('last_name', e)} required placeholder="Фамилия" mask="aaaaaaaaaaaaaaaaaaaaaa" />
                  </div>
                  <div className="form-group col-md-4">
                    <InputMask formatChars={{ a: '[А-Яа-яёЁ\\-]' }} maskChar="" className="form-control" id="firstName" onChange={(e) => this.handleInput('first_name', e)} required placeholder="Имя" mask="aaaaaaaaaaaaaaaaaaaaaa" />
                  </div>
                  <div className="form-group col-md-4">
                    <InputMask formatChars={{ a: '[А-Яа-яёЁ\\-]' }} maskChar="" className="form-control" id="middleName" onChange={(e) => this.handleInput('middle_name', e)} required placeholder="Отчество" mask="aaaaaaaaaaaaaaaaaaaaaa" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <InputMask onChange={(e) => this.handleInput('phone', e)} required className="form-control" id="phone" mask="+7 (999) 999-99-99" placeholder="+7 (999) 999-99-99" />
                  </div>
                  <div className="form-group col-md-4">
                    <input type="email" className="form-control" id="email" placeholder="E-mail" onChange={(e) => this.handleInput('email', e)} required />
                  </div>
                  <div className="form-group col-md-4">
                    <ReactDadata required className="form-control" bounds="city" token="2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed" onChange={(e) => this.handleInput('city', e)} placeholder="Регион" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-8">
                    <input type="text" className="form-control" id="comment" onChange={(e) => this.handleInput('comment', e)} placeholder="Комментарий" />
                  </div>
                  {(userInfo.domain === 'samoletplus' || userInfo.full_time_employee) ? (
                    <div className="form-group col-md-4">
                      <InputMask maskChar="" className="form-control" id="officeNumber" onChange={(e) => this.handleInput('officeNumber', e)} formatChars={getMask('officeNumber').formatChars} mask={getMask('officeNumber').mask} placeholder="Номер офиса" />
                    </div>
                  ) : null}
                </div>
                {userInfo.allow_checkbox_payment_partner ? (
                  <div className="form-group">
                    <div className="form-check custom-checkbox">
                      <input checked={newAgent.payment_partner} onChange={(e) => this.handleInput('payment_partner', e)} name="payment_partner" className="form-check-input checkbox-styled" type="checkbox" id="payment_partner" />
                      <label className="form-check-label" htmlFor="payment_partner">
                        Получает комиссионное вознаграждение от Polis.online
                      </label>
                    </div>
                  </div>
                ) : null}
                {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
                  <>
                    <div className="form-row">
                      <div className="form-group col-lg-12">
                        <span>Для администратора:</span>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <input type="text" className="form-control" id="linkToCrm" onChange={(e) => this.handleInput('link_to_crm', e)} placeholder="Ссылка на лид в CRM" />
                      </div>
                      <div className="form-group col-lg-4">
                        <Select
                          classNamePrefix="react-select"
                          styles={customSelectStyle()}
                          name="agentCategory"
                          isSearchable={false}
                          placeholder="Выберите категорию"
                          id="agentCategory"
                          value={agentCategory}
                          onChange={this.changeAgentCategory}
                          options={[
                            { value: 'vip', label: 'Вип' },
                            { value: 'pro', label: 'Профессионал' },
                            { value: 'standard', label: 'Стандарт' },
                            { value: 'novice', label: 'Новичок' },
                          ]}
                        />
                      </div>
                      <div className="form-group col-lg-4">
                        <Select
                          classNamePrefix="react-select"
                          styles={customSelectStyle()}
                          name="agentType"
                          isSearchable={false}
                          placeholder="Выберите тип"
                          id="agentType"
                          value={agentType}
                          onChange={this.changeAgentType}
                          options={[
                            { value: 'natural', label: 'Физ. лицо' },
                            { value: 'self_employed', label: 'Физ. лицо (самозанятый)' },
                            { value: 'individual', label: 'Индивидуальный предприниматель' },
                            { value: 'legal', label: 'Юр. лицо' },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-4">
                        <Select
                          classNamePrefix="react-select"
                          styles={customSelectStyle()}
                          name="agentSalesVolume"
                          isSearchable={false}
                          placeholder="Выберите объем продаж"
                          id="agentSalesVolume"
                          value={agentSalesVolume}
                          onChange={this.changeAgentSalesVolume}
                          options={[
                            { value: '1', label: 'до 10' },
                            { value: '2', label: 'до 50' },
                            { value: '3', label: 'до 100' },
                            { value: '4', label: '100+' },
                          ]}
                        />
                      </div>
                      <div className="form-group col-lg-4">
                        <Select
                          classNamePrefix="react-select"
                          styles={customSelectStyle()}
                          name="curator"
                          placeholder="Выберите куратора"
                          id="curator"
                          value={fullTimeEmployeeAgents.filter(({ value }) => value === newAgent.curator)}
                          onChange={this.handleChangeSelect}
                          options={fullTimeEmployeeAgents}
                        />
                      </div>
                      <div className="form-group col-lg-4">
                        <Select
                          classNamePrefix="react-select"
                          styles={customSelectStyle()}
                          name="tech_agent_id"
                          isSearchable={false}
                          placeholder="Выберите тех.агента"
                          id="techAgentId"
                          value={techAgents.filter(({ value }) => value === newAgent.tech_agent_id)}
                          onChange={this.handleChangeSelect}
                          options={techAgents}
                        />
                      </div>
                      {(agentType.value === 'legal') && (
                        <div className="form-group col-lg-4">
                          <input required type="text" className="form-control" id="comment" onChange={(e) => this.handleInput('company', e)} placeholder="Юр. лицо" />
                        </div>
                      )}
                    </div>
                    <div className="form-row">
                      <div className="form-group col-lg-4">
                        <AsyncCreatableSelect
                          classNamePrefix="react-select"
                          name="description"
                          placeholder="Описание"
                          isMulti
                          onChange={this.handleChangeDescription}
                          styles={customSelectStyle()}
                          formatCreateLabel={(userInnput) => `Добавить ${userInnput}`}
                          options={descriptionOptions}
                          onFocus={descriptionOptions.length === 0 ? this.dowloadOptions : () => {}}
                        />
                      </div>
                    </div>
                    {(userInfo.role === 'superadmin') && (
                      <>
                        <div className="form-group">
                          <div className="form-check custom-checkbox">
                            <input onChange={(e) => this.handleInput('full_time_employee', e)} checked={fullTimeEmployee} name="fullTimeEmployee" className="form-check-input checkbox-styled" type="checkbox" id="fullTimeEmployee" />
                            <label className="form-check-label" htmlFor="fullTimeEmployee">
                              Штатный сотрудник
                            </label>
                          </div>
                          <div className="form-check custom-checkbox">
                            <input onChange={(e) => this.handleInput('tech_agent', e)} checked={techAgent} name="techAgent" className="form-check-input checkbox-styled" type="checkbox" id="techAgent" />
                            <label className="form-check-label" htmlFor="techAgent">
                              Тех. агент
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {userInfo.hide_commission === 0 && (
                  <>
                    <div className="form-group row">
                      <div className="col-md-3">
                        <label htmlFor="commissionType">Тип начисления вознаграждения</label>
                        <Select
                          classNamePrefix="react-select"
                          styles={customSelectStyle()}
                          name="commissionType"
                          isSearchable={false}
                          placeholder="Выберите тип"
                          id="commissionType"
                          value={commissionType}
                          onChange={this.handleChangeCommissionType}
                          options={[
                            { value: 'quota', label: 'Квота от входящей' },
                            { value: 'hide', label: 'Скрывать' },
                          ]}
                        />
                      </div>
                    </div>
                    {(commissionType.value !== 'hide' && !newAgent.payment_partner) ? (
                      <>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <SimpleNotification>После добавления агента, вы можете детально настроить размер КВ. Условия вознаграждения могут быть изменены, точный размер вознаграждения определяется в момент расчета стоимости полиса и указывается в выдаче результатов.</SimpleNotification>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-3">
                            <label htmlFor="commissionType">Размер квоты</label>
                            <InputMask
                              value={quota}
                              maskChar=""
                              onChange={this.handleChangeQuota}
                              className="form-control"
                              formatChars={{ 9: '[0-9 .]' }}
                              mask="999999"
                              placeholder="0%"
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <button disabled={uploading} type="submit" className="btn btn-success mr-2">Сохранить</button>
            <button disabled={uploading} type="button" className="btn btn-secondary" onClick={this.handleClick}>Отмена</button>
            {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
          </form>
          <Modal classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner only-header' }} open={openModal} onClose={this.onCloseModal} center>
            <h4>Агент успешно добавлен</h4>
          </Modal>
        </div>
      </div>
    );
  }
}

AgentsForm.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(AgentsForm);
