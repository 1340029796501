import React, { Component } from 'react';
import ReactTable from 'react-table-6';
import Modal from 'react-responsive-modal';
import Slider from 'rc-slider';
import Select from 'react-select';
import Tooltip from 'rc-tooltip';
import ReactLoading from 'react-loading';
import { format, parse, parseISO } from 'date-fns';
import queryString from 'query-string';
import matchSorter from 'match-sorter';
import {
  customSelectStyle, customFetch, getProducts,
} from '../Utils/Helpers';
import CommissionInput from './CommissionInput';
import Regions from './Regions';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';

let abortController = new AbortController();

const regionName = {
  'Москва': 'moscow',
  'Санкт-Петербург': 'spb',
  'Московская обл.': 'moscowObl',
  'Ленинградская обл.': 'spbObl',
  'Россия': 'other',
};

const regionNameMortgage = {
  'Россия': 'other',
};

const categoryNames = {
  novice: 'Новичок',
  standard: 'Стандарт',
  pro: 'Профи',
  vip: 'Вип',
};

const regionList = {
  moscow: 'Москва',
  moscowObl: 'Московская обл.',
  other: 'Россия',
  spb: 'Санкт-Петербург',
  spbObl: 'Ленинградская обл.',
};

class OuterCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      columns: [],
      companyName: {},
      endingCustomCommissions: false,
      filtered: [{
        id: 'status',
        value: 'true',
      }],
      uploading: false,
      openModalSure: false,
      isToggleCommissionFormOn: false,
      loading: false,
      loadingInnerCommission: false,
      commissions: [],
      innerCommissions: [],
      categories: {},
      jobs: [],
      product: { value: 'osago', label: 'ОСАГО' },
      loadingCommissions: false,
      loadingCompanies: false,
    };
  }

  componentDidMount() {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { filtered, product } = this.state;
    const { location, tab } = this.props;
    const urlParams = queryString.parse(location.search);
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/outer/jobs`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((jobs) => {
        this.setState({ jobs });
      });
    getProducts(true).then((response) => {
      this.setState({
        products: response.map((product) => ({ value: product.code, label: product.name })),
      });
    });
    this.updateTableColumns();
    this.downloadOuterCommission(product.value);
    this.downloadCompanies(product.value);
    this.downloadTableCommissions(product.value);
    this.handleChangeIntegration({ value: 'highPriority' });
  }

  downloadOuterCommission = (product) => {
    this.setState({ loadingCommissions: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/outer?product=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ categories: response, loadingCommissions: false });
      });
  };

  downloadCompanies = (product) => {
    this.setState({ loadingCompanies: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/companies?type=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((companies) => {
        const companyName = {};
        const companyList = {};
        Object.keys(companies).forEach((company) => {
          companyName[companies[company].name] = company;
          companyList[company] = {
            name: companies[company].name,
            banks: companies[company].banks,
          };
        });
        this.setState({ companyName, companyList, loadingCompanies: false });
      });
  };

  toggleCommission = () => {
    this.setState((prevState) => ({
      isToggleCommissionFormOn: !prevState.isToggleCommissionFormOn,
    }));
  };

  handleInputCommission = (category, company, key, e) => {
    const { categories } = this.state;
    if (key === 'custom') {
      categories[category][company][key][0].value = e.target.value;
    } else {
      categories[category][company][key] = e.target.value;
    }
    this.setState({ categories });
  };

  handleSubmitSure = (e, company, region, groupId, bank) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { categories, product } = this.state;
    this.setState({ uploading: true });
    const { showModalInfo } = this.context;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/outer`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        commissions: categories,
        company,
        region,
        groupId,
        product: product.value,
        bank,
      }),
    })
      .then((response) => response.json())
      .then((commissions) => {
        if (commissions.error) {
          showModalInfo(commissions.error, 'error');
          this.setState({
            openModalSure: false,
            uploading: false,
          });
        } else {
          this.setState((prevState) => ({
            isToggleCommissionFormOn: !prevState.isToggleCommissionFormOn,
            openModalSure: false,
            uploading: false,
          }));
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  downloadTableCommissions = (product) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ loading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/outer/actual?product=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((commissions) => {
        this.setState((prevState) => ({
          loading: !prevState.loading,
          commissions: Object.values(commissions),
        }));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  updateTableColumns = () => {
    const { product } = this.state;
    const columns = [{
      Header: 'Продукт',
      id: 'product',
      accessor: (d) => d.product.name,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['product'] }),
      filterAll: true,
    }, {
      Header: 'Страховая',
      accessor: 'company',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
      filterAll: true,
    }, {
      Header: 'Регион',
      accessor: 'region',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
      filterAll: true,
      show: product.value !== 'mortgage',
    }, {
      Header: 'Банк',
      id: 'bank',
      accessor: (d) => (d.bank ? d.bank.name : null),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['bank'] }),
      filterAll: true,
      show: product.value === 'mortgage',
    }, {
      Header: 'Категория',
      id: 'category',
      accessor: (d) => d.agent_category.name,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['category'] }),
      filterAll: true,
    }, {
      Header: 'Дата от',
      id: 'date_from',
      accessor: (d) => format(parseISO(d.date_from), 'dd.MM.yyyy'),
      sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['date_from'] }),
      filterAll: true,
    }, {
      id: 'price',
      Header: 'Размер вознаграждения, %',
      accessor: (d) => d.value,
    },
    ];
    this.setState({ columns });
  };

  handleChangeProduct = (e) => {
    abortController.abort();
    abortController = new AbortController();
    this.setState({
      product: e, categories: {}, companyName: {}, companyList: {}, commissions: [], innerCommissions: [],
    }, () => {
      this.updateTableColumns();
      this.handleChangeIntegration({ value: 'highPriority' });
      this.downloadCompanies(e.value);
      this.downloadOuterCommission(e.value);
      this.downloadTableCommissions(e.value);
    });
  };

  handleSubmit = (e, company, region, group = false, bank = false) => {
    e.preventDefault();
    this.setState({
      openModalSure: true,
      selectedCompany: company,
      selectedRegion: region,
      selectedGroup: group,
      selectedBank: bank,
    });
  };

  handleChangeSlider = (e, category) => {
    const {
      categories, innerCommissions, companyList, product,
    } = this.state;
    if (product.value === 'mortgage') {
      Object.keys(categories[category]).forEach((company) => {
        Object.keys(categories[category][company]).forEach((bank) => {
          const parentCommission = innerCommissions[innerCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.bank === bank && commission.product.code === product.value)];
          if (parentCommission) {
            categories[category][company][bank] = 100 + e;
            if (categories[category][company][bank] < 0) categories[category][company][bank] = 0;
          }
        });
      });
    } else {
      Object.keys(categories[category]).forEach((company) => {
        Object.keys(categories[category][company]).forEach((region) => {
          if (region !== 'custom') {
            const parentCommission = innerCommissions[innerCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.region === regionList[region] && commission.product.code === product.value)];
            if (parentCommission) {
              categories[category][company][region] = 100 + e;
              if (categories[category][company][region] < 0) categories[category][company][region] = 0;
            }
          } else {
            Object.keys(categories[category][company][region]).forEach((customRegion) => {
              const parentCommission = innerCommissions[innerCommissions.findIndex((commission) => commission.company === companyList[company].name && commission.product.code === product.value && commission.group_id === categories[category][company][region][customRegion].groupId)];
              if (categories[category][company][region][customRegion].groupId !== '' && parentCommission) {
                categories[category][company][region][customRegion].value = 100 + e;
                if (categories[category][company][region][customRegion].value < 0) categories[category][company][region][customRegion].value = 0;
              }
            });
          }
        });
      });
    }

    this.setState({ categories });
  };

  onCloseModalSure = () => {
    this.setState({ openModalSure: false });
  };

  handleInputCustomCommission = (integration, company, key, e) => {
    const { categories } = this.state;
    categories[integration][company].custom.forEach((element) => {
      if (element.regions === key) {
        element.value = e.target.value;
      }
    });
    this.setState({ categories });
  };

  handleEndCustomCommission = (e, company, regions) => {
    const { showModalInfo } = this.context;
    const { product } = this.state;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ endingCustomCommissions: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/outer/end`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company,
        regions,
        product: product.value,
      }),
    })
      .then((response) => response.json())
      .then((commissions) => {
        this.setState((prevState) => ({
          commissions: Object.values(commissions),
          endingCustomCommissions: false,
          isToggleCommissionFormOn: !prevState.isToggleCommissionFormOn,
        }));
      })
      .catch(() => {
        showModalInfo('Возникла ошибка');
        this.setState({ endingCustomCommissions: false });
      });
  };

  customRegionBlock = (company) => {
    const {
      commissions, categories, endingCustomCommissions, companyName, innerCommissions, product, loadingInnerCommission,
    } = this.state;
    const customCommissions = commissions.filter((commission) => !regionName.hasOwnProperty(commission.region)
      && commission.company === company && commission.product.code === product.value);
    Array.prototype.groupBy = function (prop) {
      return this.reduce((groups, item) => {
        const val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    };
    const groupedByGroup = customCommissions.groupBy('group_id');
    return Object.keys(groupedByGroup).map((group, index) => {
      const regionsArray = Object.keys(groupedByGroup[group].groupBy('region')).map((value) => (value));
      const regions = regionsArray.join(', ');
      const allCustomCommissions = {};
      Object.keys(categoryNames).forEach((category) => {
        allCustomCommissions[category] = categories[category][companyName[company]].custom.find((element) => {
          if (element.groupId === group) {
            return element;
          }
          return false;
        });
      });

      return (
        <div key={`${companyName[company]}custom${group}`} className="row">
          <Regions
            regions={regions}
            index={index}
          />
          {Object.keys(categoryNames).map((category) => (
            <div key={`${companyName[company]}${category}custom${group}`} className="col-md-1 form-group">
              {loadingInnerCommission
                ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />
                : (
                  <CommissionInput
                    handleInputCommission={this.handleInputCustomCommission}
                    integration={category}
                    value={allCustomCommissions[category] ? allCustomCommissions[category].value : ''}
                    region={allCustomCommissions[category] ? allCustomCommissions[category].regions : ''}
                    company={companyName[company]}
                  />
                )}
            </div>
          ))}
          <div className="col-md-1 form-group">
            {loadingInnerCommission
              ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />
              : <input value={innerCommissions.length && innerCommissions[innerCommissions.findIndex((commission) => commission.company === company && commission.group_id === group && commission.product.code === product.value)] ? innerCommissions[innerCommissions.findIndex((commission) => commission.company === company && commission.group_id === group && commission.product.code === product.value)].price : '0'} disabled className="form-control" />}
          </div>
          <div className="col-md-2 form-group">
            <button type="button" className="btn btn-success" onClick={(e) => this.handleSubmit(e, companyName[company], 'custom', group)}>Применить</button>
          </div>
        </div>
      );
    });
  };

  handleSelectRegion = (e, company) => {
    const { categories } = this.state;
    if (e === null) {
      categories.novice[company].custom[0].regions = [];
      categories.standard[company].custom[0].regions = [];
      categories.pro[company].custom[0].regions = [];
      categories.vip[company].custom[0].regions = [];
    } else {
      const groupId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      categories.novice[company].custom[0].groupId = groupId;
      categories.standard[company].custom[0].groupId = groupId;
      categories.pro[company].custom[0].groupId = groupId;
      categories.vip[company].custom[0].groupId = groupId;
      categories.novice[company].custom[0].regions = e.map((region) => region.value);
      categories.standard[company].custom[0].regions = e.map((region) => region.value);
      categories.pro[company].custom[0].regions = e.map((region) => region.value);
      categories.vip[company].custom[0].regions = e.map((region) => region.value);
    }
    this.setState({ categories });
  };

  handleChangeIntegration = (e) => {
    const { value } = e;
    const { product } = this.state;
    this.setState({ loadingInnerCommission: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    let path = `inner/actual?integration=${value}`;
    if (value === 'highPriority') {
      path = `inner/highPriority?product=${product.value}`;
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/${path}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((innerCommissions) => {
        this.setState({ innerCommissions: Object.values(innerCommissions) });
      })
      .finally(() => {
        this.setState({ loadingInnerCommission: false });
      });
  };

  onFilteredChangeCustom = (filtered) => {
    const { tab } = this.props;
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  banksBlock = (company) => {
    const {
      categories,
      companyName,
      innerCommissions,
      product,
      loadingInnerCommission,
      companyList,
    } = this.state;

    return (
      <>
        {Object.keys(categories.novice[companyName[company]]).map((bank) => (
          <div key={`${companyName[company]}${bank}`} className="row">
            <label className="col-md-2 col-form-label">{companyList[companyName[company]].banks[bank]}</label>
            {Object.keys(categoryNames).map((category) => (
              <div key={`${companyName[company]}${bank}${category}`} className="col-md-1 form-group">
                <CommissionInput
                  handleInputCommission={this.handleInputCommission}
                  integration={category}
                  value={categories[category][companyName[company]][bank]}
                  region={bank}
                  company={companyName[company]}
                />
              </div>
            ))}
            <div className="col-md-1 form-group">
              {loadingInnerCommission
                ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />
                : <input value={innerCommissions.length && innerCommissions[innerCommissions.findIndex((commission) => commission.company === company && commission.bank === bank && commission.product.code === product.value)] ? innerCommissions[innerCommissions.findIndex((commission) => commission.company === company && commission.bank === bank && commission.product.code === product.value)].price : '0'} disabled className="form-control" />}
            </div>
            <div className="col-md-2 form-group">
              <button type="button" className="btn btn-success" onClick={(e) => this.handleSubmit(e, companyName[company], false, false, bank)}>Применить</button>
            </div>
          </div>
        ))}
      </>
    );
  };

  regionsBlock = (company) => {
    const {
      categories,
      companyName,
      innerCommissions,
      product,
      loadingInnerCommission,
    } = this.state;

    let filteredRegions = regionName;
    if (product.value !== 'osago') {
      filteredRegions = regionNameMortgage;
    }

    return (
      <>
        {Object.keys(filteredRegions).map((region) => (
          <div key={`${companyName[company]}${filteredRegions[region]}`} className="row">
            <label className="col-md-2 col-form-label">{region}</label>
            {Object.keys(categoryNames).map((category) => (
              <div key={`${companyName[company]}${filteredRegions[region]}${category}`} className="col-md-1 form-group">
                {loadingInnerCommission
                  ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />
                  : (
                    <CommissionInput
                      handleInputCommission={this.handleInputCommission}
                      integration={category}
                      value={categories[category][companyName[company]][filteredRegions[region]]}
                      region={filteredRegions[region]}
                      company={companyName[company]}
                    />
                  )}
              </div>
            ))}
            <div className="col-md-1 form-group">
              {loadingInnerCommission
                ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />
                : <input value={innerCommissions.length && innerCommissions[innerCommissions.findIndex((commission) => commission.company === company && commission.region === region && commission.product.code === product.value)] ? innerCommissions[innerCommissions.findIndex((commission) => commission.company === company && commission.region === region && commission.product.code === product.value)].price : '0'} disabled className="form-control" />}
            </div>
            <div className="col-md-2 form-group">
              <button type="button" className="btn btn-success" onClick={(e) => this.handleSubmit(e, companyName[company], filteredRegions[region], false)}>Применить</button>
            </div>
          </div>
        ))}
      </>
    );
  };

  render() {
    const {
      uploading,
      isToggleCommissionFormOn,
      loading,
      commissions,
      categories,
      openModalSure,
      selectedCompany,
      selectedRegion,
      selectedGroup,
      selectedBank,
      companyName,
      jobs,
      product,
      loadingCompanies,
      loadingCommissions,
      columns,
      products,
    } = this.state;
    const commissionsForm = Object.keys(companyName).map((company) => {
      let productHasCompany = false;
      Object.entries(categories).some(([categoryName, category]) => {
        if (category.hasOwnProperty(companyName[company])) {
          productHasCompany = categoryName;
          return true;
        }
        return false;
      });
      if (productHasCompany) {
        return (
          <div className="card mb-4">
            <div className="card-body">
              <div key={companyName[company]} className="form-group row">
                <div className="col">
                  <div className="form-group row">
                    <div className="col-md-2"><p className="h5">{company}</p></div>
                    {Object.keys(categoryNames).map((category) => (
                      <div key={`${companyName[company]}${category}`} className="col-md-1"><p className="h5">{categoryNames[category]}, %.</p></div>
                    ))}
                    <div className="col-md-1"><p className="h5">Входящее, %.</p></div>
                    <div className="col-md-2">
                      <button type="button" className="btn btn-secondary" onClick={(e) => this.handleSubmit(e, companyName[company], 'all', false, 'all')}>Применить</button>
                    </div>
                  </div>
                  {product.value !== 'mortgage' ? (
                    <>
                      {this.regionsBlock(company)}
                      {product.value === 'osago' ? this.customRegionBlock(company) : null}
                    </>
                  ) : this.banksBlock(company)}
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
    return (
      <>
        {jobs && jobs.map((job) => (
          <div className="row">
            <div className="col-md-6">
              <div key={job.id} className="error_block mb-2">Запущен процесс обновления вознаграждения! Страховая - {job.company}, регион - {job.region}, продукт - {job.product}.</div>
            </div>
          </div>
        ))}
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-2">
                <Select
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  name="product"
                  isSearchable={false}
                  placeholder="Выберите продукт"
                  id="product"
                  value={product}
                  onChange={this.handleChangeProduct}
                  options={products}
                />
              </div>
            </div>
          </div>
        </div>
        {isToggleCommissionFormOn
          ? (
            <LoadingBanner loadingFlag={loadingCompanies || loadingCommissions}>
              <form onSubmit={(e) => this.handleSubmit(e, 'all', 'all', 'all', 'all')} className="mb-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col">
                        <div className="form-group row">
                          <div className="col-md-1 offset-md-2 my-auto">
                            <Slider
                              onAfterChange={(e) => this.handleChangeSlider(e, 'novice')}
                              min={-45}
                              max={0}
                              step={0.5}
                              handleRender={(node, handleProps) => (
                                <Tooltip
                                  prefixCls="rc-slider-tooltip"
                                  overlay={`${handleProps.value}%`}
                                  placement="top"
                                  visible={handleProps.dragging}
                                >
                                  {node}
                                </Tooltip>
                              )}
                              defaultValue={0}
                            />
                          </div>
                          <div className="col-md-1 my-auto">
                            <Slider
                              onAfterChange={(e) => this.handleChangeSlider(e, 'standard')}
                              min={-45}
                              max={0}
                              step={0.5}
                              handleRender={(node, handleProps) => (
                                <Tooltip
                                  prefixCls="rc-slider-tooltip"
                                  overlay={`${handleProps.value}%`}
                                  placement="top"
                                  visible={handleProps.dragging}
                                >
                                  {node}
                                </Tooltip>
                              )}
                              defaultValue={0}
                            />
                          </div>
                          <div className="col-md-1 my-auto">
                            <Slider
                              onAfterChange={(e) => this.handleChangeSlider(e, 'pro')}
                              min={-45}
                              max={0}
                              step={0.5}
                              handleRender={(node, handleProps) => (
                                <Tooltip
                                  prefixCls="rc-slider-tooltip"
                                  overlay={`${handleProps.value}%`}
                                  placement="top"
                                  visible={handleProps.dragging}
                                >
                                  {node}
                                </Tooltip>
                              )}
                              defaultValue={0}
                            />
                          </div>
                          <div className="col-md-1 my-auto">
                            <Slider
                              onAfterChange={(e) => this.handleChangeSlider(e, 'vip')}
                              min={-45}
                              max={0}
                              step={0.5}
                              handleRender={(node, handleProps) => (
                                <Tooltip
                                  prefixCls="rc-slider-tooltip"
                                  overlay={`${handleProps.value}%`}
                                  placement="top"
                                  visible={handleProps.dragging}
                                >
                                  {node}
                                </Tooltip>
                              )}
                              defaultValue={0}
                            />
                          </div>
                          {product.value === 'osago' ? (
                            <div className="col-md-2 my-auto">
                              <Select
                                styles={customSelectStyle()}
                                name="integration"
                                isSearchable={false}
                                placeholder="Интеграция"
                                classNamePrefix="react-select"
                                inputId="integration"
                                onChange={this.handleChangeIntegration}
                                className="form-control-custom"
                                defaultValue={{ value: 'highPriority', label: 'Приоритет' }}
                                options={[
                                  { value: 'highPriority', label: 'Приоритет' },
                                  { value: 'inguru', label: 'Ингуру' },
                                  { value: 'smart', label: 'УП' },
                                  { value: 'insapp', label: 'Инсапп' },
                                ]}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h2>{product.label}</h2>
                {commissionsForm}
                <button type="submit" className="btn btn-success mr-2">Сохранить</button>
                <button type="button" className="btn btn-secondary" onClick={this.toggleCommission}>Отмена</button>
              </form>
            </LoadingBanner>
          )
          : <button type="button" className="btn btn-success mb-4" onClick={this.toggleCommission}>Редактировать условия вознаграждения</button>}
        <ReactTable
          className="table"
          data={commissions}
          defaultFiltered={this.state.filtered}
          onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
          columns={columns}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
          defaultSorted={[
            {
              id: 'date_to',
              desc: true,
            },
          ]}
        />
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalSure}
          onClose={this.onCloseModalSure}
          center
        >
          <h4>Вы уверены?</h4>
          <button disabled={uploading} className="btn btn-success mr-2" type="button" onClick={(e) => this.handleSubmitSure(e, selectedCompany, selectedRegion, selectedGroup, selectedBank)}>Да</button>
          <button disabled={uploading} type="button" className="btn btn-secondary" onClick={this.onCloseModalSure}>Нет</button>
          {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
        </Modal>
      </>
    );
  }
}

OuterCommission.contextType = ModalMessagesContext;

export default OuterCommission;
