import React, { Component } from 'react';
import ru from 'date-fns/locale/ru';
import {
  parse, parseISO, format, getDayOfYear, getYear,
} from 'date-fns';
import ReactTable from 'react-table-6';
import AsyncSelect from 'react-select/async';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import matchSorter from 'match-sorter';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { faCog } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Regions from './Regions';
import CommissionInput from './CommissionInput';
import {
  toUTC,
  customSelectStyle,
  customFetch,
  getProducts,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../Utils/UiComponents/LoadingBanner';

let abortController = new AbortController();

const regionName = {
  'Москва': 'moscow',
  'Московская обл.': 'moscowObl',
  'Санкт-Петербург': 'spb',
  'Ленинградская обл.': 'spbObl',
  'Россия': 'other',
};

const regionNameMortgage = {
  'Россия': 'other',
};

const integrationNames = {
  smart: 'УП',
  inguru: 'Ингуру',
  insapp: 'Инсапп',
  direct: 'Прямая интеграция',
};

class InnerCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      editableGroup: false,
      columns: [],
      endingCustomCommissions: false,
      companyNames: {},
      filtered: [{
        id: 'status',
        value: 'true',
      }],
      openModalSure: false,
      uploading: false,
      isToggleCommissionFormOn: false,
      commissionDate: null,
      loading: true,
      commissions: [],
      oldIntegrations: {},
      integrations: {},
      product: { value: 'osago', label: 'ОСАГО' },
      jobs: [],
      loadingCommissions: false,
      loadingCompanies: false,
    };
  }

  componentDidMount() {
    this.updateTableColumns();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { filtered, product } = this.state;
    const { location, tab } = this.props;
    const urlParams = queryString.parse(location.search);
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/outer/jobs`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((jobs) => {
        this.setState({ jobs });
      });
    getProducts(true).then((response) => {
      this.setState({
        products: response.map((product) => ({ value: product.code, label: product.name })),
      });
    });
    this.downloadTableCommissions(product.value);
    this.downloadCommissions(product.value);
    this.downloadCompanies(product.value);
  }

  toggleCommission = () => {
    this.setState((prevState) => ({
      isToggleCommissionFormOn: !prevState.isToggleCommissionFormOn,
    }));
  };

  handleInputCustomCommission = (integration, company, key, e) => {
    const { integrations } = this.state;
    integrations[integration][company].custom.forEach((element) => {
      if (element.regions === key) {
        element.value = e.target.value;
      }
    });
    this.setState({ integrations });
  };

  handleInputCommission = (integration, company, key, e) => {
    const { integrations } = this.state;
    if (key === 'custom') {
      integrations[integration][company][key][0].value = e.target.value;
    } else {
      integrations[integration][company][key] = e.target.value;
    }
    this.setState({ integrations });
  };

  handleChangeDate = (date) => {
    if (getDayOfYear(date) === getDayOfYear(new Date()) && getYear(date) === getYear(new Date())) {
      this.setState({ commissionDate: toUTC(new Date()) });
    } else {
      this.setState({ commissionDate: date });
    }
  };

  downloadCompanies = (product) => {
    this.setState({ loadingCompanies: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/companies?type=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((companies) => {
        const companyNames = {};
        Object.keys(companies).forEach((company) => {
          companyNames[companies[company].name] = {
            code: company,
            banks: companies[company].banks,
          };
        });
        this.setState({ companyNames, loadingCompanies: false });
      });
  };

  downloadCommissions = (product) => {
    this.setState({ loadingCommissions: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/inner?product=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          integrations: response,
          loadingCommissions: false,
        });
      });
  };

  downloadTableCommissions = (product) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ loading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/inner/actual?product=${product}`, {
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((commissions) => {
        this.setState({ commissions: Object.values(commissions) });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleChangeProduct = (e) => {
    abortController.abort();
    abortController = new AbortController();
    this.setState({
      product: e, integrations: {}, companyNames: {}, commissions: [],
    }, () => {
      this.updateTableColumns();
      this.downloadCompanies(e.value);
      this.downloadCommissions(e.value);
      this.downloadTableCommissions(e.value);
    });
  };

  handleSubmitSure = (e, company, region, groupId, bank) => {
    e.preventDefault();
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const {
      integrations, commissionDate, product,
    } = this.state;
    if (commissionDate == null) {
      this.setState({
        commissionDate: toUTC(new Date()),
      });
      showModalInfo('Дата начала действия вознаграждения не может быть раньше текущей даты');
      return 0;
    }
    this.setState({ uploading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/inner`, {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        commissions: integrations,
        commissionDate: format(commissionDate, 'yyyy-MM-dd HH:mm:ss'),
        company,
        region,
        bank,
        groupId,
        product: product.value,
      }),
    })
      .then((response) => response.json())
      .then((commissions) => {
        if (commissions.error) {
          showModalInfo(commissions.error);
        } else {
          this.downloadCommissions(product.value);
          this.setState((prevState) => ({
            commissions: Object.values(commissions),
            isToggleCommissionFormOn: !prevState.isToggleCommissionFormOn,
          }));
        }
      })
      .catch(() => {
        showModalInfo('Возникла ошибка');
      })
      .finally(() => {
        this.setState({
          uploading: false,
          openModalSure: false,
        });
      });
  };

  handleSubmit = (e, company, region, group = false, bank = false) => {
    e.preventDefault();
    this.setState({
      openModalSure: true,
      selectedCompany: company,
      selectedRegion: region,
      selectedGroup: group,
      selectedBank: bank,
    });
  };

  onCloseModalSure = () => {
    this.setState({ openModalSure: false });
  };

  handleChangeRegion = (e, company, groupId) => {
    const { oldIntegrations, integrations } = this.state;
    if (Object.keys(oldIntegrations).length === 0) {
      this.setState({ oldIntegrations: JSON.parse(JSON.stringify(integrations)) });
    }
    if (e !== null) {
      Object.keys(integrationNames).map((integrationName) => {
        if (integrations[integrationName] && integrations[integrationName][company]) {
          const index = integrations[integrationName][company].custom.findIndex((group) => group.groupId === groupId);
          integrations[integrationName][company].custom[index].regions = e.map((region) => region.value);
          this.setState({ integrations });
        }
      });
    }
    return 0;
  };

  handleSelectRegion = (e, company) => {
    const { integrations } = this.state;
    if (e === null) {
      Object.keys(integrationNames).map((integrationName) => {
        if (integrations[integrationName] && integrations[integrationName][company]) {
          integrations[integrationName][company].custom[0].regions = [];
        }
      });
    } else {
      const groupId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      Object.keys(integrationNames).map((integrationName) => {
        if (integrations[integrationName] && integrations[integrationName][company]) {
          integrations[integrationName][company].custom[0].groupId = groupId;
          integrations[integrationName][company].custom[0].regions = e.map((region) => region.value);
        }
      });
    }
    this.setState({ integrations });
  };

  handleEndCustomCommission = (e, company, regions, groupId) => {
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ endingCustomCommissions: true });
    const { product } = this.state;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/inner/end`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company,
        regions,
        product: product.value,
      }),
    })
      .then((response) => response.json())
      .then((commissions) => {
        const { integrations } = this.state;
        Object.values(integrations).forEach((integration, key, object) => {
          if (integration[company]) {
            const groupIndex = object[key][company].custom.findIndex((customGroup) => customGroup.groupId === groupId);
            object[key][company].custom.splice(groupIndex, 1);
          }
        });
        this.setState({
          commissions: Object.values(commissions),
          endingCustomCommissions: false,
          integrations,
        });
      })
      .catch(() => {
        showModalInfo('Возникла ошибка');
        this.setState({ endingCustomCommissions: false });
      });
  };

  handleSaveGroup = (e, company, regions, groupId) => {
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    this.setState({ endingCustomCommissions: true });
    const { product, integrations } = this.state;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commission/inner/edit`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company,
        regions,
        product: product.value,
        commissions: integrations,
        commissionDate: format(toUTC(new Date()), 'yyyy-MM-dd HH:mm:ss'),
        region: 'custom',
        bank: false,
        groupId,
      }),
    })
      .then((response) => response.json())
      .then((commissions) => {
        if (commissions.error) {
          showModalInfo(commissions.error);
        }
      })
      .catch(() => {
        showModalInfo('Возникла ошибка');
      })
      .finally(() => {
        this.setState({ endingCustomCommissions: false, editableGroup: false, oldIntegrations: {} });
      });
  };

  handleSetEditGroup = (e, companyCode, group) => {
    const { oldIntegrations } = this.state;
    if (Object.keys(oldIntegrations).length !== 0) {
      this.setState({ integrations: JSON.parse(JSON.stringify(oldIntegrations)), oldIntegrations: {} });
    }
    this.setState({ editableGroup: group });
  };

  customRegionBlock = (company) => {
    const {
      integrations, companyNames, endingCustomCommissions, commissions, product, editableGroup,
    } = this.state;

    const customCommissions = commissions.filter((commission) => !regionName.hasOwnProperty(commission.region)
      && commission.company === company && commission.product.code === product.value);
    Array.prototype.groupBy = function (prop) {
      return this.reduce((groups, item) => {
        const val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    };
    const groupedByGroup = customCommissions.groupBy('group_id');
    return Object.keys(groupedByGroup).map((group, index) => {
      const regionsArray = Object.keys(groupedByGroup[group].groupBy('region')).map((value) => (value));
      const allIntegrationCommission = {};
      Object.keys(integrationNames).forEach((integrationName) => {
        if (integrations[integrationName] && integrations[integrationName][companyNames[company].code]) {
          allIntegrationCommission[integrationName] = integrations[integrationName][companyNames[company].code].custom.find((element) => {
            if (element.groupId === group) {
              return element;
            }
            return false;
          });
        }
      });
      let integrationHasCompany = false;
      Object.entries(integrations).some((integration) => {
        if (integration[1].hasOwnProperty(companyNames[company].code)) {
          integrationHasCompany = integration[0];
          return true;
        }
        return false;
      });
      if (integrationHasCompany) {
        const groupIndex = integrations[integrationHasCompany][companyNames[company].code].custom.findIndex((customGroup) => customGroup.groupId === group);
        const selectValue = integrations ? integrations[integrationHasCompany][companyNames[company].code].custom[groupIndex].regions.map((region) => ({ value: region, label: region })) : null;
        const regions = integrations[integrationHasCompany][companyNames[company].code].custom[groupIndex].regions.map((region) => (region)).join(', ');
        return (
          <div key={`${companyNames[company].code}custom${group}`} className="row">
            {editableGroup === group ? (
              <div className="col-md-2">
                <AsyncSelect
                  value={selectValue}
                  classNamePrefix="react-select"
                  placeholder="Регион"
                  isMulti
                  cacheOptions
                  loadingMessage={() => 'Загрузка'}
                  noOptionsMessage={() => 'Не найдено'}
                  onChange={(e) => this.handleChangeRegion(e, companyNames[company].code, group)}
                  styles={customSelectStyle()}
                  loadOptions={(e) => this.promiseOptions(e, companyNames[company].code, integrationHasCompany)}
                />
              </div>
            ) : (
              <Regions
                regions={regions}
                index={index}
              />
            )}
            {Object.keys(integrationNames).map((integrationName) => {
              if (integrations[integrationName] && integrations[integrationName][companyNames[company].code]) {
                return (
                  <div key={`${companyNames[company].code}${integrationName}custom${group}`} className="col-md-1 form-group">
                    <CommissionInput
                      handleInputCommission={this.handleInputCustomCommission}
                      integration={integrationName}
                      value={allIntegrationCommission[integrationName] ? allIntegrationCommission[integrationName].value : ''}
                      region={allIntegrationCommission[integrationName] ? allIntegrationCommission[integrationName].regions : []}
                      company={companyNames[company].code}
                    />
                  </div>
                );
              }
              return null;
            })}
            <div className="col-md-2 form-group">
              <button disabled={editableGroup || endingCustomCommissions} type="button" className="btn btn-success" onClick={(e) => this.handleSubmit(e, companyNames[company].code, 'custom', group)}>Применить</button>
            </div>
            <div className="col-md-1 form-group">
              {editableGroup === group ? (
                <button disabled={endingCustomCommissions} type="button" className="btn btn-success" onClick={(e) => this.handleSaveGroup(e, companyNames[company].code, regionsArray, group)}>Сохранить</button>
              ) : (
                <button disabled={endingCustomCommissions} type="button" className="btn btn-success" onClick={(e) => this.handleSetEditGroup(e, companyNames[company].code, group)}><FontAwesomeIcon icon={faCog} className="fa-fw" /></button>
              )}
            </div>
            <div className="col-md-2 form-group">
              {endingCustomCommissions && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              {!endingCustomCommissions && <button disabled={editableGroup} type="button" className="btn btn-danger" onClick={(e) => this.handleEndCustomCommission(e, companyNames[company].code, regionsArray, group)}>Завершить</button>}
            </div>
          </div>
        );
      }
    });
  };

  promiseOptions = (inputValue, company, integrationHasCompany) => new Promise((resolve) => {
    const token = 'Token 2eaf8f9bcb7ecb682e99f0c177b0ea86a1c786ed';
    const { integrations } = this.state;
    customFetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
      method: 'post',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: inputValue,
        count: 10,
        from_bound: { value: 'region' },
        to_bound: { value: 'city' },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        const regions = response.suggestions.map((address) => ({ value: address.value, label: address.value }));
        const regionsFiltred = regions.filter((address) => {
          const regionExist = integrations[integrationHasCompany][company].custom.find((element) => {
            if (element.regions.find((region) => region === address.value)) {
              return element;
            }
            return false;
          });
          if (regionExist === undefined) {
            return address;
          }
        });
        resolve(regionsFiltred);
      });
  });

  onFilteredChangeCustom = (filtered) => {
    this.setState({ filtered });
    const { tab } = this.props;
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  updateTableColumns = () => {
    const { product } = this.state;
    const columns = [{
      Header: 'Продукт',
      id: 'product',
      accessor: (d) => d.product.name,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['product'] }),
      filterAll: true,
    }, {
      Header: 'Страховая',
      accessor: 'company',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
      filterAll: true,
    }, {
      Header: 'Регион',
      accessor: 'region',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
      filterAll: true,
      show: product.value !== 'mortgage',
    }, {
      Header: 'Банк',
      id: 'bank',
      accessor: (d) => (d.bank ? d.bank.name : null),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['bank'] }),
      filterAll: true,
      show: product.value === 'mortgage',
    }, {
      Header: 'Интеграция',
      accessor: 'integration',
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['integration'] }),
      filterAll: true,
    }, {
      Header: 'Дата от',
      id: 'date_from',
      accessor: (d) => format(parseISO(d.date_from), 'dd.MM.yyyy'),
      sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['date_from'] }),
      filterAll: true,
    }, {
      id: 'price',
      Header: 'Размер вознаграждения, %',
      accessor: (d) => d.price,
    },
    ];
    this.setState({ columns });
  };

  banksBlock = (company) => {
    const {
      integrations,
      companyNames,
    } = this.state;
    if (integrations.direct && integrations.direct[companyNames[company].code]) {
      return (
        <>
          {Object.entries(integrations.direct[companyNames[company].code]).map(([key, value]) => (
            <div key={`${companyNames[company].code}${key}`} className="row">
              <label className="col-md-2 col-form-label">{companyNames[company].banks[key]}</label>
              <div className="col-md-1 form-group">
                <CommissionInput
                  handleInputCommission={this.handleInputCommission}
                  integration="direct"
                  value={value}
                  region={key}
                  company={companyNames[company].code}
                />
              </div>
              <div className="col-md-2">
                <button type="button" className="btn btn-success" onClick={(e) => this.handleSubmit(e, companyNames[company].code, false, false, key)}>Применить</button>
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  regionsBlock = (company) => {
    const {
      companyNames,
      integrations,
      product,
    } = this.state;
    let filteredRegions = regionName;
    if (product.value !== 'osago') {
      filteredRegions = regionNameMortgage;
    }

    return (
      <>
        {Object.keys(filteredRegions).map((region) => (
          <div key={`${companyNames[company].code}${filteredRegions[region]}`} className="row">
            <label className="col-md-2 col-form-label">{region}</label>
            {Object.keys(integrationNames).map((integrationName) => {
              if (integrations[integrationName] && integrations[integrationName][companyNames[company].code]) {
                return (
                  <div key={`${companyNames[company].code}${filteredRegions[region]}${integrationName}`} className="col-md-1 form-group">
                    <CommissionInput
                      handleInputCommission={this.handleInputCommission}
                      integration={integrationName}
                      value={integrations[integrationName][companyNames[company].code][filteredRegions[region]]}
                      region={filteredRegions[region]}
                      company={companyNames[company].code}
                    />
                  </div>
                );
              }
              return null;
            })}
            <div className="col-md-2">
              <button type="button" className="btn btn-success" onClick={(e) => this.handleSubmit(e, companyNames[company].code, filteredRegions[region], false)}>Применить</button>
            </div>
          </div>
        ))}
      </>
    );
  };

  render() {
    const {
      columns,
      commissionDate,
      isToggleCommissionFormOn,
      uploading,
      companyNames,
      loading,
      commissions,
      openModalSure,
      selectedCompany,
      selectedRegion,
      selectedBank,
      selectedGroup,
      integrations,
      jobs,
      product,
      loadingCompanies,
      loadingCommissions,
      products,
    } = this.state;

    const commissionsForm = Object.keys(companyNames).map((company) => {
      let integrationHasCompany = false;
      Object.entries(integrations).some(([integrationName, integration]) => {
        if (integration.hasOwnProperty(companyNames[company].code)) {
          integrationHasCompany = integrationName;
          return true;
        }
        return false;
      });
      return (
        <div className="card mb-4">
          <div className="card-body">
            <div key={`${companyNames[company].code}`} className="form-group row">
              <div className="col">
                <div className="form-group row">
                  <div className="col-md-2"><p className="h5">{company}</p></div>
                  {Object.keys(integrationNames).map((integrationName) => {
                    if (integrations[integrationName] && integrations[integrationName][companyNames[company].code]) {
                      return (
                        <div key={`${companyNames[company].code}${integrationName}`} className="col-md-1">
                          <p className="h5" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{integrationNames[integrationName]}, %</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                  <div className="col-md-2">
                    <button type="button" className="btn btn-secondary" onClick={(e) => this.handleSubmit(e, companyNames[company].code, 'all', false, 'all')}>Применить</button>
                  </div>
                </div>
                {product.value !== 'mortgage' ? (
                  <>
                    {this.regionsBlock(company)}
                    {this.customRegionBlock(company)}
                    {product.value === 'osago' ? (
                      <div className="row">
                        <div className="col-md-2">
                          <AsyncSelect
                            classNamePrefix="react-select"
                            placeholder="Регион"
                            isMulti
                            cacheOptions
                            loadingMessage={() => 'Загрузка'}
                            noOptionsMessage={() => 'Не найдено'}
                            onChange={(e) => this.handleSelectRegion(e, companyNames[company].code)}
                            styles={customSelectStyle()}
                            loadOptions={(e) => this.promiseOptions(e, companyNames[company].code, integrationHasCompany)}
                          />
                        </div>
                        {Object.keys(integrationNames).map((integrationName) => {
                          if (integrations[integrationName] && integrations[integrationName][companyNames[company].code]) {
                            return (
                              <div key={`${companyNames[company].code}${integrationName}custom`} className="col-md-1 form-group">
                                <CommissionInput
                                  handleInputCommission={this.handleInputCommission}
                                  integration={integrationName}
                                  value={integrations[integrationName][companyNames[company].code].custom.value}
                                  region="custom"
                                  company={companyNames[company].code}
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                        <div className="col-md-2">
                          <button type="button" className="btn btn-success" onClick={(e) => this.handleSubmit(e, companyNames[company].code, 'custom', integrations[integrationHasCompany][companyNames[company].code].custom[0].groupId)}>Применить</button>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : this.banksBlock(company)}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <>
        {jobs && jobs.map((job) => (
          <div className="row">
            <div className="col-md-6">
              <div key={job.id} className="error_block mb-2">Запущен процесс обновления вознаграждения! Страховая - {job.company}, регион - {job.region}, продукт - {job.product}.</div>
            </div>
          </div>
        ))}
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-2">
                <label htmlFor="commissionType">Продукт</label>
                <Select
                  classNamePrefix="react-select"
                  styles={customSelectStyle()}
                  name="product"
                  isSearchable={false}
                  placeholder="Выберите продукт"
                  id="product"
                  value={product}
                  onChange={this.handleChangeProduct}
                  options={products}
                />
              </div>
            </div>
          </div>
        </div>
        {isToggleCommissionFormOn
          ? (
            <LoadingBanner loadingFlag={loadingCompanies || loadingCommissions}>
              <form onSubmit={(e) => this.handleSubmit(e, 'all', 'all', 'all', 'all')} className="mb-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-md-2 my-auto">
                        <DatePicker
                          popperModifiers={{
                            computeStyle: { gpuAcceleration: false },
                          }}
                          selected={commissionDate}
                          onChange={(date) => this.handleChangeDate(date)}
                          className="form-control"
                          name="commissionDate"
                          id="commissionDate"
                          locale={ru}
                          showYearDropdown
                          minDate={new Date()}
                          showMonthDropdown
                          dateFormat="dd.MM.yyyy"
                          placeholderText="Дата от"
                          customInput={
                            <InputMask mask="99.99.9999" inputMode="tel"/>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {commissionsForm}
                <button type="submit" className="btn btn-success mr-2">Сохранить</button>
                <button type="button" className="btn btn-secondary" onClick={this.toggleCommission}>Отмена</button>
                {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
              </form>
            </LoadingBanner>
          )
          : <button type="button" className="btn btn-success mb-4" onClick={this.toggleCommission}>Редактировать условия вознаграждения</button>}
        <ReactTable
          className="table"
          data={commissions}
          columns={columns}
          defaultFiltered={this.state.filtered}
          onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
          defaultSorted={[
            {
              id: 'date_to',
              desc: true,
            },
          ]}
        />
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalSure}
          onClose={this.onCloseModalSure}
          center
        >
          <h4>Вы уверены?</h4>
          <button disabled={uploading} className="btn btn-success mr-2" type="button" onClick={(e) => this.handleSubmitSure(e, selectedCompany, selectedRegion, selectedGroup, selectedBank)}>Да</button>
          <button disabled={uploading} type="button" className="btn btn-secondary" onClick={this.onCloseModalSure}>Нет</button>
          {uploading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
        </Modal>
      </>
    );
  }
}

InnerCommission.contextType = ModalMessagesContext;

export default InnerCommission;
