import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import PoliciesTable from './PoliciesTable';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class Policies extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      activeTab: '1',
      generateXlsxTableFlag: false,
    };
  }

  componentDidMount() {
    document.title = 'Полисы';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      const urlParams = queryString.parse(this.props.location.search);
      if (urlParams.activeTab !== this.state.activeTab && this.props.history.location.state && this.props.history.location.state.renewal) {
        this.setState({ activeTab: urlParams.activeTab });
      }
    }
  }

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  setGenerateXlsxTableFlag = (value) => {
    this.setState({ generateXlsxTableFlag: value });
  };

  toggle(tab) {
    const { activeTab } = this.state;
    const { history } = this.props;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    history.push({ search: `?activeTab=${tab}` });
  }

  render() {
    const { activeTab, generateXlsxTableFlag } = this.state;
    const {
      history, location, customField, userInfo, domain,
    } = this.props;
    return (
      <div className="position-relative">
        <h1 className="page-wrapper__title d-inline-block mr-3">Список договоров</h1>
        <div className="page_header__control mb-2 mb-md-0 d-inline-flex">
          {(activeTab === '1' || activeTab === '2' || userInfo.role === 'admin' || userInfo.role === 'superadmin')
            ? generateXlsxTableFlag
              ? <ReactLoading className="loading-circle mr-1 d-inline-block" type="spin" height={38} width={38} />
              : (
                <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
                  <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
                  Скачать в Excel
                </Button>
              )
            : null}
          <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
            <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
            Настроить таблицу
          </Button>
        </div>
        <h2>Оформленные полисы страхования</h2>
        <TabNavList>
          <TabNavItem
            active={activeTab === '1'}
            onClick={() => { this.toggle('1'); }}
            text="Мои полисы"
          />
          <TabNavItem
            active={activeTab === '2'}
            onClick={() => { this.toggle('2'); }}
            text="Полисы агентов"
          />
          {userInfo.full_time_employee === 1 && userInfo.remote_employee === 0 && (
            <TabNavItem
              active={activeTab === '3'}
              onClick={() => { this.toggle('3'); }}
              text="Все полисы (штатные)"
            />
          )}
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <TabNavItem
              active={activeTab === '4'}
              onClick={() => { this.toggle('4'); }}
              text="Все полисы (админ)"
            />
          )}
        </TabNavList>
        <TabContent animation="false" activeTab={activeTab}>
          <TabPane tabId="1">
            { activeTab === '1' ? <PoliciesTable ref={activeTab === '1' ? this.child : undefined} tab="1" location={location} domain={domain} setGenerateXlsxTableFlag={this.setGenerateXlsxTableFlag} customField={customField} history={history} /> : null }
          </TabPane>
          <TabPane tabId="2">
            { activeTab === '2' ? <PoliciesTable ref={activeTab === '2' ? this.child : undefined} tab="2" location={location} domain={domain} setGenerateXlsxTableFlag={this.setGenerateXlsxTableFlag} customField={customField} history={history} show="subagents_network" /> : null }
          </TabPane>
          {userInfo.full_time_employee === 1 && userInfo.remote_employee === 0 && (
            <TabPane tabId="3">
              { activeTab === '3' ? <PoliciesTable ref={activeTab === '3' ? this.child : undefined} tab="3" location={location} domain={domain} setGenerateXlsxTableFlag={this.setGenerateXlsxTableFlag} customField={customField} history={history} show="full_time_employee" /> : null }
            </TabPane>
          )}
          {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
            <TabPane tabId="4">
              { activeTab === '4' ? <PoliciesTable ref={activeTab === '4' ? this.child : undefined} tab="4" location={location} domain={domain} setGenerateXlsxTableFlag={this.setGenerateXlsxTableFlag} customField={customField} history={history} show="all" /> : null }
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  }
}

export default withUserInfoStoreStore(Policies);
