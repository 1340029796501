import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import logoGoogleAnalytics from '../../img/analytics/GoogleAnalytics.png';
import logoYandexMetrika from '../../img/analytics/YandexMetrika.png';
import logoRoistat from '../../img/analytics/Roistat.png';
import logoCalltouch from '../../img/analytics/Calltouch.jpg';
import WidgetAnalyticsIntegrationYandex from './WidgetAnalyticsIntegrationYandex';
import WidgetAnalyticsIntegrationGoogle from './WidgetAnalyticsIntegrationGoogle';
import WidgetAnalyticsIntegrationRoistat from './WidgetAnalyticsIntegrationRoistat';
import WidgetAnalyticsIntegrationCalltouch from './WidgetAnalyticsIntegrationCalltouch';

export default class WidgetAnalyticsIntegrationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: true,
      showIntegration: '',
    };
  }

  showIntegration = (e, type) => {
    e.preventDefault();
    this.setState({
      showList: false,
      showIntegration: type,
    });
  };

  showMenu = (e) => {
    e.preventDefault();
    this.setState({
      showList: true,
      showIntegration: '',
    });
  };

  render() {
    const {
      showList,
      showIntegration,
    } = this.state;

    const {
      widget,
      toggleFlag,
      changeWidgetAnalyticsIntegrationId,
      updateWidgetWithInfo,
    } = this.props;
    const activeButtonStyle = {
      fontSize: '12px',
      padding: '6px 12px',
      minHeight: 'auto',
      marginTop: '10px',
    };
    const buttonStyle = {
      ...activeButtonStyle,
      opacity: 0.6,
    };

    return (
      <>
        {showList ? (
          <>
            <div className="widget-crm-list">
              <a className="widget-crm-list__item" style={widget.settings.yandexId === '' ? { borderColor: 'lightgray' } : {}}>
                <img src={logoYandexMetrika} alt="bitrix24" style={widget.settings.yandexId === '' ? { filter: 'grayscale(100%)', opacity: 0.6 } : {}} />
                <button type="button" className="btn btn-success mr-3" style={widget.settings.yandexId !== '' ? activeButtonStyle : buttonStyle} onClick={(e) => this.showIntegration(e, 'yandex')}>
                  <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '10px' }} />Редактировать
                </button>
              </a>
              <a className="widget-crm-list__item" style={widget.settings.googleId === '' ? { borderColor: 'lightgray' } : {}}>
                <img src={logoGoogleAnalytics} alt="amo" style={widget.settings.googleId === '' ? { filter: 'grayscale(100%)', opacity: 0.6 } : {}} />
                <button type="button" className="btn btn-success mr-3" style={widget.settings.googleId !== '' ? activeButtonStyle : buttonStyle} onClick={(e) => this.showIntegration(e, 'google')}>
                  <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '10px' }} />Редактировать
                </button>
              </a>
              <a className="widget-crm-list__item" style={!widget.settings.roistatProjectId ? { borderColor: 'lightgray' } : {}}>
                <img src={logoRoistat} alt="amo" style={!widget.settings.roistatProjectId ? { filter: 'grayscale(100%)', opacity: 0.6 } : {}} />
                <button type="button" className="btn btn-success mr-3" style={widget.settings.roistatProjectId ? activeButtonStyle : buttonStyle} onClick={(e) => this.showIntegration(e, 'roistat')}>
                  <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '10px' }} />Редактировать
                </button>
              </a>
              <a className="widget-crm-list__item" style={!widget.settings.calltouch_id ? { borderColor: 'lightgray' } : {}}>
                <img src={logoCalltouch} alt="amo" style={!widget.settings.calltouch_id ? { filter: 'grayscale(100%)', opacity: 0.6 } : {}} />
                <button type="button" className="btn btn-success mr-3" style={widget.settings.calltouch_id ? activeButtonStyle : buttonStyle} onClick={(e) => this.showIntegration(e, 'calltouch')}>
                  <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '10px' }} />Редактировать
                </button>
              </a>
            </div>
          </>
        ) : null}
        {showIntegration === 'yandex' ? (
          <WidgetAnalyticsIntegrationYandex
            showMenu={this.showMenu}
            yandexId={widget.settings.yandexId}
            changeWidgetAnalyticsIntegrationId={changeWidgetAnalyticsIntegrationId}
            updateWidgetWithInfo={updateWidgetWithInfo}
            widgetId={widget.id}
            login={widget.settings.yandex_login}
            token={widget.settings.yandex_oauth_token}
            tokenExpires={widget.settings.yandex_oauth_token_expires}
            tokenDate={widget.settings.yandex_oauth_token_date}
            widget={widget}
          />
        ) : null}
        {showIntegration === 'google' ? (
          <WidgetAnalyticsIntegrationGoogle
            showMenu={this.showMenu}
            googleId={widget.settings.googleId}
            changeWidgetAnalyticsIntegrationId={changeWidgetAnalyticsIntegrationId}
            updateWidgetWithInfo={updateWidgetWithInfo}
            widget={widget}
          />
        ) : null}
        {showIntegration === 'roistat' ? (
          <WidgetAnalyticsIntegrationRoistat
            showMenu={this.showMenu}
            roistatProjectId={widget.settings.roistatProjectId}
            sendUrl={widget.settings.roistatSendUrl}
            toggleFlag={toggleFlag}
            changeWidgetAnalyticsIntegrationId={changeWidgetAnalyticsIntegrationId}
            updateWidgetWithInfo={updateWidgetWithInfo}
            widget={widget}
          />
        ) : null}
        {showIntegration === 'calltouch' ? (
          <WidgetAnalyticsIntegrationCalltouch
            showMenu={this.showMenu}
            calltouchId={widget.settings.calltouch_id}
            changeWidgetAnalyticsIntegrationId={changeWidgetAnalyticsIntegrationId}
            updateWidgetWithInfo={updateWidgetWithInfo}
            widget={widget}
          />
        ) : null}
      </>
    );
  }
}
