import React, {
  useContext, useState,
} from 'react';
import classnames from 'classnames';
import ru from 'date-fns/locale/ru';
import {
  endOfDay,
  endOfMonth,
  startOfMonth,
  subMonths,
} from 'date-fns';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import ReactLoading from 'react-loading';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch,
  customSelectStyle,
  loadSuggestions,
  toLocalDate,
} from '../Utils/Helpers';

const FileSaver = require('file-saver');

export default function Monitoring() {
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(false);
  const [activeFilterTab, setActiveFilterTab] = useState(1);
  const [loadingReportFlag, setLoadingReportFlag] = useState(false);
  const [agent, setAgent] = useState('');

  const downloadReport = () => {
    setLoadingReportFlag(true);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/statistic/report`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: toLocalDate(startDate),
        endDate: toLocalDate(endDate),
        agent,
      }),
    }).then((response) => response.blob())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else if (response) {
          FileSaver.saveAs(response, 'report.xlsx');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoadingReportFlag(false);
      });
  };

  const toggle = (tab) => {
    const date = new Date();
    if (tab === 1) {
      setStartDate(startOfMonth(date));
    }
    if (tab === 2) {
      setStartDate(startOfMonth(subMonths(date, 1)));
      setEndDate(endOfMonth(subMonths(date, 1)));
    }
    if (tab === 3) {
      setStartDate(false);
    }
    setActiveFilterTab(tab);
  };

  return (
    <>
      <h2>Bigautodata</h2>
      <div className="dateFilter">
        <div className="d-inline-block mb-2">
          <div
            onClick={() => { toggle(1); }}
            className={classnames({ active: activeFilterTab === 1 }, 'dateFilter-preset')}
          >
            Текущий месяц
          </div>
          <div
            onClick={() => { toggle(2); }}
            className={classnames({ active: activeFilterTab === 2 }, 'dateFilter-preset')}
          >
            Прошлый месяц
          </div>
          <div
            onClick={() => { toggle(3); }}
            className={classnames({ active: activeFilterTab === 3 }, 'dateFilter-preset')}
          >
            Все время
          </div>
        </div>
        <div className="d-inline-block mb-2">
          <span>Точные даты с</span>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            selected={startDate}
            className="form-control"
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={setStartDate}
            maxDate={endDate}
            showYearDropdown
            showMonthDropdown
            locale={ru}
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
        </div>
        <div className="d-inline-block mb-2">
          <span>по</span>
          <DatePicker
            popperModifiers={{
              computeStyle: { gpuAcceleration: false },
            }}
            selected={endDate}
            className="form-control"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            onChange={(date) => setEndDate(endOfDay(date))}
            minDate={startDate}
            showYearDropdown
            showMonthDropdown
            locale={ru}
            dateFormat="dd.MM.yyyy"
            placeholderText="ДД.ММ.ГГГГ"
            customInput={
              <InputMask mask="99.99.9999" inputMode="tel" />
            }
          />
        </div>
        <div className="d-inline-block mb-2 mr-2">
          <AsyncSelect
            styles={customSelectStyle()}
            name="newAgent"
            placeholder="Укажите агента"
            classNamePrefix="react-select"
            inputId="newAgent"
            value={agent || ''}
            onChange={(e) => setAgent(e)}
            className="form-control-custom"
            loadOptions={debounce(loadSuggestions(''), 500)}
            noOptionsMessage={() => 'Не найдено'}
          />
        </div>
        <div className="form-inline mt-2 d-inline-flex">
          {loadingReportFlag
            ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />
            : <button type="button" className="btn btn-success" onClick={downloadReport}>Скачать отчет</button>}
        </div>
      </div>
    </>
  );
}
