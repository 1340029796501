import React, { Component } from 'react';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import { customFetch } from '../Utils/Helpers';

export default class SetYandexOauthToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const urlParams = queryString.parse(location.hash);
    const widgetId = urlParams.state.split('__')[0];
    const lsToken = urlParams.state.split('__')[1];
    const url = urlParams.state.split('__')[2];

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/widgets/set-yandex-oauth-token`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        widgetId,
        token: urlParams.access_token,
        expires: urlParams.expires_in,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        window.location.assign(url);
      });
  }

  render() {
    return (
      <div className="b-load-yandex-token">
        <h3>Обрабатываем данные...</h3>
        <ReactLoading type="spin" height={70} width={70} className="loading-circle ml-3" />
      </div>
    );
  }
}
