import React, { Component } from 'react';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import { reactLocalStorage } from 'reactjs-localstorage';
import { format } from 'date-fns';
import styled from 'styled-components';
import NotificationSlider from './NotificationsSlider/NotificationsSlider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { customFetch } from '../Utils/Helpers';
import CustomIcon from './CustomIcon';
import LinkUnderLine from './LinkUnderLine';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1924px;
    margin: 0 auto;
`;

const List = styled.ul`
    margin: 0 0 40px;
    padding: 0;
    list-style: none;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #ffffff;
    
    @media (max-width: 767px) {
        width: 100vw;
        margin: 0 0 20px;
        align-self: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

const Item = styled.li`
    padding: 13px 24px 13px 24px;
    display: flex;
    align-items: center;
    border: 1px solid #e9e9e9;
    border-bottom: none;
    &:first-of-type {
        border-top-left-radius: 10px;
    }
    &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    span:last-child {
        margin-left: auto;
    }
    
    @media (max-width: 1439px) {
        padding: 13px 16px;
    }
    @media (max-width: 767px) {
        padding: 12px 9px 12px 16px;
        border-left: none;
        border-right: none;
        
        &:first-of-type {
            border-top-left-radius: 0;
        }
        &:last-of-type {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
`;

const IconContainer = styled.div`
    margin: 0 16px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    
    color: ${(props) => (props.important ? '#ff4c00' : '#ff9900')};

    @media (max-width: 767px) {
        margin: 0 14px 0 0;
        svg {
            width: 17px;
            height: 17px;
        }
    }
`;

const Text = styled.div`
    margin: 0 16px 0 0;
    width: 82.5%;
    @media (max-width: 1439px) {
        width: 70.13%;
    }
    @media (max-width: 767px) {
        margin: 0 8px 0 0;
        width: 83.84%;
        font-size: 13px;
        line-height: 20px;
        overflow: hidden;
    }
    
    & + ${LinkUnderLine} {
        margin-left: auto;
        min-width: 133px;
        @media (max-width: 767px) {
            min-width: auto;
            width: 20px;
            height: 20px;
        }
    }
    
    p:last-child{
        margin-bottom: 0;
    }
    
    a{
        padding-bottom: 2px;
        background: linear-gradient(to right, rgba(17, 212, 95, 0.5), rgba(17, 212, 95, 0.5)) 100% 100% / 100% 0.1em no-repeat,
        linear-gradient(to right, ${(props) => (props.theme && props.theme.mainColor ? props.theme.mainColor : '#72c367')}, ${(props) => (props.theme && props.theme.mainColor ? props.theme.mainColor : '#72c367')}) 0 100% / 0 0.1em no-repeat;
        user-select: none;
        transition: background-size 0.3s, opacity 0.3s;
        cursor: pointer !important;

        &:hover,
        &:focus {
            background-size: 0 0.1em, 100% 0.1em;
        }

        &:before, &:after {
            content: "\\00a0";
        }
    }
`;

const CloseButton = styled.div`
    cursor: pointer;
    color: #000;
    opacity: 0.3;
    transition: opacity 0.2s linear;
    margin-left: auto;
    
    &:hover{
        opacity: 0.75;
    }
`;

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      renewalCount: 0,
      showFinancesBlock: false,
    };
  }

  componentDidMount() {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/news?type=top_block`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((news) => {
        const notifications = reactLocalStorage.getObject('notifications');
        Object.entries(notifications).forEach(([id, value]) => {
          if (value === false) {
            const index = news.findIndex((obj) => obj.id === Number(id));
            if (index === -1) {
              delete notifications[id];
            } else {
              news[index].hideNotification = true;
            }
          }
        });
        const financesBlock = reactLocalStorage.get('financesBlock');
        if (!financesBlock) {
          this.setState({ showFinancesBlock: true });
        }
        reactLocalStorage.setObject('notifications', notifications);
        this.setState({ news });
      });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/renewal`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((renewalCount) => {
        this.setState({ renewalCount });
      });
  }

  hideNotification = (e, newsId) => {
    const { news } = this.state;
    news[news.findIndex((value) => value.id === newsId)].hideNotification = true;
    this.setState({ news });
    const notifications = reactLocalStorage.getObject('notifications');
    notifications[newsId] = false;
    reactLocalStorage.setObject('notifications', notifications);
  };

  hideFinancesBlock = () => {
    reactLocalStorage.set('financesBlock', format(new Date(), 'dd'));
    this.setState({ showFinancesBlock: false });
  };

  goToRenewal = () => {
    const { history } = this.props;
    history.push({
      pathname: '/policies',
      search: `?filter_date=null ${format(new Date(), 'dd.MM.yyyy')}&dateTab=3&activeTab=1&renewal=true`,
      state: { renewal: true },
    });
  }

  goToProfile = () => {
    const { history } = this.props;
    history.push('/profile');
  }

  render() {
    const { news, renewalCount, showFinancesBlock } = this.state;
    const {
      history, userInfo, theme,
    } = this.props;

    const newsBlock = news.map((currentNews) => {
      if (!currentNews.hideNotification) {
        return (
          <Item>
            <IconContainer><CustomIcon icon="exclamation" pureSvg /></IconContainer>
            <Text>{parse(draftToHtml(JSON.parse(currentNews.txt)))}</Text>
            <CloseButton onClick={(e) => this.hideNotification(e, currentNews.id)}><CustomIcon icon="cross" width="20px" heigth="20px" /></CloseButton>
          </Item>
        );
      }
      return null;
    });

    return (
      (history.location.pathname !== '/finances'
        && history.location.pathname !== '/kasko_request'
        && history.location.pathname !== '/osago'
        && history.location.pathname !== '/cargoInsurance'
        && history.location.pathname !== '/mortgage'
        && history.location.pathname !== '/kbm'
        && history.location.pathname !== '/kasko'
        && history.location.pathname !== '/zpr'
        && history.location.pathname !== '/ifl'
      ) ? (
        <Container>
          <List>
            {(showFinancesBlock && (!theme.bannersHide || theme.bannersHide.length < 4)) ? <NotificationSlider title={theme.title} linkToTelegram={theme.link_to_telegram} bannersHide={theme.bannersHide} history={history} hideSlider={() => this.hideFinancesBlock()} /> : null}
            {(userInfo.confirmed === 'no' || userInfo.confirmed === 'need_update') && userInfo.full_time_employee === 0 && (
              <Item>
                <IconContainer><CustomIcon icon="exclamation" pureSvg /></IconContainer>
                <Text>
                  Заполните реквизиты в разделе
                  <LinkUnderLine
                    onClick={(e) => {
                      e.preventDefault();
                      history.push('/profile?activeTab=3');
                    }}
                  >
                    Личные данные
                  </LinkUnderLine>для получения комиссионного вознаграждения.
                </Text>
              </Item>
            )}
            {!userInfo.poll_complete && userInfo.curator_obj.full_time_employee === 1 && history.location.pathname !== '/agent-poll' ? (
              <Item>
                <IconContainer><CustomIcon icon="exclamation" pureSvg /></IconContainer>
                <Text>
                  Давайте познакомимся! Пройдите
                  <LinkUnderLine
                    onClick={(e) => {
                      e.preventDefault();
                      history.push('/agent-poll');
                    }}
                  >
                    опрос.
                  </LinkUnderLine>
                </Text>
              </Item>
            ) : null}
            {renewalCount > 0 ? (
              <Item onClick={window.innerWidth < 620 ? this.goToRenewal : null}>
                <IconContainer important><CustomIcon icon="exclamation" pureSvg /></IconContainer>
                <Text>
                  У ваших полисов({renewalCount}) истекает срок действия.
                </Text>
                <LinkUnderLine minimized onClick={this.goToRenewal}>Перейти к списку</LinkUnderLine>
              </Item>
            ) : null}
            {userInfo.agent_type_code === 'self_employed' && (userInfo.self_employed_verified === 2 || userInfo.self_employed_verified === 0 || userInfo.parentAgentIsFullTimeEmployee) ? (
              <Item>
                <IconContainer important><CustomIcon icon="exclamation" pureSvg /></IconContainer>
                <Text>
                  Агентам, зарегистрированным в качестве самозанятого, доступны быстрые выплаты на карту и повышенное
                  вознаграждение. <LinkUnderLine onClick={this.goToProfile}>Заполните ИНН и дождитесь подтверждения статуса самозанятого администратором</LinkUnderLine>.
                  (Предварительно вы должны зарегистрироваться как самозанятый на сайте
                  <LinkUnderLine onClick={() => { window.open('https://www.nalog.ru/', '_blank').focus(); }}>ФНС РФ</LinkUnderLine>)
                </Text>
              </Item>
            ) : null}
            {newsBlock}
          </List>
        </Container>
        ) : null
    );
  }
}

export default withUserInfoStoreStore(Notifications);
