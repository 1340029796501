import React, {
  useState, useEffect, useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faStar } from '@fortawesome/fontawesome-free-solid';
import Tooltip from 'rc-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactLoading from 'react-loading';
import { faClone, faEnvelope } from '@fortawesome/fontawesome-free-regular';
import Modal from 'react-responsive-modal';
import { format } from 'date-fns';
import { customFetch } from '../../Utils/Helpers';
import PhoneVerifying from '../../Utils/PhoneVerifying';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

export default function ZprCheckInfo(props) {
  const {
    formData,
    chosenOffer,
    companies,
    backToForm,
    setChosenOffer,
    draftInfo,
    setDisableField,
    policy,
  } = props;

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [phoneVerified, setPhoneVerified] = useState(false);
  const [showModal, setShowModal] = useState({
    absolutDeclarationForm: false,
  });
  const [loadingFinalResult, setLoadingFinalResults] = useState(false);
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);
  const [visibleTooltipCopyToClipboard, setVisibleTooltipCopyToClipboard] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [emailForLink, setEmailForLink] = useState('');
  const [openModalSendLinkToEmail, setOpenModalSendLinkToEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const submitPayment = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/payment?draftHash=${draftInfo.hash}`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then(() => {
        setDisableField(true);
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const getPaymentLink = () => {
    setLoadingPaymentLink(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/getPaymentLink?draftHash=${draftInfo.hash}`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setPaymentLink(response.paymentLink);
        } else {
          showModalInfo(response.error, 'error');
          setErrorMessage(response.error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
        setErrorMessage('Ошибка при получении ссылки на оплату');
      })
      .finally(() => {
        setLoadingPaymentLink(false);
      });
  };

  const recalculate = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/calculation`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        service: true,
        draftHash: draftInfo.hash,
        insurance: chosenOffer.companyCode,
        iid: chosenOffer.integration,
        calcType: 'final',
      }),
    }).then((response) => response.json())
      .then((response) => {
        if (!response.errorMessage && !response.error && !response.errors) {
          const { price } = response;
          setChosenOffer((prev) => ({
            ...prev,
            price,
          }));
        } else {
          const error = response.errorMessage || response.error || response.errors.errors[0].message;
          showModalInfo(error, 'error');
          setErrorMessage(error);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
        setErrorMessage('Ошибка при выполнении финального расчета');
      })
      .finally(() => {
        setLoadingFinalResults(false);
      });
  };

  const sendLinkToEmail = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/sendLinkToEmail`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailForLink,
        link: paymentLink,
        policyId: draftInfo.id,
        cost: chosenOffer.price,
        company: companies[chosenOffer.companyCode].name,
        product: 'zpr',
      }),
    })
      .then((response) => response.json())
      .then(() => {
        showModalInfo('Ссылка отправлена на почту');
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setOpenModalSendLinkToEmail((prev) => !prev);
      });
    submitPayment();
  };

  const copyToClipBoard = () => {
    setVisibleTooltipCopyToClipboard(true);
    submitPayment();
  };

  useEffect(() => {
    if (!policy.is_demo) {
      setLoadingFinalResults(true);
      recalculate(chosenOffer.integration);
    }
  }, []);

  useEffect(() => {
    if (visibleTooltipCopyToClipboard) {
      setTimeout(() => {
        setVisibleTooltipCopyToClipboard(false);
      }, 3000);
    }
  }, [visibleTooltipCopyToClipboard]);

  useEffect(() => {
    if (formData.contactEmail.value) {
      setEmailForLink(formData.contactEmail.value);
    }
  }, [formData.contactEmail.value]);

  return (
    <>
      <PhoneVerifying
        setPhoneVerified={setPhoneVerified}
        showModal={showModal}
        setShowModal={setShowModal}
        draftHash={draftInfo.hash}
        phoneVerified={phoneVerified}
        phone={formData.contactPhone.value}
      />
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
        closeIconSize={16}
        open={openModalSendLinkToEmail}
        onClose={() => setOpenModalSendLinkToEmail((prev) => !prev)}
        center
      >
        <form onSubmit={sendLinkToEmail}>
          <label htmlFor="inputEmail">Укажите адрес электронной почты</label>
          <input value={emailForLink} id="inputEmail" name="email" className="form-control mb-2" placeholder="Email" required="" type="email" onChange={(e) => setEmailForLink(e.target.value)} />
          <SimpleNotification>На указанный адрес отправлено письмо со ссылкой на оплату.</SimpleNotification>
          <button className="btn btn-success mt-2" type="submit">Отправить</button>
        </form>
      </Modal>
      <div name="check_info" className="search_results__main page--calck">
        <SimpleNotification offsetBottom="1rem">Отлично! Вы выбрали продукт от компании {companies[chosenOffer.companyCode].name}.</SimpleNotification>
        <SimpleNotification offsetBottom="1rem">Проверьте правильность введенных данных.</SimpleNotification>
        <div>
          <a rel="noopener noreferrer" href="#" onClick={(e) => backToForm(e, 'short')} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
        </div>
        <div className="mb-2 d-flex">
          <span className="mr-auto align-self-center">Введенные данные</span>
          <button type="button" onClick={(e) => backToForm(e, 'top')} className="btn custom-btn">Редактировать</button>
        </div>
        <table className="data_table">
          <thead>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Страхователь</span></td>
              <td className="data_table__row_content">
                <p>
                  {`${formData.lastName.value} ${formData.firstName.value} ${formData.middleName.value ? formData.middleName.value : ''}`}, {format(formData.birthday.value, 'dd.MM.yyyy')} ({formData.gender.value.label})
                </p>
                <p>
                  <b>Паспорт:</b> {formData.passport.value.substring(0, 4)} {formData.passport.value.substring(4)}, {format(formData.passportDate.value, 'dd.MM.yyyy')}, {formData.passportDivision.value} ({formData.passportUnitNumber.value})
                </p>
                <p><b>Адрес:</b> {formData.insuredAddress.value.value}</p>
                <p><b>Адрес регистрации:</b> {formData.insuredAddressRegistration.value.value}</p>
              </td>
            </tr>
            {draftInfo.type === 'mortgageLife' || draftInfo.type === 'mortgageComplex' ? (
              <tr>
                <td className="data_table__row_title"><span className="color-light">Страхование жизни и здоровья</span></td>
                <td className="data_table__row_content">
                  <p>
                    <b>Рост:</b> {formData.height.value} см.
                    <b>Вес:</b> {formData.weight.value} кг.
                    <b>Артериальное давление:</b> {formData.bloodPressureTop.value}/{formData.bloodPressureLow.value}
                  </p>
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="data_table__row_title"><span className="color-light">Данные страхуемого</span>
              </td>
              <td className="data_table__row_content">
                <p><b>Страховая сумма:</b> {formData.insuranceLimit.value} p.</p>
              </td>
            </tr>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Данные о полисе</span></td>
              <td className="data_table__row_content">
                <p>
                  <b>Продукт:</b>
                  {draftInfo.type === 'zpr' ? ' Потеря работы' : null}
                </p>
                <p><b>Страховая компания:</b> {companies[chosenOffer.companyCode].name}</p>
                <p><b>Дата начала:</b> {format(formData.startDate.value, 'dd.MM.yyyy')}, <b>Дата окончания:</b> {format(formData.endDate.value, 'dd.MM.yyyy')}</p>
              </td>
            </tr>
            <tr>
              <td className="data_table__row_title"><span className="color-light">Контактная информация</span></td>
              <td className="data_table__row_content">
                <p><b>Телефон:</b> {formData.contactPhone.value}, <b>Email:</b> {formData.contactEmail.value}</p>
              </td>
            </tr>
          </thead>
        </table>
      </div>
      <div className="sticky-top search_results__sidebar page--search js-search_results__sidebar">
        <div className="search_results__info mt-0">
          <div className="product_block">
            <div className="product_block__title">
              <div className="product_block__title-pay">{chosenOffer.price} руб.</div>
              <span className="product_block__title-name">Страхование от потери работы</span>
            </div>
            <div className="product_block__body">
              <div className="product_block__image">
                <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[chosenOffer.companyCode].logo}`} width="142" height="62" alt="" />
                <div className="company_item__star">
                  {[...Array(companies[chosenOffer.companyCode].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                </div>
              </div>
              <div className="product_block__info">
                <div className="product_block__info_title">Стоимость полиса</div>
                <dl className="description_list">
                  <dt className="color-light">
                    {draftInfo.type === 'zpr' ? 'Потеря работы' : null}
                  </dt>
                  <dd><b>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(chosenOffer.price)}</b></dd>
                </dl>
                <div className="product_block__info_footer">
                  <dl className="description_list">
                    <dt className="color-light">Итого:</dt>
                    <dd><b>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(chosenOffer.price)}</b></dd>
                  </dl>
                </div>
              </div>
              <div className="product_block__footer">
                {loadingFinalResult
                  ? (<><ReactLoading type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Выполняется финальный расчет</>)
                  : null}
                {loadingPaymentLink
                  ? (<><ReactLoading type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Получение ссылки на оплату</>)
                  : null}
                {!loadingPaymentLink && !loadingFinalResult
                  ? (
                    <div>
                      {errorMessage ? (
                        <div className="error_block">{errorMessage}</div>
                      ) : (
                        <div>
                          <div>
                            {policy.is_demo ? (
                              <a type="button" rel="noopener noreferrer" onClick={(e) => e.preventDefault()} className="btn custom-btn mr-1">Оплатить</a>
                            ) : (
                              <div>
                                {paymentLink ? (
                                  <>
                                    <a type="button" rel="noopener noreferrer" target="_blank" href={paymentLink} onClick={submitPayment} className="btn custom-btn mr-1">Оплатить</a>
                                    <Tooltip
                                      animation="zoom"
                                      placement="top"
                                      visible={visibleTooltipCopyToClipboard}
                                      overlay={<span>Ссылка скопирована в буфер обмена</span>}
                                      trigger=""
                                      overlayClassName="custom-tooltip"
                                    >
                                      <CopyToClipboard text={paymentLink}>
                                        <button type="button" title="Скопировать ссылку на оплату" onClick={copyToClipBoard} className="btn btn-outline btn-square sq mr-1">
                                          <FontAwesomeIcon icon={faClone} className="fa-fw" />
                                        </button>
                                      </CopyToClipboard>
                                    </Tooltip>
                                    <button type="button" title="Отправить ссылку на оплату" onClick={() => setOpenModalSendLinkToEmail(true)} className="btn btn-outline btn-square">
                                      <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
                                    </button>
                                  </>
                                ) : (
                                  <a type="button" rel="noopener noreferrer" target="_blank" onClick={getPaymentLink} className="btn custom-btn mr-1">Получить ссылку на оплату</a>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
