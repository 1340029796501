import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import ru from 'date-fns/locale/ru';
import { format } from 'date-fns';
import InputMask from 'react-input-mask';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import {
  customFetch, customSelectStyle, getManagers, loadSuggestions,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import getCompanies from '../Utils/InsurancesCompaniesStyle';
import getBanks from '../Utils/ClassifiersLoaders';

export default function AddPolicy(props) {
  const { history } = props;
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [policyType, setPolicyType] = useState({ label: 'КАСКО', value: 'kasko' });
  const [policyDeclaration, setPolicyDeclaration] = useState(null);
  const [middleOfficeAgentsOptions, setMiddleOfficeAgentsOptions] = useState([]);
  const [middleOfficeAgent, setMiddleOfficeAgent] = useState(null);
  const [integration, setIntegration] = useState({ label: 'B2B', value: 'b2b' });
  const [widgetId, setWidgetId] = useState(null);
  const [bank, setBank] = useState({ label: 'Не менять', value: null });
  const [banks, setBanks] = useState([]);
  const [managerIssued, setManagerIssued] = useState(false);
  const [broker, setBroker] = useState(false);
  const [managers, setManagers] = useState([]);
  const [insurance, setInsurance] = useState(false);
  const customStyles = useMemo(() => customSelectStyle(), []);
  const [policyId, setPolicyId] = useState('');
  const [agentId, setAgentId] = useState('');
  const [addToErp, setAddToErp] = useState(true);
  const [erpId, setErpId] = useState('');
  const [objectInsurance, setObjectInsurance] = useState(false);
  const [innerCommission, setInnerCommission] = useState(0);
  const [outerCommission, setOuterCommission] = useState(0);
  const [bonusPartner, setBonusPartner] = useState(0);
  const [premium, setPremium] = useState(0);
  const [orderDate, setOrderDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [insuranceId, setInsuranceId] = useState('');
  const [companies, setCompanies] = useState([]);
  const [policyDocument, setPolicyDocument] = useState({
    name: '',
    file: null,
  });

  const downloadPolicyInfo = (e) => {
    e.preventDefault();
    if (policyId) {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      const model = policyType.value === 'mortgage' ? 'mortgage' : 'policy';
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/${model}/${policyId}/objectInsurance`, {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            showModalInfo(response.error, 'error');
          } else if (response) {
            setObjectInsurance(response[0]);
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        });
    }
  };

  const savePolicy = (e) => {
    e.preventDefault();
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const formData = new FormData();
    formData.append('policyDocument', policyDocument.file);
    formData.append('managerIssued', managerIssued.value);
    formData.append('broker', broker ? broker.value : '');
    formData.append('insurance', insurance.value);
    formData.append('policyType', policyType.value);
    formData.append('integration', integration.value);
    formData.append('policyId', policyId);
    formData.append('agentId', agentId);
    formData.append('innerCommission', innerCommission);
    formData.append('outerCommission', outerCommission);
    formData.append('bonusPartner', bonusPartner);
    formData.append('premium', premium);
    formData.append('addToErp', addToErp);
    formData.append('erpId', erpId);
    formData.append('widgetId', widgetId);
    formData.append('insuranceId', insuranceId);
    formData.append('orderDate', format(orderDate, 'yyyy-MM-dd HH:mm:ss'));
    formData.append('startDate', format(startDate, 'yyyy-MM-dd HH:mm:ss'));
    formData.append('endDate', format(endDate, 'yyyy-MM-dd HH:mm:ss'));
    formData.append('bank', bank.value);
    if (integration.value === 'b2b') {
      formData.append('policyDeclaration', policyDeclaration.value);
    }
    if (policyDeclaration?.value === 'middle-office') {
      formData.append('middleOfficeManagerId', middleOfficeAgent.value);
    }
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/manual`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else if (response.id) {
          history.push(`${policyType.value}/${response.id}`);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const getPartnerCommission = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const innerCommissionRubles = (innerCommission * premium) / 100;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/commissions/partner_commission?agentId=${agentId}&integration=${integration.value}&orderDate=${format(orderDate, 'yyyy-MM-dd HH:mm:ss')}&widgetId=${widgetId}&innerCommission=${innerCommissionRubles}&policyId=${policyId}&insurance=${insurance.value}&policyType=${policyType.value}&bank=${bank.value ? bank.value : ''}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          setBonusPartner(response.bonusPercent);
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  useEffect(() => {
    if (managers.length === 0) {
      getManagers().then((response) => {
        setManagers(response.map((manager) => ({ value: manager.id, label: manager['Представление'] })));
      });
    }
  }, []);

  useEffect(() => {
    if (companies.length === 0) {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      getCompanies(token, 'all').then((response) => {
        const companiesOptions = Object.entries(response).map((company) => ({ value: company[0], label: company[1].name }));
        setCompanies(companiesOptions);
      });
    }
    if (banks.length === 0) {
      const token = `Bearer ${localStorage.getItem('id_token')}`;
      getBanks(token).then((response) => {
        const banksOptions = Object.entries(response).map((bank) => ({ value: bank[1].code, label: bank[1].name }));
        setBanks(banksOptions);
      });
    }
  }, []);

  useEffect(() => {
    if (integration.value !== 'b2b') {
      setBroker(false);
    }
  }, [integration]);

  useEffect(() => {
    if (policyType.value !== 'mortgage') {
      setBank({ label: 'Не менять', value: null });
    }
  }, [policyType]);

  useEffect(() => {
    if (!addToErp) {
      setErpId('');
    }
  }, [addToErp]);

  useEffect(() => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/agents/middle_office`, {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setMiddleOfficeAgentsOptions(
          response.map((agent) => ({ value: agent.id, label: `${agent.first_name} ${agent.middle_name} ${agent.last_name}` })),
        );
      });
  }, [policyDeclaration?.value === 'middle-office']);

  useEffect(() => {
    if (policyType && insurance && policyId && agentId && premium && innerCommission && outerCommission && orderDate && integration) {
      getPartnerCommission();
    }
  }, [policyType, policyId, agentId, insurance, bank, innerCommission, premium, outerCommission, widgetId, orderDate, integration]);

  return (
    <>
      <h2>Добавить полис</h2>
      <form onSubmit={savePolicy}>
        <div className="card mb-4">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="policyType">Выберите продукт</label>
                <Select
                  classNamePrefix="react-select"
                  inputId="policyType"
                  className="form-control-custom"
                  name="policyType"
                  placeholder="Тип"
                  noOptionsMessage={() => 'Не найдено'}
                  value={policyType}
                  styles={customStyles}
                  onChange={(e) => setPolicyType(e)}
                  options={[
                    { label: 'КАСКО', value: 'kasko' },
                    { label: 'ОСАГО', value: 'osago' },
                    { label: 'Ипотека', value: 'mortgage' },
                    { value: 'dago', label: 'ДАГО' },
                    { value: 'vzr', label: 'ВЗР' },
                    { value: 'ifl', label: 'ИФЛ' },
                    { value: 'dmsStudent', label: 'ДМС' },
                    { value: 'telemedicine', label: 'Телемедицина' },
                    { value: 'ns', label: 'Несчастный случай' },
                    { value: 'cargoInsurance', label: 'Страхование грузов' },
                    { value: 'infullBroker', label: 'Услуга INFULL - Ипотечный Брокер' },
                    { value: 'infullLizing', label: 'Услуга INFULL - Лизинг' },
                  ]}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="orderDate">Дата оформления</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  required
                  className="form-control"
                  selected={orderDate}
                  onChange={setOrderDate}
                  name="orderDate"
                  id="orderDate"
                  locale={ru}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="policyId">ID черновика</label>
                <input
                  className="form-control"
                  required
                  value={policyId}
                  type="number"
                  id="policyId"
                  name="policyId"
                  placeholder="9999"
                  onBlur={downloadPolicyInfo}
                  onChange={(e) => setPolicyId(e.target.value)}
                />
                {objectInsurance && (
                  <div className="validation-error">
                    {objectInsurance}
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="agentId">Агент</label>
                <AsyncSelect
                  styles={customSelectStyle()}
                  name="agentId"
                  placeholder="Укажите агента"
                  classNamePrefix="react-select"
                  inputId="agentId"
                  onChange={(e) => setAgentId(e.value)}
                  className="form-control-custom"
                  loadOptions={debounce(loadSuggestions(''), 500)}
                  noOptionsMessage={() => 'Не найдено'}
                />
              </div>
            </div>
            {policyType.value === 'mortgage' ? (
              <div className="form-group row">
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="bank">Выберите банк</label>
                  <Select
                    classNamePrefix="react-select"
                    inputId="bank"
                    className="form-control-custom"
                    name="bank"
                    placeholder="Банк>"
                    noOptionsMessage={() => 'Не найдено'}
                    value={bank}
                    styles={customStyles}
                    onChange={(e) => setBank(e)}
                    options={banks}
                  />
                </div>
              </div>
            ) : null}
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="premium">Страховая премия</label>
                <input
                  className="form-control"
                  required
                  value={premium}
                  type="number"
                  id="premium"
                  name="premium"
                  placeholder="9999"
                  onChange={(e) => setPremium(e.target.value)}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="innerCommission">Входящее КВ(%)</label>
                <input
                  className="form-control"
                  required
                  value={innerCommission}
                  type="number"
                  max="100"
                  id="innerCommission"
                  name="innerCommission"
                  placeholder="9999"
                  onChange={(e) => setInnerCommission(e.target.value)}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="outerCommission">КВ агента 1-го уровня (%)</label>
                <input
                  className="form-control"
                  required
                  value={outerCommission}
                  type="number"
                  max="100"
                  id="outerCommission"
                  name="outerCommission"
                  placeholder="9999"
                  onChange={(e) => setOuterCommission(e.target.value)}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="bonusPartner">Бонус партнера (% <b>от входящего КВ</b>)</label>
                <input
                  className="form-control"
                  required
                  value={bonusPartner}
                  type="number"
                  max="100"
                  id="bonusPartner"
                  name="bonusPartner"
                  placeholder="9999"
                  onChange={(e) => setBonusPartner(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="insurance">Страховая компания</label>
                <Select
                  classNamePrefix="react-select"
                  inputId="insurance"
                  name="insurance"
                  placeholder="СК"
                  noOptionsMessage={() => 'Не найдено'}
                  value={insurance}
                  styles={customStyles}
                  onChange={(e) => setInsurance(e)}
                  options={companies}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="insuranceId">Номер полиса</label>
                <input
                  className="form-control"
                  required
                  value={insuranceId}
                  type="text"
                  id="insuranceId"
                  name="insuranceId"
                  placeholder="XXX9999999"
                  onChange={(e) => setInsuranceId(e.target.value)}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label className="d-block" htmlFor="policyDocument">Печатная форма</label>
                <div className="jq-file styler">
                  <div className="jq-file__name">{policyDocument.name}</div>
                  {policyDocument.file
                    ? (<div onClick={() => setPolicyDocument({ name: '', file: null })} className="jq-file__remove" />)
                    : (<div className="jq-file__browse" />)}
                  <input onChange={(e) => setPolicyDocument({ name: e.target.files[0].name, file: e.target.files[0] })} type="file" id="policyDocument" name="policyDocument" className="custom-input-file" />
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="integration">Выберите интеграцию</label>
                <Select
                  classNamePrefix="react-select"
                  inputId="integration"
                  className="form-control-custom"
                  name="integration"
                  placeholder="Тип"
                  noOptionsMessage={() => 'Не найдена'}
                  value={integration}
                  styles={customStyles}
                  onChange={(e) => setIntegration(e)}
                  options={[
                    { label: 'B2B', value: 'b2b' },
                    { label: 'Прямая', value: 'direct' },
                    { label: 'Сравни', value: 'sravni' },
                    { label: 'Ингуру', value: 'inguru' },
                    { label: 'Инсапп', value: 'insapp' },
                    { label: 'Умный полис', value: 'smart' },
                    { label: 'Азур', value: 'azure' },
                  ]}
                />
              </div>
            </div>
            {integration.value === 'b2b' ? (
              <div className="form-group row">
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="policyDeclaration">Тип оформления</label>
                  <Select
                    required={integration.value === 'b2b'}
                    classNamePrefix="react-select"
                    inputId="policyDeclaration"
                    className="form-control-custom"
                    name="policyDeclaration"
                    placeholder="Тип оформления"
                    noOptionsMessage={() => 'Не найдено'}
                    value={policyDeclaration}
                    styles={customStyles}
                    onChange={(e) => setPolicyDeclaration(e)}
                    options={[
                      { label: 'Мидл-офис', value: 'middle-office' },
                      { label: 'Цифровой брокер', value: 'digital-broker' },
                      { label: 'Куратор СК', value: 'curator' },
                      { label: 'Доступ B2B СК', value: 'direct' },
                      { label: 'Виджет', value: 'widget' },
                      { label: 'Передача лидов', value: 'lead' },
                    ]}
                  />
                </div>
                {policyDeclaration?.value === 'middle-office' ? (
                  <div className="col-lg-6 col-sm-12">
                    <label htmlFor="midleOfficeManager">Менеджер мидл-офиса</label>
                    <Select
                      required={policyDeclaration.value === 'middle-office'}
                      classNamePrefix="react-select"
                      inputId="midleOfficeManager"
                      className="form-control-custom"
                      name="policyDeclaration"
                      placeholder="Менеджер"
                      noOptionsMessage={() => 'Не найдено'}
                      value={middleOfficeAgent}
                      styles={customStyles}
                      onChange={(e) => setMiddleOfficeAgent(e)}
                      options={middleOfficeAgentsOptions}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="startDate">Дата начала действия</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  required
                  className="form-control"
                  selected={startDate}
                  onChange={setStartDate}
                  name="startDate"
                  id="startDate"
                  locale={ru}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="endDate">Дата окончания действия</label>
                <DatePicker
                  popperModifiers={{
                    computeStyle: { gpuAcceleration: false },
                  }}
                  required
                  className="form-control"
                  selected={endDate}
                  onChange={setEndDate}
                  name="endDate"
                  id="endDate"
                  locale={ru}
                  showYearDropdown
                  showMonthDropdown
                  dateFormat="dd.MM.yyyy"
                  placeholderText="ДД.ММ.ГГГГ"
                  customInput={
                    <InputMask mask="99.99.9999" inputMode="tel" />
                  }
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="managerIssued">Менеджер оформил</label>
                <Select
                  menuPlacement="top"
                  classNamePrefix="react-select"
                  inputId="managerIssued"
                  name="managerIssued"
                  placeholder="Менеджер"
                  noOptionsMessage={() => 'Не найдено'}
                  value={managerIssued}
                  styles={customStyles}
                  onChange={(e) => setManagerIssued(e)}
                  options={managers}
                />
              </div>
              {integration.value === 'b2b' ? (
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="policyType">Брокер</label>
                  <Select
                    menuPlacement="top"
                    classNamePrefix="react-select"
                    inputId="broker"
                    className="form-control-custom"
                    name="broker"
                    placeholder="Брокер"
                    noOptionsMessage={() => 'Не найдено'}
                    value={broker}
                    styles={customStyles}
                    onChange={(e) => setBroker(e)}
                    options={[
                      { label: 'ИНФУЛЛ Страховой брокер, ООО', value: '4c8a3405-bdcc-11e4-91b9-bc5ff4cd677b' },
                      { label: 'Инфулл (контрагент)', value: '223b2c90-d8ac-11eb-b30a-abaf64e20881' },
                      { label: 'Полис Онлайн (контрагент)', value: '179a1080-7c88-11e9-8676-ae2dff34a4f1' },
                    ]}
                  />
                </div>
              ) : null}
            </div>
            <div className="form-group row">
              <div className="col-lg-3 col-sm-12">
                <label htmlFor="widgetId">ID виджета</label>
                <input
                  className="form-control"
                  value={widgetId}
                  type="number"
                  id="widgetId"
                  name="widgetId"
                  placeholder="9999"
                  onChange={(e) => setWidgetId(e.target.value)}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="form-check custom-checkbox">
                  <input
                    onChange={(e) => setAddToErp(e.target.checked)}
                    checked={addToErp}
                    className="form-check-input checkbox-styled"
                    type="checkbox"
                    name="addToErp"
                    id="addToErp"
                  />
                  <label className="form-check-label" htmlFor="addToErp">
                    Добавить полис в ERP
                  </label>
                </div>
              </div>
            </div>
            {!addToErp ? (
              <div className="form-group row">
                <div className="col-lg-3 col-sm-12">
                  <label htmlFor="erpId">ERP ID</label>
                  <input
                    className="form-control"
                    required
                    value={erpId}
                    type="text"
                    id="erpId"
                    name="erpId"
                    placeholder="000009999"
                    onChange={(e) => setErpId(e.target.value)}
                  />
                </div>
              </div>
            ) : null}
            <button type="submit" className="btn btn-success">Добавить</button>
          </div>
        </div>
      </form>
    </>
  );
}
