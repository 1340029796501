import React, { Component } from 'react';
import ReactTable from 'react-table-6';
import { reactLocalStorage } from 'reactjs-localstorage';
import ReactLoading from 'react-loading';
import {
  customFetch, getThemes, setTableSettings, setTableSorting,
} from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';

const columns = [{
  Header: '№',
  accessor: 'id',
  width: reactLocalStorage.getObject('themesTableSettings').id,
  maxWidth: 70,
}, {
  Header: 'Дата создания',
  accessor: 'created_at',
  width: reactLocalStorage.getObject('themesTableSettings').created_at,
}, {
  Header: 'Домен',
  accessor: 'domain',
  width: reactLocalStorage.getObject('themesTableSettings').domain,
}, {
  Header: 'Агент',
  accessor: 'agent',
  width: reactLocalStorage.getObject('themesTableSettings').agent,
}, {
  Header: 'Куратор',
  accessor: 'curator',
  width: reactLocalStorage.getObject('themesTableSettings').curator,
}, {
  Header: 'Заголовок',
  accessor: 'title',
  width: reactLocalStorage.getObject('themesTableSettings').title,
}, {
  Header: 'E-mail',
  accessor: 'email',
  width: reactLocalStorage.getObject('themesTableSettings').email,
}, {
  Header: 'Телефон',
  accessor: 'phone',
  width: reactLocalStorage.getObject('themesTableSettings').phone,
},
];

class Themes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      themes: [],
      filtered: [],
    };
  }

  componentDidMount() {
    getThemes(false).then((themes) => {
      this.setState({ themes, loading: false });
    });
  }

  onFilteredChangeCustom = (filtered) => {
    const { tab } = this.props;
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  handleCreateTheme = () => {
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { history } = this.props;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/themes`, {
      method: 'post',
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        history.push(`/themes/${response.id}`);
      })
      .catch(() => {
        showModalInfo('Ошибка');
      });
  };

  render() {
    const { history } = this.props;
    const {
      loading, filtered, themes,
    } = this.state;
    let defaultSortingField = 'id';
    let defaultSortingOrder = 'true';
    if (reactLocalStorage.getObject('themesTableSettings').defaultSortingField) {
      defaultSortingField = reactLocalStorage.getObject('themesTableSettings').defaultSortingField;
      defaultSortingOrder = reactLocalStorage.getObject('themesTableSettings').defaultSortingOrder;
    }
    return (
      <>
        <button type="button" className="btn btn-success mb-3" onClick={this.handleCreateTheme}>Создать кастомизацию</button>
        <ReactTable
          getTdProps={(state, rowInfo, column) => {
            if (rowInfo && column.id) {
              return {
                onMouseDown: (e) => {
                  e.preventDefault();
                  if (e.button === 0) {
                    history.push(`themes/${rowInfo.row.id}`);
                  }
                  if (e.button === 1) {
                    window.open(`/themes/${rowInfo.row.id}`, '_blank');
                  }
                },
              };
            } return {};
          }}
          className="table"
          data={themes}
          columns={columns}
          defaultFiltered={filtered}
          onFilteredChange={(data) => this.onFilteredChangeCustom(data)}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          minRows={1}
          defaultSorted={[
            {
              id: defaultSortingField,
              desc: defaultSortingOrder,
            },
          ]}
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'themesTableSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings(newResized, 'themesTableSettings');
          }}
        />
      </>
    );
  }
}

Themes.contextType = ModalMessagesContext;

export default Themes;
