import React, { Component } from 'react';
import ru from 'date-fns/locale/ru';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import queryString from 'query-string';
import { format, parse, parseISO } from 'date-fns';
import { reactLocalStorage } from 'reactjs-localstorage';
import Modal from 'react-responsive-modal';
import MultiRef from 'react-multi-ref';
import ReactExport from 'react-data-export';
import matchSorter from 'match-sorter';
import {
  toLocalDate, customFetch, setTableSorting, setTableSettings7, getDefaultSorting7, sortConversion,
} from '../Utils/Helpers';
import Table from '../Layout/Table';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

let abortController = new AbortController();

class RequestsTable extends Component {
  simulateClick = new MultiRef();

  constructor(props) {
    super(props);
    this.reactTableRef = React.createRef();
    this.state = {
      columns: [],
      excelcolumns: [],
      dataSet: [],
      filtered: [],
      requestsList: [],
      loading: false,
      startDate: null,
      endDate: null,
      activeFilterTab: '1',
      defaultSorted: [{
        id: 'updated_at',
        desc: 'true',
      }],
      openModalTableCustomized: false,
    };
  }

  componentDidMount() {
    const { filtered, defaultSorted } = this.state;
    const { tab } = this.props;
    const urlParams = queryString.parse(window.location.search);
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    let dateFrom = format(parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    let dateTo = format(parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd');
    if (urlParams.dateTab) {
      this.setState({ activeFilterTab: urlParams.dateTab });
    } else {
      this.setState({ activeFilterTab: '1' });
    }
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        if (filter === 'filter_date') {
          const startDate = urlParams[filter].split(' ')[0] !== 'null' ? parse(urlParams[filter].split(' ')[0], 'dd.MM.yyyy', new Date()) : null;
          const endDate = urlParams[filter].split(' ')[1] !== 'null' ? parse(urlParams[filter].split(' ')[1], 'dd.MM.yyyy', new Date()) : null;
          dateFrom = startDate !== null ? format(startDate, 'yyyy-MM-dd') : null;
          dateTo = endDate !== null ? format(endDate, 'yyyy-MM-dd') : null;
          this.setState({
            startDate,
            endDate,
          });
        } else {
          filtered.push({ id: filter, value: urlParams[filter] });
        }
      });
    }
    this.setState({ filtered }, () => {
      this.loadRequests(dateFrom, dateTo);
    });
    const newDefaultSorted = getDefaultSorting7('requestListSettings', defaultSorted);
    this.setState({ defaultSorted: newDefaultSorted });
    this.updateTableColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { openModalTableCustomized } = this.state;
    const { type } = this.props;
    if ((openModalTableCustomized !== prevState.openModalTableCustomized && !openModalTableCustomized) || type !== prevProps.type) {
      this.updateTableColumns();
    }
  }

  componentWillUnmount() {
    abortController.abort();
  }

  updateTableColumns = () => {
    const {
      show, type, userInfo,
    } = this.props;
    const columns = [{
      Header: '№',
      accessor: 'id',
      minWidth: 70,
      width: reactLocalStorage.getObject('requestListSettings').id,
      show: reactLocalStorage.getObject('requestListSettings').show_id !== false,
      showCustomized: true,
    }, {
      Header: 'Наименование виджета',
      accessor: 'widget_name',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').widget_name,
      show: reactLocalStorage.getObject('requestListSettings').show_widget_name !== false,
      showCustomized: true,
    }, {
      Header: '№ Виджета',
      accessor: 'widget_id',
      minWidth: 70,
      width: reactLocalStorage.getObject('requestListSettings').widget_id,
      show: reactLocalStorage.getObject('requestListSettings').show_widget_id !== false,
      showCustomized: true,
    }, {
      Header: 'Агент',
      id: 'agent_name',
      minWidth: 150,
      accessor: (d) => `${d.agent.last_name} ${d.agent.first_name} ${d.agent.middle_name}`,
      width: reactLocalStorage.getObject('widgetListSettings').agent_name,
      show: reactLocalStorage.getObject('widgetListSettings').show_agent_name !== false && (show === 'all' || show === 'subagents'),
      showCustomized: show === 'all' || show === 'subagents',
    }, {
      Header: 'Продукт',
      accessor: 'type_insurance',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').type_insurance,
      show: reactLocalStorage.getObject('requestListSettings').show_type_insurance !== false,
      showCustomized: true,
    }, {
      Header: 'Страхователь',
      accessor: 'insuredName',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').insuredName,
      show: reactLocalStorage.getObject('requestListSettings').show_insuredName !== false,
      showCustomized: true,
    }, {
      Header: 'Телефон',
      accessor: 'phone',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').phone,
      show: reactLocalStorage.getObject('requestListSettings').show_phone !== false,
      showCustomized: true,
    }, {
      Header: 'Почта',
      accessor: 'email',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').email,
      show: reactLocalStorage.getObject('requestListSettings').show_email !== false,
      showCustomized: true,
    }, {
      Header: 'Контактное лицо',
      accessor: 'contactName',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').contactName,
      show: reactLocalStorage.getObject('requestListSettings').show_contactName !== false,
      showCustomized: true,
    }, {
      Header: 'Объект страхования',
      accessor: 'object_insurance',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').object_insurance,
      show: reactLocalStorage.getObject('requestListSettings').show_object_insurance !== false,
      showCustomized: true,
    }, {
      Header: 'Регион',
      id: 'region',
      accessor: 'region',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').region,
      show: (reactLocalStorage.getObject('requestListSettings').show_region !== false),
      showCustomized: true,
    }, {
      Header: 'Банк',
      accessor: 'bank',
      minWidth: 150,
      filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['bank'] }),
      filterAll: true,
      width: reactLocalStorage.getObject('requestListSettings').bank,
      show: reactLocalStorage.getObject('requestListSettings').show_bank !== false,
      showCustomized: true,
    }, {
      Header: 'Дата расчета',
      width: reactLocalStorage.getObject('requestListSettings').calculated_date,
      id: 'calculated_date',
      accessor: (d) => (d.calculated_date ? format(toLocalDate(parseISO(d.calculated_date)), 'yyyy-MM-dd, HH:mm:ss') : ''),
      minWidth: 150,
      disableFilters: true,
      show: reactLocalStorage.getObject('requestListSettings').show_calculated_date !== false,
      showCustomized: true,
    }, {
      Header: 'Дата начала действия',
      id: 'begin_date',
      accessor: (d) => (d.begin_date ? format(toLocalDate(parse(d.begin_date, 'dd.MM.yyyy', new Date())), 'yyyy-MM-dd, HH:mm:ss') : ''),
      minWidth: 150,
      disableFilters: true,
      width: reactLocalStorage.getObject('requestListSettings').begin_date,
      show: reactLocalStorage.getObject('requestListSettings').show_begin_date !== false,
      showCustomized: true,
    }, {
      Header: 'Отправлено в CRM',
      accessor: 'inCrm',
      minWidth: 100,
      width: reactLocalStorage.getObject('requestListSettings').inCrm,
      show: reactLocalStorage.getObject('requestListSettings').show_inCrm !== false,
      showCustomized: true,
    }, {
      Header: 'Статус',
      id: 'status',
      minWidth: 100,
      width: reactLocalStorage.getObject('requestListSettings').status,
      accessor: (d) => {
        if (d.status === 'need_help') return 'Нужна помощь';
        if (d.status === 'calculated') return 'Расчет';
        if (d.status === 'ordered') return 'Заказ';
        if (d.status === 'processing') return 'Ждет оплаты';
        if (d.status === 'reserved') return 'Ждет оплаты';
        return 'Черновик';
      },
      Filter: 'selectColumnFilter',
      show: type !== 'draft' && reactLocalStorage.getObject('requestListSettings').show_status !== false,
      showCustomized: type !== 'draft',
    }, {
      Header: 'Partner ID',
      accessor: 'partner_id',
      minWidth: 150,
      width: reactLocalStorage.getObject('requestListSettings').partner_id,
      show: (reactLocalStorage.getObject('requestListSettings').show_partner_id !== false),
      showCustomized: true,
    }, {
      Header: 'Azure',
      minWidth: 100,
      disableFilters: true,
      id: 'azure_conversion_rate',
      accessor: (d) => {
        if (d.azure_conversion_rate) {
          if (d.azure_conversion_rate.all > 0) {
            const percent = (parseFloat(d.azure_conversion_rate.success) / parseFloat(d.azure_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.azure_conversion_rate.success}/${d.azure_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('requestListSettings').show_azure_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'УП',
      minWidth: 100,
      disableFilters: true,
      id: 'smart_conversion_rate',
      accessor: (d) => {
        if (d.smart_conversion_rate) {
          if (d.smart_conversion_rate.all > 0) {
            const percent = (parseFloat(d.smart_conversion_rate.success) / parseFloat(d.smart_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.smart_conversion_rate.success}/${d.smart_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('requestListSettings').show_smart_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Сравни',
      minWidth: 100,
      disableFilters: true,
      id: 'sravni_conversion_rate',
      accessor: (d) => {
        if (d.sravni_conversion_rate) {
          if (d.sravni_conversion_rate.all > 0) {
            const percent = (parseFloat(d.sravni_conversion_rate.success) / parseFloat(d.sravni_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.sravni_conversion_rate.success}/${d.sravni_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('requestListSettings').show_sravni_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Ингуру',
      minWidth: 100,
      disableFilters: true,
      id: 'inguru_conversion_rate',
      accessor: (d) => {
        if (d.inguru_conversion_rate) {
          if (d.inguru_conversion_rate.all > 0) {
            const percent = (parseFloat(d.inguru_conversion_rate.success) / parseFloat(d.inguru_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.inguru_conversion_rate.success}/${d.inguru_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('requestListSettings').show_inguru_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Инсапп',
      minWidth: 100,
      disableFilters: true,
      id: 'insapp_conversion_rate',
      accessor: (d) => {
        if (d.insapp_conversion_rate) {
          if (d.insapp_conversion_rate.all > 0) {
            const percent = (parseFloat(d.insapp_conversion_rate.success) / parseFloat(d.insapp_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.insapp_conversion_rate.success}/${d.insapp_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('requestListSettings').show_insapp_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Прямые интеграции',
      minWidth: 100,
      disableFilters: true,
      id: 'direct_conversion_rate',
      accessor: (d) => {
        if (d.direct_conversion_rate) {
          if (d.direct_conversion_rate.all > 0) {
            const percent = (parseFloat(d.direct_conversion_rate.success) / parseFloat(d.direct_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.direct_conversion_rate.success}/${d.direct_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('requestListSettings').show_direct_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    }, {
      Header: 'Общая',
      minWidth: 100,
      disableFilters: true,
      id: 'total_conversion_rate',
      accessor: (d) => {
        if (d.total_conversion_rate) {
          if (d.total_conversion_rate.all > 0) {
            const percent = (parseFloat(d.total_conversion_rate.success) / parseFloat(d.total_conversion_rate.all)) * 100;
            return `${percent.toFixed(2)}% (${d.total_conversion_rate.success}/${d.total_conversion_rate.all})`;
          }
        }
        return '-';
      },
      sortMethod: (a, b) => sortConversion(a, b),
      show: (userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1) && reactLocalStorage.getObject('requestListSettings').show_total_conversion_rate !== false,
      showCustomized: userInfo.role === 'admin' || userInfo.role === 'superadmin' || userInfo.full_time_employee === 1,
    },
    ];
    this.setState({ columns });
  };

  downloadReport = () => {
    const { columns } = this.state;
    const { ExcelColumn } = ReactExport.ExcelFile;
    const { filteredData } = this.reactTableRef.current.getTableData();
    const dataToDownload = [];
    for (let index = 0; index < filteredData.length; index += 1) {
      const recordToDownload = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex += 1) {
        if (columns[colIndex].show !== false) {
          if (filteredData[index].values[columns[colIndex].accessor]) {
            recordToDownload[columns[colIndex].Header] = filteredData[index].values[columns[colIndex].accessor];
          } else {
            recordToDownload[columns[colIndex].Header] = filteredData[index].values[columns[colIndex].id];
          }
        }
      }
      dataToDownload.push(recordToDownload);
    }
    const excelcolumns = columns.filter((column) => {
      return column.show !== false;
    }).map((column) => <ExcelColumn label={column.Header} value={column.Header} />);

    this.setState({ excelcolumns, dataSet: dataToDownload }, function () {
      this.excelFile.handleDownload();
    });
  };

  loadRequests = (dateFrom, dateTo) => {
    abortController.abort();
    abortController = new AbortController();
    const { show } = this.props;
    this.setState({ loading: true });
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policies/requests-list?&dateFrom=${dateFrom}&dateTo=${dateTo}&show=${show}`, {
      method: 'get',
      signal: abortController.signal,
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((requests) => {
        requests.forEach((r) => {
          let formData;
          if (r.policy_type.code === 'kbm') {
            formData = JSON.parse(r.form_data);
            r.calculated_date = r.created_at;
            r.object_insurance = '-';
            r.insuredName = `${formData.lastName} ${formData.firstName} ${formData.middleName}`;
            r.contactName = r.insuredName;
            r.region = formData.address ? formData.address.data.region_with_type : '';
            r.phone = formData.contactPhone;
            r.email = formData.contactEmail;
            r.begin_date = '';
            r.type_insurance = r.policy_type.name;
            r.widget_name = r.widget.name;
          } else if (r.policy_type.code === 'osago') {
            formData = r.form_data;
            r.object_insurance = `${formData.markName} ${formData.modelName}`;
            r.insuredName = `${formData.lastNameInsured} ${formData.firstNameInsured} ${formData.middleNameInsured}`;
            r.contactName = formData.contactFirstName && formData.contactLastName
              ? `${formData.contactLastName} ${formData.contactFirstName}` : r.insuredName;
            r.type_insurance = r.policy_type.name;
            r.widget_name = r.widget.name;
            r.begin_date = `${formData.policyDate}`;
            r.phone = formData.contactPhone ? `${formData.contactPhone}` : `${formData.phone}`;
            r.email = formData.contactEmail ? `${formData.contactEmail}` : `${formData.email}`;
            r.region = formData.ownerAddressData ? formData.ownerAddressData.region_with_type : '';
            r.widget_name = r.widget.name;
          } else if (r.policy_type.code === 'dkp') {
            formData = r.form_data;
            r.object_insurance = formData.carMark.label !== '' && formData.carModel.label !== '' ? `${formData.carMark.label} ${formData.carModel.label}` : '-';
            r.seller = formData.sellerLastName !== ''
            && formData.sellerFirstName !== ''
            && formData.sellerMiddleName !== '' ? `${formData.sellerLastName} ${formData.sellerFirstName} ${formData.sellerMiddleName}` : '-';
            r.buyer = formData.buyerLastName !== ''
            && formData.buyerFirstName !== ''
            && formData.buyerMiddleName !== '' ? `${formData.buyerLastName} ${formData.buyerFirstName} ${formData.buyerMiddleName}` : '-';
            r.phone = formData.buyerPhone || formData.sellerPhone;
            r.email = formData.buyerEmail || formData.sellerEmail;
            r.contactName = r.seller || r.buyer;
            r.widget_name = r.widget.name;
            r.region = formData.buyerAddress.value ? formData.buyerAddress.data.region_with_type : '';
          } else if (r.policy_type.code === 'dmsStudent') {
            formData = JSON.parse(r.form_data);
            r.calculated_date = r.order_date;
            r.object_insurance = '-';
            r.insuredName = `${formData.lastName} ${formData.firstName} ${formData.middleName}`;
            r.contactName = r.insuredName;
            r.region = formData.address ? formData.address.data.region_with_type : '';
            r.phone = formData.contactPhone;
            r.email = formData.contactEmail;
            r.begin_date = formData.startDate.substring(0, 10).split('-').reverse().join('.');
            r.type_insurance = r.policy_type.name;
            r.widget_name = r.widget.name;
          } else if (r.type_insurance === 'mortgage') {
            formData = r.form_data;
            r.calculated_date = r.order_date;
            if (formData.lastName) {
              r.insuredName = `${formData.lastName}`;
            }
            if (formData.firstName) {
              r.insuredName = `${r.insuredName} ${formData.firstName}`;
            }
            if (formData.middleName && r.insuredName) {
              r.insuredName = `${r.insuredName} ${formData.middleName}`;
            }
            r.contactName = r.insuredName;
            r.region = formData.insuredAddress ? formData.insuredAddress.data.region_with_type : '';
            r.phone = formData.contactPhone;
            r.email = formData.contactEmail;
            r.bank = formData.bank.label;
            r.begin_date = formData.startDate ? formData.startDate.substring(0, 10).split('-').reverse().join('.') : '';
            r.widget_name = r.widget.name;
            switch (r.product_insurance) {
              case 'mortgage':
                r.type_insurance = 'Ипотека.Имущество';
                r.object_insurance = formData.buildingSquare ? `${formData.buildingType.label}, ${formData.buildingSquare} м2` : `${formData.buildingType.label}`;
                break;
              case 'mortgageLife':
                r.object_insurance = r.insuredName ? `${r.insuredName} (жизнь и здоровье)` : 'Жизнь и здоровье';
                r.type_insurance = 'Ипотека.Жизнь';
                break;
              case 'mortgageComplex':
                r.type_insurance = 'Ипотека.Комплексное страхование';
                r.object_insurance = formData.buildingSquare ? `${formData.buildingType.label}, ${formData.buildingSquare} м2` : `${formData.buildingType.label}`;
                break;
              default:
                break;
            }
          } else {
            r.widget_name = r.widget.name;
            formData = r.form_data;
            if (formData.regularCarMarkId.label !== '' && formData.regularCarModelId.label !== '') {
              r.object_insurance = `${formData.regularCarMarkId.label} ${formData.regularCarModelId.label}`;
            } else if (formData.otherCarMarkId !== '' && formData.otherCarModelId !== '') {
              r.object_insurance = `${formData.otherCarMarkId} ${formData.otherCarModelId}`;
            } else {
              r.object_insurance = '-';
            }
            r.insuredName = `${formData.lastName} ${formData.firstName} ${formData.middleName}`;
            r.contactName = r.insuredName;
            r.type_insurance = r.policy_type.name;
            r.widget_name = r.widget.name;
            r.begin_date = formData.policyDate;
            r.phone = formData.phone;
            r.email = formData.email;
            r.region = formData.territory.label;
          }
          const inCrmArr = [];
          if (r.bitrix24_lead_id) {
            inCrmArr.push('Bitrix24');
          }
          if (r.amocrm_lead_id) {
            inCrmArr.push('AmoCRM');
          }
          if (inCrmArr.length > 0) {
            r.inCrm = inCrmArr.join('/');
          } else {
            r.inCrm = '-';
          }
        });
        this.setState({ requestsList: requests, loading: false });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          const { showModalInfo } = this.context;
          showModalInfo('Ошибка', 'error');
          this.setState({ loading: false });
        }
      });
  };

  onFilteredChangeCustom = (filtered) => {
    const { startDate } = this.state;
    const { tab } = this.props;
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        if (filter.id === 'filter_date' && startDate === null) {
          queryParams = `${queryParams + filter.id}=null ${filter.value}`;
        } else {
          queryParams = `${queryParams + filter.id}=${filter.value}`;
        }
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  toggleFilter = (tab) => {
    this.setState({ requestsList: [] }, () => {
      const { activeFilterTab, filtered } = this.state;
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      let lastButOneMonth = date.getMonth();
      let currentYear = date.getFullYear();
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
      let lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
      const startDateCurrentMonth = parse(`01.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      const endDateCurrentMonth = parse(`${lastDayOfMonth}.${currentMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
      let dateFrom;
      let dateTo;
      if (activeFilterTab !== tab) {
        this.setState({ activeFilterTab: tab });
        let filterDate = null;
        if (tab === '1') {
          dateFrom = format(startDateCurrentMonth, 'yyyy-MM-dd');
          dateTo = format(endDateCurrentMonth, 'yyyy-MM-dd');
          this.setState({
            startDate: startDateCurrentMonth,
            endDate: endDateCurrentMonth,
          });
          filterDate = `01.${currentMonth}.${currentYear} ${lastDayOfMonth}.${currentMonth}.${currentYear}`;
        } else if (tab === '2') {
          if (currentMonth === 1) {
            currentYear -= 1;
            lastButOneMonth = '12';
            lastDayOfLastButOneMonth = new Date(currentYear, lastButOneMonth, 0).getDate();
            startDateLastButOneMonth = parse(`01.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
            endDateLastButOneMonth = parse(`${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`, 'dd.MM.yyyy', new Date());
          }
          dateFrom = format(startDateLastButOneMonth, 'yyyy-MM-dd');
          dateTo = format(endDateLastButOneMonth, 'yyyy-MM-dd');
          this.setState({
            startDate: startDateLastButOneMonth,
            endDate: endDateLastButOneMonth,
          });
          filterDate = `01.${lastButOneMonth}.${currentYear} ${lastDayOfLastButOneMonth}.${lastButOneMonth}.${currentYear}`;
        } else if (tab === '3') {
          dateFrom = null;
          dateTo = null;
          this.setState({
            startDate: null,
            endDate: date,
          });
          filterDate = `${format(date, 'dd.MM.yyyy')}`;
        }
        const newFiltered = filtered;
        let findedIndex = -1;
        let findedIndexDateTab = -1;
        newFiltered.forEach((filter, i) => {
          if (filter.id === 'filter_date') {
            findedIndex = i;
          }
          if (filter.id === 'dateTab') {
            findedIndexDateTab = i;
          }
        });
        if (findedIndex !== -1) {
          if (filterDate === null) {
            newFiltered.splice(findedIndex, 1);
          } else {
            newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
          }
        } else if (filterDate !== null) {
          newFiltered.push({ id: 'filter_date', value: filterDate });
        }
        if (findedIndexDateTab !== -1) {
          newFiltered[findedIndexDateTab] = { id: 'dateTab', value: tab };
        } else {
          newFiltered.push({ id: 'dateTab', value: tab });
        }
        let queryParams = '?';
        newFiltered.forEach((filter, key, arr) => {
          if (filter.id !== 'activeTab') {
            if (filter.id === 'filter_date' && tab === '3') {
              queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            } else {
              queryParams = `${queryParams + filter.id}=${filter.value}`;
            }
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        });
        queryParams = `${queryParams}&activeTab=${this.props.tab}`;
        const { history } = this.props;
        history.push({ search: queryParams });
        this.setState({ filtered: newFiltered });
        this.loadRequests(dateFrom, dateTo);
      }
    });
  };

  handleChangeStart = (date) => {
    this.setState({ requestsList: [] }, () => {
      const { endDate, filtered } = this.state;
      this.setState({ startDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateTo = null;
      const dateFrom = format(date, 'yyyy-MM-dd');
      if (endDate !== null) {
        filterDate = `${format(date, 'dd.MM.yyyy')} ${format(endDate, 'dd.MM.yyyy')}`;
        dateTo = format(endDate, 'yyyy-MM-dd');
      }
      this.loadRequests(dateFrom, dateTo);
      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (endDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=${filter.value} null`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      const { tab, history } = this.props;
      queryParams = `${queryParams}&activeTab=${tab}`;
      history.push({ search: queryParams });
    });
  };

  handleChangeEnd = (date) => {
    this.setState({ requestsList: [] }, () => {
      const { startDate, filtered } = this.state;
      this.setState({ endDate: date });
      let filterDate = format(date, 'dd.MM.yyyy');
      let dateFrom = null;
      const dateTo = format(date, 'yyyy-MM-dd');
      if (startDate !== null) {
        filterDate = `${format(startDate, 'dd.MM.yyyy')} ${format(date, 'dd.MM.yyyy')}`;
        dateFrom = format(startDate, 'yyyy-MM-dd');
      }
      this.loadRequests(dateFrom, dateTo);
      const newFiltered = filtered;
      let findedIndex = -1;
      newFiltered.forEach((filter, i) => {
        if (filter.id === 'filter_date') {
          findedIndex = i;
        }
      });
      if (findedIndex !== -1) {
        newFiltered[findedIndex] = { id: 'filter_date', value: filterDate };
      } else {
        newFiltered.push({ id: 'filter_date', value: filterDate });
      }
      let queryParams = '?';
      newFiltered.forEach((filter, key, arr) => {
        if (filter.id !== 'activeTab') {
          if (startDate === null && filter.id === 'filter_date') {
            queryParams = `${queryParams + filter.id}=null ${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          } else {
            queryParams = `${queryParams + filter.id}=${filter.value}`;
            if (!Object.is(arr.length - 1, key)) {
              queryParams = `${queryParams}&`;
            }
          }
        }
      });
      const { tab, history } = this.props;
      queryParams = `${queryParams}&activeTab=${tab}`;
      history.push({ search: queryParams });
    });
  };

  customizeTable = () => {
    this.setState({ openModalTableCustomized: true });
  };

  onCloseModalTableCustomized = () => {
    this.setState({ openModalTableCustomized: false });
  };

  handleTableCustomizedSetAll = () => {
    this.simulateClick.map.forEach((input) => {
      if (!input.checked) {
        input.click();
      }
    });
  };

  handleTableCustomizedSetNone = () => {
    this.simulateClick.map.forEach((input) => {
      if (input.checked) {
        input.click();
      }
    });
  };

  handleSubmitTableCustomized = (e) => {
    e.preventDefault();
    const columns = Array.from(e.target.elements).slice(0, -1);
    const settings = reactLocalStorage.getObject('requestListSettings');
    columns.forEach((column) => {
      settings[`show_${column.name}`] = column.checked;
    });
    reactLocalStorage.setObject('requestListSettings', settings);
    this.setState({ openModalTableCustomized: false });
  };

  onTableCellClick = (e, row, cell) => {
    e.preventDefault();
    if (!cell.column.clickable) {
      if (e.button === 0) {
        this.props.history.push(`/${row.original.policy_type.code}/${row.original.id}`);
      }
      if (e.button === 1) {
        window.open(`/${row.original.policy_type.code}/${row.original.id}`, '_blank');
      }
    }
  };

  render() {
    const {
      requestsList,
      loading,
      activeFilterTab,
      startDate,
      endDate,
      openModalTableCustomized,
      columns,
      dataSet,
      excelcolumns,
      defaultSorted,
    } = this.state;

    const customizedColumns = columns.map((column) => {
      const id = column.id ? column.id : column.accessor;
      if (column.showCustomized) {
        return (
          <li key={id} className="form-check">
            <input ref={this.simulateClick.ref(id)} name={id} defaultChecked={column.show} type="checkbox" className="form-check-input" id={id} />
            <label className="form-check-label" htmlFor={id}>{column.Header}</label>
          </li>
        );
      }
      return null;
    });
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalTableCustomized}
          onClose={this.onCloseModalTableCustomized}
          center
        >
          <h4>Настройка отображения таблицы</h4>
          <p onClick={this.handleTableCustomizedSetAll} className="text-muted select-button d-inline-block mr-2">Выбрать все</p>
          <p onClick={this.handleTableCustomizedSetNone} className="text-muted select-button d-inline-block">Отменить все</p>
          <form ref={this.customizeTableRef} onSubmit={this.handleSubmitTableCustomized} id="tableCustomized">
            <ul className="two-column-list">
              {customizedColumns}
            </ul>
            <button type="submit" className="btn btn-success">Сохранить</button>
          </form>
        </Modal>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button type="button">Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="dateFilter">
          <div className="d-inline-block mb-2">
            <div
              onClick={() => { this.toggleFilter('1'); }}
              className={classnames({ active: activeFilterTab === '1' }, 'dateFilter-preset')}
            >
              Текущий месяц
            </div>
            <div
              onClick={() => { this.toggleFilter('2'); }}
              className={classnames({ active: activeFilterTab === '2' }, 'dateFilter-preset')}
            >
              Прошлый месяц
            </div>
            <div
              onClick={() => { this.toggleFilter('3'); }}
              className={classnames({ active: activeFilterTab === '3' }, 'dateFilter-preset')}
            >
              Все время
            </div>
          </div>
          <div className="d-inline-block mb-2">
            <span>Точные даты с</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={startDate}
              className="form-control"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeStart}
              maxDate={endDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
          <div className="d-inline-block">
            <span>по</span>
            <DatePicker
              popperModifiers={{
                computeStyle: { gpuAcceleration: false },
              }}
              selected={endDate}
              className="form-control"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              onChange={this.handleChangeEnd}
              minDate={startDate}
              showYearDropdown
              showMonthDropdown
              locale={ru}
              dateFormat="dd.MM.yyyy"
              placeholderText="ДД.ММ.ГГГГ"
              customInput={
                <InputMask mask="99.99.9999" inputMode="tel" />
              }
            />
          </div>
        </div>
        <Table
          ref={this.reactTableRef}
          data={requestsList}
          columns={columns}
          onTableCellClick={this.onTableCellClick}
          loading={loading}
          noDataText="Лиды не найдены"
          defaultFiltered={this.state.filtered}
          defaultSorted={defaultSorted}
          onSortedChange={(newSort) => {
            setTableSorting(newSort, 'requestListSettings');
          }}
          onResizedChange={(newResized) => {
            setTableSettings7(newResized, 'requestListSettings');
          }}
          onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
        />
      </>
    );
  }
}

RequestsTable.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(RequestsTable);
