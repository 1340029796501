import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import Modal from 'react-responsive-modal';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import {
  TabContent, TabPane,
} from 'reactstrap';
import { customFetch } from '../Utils/Helpers';
import InsuranceProgramBaseSettings from './InsuranceProgramBaseSettings';
import InsuranceProgramOptions from './InsuranceProgramOptions';
import InsuranceProgramRisks from './InsuranceProgramRisks';
import InsuranceProgramSales from './InsuranceProgramSales';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import LinkBreadcrumbs from '../Layout/LinkBreadcrumbs';

export default function InsuranceProgram(props) {
  const {
    program,
    setProgram,
    insurancesList,
    insuranceTypesList,
    insuranceProductList,
    customStyles,
    history,
  } = props;

  const costTypeOptions = [
    { value: 'rub', label: 'руб.' },
    { value: 'percent', label: '%' },
  ];

  const periodOptions = [
    { value: 1, label: '1 месяц' },
    { value: 2, label: '2 месяца' },
    { value: 3, label: '3 месяца' },
    { value: 4, label: '4 месяца' },
    { value: 5, label: '5 месяцев' },
    { value: 6, label: '6 месяцев' },
    { value: 7, label: '7 месяцев' },
    { value: 8, label: '8 месяцев' },
    { value: 9, label: '9 месяцев' },
    { value: 10, label: '10 месяцев' },
    { value: 11, label: '11 месяцев' },
    { value: 12, label: '12 месяцев' },
  ];

  const { showModalInfo } = useContext(ModalMessagesContext);

  const [flagModalSure, setFlagModalSure] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [insurancesOptions, setInsurancesOptions] = useState([]);
  const [statusList] = useState([
    { label: 'Неактивная', value: 'inactive' },
    { label: 'Активная', value: 'active' },
  ]);
  const [clearSelectedInsuranceFlag, setClearSelectedInsuranceFlag] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);
  const [risksList, setRisksList] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [loadingNameOptions, setLoadingNameOptions] = useState(false);
  const [goToSaveFlag, setGoToSaveFlag] = useState('');
  const [savingProgramFlag, setSavingProgramFlag] = useState(false);
  const [programData, setProgramData] = useState({
    name: {
      value: null,
      validationRequired: true,
      errorMessage: '',
      validationType: 'select',
    },
    insuranceType: {
      value: null,
      validationRequired: true,
      errorMessage: '',
      validationType: 'select',
    },
    insuranceProduct: {
      value: null,
      validationRequired: true,
      errorMessage: '',
      validationType: 'select',
    },
    costType: {
      value: null,
      validationRequired: true,
      errorMessage: '',
      validationType: 'select',
    },
    cost: {
      value: 0,
      validationRequired: true,
      errorMessage: '',
      validationType: 'decimal',
    },
    status: {
      value: null,
      validationRequired: true,
      errorMessage: '',
      validationType: 'select',
    },
    hasRisks: {
      value: false,
      errorMessage: '',
      validationRequired: false,
      validationType: 'checkbox',
    },
    hasOptions: {
      value: false,
      errorMessage: '',
      validationRequired: false,
      validationType: 'checkbox',
    },
    hasSales: {
      value: false,
      errorMessage: '',
      validationRequired: false,
      validationType: 'checkbox',
    },
    country: {
      value: [],
      errorMessage: '',
      validationRequired: false,
      validationType: 'multiSelect',
    },
    insurance: {
      value: null,
      validationRequired: true,
      errorMessage: '',
      validationType: 'select',
    },
  });

  const documentsRef = useRef(null);
  const optionsRef = useRef(null);
  const salesRef = useRef(null);
  const risksRef = useRef(null);
  const baseSettingsRef = useRef(null);

  const delProgram = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-program/${program.id}`, {
      method: 'delete',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setProgram({});
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const loadProgramInfoLists = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-programs/info-lists?programId=${program.id}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.error) {
          data.documents.forEach((document) => {
            document.downloaded = true;
          });
          const loadedOptions = data.options.map((option) => ({
            name: {
              value: option.name,
              errorMessage: '',
              validationRequired: true,
              validationType: 'name',
            },
            cost: {
              value: option.cost,
              validationRequired: true,
              errorMessage: '',
              validationType: 'decimal',
            },
            changed: false,
            id: option.id,
          }));

          const loadedRisks = data.risks.map((risk) => ({
            name: {
              value: risk.name,
              errorMessage: '',
              validationRequired: true,
              validationType: 'name',
            },
            cost: {
              value: risk.cost,
              validationRequired: true,
              errorMessage: '',
              validationType: 'decimal',
            },
            changed: false,
            id: risk.id,
          }));

          const loadedSales = data.sales.map((sale) => {
            let param;
            const paramIndex = periodOptions.findIndex((period) => period.value === sale.parameter);
            if (paramIndex !== -1) {
              param = periodOptions[paramIndex];
            } else {
              param = periodOptions[0];
            }
            return {
              name: {
                value: sale.name,
                errorMessage: '',
                validationRequired: true,
                validationType: 'name',
              },
              sale: {
                value: sale.sale,
                validationRequired: true,
                errorMessage: '',
                validationType: 'percent',
              },
              parameter: {
                value: param,
                validationRequired: true,
                errorMessage: '',
                validationType: 'select',
              },
              changed: false,
              id: sale.id,
            };
          });
          setOptionsList(loadedOptions);
          setRisksList(loadedRisks);
          setDocumentsList(data.documents);
          setSalesList(loadedSales);
        } else {
          showModalInfo(data.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      });
  };

  const addQueryParams = (urlParams) => {
    let queryParams = '?';
    Object.entries(urlParams).forEach(([key, value]) => {
      if (key !== 'activeProgramTab' && key !== '') {
        if (queryParams === '?') {
          queryParams = `${queryParams}${key}=${value}`;
        } else {
          queryParams = `${queryParams}&${key}=${value}`;
        }
      }
    });
    queryParams = `${queryParams}&activeProgramTab=${activeTab}`;
    history.push({ search: queryParams });
  };

  const loadProgramData = () => {
    let insuranceProductValue = { value: null, label: null };
    if (program.type_insurance.code === 'mortgage' && insuranceProductList.mortgage) {
      const mortgageProductList = insuranceProductList.mortgage;
      const programInsuranceProductIndex = mortgageProductList.findIndex((option) => option.value === program.product_insurance);
      insuranceProductValue = programInsuranceProductIndex !== -1 ? mortgageProductList[programInsuranceProductIndex] : null;
    }
    const programInsuranceTypeIndex = insuranceTypesList.findIndex((option) => option.value === program.type_insurance.code);
    const costTypeIndex = costTypeOptions.findIndex((option) => option.value === program.cost_type);
    const programStatusIndex = statusList.findIndex((option) => option.value === program.status);

    let countryArray = [];
    if (typeof program.country === 'string') {
      countryArray = JSON.parse(program.country);
    }

    setProgramData((prev) => ({
      ...prev,
      name: {
        value: program.name ? { value: program.name.toLowerCase(), label: program.name } : null,
        errorMessage: '',
        validationRequired: true,
        validationType: 'select',
      },
      insuranceType: {
        value: programInsuranceTypeIndex !== -1 ? insuranceTypesList[programInsuranceTypeIndex] : null,
        validationRequired: true,
        errorMessage: '',
        validationType: 'select',
      },
      insuranceProduct: {
        value: insuranceProductValue,
        validationRequired: true,
        errorMessage: '',
        validationType: 'select',
      },
      costType: {
        value: costTypeIndex !== -1 ? costTypeOptions[costTypeIndex] : null,
        validationRequired: true,
        errorMessage: '',
        validationType: 'select',
      },
      cost: {
        value: program.cost ? program.cost : 0,
        validationRequired: true,
        errorMessage: '',
        validationType: program.cost_type && program.cost_type === 'percent' ? 'decimalPercent' : 'decimal',
      },
      status: {
        value: programStatusIndex !== -1 ? statusList[programStatusIndex] : null,
        validationRequired: true,
        errorMessage: '',
        validationType: 'select',
      },
      hasRisks: {
        value: program.has_risks,
        errorMessage: '',
        validationRequired: false,
        validationType: 'checkbox',
      },
      hasOptions: {
        value: program.has_options,
        errorMessage: '',
        validationRequired: false,
        validationType: 'checkbox',
      },
      hasSales: {
        value: program.has_sales,
        errorMessage: '',
        validationRequired: false,
        validationType: 'checkbox',
      },
      country: {
        value: countryArray,
        errorMessage: '',
        validationRequired: false,
        validationType: 'multiSelect',
      },
      insurance: {
        value: null,
        validationRequired: true,
        errorMessage: '',
        validationType: 'select',
      },
    }));
  };

  const loadProgramsNames = () => {
    setLoadingNameOptions(true);
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const programType = programData.insuranceType.value && programData.insuranceType.value.value ? programData.insuranceType.value.value : program.type_insurance;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/insurance-programs/get-names/${programType}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const newOptions = response.map((name) => ({
            label: name,
            value: name.toLowerCase(),
          }));
          if (programData.name.value && programData.name.value.label && newOptions.findIndex((option) => option.label === programData.name.value.label) === -1) {
            newOptions.unshift(programData.name.value);
          }
          setNameOptions(newOptions);
        } else {
          showModalInfo(response.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoadingNameOptions(false);
      });
  };

  const handleSaveProgram = (e) => {
    e.preventDefault();

    switch (activeTab) {
      case '1':
        baseSettingsRef.current.validateFields();
        break;
      case '2':
        optionsRef.current.save();
        break;
      case '3':
        risksRef.current.save();
        break;
      case '4':
        salesRef.current.save();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (programData.insuranceType.value
      && programData.insuranceType.value.value === 'mortgage'
      && insuranceProductList.mortgage
      && insuranceProductList.mortgage.length) {
      if (programData.insuranceProduct.value
          && programData.insuranceProduct.value.value === null) {
        setProgramData((prev) => ({
          ...prev,
          insuranceProduct: {
            ...prev.insuranceProduct,
            errorMessage: '',
            validationRequired: false,
            value: insuranceProductList.mortgage[0],
          },
        }));
      }
    } else {
      setProgramData((prev) => ({
        ...prev,
        insuranceProduct: {
          ...prev.insuranceProduct,
          errorMessage: '',
          validationRequired: false,
          value: { label: null, value: null },
        },
      }));
    }
  }, [programData.insuranceType.value]);

  useEffect(() => {
    if (goToSaveFlag) {
      switch (goToSaveFlag) {
        case 'baseSettings':
          baseSettingsRef.current.save();
          break;
        default:
          break;
      }
      setGoToSaveFlag('');
    }
  }, [goToSaveFlag]);

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    if (!('activeProgramTab' in urlParams) || (parseInt(urlParams.activeProgramTab, 10) !== activeTab)) {
      addQueryParams(urlParams);
    }
  }, [activeTab]);

  useEffect(() => {
    const urlParams = queryString.parse(window.location.search);
    if (program.id && 'activeProgramTab' in urlParams) {
      setActiveTab(urlParams.activeProgramTab);
    }
  }, [program.id]);

  useEffect(() => {
    let filteredInsuranceList = [];
    if (programData.insuranceType.value) {
      if (programData.insuranceType.value.value === 'greenCard') {
        filteredInsuranceList = insurancesList.filter((insurance) => insurance.active_green_card).map((insurance) => ({ value: insurance.code, label: insurance.name }));
      } else if (programData.insuranceType.value.value === 'dmsStudent') {
        filteredInsuranceList = insurancesList.filter((insurance) => insurance.active_dms_student).map((insurance) => ({ value: insurance.code, label: insurance.name }));
      } else if (programData.insuranceType.value.value === 'osago') {
        filteredInsuranceList = insurancesList.filter((insurance) => insurance.active).map((insurance) => ({ value: insurance.code, label: insurance.name }));
      } else if (programData.insuranceType.value.value === 'covid') {
        filteredInsuranceList = insurancesList.filter((insurance) => insurance.code === 'alpha').map((insurance) => ({ value: insurance.code, label: insurance.name }));
      } else if (programData.insuranceType.value.value === 'mortgage') {
        filteredInsuranceList = insurancesList.filter((insurance) => insurance.active_mortgage).map((insurance) => ({ value: insurance.code, label: insurance.name }));
      }
    }
    let programInsurance = [];
    if (program.insurance && !clearSelectedInsuranceFlag) {
      programInsurance = filteredInsuranceList[filteredInsuranceList.findIndex((option) => option.value === program.insurance.code)];
    }
    setProgramData((prev) => ({
      ...prev,
      insurance: {
        errorMessage: '',
        validationRequired: true,
        value: programInsurance || null,
      },
    }));
    setInsurancesOptions([...filteredInsuranceList]);
    setClearSelectedInsuranceFlag(false);
  }, [programData.insuranceType.value]);

  useEffect(() => {
    if (program.id) {
      loadProgramData();
      loadProgramInfoLists();
    }
  }, [program.id]);

  useEffect(() => {
    if (program.id && programData.insuranceType.value && programData.insuranceType.value.value) {
      loadProgramsNames();
    }
  }, [programData.insuranceType.value]);

  return (
    <>
      <div className="position-relative">
        <div className="row">
          <div className="col-12">
            <LinkBreadcrumbs onClick={(e) => { e.preventDefault(); setProgram({}); }}>К списку программ страхования</LinkBreadcrumbs>
          </div>
        </div>
        <TabNavList>
          <TabNavItem
            active={activeTab === '1'}
            onClick={() => { setActiveTab('1'); }}
            text="Основные настройки"
          />
          {programData.hasOptions.value ? (
            <TabNavItem
              active={activeTab === '2'}
              onClick={() => { setActiveTab('2'); }}
              text="Доп. опции"
            />
          ) : null}
          {programData.hasRisks.value ? (
            <TabNavItem
              active={activeTab === '3'}
              onClick={() => { setActiveTab('3'); }}
              text="Риски"
            />
          ) : null}
          {programData.hasSales.value ? (
            <TabNavItem
              active={activeTab === '4'}
              onClick={() => { setActiveTab('4'); }}
              text="Скидки"
            />
          ) : null}
        </TabNavList>
        <TabContent animation="false" activeTab={activeTab}>
          <TabPane tabId="1">
            <InsuranceProgramBaseSettings
              program={program}
              customStyles={customStyles}
              insuranceTypesList={insuranceTypesList}
              insurancesOptions={insurancesOptions}
              statusList={statusList}
              setProgram={setProgram}
              documentsList={documentsList}
              setDocumentsList={setDocumentsList}
              costTypeOptions={costTypeOptions}
              nameOptions={nameOptions}
              setNameOptions={setNameOptions}
              programData={programData}
              setProgramData={setProgramData}
              loadingNameOptions={loadingNameOptions}
              documentsRef={documentsRef}
              setSavingProgramFlag={setSavingProgramFlag}
              ref={baseSettingsRef}
              setGoToSaveFlag={setGoToSaveFlag}
              setOptionsList={setOptionsList}
              setRisksList={setRisksList}
              setSalesList={setSalesList}
              insuranceProductList={insuranceProductList}
            />
          </TabPane>
          <TabPane tabId="2">
            <InsuranceProgramOptions
              program={program}
              optionsList={optionsList}
              setOptionsList={setOptionsList}
              ref={optionsRef}
              setSavingProgramFlag={setSavingProgramFlag}
            />
          </TabPane>
          <TabPane tabId="3">
            <InsuranceProgramRisks
              program={program}
              risksList={risksList}
              setRisksList={setRisksList}
              ref={risksRef}
              setSavingProgramFlag={setSavingProgramFlag}
            />
          </TabPane>
          <TabPane tabId="4">
            <InsuranceProgramSales
              salesList={salesList}
              setSalesList={setSalesList}
              ref={salesRef}
              setSavingProgramFlag={setSavingProgramFlag}
              customStyles={customStyles}
              program={program}
              periodOptions={periodOptions}
            />
          </TabPane>
        </TabContent>
        <br />
        {savingProgramFlag ? (
          <div className="form-group row">
            <div className="col-lg-12">
              <ReactLoading className="loading-circle mr-3 mt-3 d-inline-block" type="spin" height={20} width={20} />
            </div>
          </div>
        ) : null}
        <div className="form-group row">
          <div className="col-lg-12">
            <button type="button" disabled={savingProgramFlag} className="btn btn-success mr-3 mb-3" onClick={handleSaveProgram}>Сохранить</button>
            <button type="button" className="btn btn-danger mr-3 mb-3" onClick={() => { setFlagModalSure(true); }}>Удалить программу</button>
          </div>
        </div>
      </div>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={flagModalSure}
        onClose={() => { setFlagModalSure(false); }}
        center
      >
        <h4>Вы уверены?</h4>
        <button className="btn btn-success mr-2" type="button" onClick={delProgram}>Да</button>
        <button type="button" className="btn btn-secondary" onClick={() => { setFlagModalSure(false); }}>Нет</button>
      </Modal>
    </>
  );
}
