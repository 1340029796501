import React, {
  useContext, useEffect, useState,
} from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import { faCircle } from '@fortawesome/fontawesome-free-solid';
import { format, parseISO } from 'date-fns';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch, getDefaultSorting7, onFilteredChangeCustom, setTableSettings7, setTableSorting,
} from '../../Utils/Helpers';
import Table from '../../Layout/Table';

export default function PartnerProgramExclusionTable(props) {
  const {
    agent,
    setTableSelectedExclusion,
    setShowForm,
    exclusions,
    getExclusions,
  } = props;
  const defaultSorting = getDefaultSorting7('exclusionsTableSettings');
  const [filtered, setFiltered] = useState([{ id: 'active', value: 'true' }]);
  const [columns, setColumns] = useState([]);
  const { showModalInfo } = useContext(ModalMessagesContext);

  const deleteCustomContract = (id) => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/partnerCommission/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          getExclusions();
          showModalInfo('Исключение удалено');
        } else {
          showModalInfo(response.error);
        }
      })
      .catch((error) => {
        error.customMessage = error.customMessage ? error.customMessage : 'Ошибка';
        showModalInfo(error);
      });
  };

  const updateTableColumns = () => {
    const updatedColumns = [{
      Header: 'ID',
      accessor: 'id',
      maxWidth: 15,
      width: reactLocalStorage.getObject('exclusionsTableSettings').id,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_id !== false,
    }, {
      Header: 'Страховая',
      accessor: (d) => d.contract.insurance.name,
      width: reactLocalStorage.getObject('exclusionsTableSettings').insurance,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_insurance !== false,
    }, {
      Header: 'Продукт',
      accessor: (d) => d.contract.product.name,
      width: reactLocalStorage.getObject('exclusionsTableSettings').product,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_product !== false,
    }, {
      Header: 'Риск',
      accessor: (d) => (d.risk ? d.risk.name : 'Общий'),
      width: reactLocalStorage.getObject('exclusionsTableSettings').risk,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_risk !== false,
    }, {
      Header: 'Тип агента',
      accessor: (d) => (d.agent_type ? d.agent_type.name : 'Для всех'),
      width: reactLocalStorage.getObject('exclusionsTableSettings').agent_type,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_agent_type !== false,
    }, {
      Header: 'Банк/Регион',
      accessor: (d) => (d.bank ? d.bank.name : d.region.name),
      width: reactLocalStorage.getObject('exclusionsTableSettings').bank,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_bank !== false,
    }, {
      Header: 'Логин ключа',
      accessor: (d) => d.contract.login,
      width: reactLocalStorage.getObject('exclusionsTableSettings').login,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_login !== false,
    }, {
      Header: 'Фио агента ключа',
      accessor: (d) => `${d.contract.agent.last_name} ${d.contract.agent.first_name} ${d.contract.agent.middle_name}`,
      width: reactLocalStorage.getObject('exclusionsTableSettings').fio,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_fio !== false,
    }, {
      Header: 'Активность',
      id: 'active',
      Filter: 'selectColumnFilter',
      filterValue: true,
      filterOptions: [
        { value: '', label: 'Без фильтра' },
        { value: true, label: 'Активный' },
        { value: false, label: 'Неактивный' },
      ],
      accessor: (d) => new Date(d.start_date) <= new Date() && (new Date(d.end_date) > new Date() || !d.end_date),
      Cell: ({ row }) => {
        if (new Date(row.original.start_date) <= new Date() && (new Date(row.original.end_date) > new Date() || !row.original.end_date)) {
          return (<FontAwesomeIcon color="#00E646" icon={faCircle} className="fa-fw" />);
        }
        return (<FontAwesomeIcon color="#ff5f00" icon={faCircle} className="fa-fw" />);
      },
      width: reactLocalStorage.getObject('exclusionsTableSettings').active,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_active !== false,
    }, {
      Header: 'Квота агента',
      accessor: (d) => d.commission.franch,
      width: reactLocalStorage.getObject('exclusionsTableSettings').franch,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_franch !== false,
    }, {
      Header: 'Квота партнера',
      accessor: (d) => d.commission.uk,
      width: reactLocalStorage.getObject('exclusionsTableSettings').uk,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_uk !== false,
    }, {
      Header: 'Дата начала',
      accessor: 'start_date',
      Cell: ({ row }) => format(parseISO(row.original.start_date), 'dd.MM.yyyy'),
      width: reactLocalStorage.getObject('exclusionsTableSettings').start_date,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_start_date !== false,
    }, {
      Header: 'Дата окончания',
      accessor: 'end_date',
      Cell: ({ row }) => (row.original.end_date ? format(parseISO(row.original.end_date), 'dd.MM.yyyy') : ''),
      width: reactLocalStorage.getObject('exclusionsTableSettings').end_date,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_end_date !== false,
    }, {
      Header: 'Создано',
      accessor: 'created_at',
      Cell: ({ row }) => format(parseISO(row.original.created_at), 'dd.MM.yyyy'),
      width: reactLocalStorage.getObject('exclusionsTableSettings').created_at,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_created_at !== false,
    }, {
      Header: 'Изменено',
      accessor: 'updated_at',
      Cell: ({ row }) => format(parseISO(row.original.updated_at), 'dd.MM.yyyy'),
      width: reactLocalStorage.getObject('exclusionsTableSettings').updated_at,
      show: reactLocalStorage.getObject('exclusionsTableSettings').show_updated_at !== false,
    }, {
      Header: () => null, // No header
      id: 'delete',
      maxWidth: 10,
      disableFilters: true,
      show: true,
      clickable: true,
      Cell: ({ row }) => (
        <div title="Удалить кастомизацию"><FontAwesomeIcon onClick={() => deleteCustomContract(row.original.id)} icon={faTrashAlt} className="fa-fw" /></div>
      ),
    },
    ];
    setColumns(updatedColumns);
  };

  const onTableCellClick = (e, row, cell) => {
    e.preventDefault();
    if (!cell.column.clickable) {
      setTableSelectedExclusion({
        contract: row.original.custom_contract_id,
        exclusions: row.original.id,
      });
      setShowForm(true);
    }
  };

  useEffect(() => {
    if (agent.id) {
      updateTableColumns();
    }
  }, []);

  return (
    <Table
      onTableCellClick={onTableCellClick}
      defaultSorted={defaultSorting}
      defaultFiltered={filtered}
      data={exclusions}
      columns={columns}
      noDataText="Исключения не найдены"
      onSortedChange={(newSort) => {
        setTableSorting(newSort, 'exclusionsTableSettings');
      }}
      onResizedChange={(newResized) => {
        setTableSettings7(newResized, 'exclusionsTableSettings');
      }}
      onFilteredChange={(newFiltered) => {
        setFiltered(onFilteredChangeCustom(newFiltered, null, 12));
      }}
    />
  );
}
