import React, { Component } from 'react';
import ReactTable from 'react-table-6';
import { parse, parseISO, format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/fontawesome-free-regular';
import matchSorter from 'match-sorter';
import ReactExport from 'react-data-export';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import { customFetch } from '../Utils/Helpers';
import FormNotificationColorLine from '../Layout/FormNotifications/FormNotificationColorLine';

const columns = [{
  Header: 'Продукт',
  id: 'product',
  accessor: (d) => d.product.name,
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['product'] }),
  filterAll: true,
}, {
  Header: 'Страховая компания',
  accessor: 'company',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
  filterAll: true,
}, {
  Header: 'Регион',
  accessor: 'region',
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['region'] }),
  filterAll: true,
}, {
  Header: 'Банк',
  id: 'bank',
  accessor: (d) => (d.bank ? d.bank.name : ''),
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['bank'] }),
  filterAll: true,
}, {
  Header: 'Дата от',
  id: 'date_from',
  accessor: (d) => format(parseISO(d.date_from), 'dd.MM.yyyy'),
  sortMethod: (a, b) => (parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1),
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['date_from'] }),
  filterAll: true,
}, {
  Header: 'Дата до',
  id: 'date_to',
  accessor: (d) => ((d.date_to === null) ? '' : format(parseISO(d.date_to), 'dd.MM.yyyy')),
  sortMethod: (a, b) => {
    if (a === '') a = parseISO('2990-01-01');
    if (b === '') b = parseISO('2990-01-01');
    return parse(a, 'dd.MM.yyyy', new Date()) > parse(b, 'dd.MM.yyyy', new Date()) ? 1 : -1;
  },
  filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['date_to'] }),
  filterAll: true,
}, {
  id: 'price',
  Header: 'Размер вознаграждения, %',
  accessor: (d) => d.price,
}, {
  id: 'status',
  Header: 'Статус',
  filterMethod: (filter, row) => {
    if (filter.value === 'all') {
      return true;
    }
    if (filter.value === 'true') {
      return parseISO(row._original.date_from) <= new Date()
        && (parseISO(row._original.date_to) >= new Date() || row._original.date_to === null);
    }
    return parseISO(row._original.date_from) > new Date()
      || parseISO(row._original.date_to) < new Date();
  },
  Filter: ({ filter, onChange }) =>
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Без фильтра</option>
      <option value="true">Действует</option>
      <option value="false">Недействительно</option>
    </select>,
  accessor: (d) => ((parseISO(d.date_from) <= new Date()
    && (parseISO(d.date_to) >= new Date() || d.date_to === null))
    ? (<><FontAwesomeIcon color="#72c267" icon={faCheckCircle} className="font-awesome-custom-icon fa-fw fa-lg" />Действует</>)
    : (<><FontAwesomeIcon color="#e4502e" icon={faTimesCircle} className="font-awesome-custom-icon fa-fw fa-lg" />Недействительно</>)),
},
];

export default class Reward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [{
        id: 'status',
        value: 'true',
      }],
      commissions: [],
      loading: true,
      excelcolumns: [],
      dataSet: [],
    };
  }

  componentDidMount() {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { userId, location, tab } = this.props;
    const { filtered } = this.state;
    const urlParams = queryString.parse(location.search);
    if ((tab === urlParams.activeTab) || (!urlParams.activeTab && tab === '1')) {
      Object.keys(urlParams).forEach((filter) => {
        filtered.push({ id: filter, value: urlParams[filter] });
      });
      this.setState({ filtered });
    }
    if (userId) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/commissions/${userId}`, {
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((commissions) => {
          this.setState((prevState) => ({
            loading: !prevState.loading,
            commissions: Object.values(commissions),
          }));
        });
    }
  }

  componentDidUpdate(prevProps) {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    const { userId } = this.props;
    if (userId !== prevProps.userId) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/commissions/${userId}`, {
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((commissions) => {
          this.setState((prevState) => ({
            loading: !prevState.loading,
            commissions: Object.values(commissions),
          }));
        });
    }
  }

  onFilteredChangeCustom = (filtered) => {
    this.setState({ filtered });
    let queryParams = '?';
    filtered.forEach((filter, key, arr) => {
      if (filter.id !== 'activeTab') {
        queryParams = `${queryParams + filter.id}=${filter.value}`;
        if (!Object.is(arr.length - 1, key)) {
          queryParams = `${queryParams}&`;
        }
      }
    });
    queryParams = `${queryParams}&activeTab=${this.props.tab}`;
    window.history.replaceState(null, null, queryParams);
  };

  render() {
    const {
      loading,
      commissions,
      dataSet,
      excelcolumns,
    } = this.state;
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ExcelFile;
    return (
      <>
        <div className="d-none">
          <ExcelFile ref={(f) => this.excelFile = f} element={<button type="button">Download Data</button>}>
            <ExcelSheet data={dataSet} name="Employees">
              {excelcolumns}
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div className="form-group">
          <FormNotificationColorLine>
            Условия вознаграждения могут быть изменены, точный размер вознаграждения определяется в момент расчета стоимости полиса и указывается в выдаче результатов.
          </FormNotificationColorLine>
        </div>
        <ReactTable
          ref={(r) => this.reactTable = r}
          className="table"
          defaultFiltered={this.state.filtered}
          onFilteredChange={(filtered) => this.onFilteredChangeCustom(filtered)}
          data={commissions}
          columns={columns}
          previousText="Назад"
          nextText="Вперед"
          loadingText={<ReactLoading className="loading-circle d-inline-block" type="spin" height={37} width={37} />}
          noDataText="Данные не найдены"
          pageText="Страница"
          ofText="Из"
          rowsText="строк"
          loading={loading}
          filterable
          defaultPageSize={50}
          defaultSorted={[
            {
              id: 'date_to',
              desc: true,
            },
          ]}
          minRows={1}
        />
      </>
    );
  }
}
