import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { customFetch } from '../Utils/Helpers';
import {
  ModalMessagesContext,
} from '../Utils/ContextsServices/ModalMessagesService';

export default function RegisterSelfEmployed() {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');
  const { showModalInfo } = useContext(ModalMessagesContext);
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

  useEffect(() => {
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/alpha_bank/registerSelfEmployed`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setStatus(response);
        } else {
          setStatus('при добавлении возникла ошибка. Обратитесь к вашему куратору');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="b-load-yandex-token">
      {loading ? (
        <>
          <h3>Добавление партнера в систему Альфа-Банка...</h3>
          <ReactLoading type="spin" height={70} width={70} className="loading-circle ml-3" />
        </>
      ) : (
        <h3>Статус: {status}</h3>
      )}
    </div>
  );
}
