import React, { useState, useEffect, useContext } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/fontawesome-free-regular';
import Dropzone from 'react-dropzone';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import {
  customFetch, customSelectStyle, getThemes, uploadImage,
} from '../Utils/Helpers';

export default function FaqEditAdd(props) {
  const { history, match } = props;
  const { showModalInfo } = useContext(ModalMessagesContext);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [savingFlag, setSavingFlag] = useState(false);
  const [categoryId, setCategoryId] = useState(2);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [show, setShow] = useState(true);
  const [themeOptions, setThemeOptions] = useState([]);

  const [data, setData] = useState({
    question: '',
    faq_category_id: 1,
    answer: '',
    sort: 1,
    show: '',
    files: [],
    first_level_only: false,
    themes: [],
  });

  const removeFile = (index) => {
    const { files } = data;
    files.splice(index, 1);
    setData((prev) => ({
      ...prev,
      files,
    }));
  };

  const uploadFile = (acceptedFiles) => {
    const newData = { ...data };
    acceptedFiles.forEach((file) => {
      newData.files.push(file);
    });
    setData(newData);
  };

  const showCheckHandler = () => {
    setShow(!show);
  };

  const handleCategoryChange = (e) => {
    setCategoryId(e.value);
  };

  const path = match.params.id ? `faq/${match.params.id}` : 'faq';
  const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

  function getFaq(id) {
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq/${id}`, {
      method: 'get',
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.error) {
          setData(res);
          setCategoryId(res.faq_category_id);
          setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(res.answer))));
          setShow(res.show);
        } else {
          showModalInfo(res.error, 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка при загрузке', 'error');
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSavingFlag(true);
    const existedFiles = [];
    Object.keys(data.files).forEach((file) => {
      existedFiles.push(data.files[file].path);
    });
    const formData = new FormData();
    Object.keys(data.files).forEach((file) => {
      formData.append(file, data.files[file]);
    });
    formData.append('question', data.question);
    formData.append('sort', data.sort);
    formData.append('faq_category_id', categoryId);
    formData.append('answer', JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    formData.append('show', +show);
    formData.append('first_level_only', +data.first_level_only);
    formData.append('existedFiles', JSON.stringify(existedFiles));
    formData.append('themes', JSON.stringify(data.themes));
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/${path}`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.error) {
          history.push('/admin?activeTab=19');
        } else {
          showModalInfo('Ошибка при сохранении', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка при сохранении', 'error');
      })
      .finally(() => {
        setSavingFlag(false);
      });
  };

  const uploadImageFaq = (file) => new Promise((resolve) => {
    const formData = new FormData();
    formData.append('file', file);
    uploadImage(file, formData)
      .then((res) => {
        if (!res.error) {
          resolve({ data: { link: res } });
        } else {
          showModalInfo('Ошибка при загрузке изображения', 'error');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка при загрузке изображения', 'error');
      });
  });

  useEffect(() => {
    if (match.params.id) {
      getFaq(match.params.id);
    }
    if (themeOptions.length === 0) {
      getThemes().then((response) => {
        setThemeOptions(response.map((theme) => ({ value: theme.domain, label: theme.domain })));
      });
    }
    if (categoryOptions.length === 0) {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/faq/categories`, {
        headers: {
          Authorization: lsToken,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (!res.error) {
            setCategoryOptions(res.map((item) => (
              {
                value: item.id,
                label: item.name,
              }
            )));
          } else {
            showModalInfo('Ошибка при загрузке категорий', 'error');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка при загрузке категорий', 'error');
        });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      {match.params.id ? <h2 className="mt-2">Редактирование контента</h2> : (
        <>
          <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item">
              <a className="link" href="" onClick={(e) => { e.preventDefault(); history.push('/faq_knowledge'); }}>Вопросы и ответы</a>
            </li>
          </ul>
          <h1 className="page_title d-inline-block mr-3">Добавить контент</h1>
        </>
      )}
      <div className="card">
        <div className="card-body">
          <div className="form-group row">
            <div className="col-sm-6 col-xl-3">
              <label htmlFor="faqQuestion">Вопрос</label>
              <input
                value={data.question}
                type="text"
                className="form-control"
                id="faqQuestion"
                onChange={(e) => setData({ ...data, question: e.target.value })}
                placeholder=""
              />
            </div>
            <div className="col-sm-6 col-xl-3">
              <label htmlFor="faqCategory">Выбрать категорию вопроса</label>
              <Select
                classNamePrefix="react-select"
                styles={customSelectStyle()}
                name="faqCategory"
                placeholder="Выберите категорию"
                id="faqCategory"
                value={categoryOptions.filter((elem) => elem.value === categoryId)}
                onChange={handleCategoryChange}
                options={categoryOptions}
              />
            </div>
            <div className="col-sm-6 col-xl-3">
              <label htmlFor="theme">Выбрать кастомизацию</label>
              <Select
                isMulti
                classNamePrefix="react-select"
                styles={customSelectStyle()}
                name="theme"
                placeholder="Только для выбранных"
                id="theme"
                value={data.themes}
                onChange={(e) => setData({ ...data, themes: e })}
                options={themeOptions}
              />
            </div>
            <div className="col-sm-6 col-xl-2 mt-auto">
              <button
                className="btn btn-success mr-0"
                type="button"
                onClick={() => setData({ ...data, themes: themeOptions })}
              >Добавить все
              </button>
            </div>
            <div className="col-sm-6 col-xl-1">
              <label htmlFor="faqSort">Сортировка</label>
              <input
                value={data.sort}
                type="text"
                className="form-control"
                id="faqSort"
                onChange={(e) => setData({ ...data, sort: e.target.value })}
                placeholder=""
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <label htmlFor="courseText">Ответ</label>
              <Editor
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                  image: {
                    uploadEnabled: true,
                    uploadCallback: (file) => uploadImageFaq(file),
                    previewImage: true,
                  },
                }}
                stripPastedStyles
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="form-control"
                onEditorStateChange={setEditorState}
                toolbarStyle={{
                  position: 'sticky',
                  top: '70px',
                  zIndex: 2,
                }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Файлы</label>
              {data.files.length > 0 ? (
                <ul>
                  {data.files.map((file, index) => (
                    <li key={file.path}>
                      {file.path.split('/').slice(-1)[0].split('.')[0]} - {file.size} bytes <FontAwesomeIcon
                        onClick={() => removeFile(index)}
                        icon={faTrashAlt}
                        className="fa-fw"
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
              <Dropzone onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>Загрузить файл или картинку</p>
                      <p>Перетащить с помощью Drag'n'Drop</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <div className="form-check custom-checkbox">
                <input
                  checked={show}
                  className="form-check-input checkbox-styled"
                  onChange={showCheckHandler}
                  type="checkbox"
                  id="faqContentShow"
                />
                <label className="form-check-label" htmlFor="faqContentShow">
                  Показать
                </label>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-check custom-checkbox">
                <input
                  checked={data.first_level_only}
                  className="form-check-input checkbox-styled"
                  onChange={(e) => setData({ ...data, first_level_only: e.target.checked })}
                  type="checkbox"
                  id="firstLevelOnly"
                />
                <label className="form-check-label" htmlFor="firstLevelOnly">
                  Только для агентов 1 уровня
                </label>
              </div>
            </div>
          </div>
          <div className="form-grou row">
            <div className="col-12">
              <button
                type="submit"
                disabled={savingFlag}
                className="btn btn-success mr-2"
              >
                {match.params.id ? 'Сохранить' : 'Добавить'}
              </button>
              {match.params.id ? (
                <button
                  type="button"
                  disabled={savingFlag}
                  className="btn btn-secondary"
                  onClick={() => history.push('/admin?activeTab=19')}
                >
                  Отмена
                </button>
              ) : null}
              {savingFlag ? <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} /> : null}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
