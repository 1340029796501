import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faStar } from '@fortawesome/fontawesome-free-solid';
import { format, parseISO } from 'date-fns';
import ReactLoading from 'react-loading';
import Tooltip from 'rc-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faClone, faEnvelope } from '@fortawesome/fontawesome-free-regular';
import Modal from 'react-responsive-modal';
import { customFetch } from '../../Utils/Helpers';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import SimpleNotification from '../../Layout/FormNotifications/SimpleNotification';

const FileSaver = require('file-saver');

class CheckInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadingPolicyTemplate: false,
      paymentLink: null,
      emailForLink: this.props.formData.email.value,
      loadingPaymentLink: true,
      visibleTooltipCopyToClipboard: false,
      openModalSendLinkToEmail: false,
    };
  }

  componentDidMount() {
    const { showModalInfo } = this.context;
    window.scrollTo(0, 0);
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { policyId, choosenOffer } = this.props;
    this.setState({ loadingPaymentLink: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/covid/getPaymentLink`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        policyId,
        premium: choosenOffer.premium,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.paymentLink) {
          this.setState({ paymentLink: response.paymentLink });
        } else {
          showModalInfo('Ошибка');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ loadingPaymentLink: false });
      });
  }

  onCloseModalSendLinkToEmail = () => {
    this.setState({ openModalSendLinkToEmail: false });
  };

  handleChangeInput = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  sendLinkToEmail = (e) => {
    e.preventDefault();
    const {
      emailForLink,
      paymentLink,
    } = this.state;
    const {
      policyId,
      companies,
      choosenOffer,
    } = this.props;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/sendLinkToEmail`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailForLink,
        link: paymentLink,
        cost: choosenOffer.premium,
        policyId,
        company: companies[choosenOffer.polisOnlineCode].name,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        this.setState({ openModalSendLinkToEmail: false });
      });
  };

  handleChangeEmail = (e) => {
    this.setState({
      emailForLink: e.target.value,
    });
  };

  openModalSendLinkToEmail = () => {
    this.setState({
      openModalSendLinkToEmail: true,
    });
    this.submitPayment();
  };

  downloadPolicyTemplate = (premium) => {
    const { showModalInfo } = this.context;
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { policyId } = this.props;
    this.setState({ downloadingPolicyTemplate: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/covid/${policyId}/getPolicyTemplate`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ premium }),
    })
      .then((response) => response.blob())
      .then((response) => {
        FileSaver.saveAs(response, 'template.pdf');
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ downloadingPolicyTemplate: false });
      });
  };

  submitPayment = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { policyId, disableField } = this.props;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/${policyId}/processing`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then(() => {
        disableField();
      });
  };

  copyToClipBoard = () => {
    this.setState({ visibleTooltipCopyToClipboard: true },
      () => {
        setTimeout(() => {
          this.setState({ visibleTooltipCopyToClipboard: false });
        }, 3000);
      });
    this.submitPayment();
  };

  render() {
    const {
      openModalSendLinkToEmail,
      loadingPaymentLink,
      paymentLink,
      visibleTooltipCopyToClipboard,
      emailForLink,
      downloadingPolicyTemplate,
    } = this.state;
    const {
      formData,
      backToPickCompany,
      backToEditForm,
      choosenOffer,
      companies,
    } = this.props;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner' }}
          closeIconSize={16}
          open={openModalSendLinkToEmail}
          onClose={this.onCloseModalSendLinkToEmail}
          center
        >
          <form onSubmit={this.sendLinkToEmail}>
            <label htmlFor="inputEmail">Укажите адрес электронной почты</label>
            <input value={emailForLink} id="inputEmail" name="email" className="form-control mb-2" placeholder="Email" required="" type="email" onChange={this.handleChangeEmail} />
            <SimpleNotification>На указанный адрес отправлено письмо со ссылкой на оплату.</SimpleNotification>
            <button className="btn btn-success mt-2" type="submit">Отправить</button>
          </form>
        </Modal>
        <div className="search_results__main page--calck">
          <SimpleNotification offsetBottom="1rem">Отлично! Вы выбрали продукт от компании {companies[choosenOffer.polisOnlineCode].name}.</SimpleNotification>
          <SimpleNotification offsetBottom="1rem">Ваше вознаграждение: <b>{((choosenOffer.commission * choosenOffer.premium) / 100).toFixed(2)} рублей</b> = {choosenOffer.commission}%.</SimpleNotification>
          <SimpleNotification offsetBottom="1rem">Проверьте правильность введенных данных.</SimpleNotification>
          <div>
            <a rel="noopener noreferrer" href="#" onClick={backToPickCompany} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
          </div>
          <div className="mb-2 d-flex">
            <span className="mr-auto align-self-center">Введенные данные</span>
            <button rel="noopener noreferrer" href="#" onClick={backToEditForm} className="btn custom-btn">Редактировать</button>
          </div>
          <table className="data_table">
            <thead>
              <tr>
                <td className="data_table__row_title"><span className="color-light">Данные полиса</span></td>
                <td className="data_table__row_content">
                  <p>{companies[choosenOffer.polisOnlineCode].name} Коронавирус.НЕТ,</p>
                  <p><b>Дата начала:</b> {format(parseISO(formData.policyDate.value), 'dd.MM.yyyy')}, <b>Срок действия:</b> 12 месяцев</p>
                </td>
              </tr>
              <tr>
                <td className="data_table__row_title"><span className="color-light">Персональные данные страхователя</span>
                </td>
                <td className="data_table__row_content">
                  <p>
                    <b>Паспорт:</b> {formData.insurerPassportNumberSerie.value.substring(0, 4)} {formData.insurerPassportNumberSerie.value.substring(4)}, {format(parseISO(formData.insurerPassportDate.value), 'dd.MM.yyyy')}
                    <br />
                    {`${formData.lastNameInsurer.value} ${formData.firstNameInsurer.value} ${formData.middleNameInsurer.value}`}, {format(parseISO(formData.insurerBirthday.value), 'dd.MM.yyyy')}
                  </p>
                  <p><b>Адрес:</b> {formData.insurerAddress.value.value}</p>
                  <p><b>Контакты:</b> {formData.phone.value}, {formData.email.value}</p>
                </td>
              </tr>
              <tr>
                <td className="data_table__row_title"><span className="color-light">Данные застрахованных</span></td>
                <td className="data_table__row_content">
                  {formData.insuredPersons.map((insuredPerson, index) => (
                    <p key={index}>
                      {`${insuredPerson.lastNameInsured.value} ${insuredPerson.firstNameInsured.value} ${insuredPerson.middleNameInsured.value}`}, {format(parseISO(insuredPerson.insuredBirthday.value), 'dd.MM.yyyy')}
                    </p>
                  ))}
                </td>
              </tr>
            </thead>
          </table>
          <SimpleNotification>
            Для данного вида страхование возможно использование электронной версии полиса. После оплаты страховой премии вы получите электронную версию документа в формате pdf на указанный адрес e-mail: <b>{formData.email.value}</b>.
          </SimpleNotification>
        </div>
        <div className="sticky-top search_results__sidebar page--search js-search_results__sidebar">
          <div className="search_results__info" style={{ margintop: '0px' }}>
            <div className="product_block">
              <div className="product_block__title">
                <div className="product_block__title-pay">{choosenOffer.premium} руб.</div>
                <span className="product_block__title-name">Коронавирус.НЕТ</span>
              </div>
              <div className="product_block__body">
                <div className="product_block__image">
                  <img src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${companies[choosenOffer.polisOnlineCode].logo}`} width="142" height="62" alt="" />
                  <div className="company_item__star mt-2">
                    {[...Array(companies[choosenOffer.polisOnlineCode].rating)].map((e, i) => <FontAwesomeIcon key={i} icon={faStar} className="fa-fw" />)}
                  </div>
                </div>
                <div className="product_block__info">
                  <div className="product_block__info_title">Стоимость полиса</div>
                  <dl className="description_list">
                    <dt className="color-light">Полис</dt>
                    <dd><b>{choosenOffer.premium} руб.</b></dd>
                  </dl>
                  <div className="product_block__info_footer">
                    <dl className="description_list">
                      <dt className="color-light">Итого:</dt>
                      <dd><b>{choosenOffer.premium} руб.</b></dd>
                    </dl>
                  </div>
                </div>
                <div className="product_block__footer">
                  {loadingPaymentLink
                    ? (<><ReactLoading type="spin" className="loading-circle mr-1 d-inline-block" height={37} width={37} />Выполняется финальный расчет</>)
                    : (
                      <>
                        <a type="button" rel="noopener noreferrer" target="_blank" href={paymentLink} onClick={this.submitPayment} className="btn custom-btn mr-1">Оплатить</a>
                        <Tooltip
                          animation="zoom"
                          placement="top"
                          visible={visibleTooltipCopyToClipboard}
                          overlay={<span>Ссылка скопирована в буфер обмена</span>}
                          trigger=""
                          overlayClassName="custom-tooltip"
                        >
                          <CopyToClipboard text={paymentLink}>
                            <button type="button" title="Скопировать ссылку на оплату" onClick={this.copyToClipBoard} className="btn btn-outline btn-square sq mr-1">
                              <FontAwesomeIcon icon={faClone} className="fa-fw" />
                            </button>
                          </CopyToClipboard>
                        </Tooltip>
                        <button type="button" title="Отправить ссылку на оплату" onClick={this.openModalSendLinkToEmail} className="btn btn-outline btn-square">
                          <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
                        </button>
                        <div>
                          {downloadingPolicyTemplate
                            ? <ReactLoading className="loading-circle d-inline-block" type="spin" height={38} width={38} />
                            : <a role="button" tabIndex="0" onClick={() => this.downloadPolicyTemplate(choosenOffer.premium)} className="dotted_link">Скачать проект договора</a>}
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

CheckInfo.contextType = ModalMessagesContext;

export default CheckInfo;
