import React, { Component } from 'react';
import {
  TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import Modal from 'react-responsive-modal';
import queryString from 'query-string';
import ReactLoading from 'react-loading';
import BalanceTable from './BalanceTable';
import { customFetch, validateFields } from '../Utils/Helpers';
import { ModalMessagesContext } from '../Utils/ContextsServices/ModalMessagesService';
import getValidation from '../Utils/Validation';
import TabNavList from '../Layout/Tabs/TabNavList';
import TabNavItem from '../Layout/Tabs/TabNavItem';
import Button from '../Layout/Button';
import CustomIcon from '../Layout/CustomIcon';
import withUserInfoStoreStore from '../Utils/withUserInfoStoreStore';

class Balance extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      formData: {
        paymentAmount: {
          value: '',
          errorMessage: '',
          validationType: 'decimal',
          validationRequired: true,
        },
      },

      activeTab: '1',
      loading: false,
      balance: 0,
      openModalSure: false,
    };
  }

  componentDidMount() {
    document.title = 'Баланс и выплаты';
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    if (urlParams.activeTab) {
      this.setState({ activeTab: urlParams.activeTab });
    }
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/balance`, {
      method: 'get',
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((balance) => {
        this.setState({ balance });
      })
      .catch(() => {
      });
  }

  downloadReport = (e) => {
    e.preventDefault();
    this.child.current.downloadReport();
  };

  customizeTable = (e) => {
    e.preventDefault();
    this.child.current.customizeTable();
  };

  updateBalanceTable = () => {
    this.child.current.downloadPayments(null, null, true);
  }

  getAgreementPage = () => {
    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { showModalInfo } = this.context;
    this.setState({ loading: true });
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/alpha_bank/getAgreementPage`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error);
        } else {
          window.open(response.agreementPageLink, '_blank');
        }
      })
      .catch(() => {
        showModalInfo('Ошибка');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  createPayment = (e, passSelfEmployedValidation = false) => {
    const { showModalInfo } = this.context;
    const { balance } = this.state;
    const { userInfo } = this.props;
    if (balance <= 0) {
      showModalInfo('Ваш баланс меньше либо равен нулю');
    } else {
      this.setState({
        loading: true,
        openModalSure: false,
      });
      //if (!passSelfEmployedValidation && userInfo.agent_type_code === 'self_employed') {
      if (false) {
        if (userInfo.confirmed === 'yes') {
          const { formData } = this.state;
          const validatedFields = validateFields(formData);
          this.setState({
            formData: { ...validatedFields.result },
          });
          if (validatedFields.validationSuccess) {
            this.checkStatusInFNS()
              .then(() => {
                this.sendPaymentToCard();
              })
              .catch(() => {
                this.setState({
                  loading: false,
                  openModalSure: true,
                });
              });
          } else {
            this.setState({ loading: false });
          }
        } else {
          showModalInfo('Профиль не подтвержден вашим куратором');
          this.setState({ loading: false });
        }
      } else {
        const token = `Bearer ${localStorage.getItem('id_token')}`;
        const { formData } = this.state;
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/payments`, {
          method: 'post',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentSum: formData.paymentAmount.value,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              showModalInfo(response.error);
            } else {
              this.updateBalanceTable();
              showModalInfo('Заявка на выплату создана');
              this.setState({ balance: response.balance });
            }
          })
          .catch(() => {
            showModalInfo('Ошибка');
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      }
    }
  };

  sendPaymentToCard = () => {
    const { formData } = this.state;
    const { showModalInfo } = this.context;

    const token = `Bearer ${localStorage.getItem('id_token')}`;
    const { userInfo } = this.props;
    if (!userInfo.payment_card) {
      showModalInfo('Заполните номер карты');
      this.setState({ loading: false });
    } else {
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/agent/${userInfo.id}/execute-payment`, {
        method: 'post',
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentSum: formData.paymentAmount.value,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            showModalInfo(response.message);
            this.updateBalanceTable();
            this.setState((prevState) => ({ balance: prevState.balance - formData.paymentAmount.value }));
          } else {
            showModalInfo(response.error, 'error');
          }
        })
        .catch(() => {
          showModalInfo('Ошибка', 'error');
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  checkStatusInFNS = () => new Promise((resolve, reject) => {
    const { userInfo } = this.props;
    const { showModalInfo } = this.context;

    const token = `Bearer ${localStorage.getItem('id_token')}`;

    customFetch(`${process.env.REACT_APP_API_DOMAIN}/check-self-employed/${userInfo.id}`, {
      method: 'get',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          if (response.status) {
            resolve();
          } else {
            reject();
          }
        } else {
          showModalInfo(response.error, 'error');
          reject();
        }
      })
      .catch(() => {
        showModalInfo('Ошибка', 'error');
        reject();
      });
  });

  toggleModalSureWindow = (e) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    this.setState((prevState) => ({ openModalSure: !prevState.openModalSure }));
  };

  goToProfile = () => {
    const { history } = this.props;
    history.push('/profile');
  };

  handleValidateInput = (e) => {
    const fieldName = e.target.id;
    const { value } = e.target;
    const { formData } = this.state;
    const errorMessage = getValidation(value, formData[fieldName].validationType);
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [fieldName]: {
          ...prevState.formData[fieldName],
          errorMessage,
        },
      },
    }));
  };

  handleChangeInput = (e) => {
    e.preventDefault();
    const fieldName = e.target.id;
    const { value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [fieldName]: {
          ...prevState.formData[fieldName],
          value,
          errorMessage: '',
        },
      },
    }));
  };

  toggle(tab) {
    const { activeTab } = this.state;
    const { history } = this.props;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    history.push({ search: `?activeTab=${tab}` });
  }

  render() {
    const {
      location, history, userInfo,
    } = this.props;
    const {
      activeTab, loading, balance, openModalSure, formData,
    } = this.state;
    return (
      <>
        <Modal
          classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
          closeIconSize={16}
          open={openModalSure}
          onClose={this.toggleModalSureWindow}
          center
        >
          <h4>Вы не прошли подтверждение статуса самозанятого, поэтому для вас недоступны быстрые выплаты на карту.</h4>
          <h5>Можете подтвердить статус в разделе <span onClick={this.goToProfile} className="dotted_link">«Личные данные»</span>, или продолжить и оформить выплату через вашего куратора</h5>
          <button className="btn btn-success mr-2" type="button" onClick={(e) => this.createPayment(e, true)}>Продолжить</button>
          <button type="button" className="btn btn-secondary" onClick={this.toggleModalSureWindow}>Закрыть</button>
        </Modal>
        <div className="position-relative">
          {userInfo.agent_type_code === 'natural' ? (
            <div className="main-screen__container mb-2">
              <div className="main-screen__steps" name="steps">
                <h2 className="steps__title">Стать<br /> самозанятым<br /> просто</h2>
                <ol className="steps__list">
                  <li className="steps__item">
                    <h3 className="steps__item-title"><span className="steps__item-number">1</span>Регистрируйтесь<br /> самозанятым</h3>
                    <p className="steps__item-text">Регистрация займет 5 минут! Просто следуйте подробной <a rel="noreferrer" className="dotted_link" target="_blank" href={`${process.env.REACT_APP_BACKEND_DOMAIN}/storage/public/admin_documents/Инструкция для самозанятого.pdf`}>инструкции</a></p>
                  </li>
                  <li className="steps__item">
                    <h3 className="steps__item-title"><span className="steps__item-number">2</span>Поменяйте свой статус в сервисе</h3>
                    <p className="steps__item-text"> В разделе <a rel="noreferrer" className="dotted_link" target="_blank" href="" onClick={(e) => { e.preventDefault(); history.push('/profile'); }}>Личные данные</a> поменяйте статус на самозанятого, заполните платежные данные и дождитесь подтверждения аккаунта от вашего куратора </p>
                  </li>
                  <li className="steps__item steps__item--spacious">
                    <h3 className="steps__item-title"><span className="steps__item-number">3</span>Выводите деньги на карту<br /> моментально</h3>
                    <p className="steps__item-text"> Вам будет доступно повышенное вознаграждение, налоговый вычет от государства и быстрые выплаты на карту  </p>
                  </li>
                </ol>
              </div>
            </div>
          ) : null}
          <h1 className="page-wrapper__title d-inline-block mr-3">Баланс и выплаты</h1>
          <div className="page_header__control mb-2 mb-md-0 d-inline-flex">
            <Button colorType="grayBordered" type="border" width="auto" onClick={this.downloadReport}>
              <CustomIcon color="#737373" icon="excel" offsetRight="8px" />
              Скачать в Excel
            </Button>
            <Button colorType="grayBordered" type="border" width="auto" onClick={this.customizeTable}>
              <CustomIcon color="#737373" icon="settings" offsetRight="8px" />
              Настроить таблицу
            </Button>
          </div>
          {(userInfo.agent_type_code === 'individual' || userInfo.agent_type_code === 'legal' || userInfo.agent_type_code === 'self_employed') ? (
            <>
              <div className="card mb-3">
                <h2>Ваш баланс: {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(balance)} (сумма начисляется после вступления полиса в силу)</h2>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-12">
                    <label htmlFor="paymentAmount">{userInfo.agent_type_code === 'self_employed'
                      ? 'Разрешенная сумма выплаты - от 500 ₽ до 30000 ₽'
                      : 'Разрешенная сумма выплаты - от 500 ₽'}
                    </label>
                    <input
                      className={classnames('form-control mb-2', { error: formData.paymentAmount.errorMessage })}
                      value={formData.paymentAmount.value}
                      type="number"
                      id="paymentAmount"
                      name="paymentAmount"
                      placeholder="Сумма выплаты"
                      onBlur={this.handleValidateInput}
                      onChange={this.handleChangeInput}
                    />
                    {formData.paymentAmount.errorMessage && (
                      <div className="validation-error">
                        {formData.paymentAmount.errorMessage}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-sm-6 col-12">
                    <button type="button" disabled={loading} className="btn btn-success mb-3 mr-3" onClick={this.createPayment}>Запросить выплату</button>
                    {/*{!userInfo.alpha_bank_verified ? <button type="button" disabled={loading} className="btn btn-success mb-3" onClick={this.getAgreementPage}>Онбординга в Альфа-Банк</button> : null}*/}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {loading && <ReactLoading className="loading-circle ml-3 d-inline-block" type="spin" height={38} width={38} />}
          <TabNavList>
            <TabNavItem
              active={activeTab === '1'}
              onClick={() => { this.toggle('1'); }}
              text="Мои выплаты"
            />
            {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
              <TabNavItem
                active={activeTab === '2'}
                onClick={() => { this.toggle('2'); }}
                text="Все выплаты (админ)"
              />
            )}
          </TabNavList>
          <TabContent animation="false" activeTab={activeTab}>
            <TabPane tabId="1">
              { activeTab === '1' ? <BalanceTable ref={activeTab === '1' ? this.child : undefined} tab="1" location={location} history={history} /> : null }
            </TabPane>
            {(userInfo.role === 'admin' || userInfo.role === 'superadmin') && (
              <TabPane tabId="2">
                { activeTab === '2' ? <BalanceTable ref={activeTab === '2' ? this.child : undefined} tab="2" location={location} history={history} show="all" /> : null }
              </TabPane>
            )}
          </TabContent>
        </div>
      </>
    );
  }
}

Balance.contextType = ModalMessagesContext;

export default withUserInfoStoreStore(Balance);
