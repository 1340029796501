import React, { useMemo } from 'react';
import ReactLoading from 'react-loading';

export default function LoadingBanner(props) {
  const { loadingFlag, children } = props;

  const widthHeight = useMemo(() => {
    let loadWidthHeight = 80;
    if (window.innerWidth < 1024) {
      loadWidthHeight = 65;
    }
    return loadWidthHeight;
  }, []);

  return (
    <>
      {loadingFlag ? (
        <div className="loading-banner">
          <h2>Загрузка данных...</h2>
          <ReactLoading className="loading-circle ml-auto mr-auto" type="spin" width={widthHeight} height={widthHeight} />
        </div>
      ) : (
        <>
          {Array.isArray(children) ? (
            children.map((child) => child)
          ) : (
            children
          )}
        </>
      )}
    </>
  );
}
